import getKey from './getKey';

export function createSelectorForPaginationInfo(type, params) {
  const key = getKey(params);
  return state =>
    (state.pagination.requests[type] && state.pagination.requests[type][key]) || {
      loadedInitial: false,
      loading: false,
    };
}
