import React from 'react';
import { Paper } from '@mui/material';
import styled from '@emotion/styled';
const StyledPaper = styled(Paper) `
  border-radius: 8px;
  padding: ${props => (props.$noPadding ? 0 : 16)}px;
  box-shadow: none;

  &:hover {
    box-shadow: ${props => (props.$hover ? '0px 0px 8px rgba(0, 0, 0, 0.25)' : 'none')};
  }
`;
const PaperContainer = ({ children, style = {}, noPadding = false, hover = false }) => {
    return (React.createElement(StyledPaper, { "$hover": hover, "$noPadding": noPadding, style: style }, children));
};
export default PaperContainer;
