import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { DialogTitle } from '@mui/material';
import Box from '@mui/material/Box';
// @ts-ignore: #TODO-IMPLEMENT-TYPES-MATERIAL-COMPONENTS
import { Text } from '@dt/material-components';
const PolicyViolationExportToJiraDialogHeader = ({ title, handleClose }) => {
    return (React.createElement(DialogTitle, { style: {
            padding: 0,
        } },
        React.createElement(Box, { display: "flex", justifyContent: "space-between", style: { padding: '16px 24px' } },
            React.createElement(Text, { component: "div", variant: "titleM" }, title),
            React.createElement(CloseIcon, { onClick: handleClose, style: { cursor: 'pointer' } })),
        React.createElement("hr", { style: { margin: 0 } })));
};
export default PolicyViolationExportToJiraDialogHeader;
