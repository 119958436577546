import { values } from '@dt/functions';
import { AssetTypes } from '@dt/horizon-api';
import { createSelector } from 'reselect';

export const inventory_search = ({ inventory_search }) => inventory_search;

export const getCurrentSearchId = ({ inventory_search }) => inventory_search.lastQueryId;

const inventorySearchAssetTypes = ({ inventory_search }) => inventory_search.asset_types;

const inventorySearchCloudResourcesTypes = ({ inventory_search }) => inventory_search.cloud_resource_types;

const inventorySearchCloudProviderList = ({ inventory_search }) => inventory_search.cloudProviderList;

const inventorySearchQuery = ({ inventory_search }) => inventory_search.query;

export const getResultsOfSearch = (lastSearchId, state) => {
  // If there is a search, return the filtered list. Otherwise, return all the elements
  return lastSearchId
    ? (state.for_search_id[lastSearchId] || []).reduce((ids, foundId) => {
        ids[foundId] = state.id[foundId];
        return ids;
      }, {})
    : state.id;
};

export const getInventorySearchAssetList = createSelector(
  inventorySearchAssetTypes,
  inventorySearchCloudResourcesTypes,
  inventorySearchQuery,
  (assetTypes, cloudResourcesTypes, query) => {
    return assetTypes.reduce((assetList, asset) => {
      if (asset.enum === AssetTypes.CLOUD_RESOURCE && cloudResourcesTypes.for_category) {
        const catgeories = values(cloudResourcesTypes.for_category).reduce((categories, category) => {
          const categoryChildren = category.ids.reduce((cloudResourceTypesForCategory, cloudResourceTypeId) => {
            cloudResourceTypesForCategory.push({
              icon: cloudResourcesTypes.ids[cloudResourceTypeId].icon_url,
              id: cloudResourcesTypes.ids[cloudResourceTypeId].enum,
              name: cloudResourcesTypes.ids[cloudResourceTypeId].name,
              selected:
                (query.cloud_resource_types &&
                  Boolean(query.cloud_resource_types.includes(cloudResourcesTypes.ids[cloudResourceTypeId].enum))) ||
                false,
            });
            return cloudResourceTypesForCategory;
          }, []);
          const anySelected = Boolean(categoryChildren.filter(item => item.selected).length);
          const allSelected = Boolean(!categoryChildren.filter(item => !item.selected).length);
          if (category.enum === 'OTHER') {
            categories.push({
              allSelected: Boolean(anySelected && allSelected),
              anySelected,
              children: categoryChildren,
              id: category.enum,
              name: category.name,
            });
          } else {
            categories.unshift({
              allSelected: Boolean(anySelected && allSelected),
              anySelected,
              children: categoryChildren,
              id: category.enum,
              name: category.name,
            });
          }
          return categories;
        }, []);
        const anyChildrenSelected = Boolean(catgeories.filter(item => item.anySelected).length);
        const allChildrenSelected = Boolean(!catgeories.filter(item => !item.allSelected).length);
        assetList.push({
          allSelected: allChildrenSelected,
          anySelected: anyChildrenSelected,
          children: catgeories,
          id: asset.enum,
          name: asset.name,
        });
      } else {
        assetList.push({
          id: asset.enum,
          name: asset.name,
          selected: Boolean(query.asset_types && query.asset_types.includes(asset.enum)),
        });
      }

      return assetList;
    }, []);
  },
);

export const getInventorySearchEnvironmentsList = createSelector(
  inventory_search,
  inventorySearchQuery,
  inventorySearchQuery,
  (inventory_search, query) => {
    return inventory_search.cloudProviderList.reduce((list, provider) => {
      return list.concat({
        id: provider.enum,
        name: provider.name,
        selected:
          query.hosted_on_cloud_providers && query.hosted_on_cloud_providers.includes(provider.enum) ? true : false,
      });
    }, []);
  },
);

export const getSearchedText = createSelector(inventory_search, () => {});

export const getSelectedAssetsCount = createSelector(
  inventorySearchAssetTypes,
  inventorySearchCloudResourcesTypes,
  inventorySearchQuery,
  (assetTypes, cloudResourcesTypes, query) => {
    const totalCloudResourceTypes = cloudResourcesTypes.ids.length;
    const totalSelectedCloudTypes = query.cloud_resource_types.length;

    const totalAssetTypes = assetTypes.length;
    const totalSelectedAssets = query.asset_types && query.asset_types.length;

    if (totalSelectedCloudTypes + totalSelectedAssets === 0) {
      return 'any';
    } else if (totalCloudResourceTypes === totalSelectedCloudTypes && totalAssetTypes === totalSelectedAssets) {
      return 'all';
    } else if (totalSelectedAssets && totalSelectedCloudTypes === 0) {
      return totalSelectedAssets;
    } else if (totalSelectedAssets && totalSelectedCloudTypes !== 0) {
      return Number(totalSelectedAssets) - 1 + totalSelectedCloudTypes;
    }

    return 'any';
  },
);

export const getSelectedEnvironmentsCount = createSelector(
  inventorySearchCloudProviderList,
  inventorySearchQuery,
  (CloudProviders, query) => {
    const totalCloudProviders = CloudProviders.length;
    const totalSelectedCloudProviders = query.hosted_on_cloud_providers.length;

    if (!totalSelectedCloudProviders) {
      return 'any';
    } else if (totalSelectedCloudProviders && totalCloudProviders === totalSelectedCloudProviders) {
      return 'all';
    } else {
      return totalSelectedCloudProviders;
    }
  },
);

export const getServerLessCloudResourceTypes = createSelector(inventorySearchCloudResourcesTypes, cloudResourcesTypes =>
  cloudResourcesTypes.ids.filter(({ category_enum }) => category_enum === 'SERVERLESS').map(item => item.enum),
);
