import React from 'react';
import { Button, CircularProgress, DialogActions, DialogContent, Box, useTheme } from '@mui/material';
// @ts-ignore: #TODO-IMPLEMENT-TYPES-MATERIAL-COMPONENTS
import { Text } from '@dt/material-components';
import PolicyViolationExportToJiraDialogHeader from './PolicyViolationExportToJiraDialogHeader';
const PolicyViolationExportToJiraDialogStep2 = ({ handleClose, handleNext, jiraIntegrationName, policyViolationName, errorMessage, loading, }) => {
    const { palette } = useTheme();
    return (React.createElement(React.Fragment, null,
        React.createElement(PolicyViolationExportToJiraDialogHeader, { handleClose: handleClose, title: "A new Jira ticket is going to be created:" }),
        React.createElement(DialogContent, null,
            React.createElement(Box, { display: "flex", flexDirection: "column", mb: 2, mt: 2 },
                React.createElement(Text, { component: "span", variant: "body" }, `Policy violation: ${policyViolationName}`),
                React.createElement(Text, { component: "span", variant: "body" }, `Jira integration: ${jiraIntegrationName}`)),
            errorMessage && (React.createElement(Box, { bgcolor: palette.red[50], mt: 2, padding: 1 },
                React.createElement(Text, { component: "span", mb: 1, style: {
                        color: palette.red[10],
                    }, variant: "body" }, errorMessage)))),
        React.createElement(DialogActions, null,
            React.createElement(Button, { onClick: handleClose }, "Cancel"),
            React.createElement(Button, { color: "primary", disabled: loading, onClick: handleNext, startIcon: loading ? React.createElement(CircularProgress, { size: 18 }) : null }, "Confirm"))));
};
export default PolicyViolationExportToJiraDialogStep2;
