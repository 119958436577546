import React, { useEffect } from 'react';
// @ts-ignore: @reach/router types
import { useLocation } from '@reach/router';
const ScrollToTopOnNavigate = () => {
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    return React.createElement(React.Fragment, null);
};
export default ScrollToTopOnNavigate;
