// sort-imports-ignore
import { ApolloProvider, InMemoryCache } from '@apollo/client';
import { TrackingProvider } from '@dt/analytics';
import { ErrorBoundary } from '@dt/components';
import '@dt/global';
import { initialize } from '@dt/session';
import { materialTheme } from '@dt/theme';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocationProvider, createHistory, createMemorySource } from '@reach/router';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React, { memo } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';
import 'reactflow/dist/style.css';
import ApplicationRouting from './ApplicationRouting';
import { createApolloClient } from './apollo';
import { getStore } from './redux';
import { getMiddleware, runSagas } from './redux/sagas';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      staleTime: 50000,
    },
  },
});

/*
 * Root providers for the application.
 *
 * Think of this kindof like an IoC container providing base functionality for the rest of the application.
 * This component is used to bootstrap the application and to bootstrap the testing environment.
 *
 * Sets up the following:
 * - Redux
 * - Apollo client (graphql)
 * - Material UI
 * - Analytics & tracking
 *
 * @param graphQLMocks - Mocks apollo client resolvers.
 * @param initialRoute - Starting navigational context - Used for testing.
 * @param children - Sub compoennts to render.
 */
export const ApplicationProviders = function ApplicationProviders({ graphQLMocks, initialRoute, children }) {
  // Setup redux store.
  const reduxSaga = getMiddleware();
  const store = getStore([reduxSaga]);
  global.__DT_STORE = store;
  runSagas(reduxSaga);

  // Setup apollo cache.
  const cache = new InMemoryCache({
    typePolicies: {
      PaginationInformation: {
        keyFields: false,
      },
      V2PolicyRulesListResponsePolicyRule: {
        // NOTE: Used to uniquely identify policy rules.
        //       Stats have to be included because this type isn't a true entity type.
        //       Meaning it changes based on the query params to the endpoint.
        keyFields: ['id', 'included_policy_violation_stats'],
      },
    },
  });
  global.__DT_APOLLO_CACHE = cache;

  // Setup apollo client.
  const client = createApolloClient(cache, graphQLMocks);

  // Setup material UI.
  const muiTheme = createTheme(materialTheme());

  // Setup navigational router.
  const history = createHistory(!initialRoute ? window : createMemorySource(initialRoute));

  return (
    <QueryClientProvider client={queryClient}>
      <LocationProvider history={history}>
        <ReduxProvider store={store}>
          <ApolloProvider client={client}>
            <ThemeProvider theme={muiTheme}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TrackingProvider>{children}</TrackingProvider>
              </LocalizationProvider>
            </ThemeProvider>
          </ApolloProvider>
        </ReduxProvider>
      </LocationProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};

/*
 * Application entry point.
 *
 * Sets up the following:
 * - Root providers
 * - Routing for pages
 *
 * @param initialRoute - Starting navigational context - Used for testing.
 * @param graphQLMocks - Mocks apollo client resolvers.
 */
export const Application = memo(function Application({ initialRoute, graphQLMocks }) {
  return (
    <ApplicationProviders graphQLMocks={graphQLMocks} initialRoute={initialRoute}>
      <ApplicationRouting />
    </ApplicationProviders>
  );
});

/*
 * Runs the application.
 */
export const main = function main() {
  // Single page application DOM container.
  const domContainer = document.getElementById('react');
  if (!domContainer) {
    // Should never happen.
    alert(`Couldn't start the application. Clear cache and refresh.`);
    console.error(`There is no DOM element with id=react!`);
    return;
  }

  // Request authorization.
  initialize();

  // Render the application to the DOM container.
  const root = createRoot(domContainer);
  root.render(
    <ErrorBoundary>
      <Application />
    </ErrorBoundary>,
  );
};
