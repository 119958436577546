import React, { memo } from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import TableCell from '@mui/material/TableCell';
import AssetHostedOnTag from './AssetHostedOnTag';

const AssetTableCellHostedOn = props => {
  if (props.loading) {
    return (
      <TableCell>
        <Box alignItems={'center'} display={'flex'}>
          <Skeleton animation="wave" height={40} variant="text" width={100} />
        </Box>
      </TableCell>
    );
  }

  const { name, iconUrl, maxWidth } = props;

  return (
    <TableCell>
      <AssetHostedOnTag iconUrl={iconUrl} maxWidth={maxWidth} name={name} />
    </TableCell>
  );
};

export default memo(AssetTableCellHostedOn);
