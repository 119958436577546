import { format as formatDate } from 'date-fns';
import immer from 'immer';
import { dateFormats } from '../../util/dateFormats';
import { formatDateDefault } from '../../util/formatDateDefault';
import {
  detectInjectAtLeastOneOperationSelected,
  detectInjectConfigToggleMethodClicked,
  detectInjectExcludeAllOperectionsClicked,
  detectInjectExcludedOperectionIdsReceived,
  detectInjectIncludeAllOperectionsClicked,
  detectInjectMounted,
  detectInjectResetClicked,
  detectInjectStartDetectClicked,
  detectInjectStartScanClicked,
  detectInjectStatusRecived,
  detectInjectToggleOperectionClicked,
  detectInjectViewResultsClicked,
  detectInjectViewSampleResultsClicked,
  scheduleComponentClicked,
} from './actions';
import { DETECT_INJECT_POLICY_RULE_TYPE_ID } from './types';

const initialState = {
  configuration: {
    method: {
      DELETE: true,
      GET: true,
      HEAD: false,
      OPTIONS: false,
      PATCH: true,
      POST: true,
      PUT: true,
      TRACE: false,
      UNKNOWN: true,
      X_HORIZON_UNDEFINED_HTTP_METHOD: false,
    },
  },
  detect: { enabled: false },
  excludedOperations: {
    list: {},
  },
  includedOperations: {
    all: false,
    list: {},
  },

  inject: { enabled: false },

  results: {
    enabled: false,
    sampleClicked: false,
  },

  scanning: false,

  schedule: { clicked: false },

  targetRuleTypeUUID: DETECT_INJECT_POLICY_RULE_TYPE_ID,
};

export default immer((draft, action) => {
  if (action.type === detectInjectMounted.toString()) {
    draft.detect = { enabled: true, started: false };
    draft.inject = { enabled: false };
    draft.schedule = { clicked: false };

    if (draft.results.enabled) {
      draft.results.clicked = false;
    }

    draft.configuration.method['GET'] = true;
    draft.configuration.method['POST'] = true;
    draft.configuration.method['PUT'] = true;
    draft.configuration.method['DELETE'] = true;
    draft.configuration.method['PATCH'] = true;
    draft.configuration.method['UNKNOWN'] = true;

    draft.includedOperations = {
      all: false,
      list: {},
    };
  }

  if (action.type === detectInjectResetClicked.toString()) {
    draft.detect = { enabled: true, started: false };
    draft.inject = { enabled: false };
    draft.schedule = { clicked: false };

    if (draft.results.enabled) {
      draft.results.clicked = false;
    }
    draft.results.sampleClicked = false;

    draft.configuration.method['GET'] = true;
    draft.configuration.method['POST'] = true;
    draft.configuration.method['PUT'] = true;
    draft.configuration.method['DELETE'] = true;
    draft.configuration.method['PATCH'] = true;
    draft.configuration.method['UNKNOWN'] = true;

    draft.includedOperations = {
      all: false,
      list: {},
    };
  }

  if (action.type === detectInjectStatusRecived.toString()) {
    const { status, date_created, date_completed } = action.payload;
    if (status === 'ONGOING') {
      draft.detect = { enabled: false };
      draft.results = { enabled: false, sampleClicked: false };
      draft.inject = { enabled: false };
      draft.scanning = true;
    } else if (status === 'NOT_STARTED') {
      draft.detect = { enabled: true, started: false };
      if (draft.results.enabled) {
        draft.results.clicked = false;
      }
      draft.inject = { enabled: false };
    } else if (status === 'FINISHED' && isToday(date_created)) {
      draft.detect = { enabled: false };
      draft.results = {
        clicked: false,
        date_completed,
        enabled: true,
        sampleClicked: false,
      };
      draft.inject = { enabled: false };
    } else if (status === 'FINISHED') {
      draft.detect = { enabled: true, started: false };
      draft.results = {
        clicked: false,
        date_completed,
        enabled: true,
        sampleClicked: false,
      };
      draft.inject = { enabled: false };
    } else {
      draft.detect = { enabled: true, started: false };
      draft.results = { enabled: false, sampleClicked: false };
      draft.inject = { enabled: true, started: false };
    }
  }

  if (action.type === scheduleComponentClicked.toString()) {
    const { clicked } = action.payload;
    draft.detect = { enabled: true, started: false };
    draft.inject = { enabled: false };
    draft.schedule = { clicked: clicked };

    if (draft.results.enabled) {
      draft.results.clicked = false;
    }

    draft.configuration.method['GET'] = true;
    draft.configuration.method['POST'] = true;
    draft.configuration.method['PUT'] = true;
    draft.configuration.method['DELETE'] = true;
    draft.configuration.method['PATCH'] = true;
    draft.configuration.method['UNKNOWN'] = true;

    draft.includedOperations = {
      all: false,
      list: {},
    };
  }

  if (action.type === detectInjectStartDetectClicked.toString()) {
    draft.detect = { enabled: true, started: true };
    draft.inject = { enabled: false };
    draft.schedule = { clicked: false };
    if (draft.results.enabled) {
      draft.results.clicked = false;
    }
  }

  if (action.type === detectInjectStartScanClicked.toString()) {
    draft.detect = { enabled: false };
    draft.inject = { enabled: false };
    draft.schedule = { clicked: false };
    if (draft.results.enabled) {
      draft.results.clicked = false;
    }
    draft.scanning = true;
  }

  if (action.type === detectInjectAtLeastOneOperationSelected.toString()) {
    const enabled = action.payload;
    draft.inject = enabled ? { enabled: true, started: false } : { enabled: false };
  }

  if (action.type === detectInjectViewResultsClicked.toString()) {
    draft.schedule = { clicked: false };
    if (draft.detect.enabled) {
      draft.detect.started = false;
    }
    if (draft.results.enabled) {
      draft.results.clicked = true;
    }
    draft.results.sampleClicked = false;
  }

  if (action.type === detectInjectViewSampleResultsClicked.toString()) {
    draft.schedule = { clicked: false };
    if (draft.detect.enabled) {
      draft.detect.started = false;
    }
    if (draft.results.enabled) {
      draft.results.clicked = false;
    }
    draft.results.sampleClicked = true;
  }

  if (action.type === detectInjectConfigToggleMethodClicked.toString()) {
    const methodType = action.payload;
    draft.configuration.method[methodType] = !draft.configuration.method[methodType];
    draft.includedOperations.all = false;
  }

  if (action.type === detectInjectExcludedOperectionIdsReceived.toString()) {
    const operationIds = action.payload;
    if (operationIds.length) {
      operationIds.map(id => {
        draft.excludedOperations.list[id] = true;
      });
    } else {
      draft.excludedOperations.list = {};
    }
  }

  if (action.type === detectInjectToggleOperectionClicked.toString()) {
    const operationId = action.payload;
    draft.includedOperations.list[operationId] = !draft.includedOperations.list[operationId];
  }

  if (action.type === detectInjectIncludeAllOperectionsClicked.toString()) {
    draft.includedOperations = {
      all: true,
      list: {},
    };
    draft.excludedOperations = {
      list: {},
    };
  }

  if (action.type === detectInjectExcludeAllOperectionsClicked.toString()) {
    draft.includedOperations = {
      all: false,
      list: {},
    };
    draft.excludedOperations = {
      list: {},
    };
  }
}, initialState);

function isToday(completed_date) {
  return completed_date &&
    formatDateDefault({ date: completed_date, formatStr: dateFormats.yyyyMMdd }) ===
      formatDate(new Date(), dateFormats.yyyyMMdd)
    ? true
    : false;
}
