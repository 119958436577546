import React, { memo } from 'react';
import ArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { ListItemButton, ListItemSecondaryAction, ListItemText } from '@mui/material';

const AssetFilterSelectLabel = ({ title, filterType, selected, disabled, onClick }) => {
  return (
    <ListItemButton disabled={disabled} onClick={() => onClick(filterType)} selected={selected}>
      <ListItemText primary={title} />
      <ListItemSecondaryAction>
        <ArrowRight />
      </ListItemSecondaryAction>
    </ListItemButton>
  );
};

export default memo(AssetFilterSelectLabel);
