// sort-imports-ignore
import { Raven } from '@dt/global';
import { put, spawn, all, takeEvery, select, call } from 'redux-saga/effects';
import { notification_preferences } from '@dt/user-api/current_user';
import { callPromise } from '@dt/redux-saga-wrapped-effects';
import { createAction } from 'redux-actions';
import { withProgressIndicator } from '@dt/progress-indicator';
import { anErrorOccurred } from '../actions';
import { setNotificationPreferences } from '../reducers/preferences';
import tracking, { dataCreators } from '@dt/analytics';
import merge from 'lodash/fp/merge';

function* getNotificationPreferencesSaga() {
  yield* withProgressIndicator(function* () {
    try {
      const notificationpreferences = yield* callPromise(notification_preferences.get);

      // @remove: once https://datatheorem.atlassian.net/browse/SASB-783 is completed, we don't need to support old schema anymore
      if (notificationpreferences) {
        yield put(setNotificationPreferences(notificationpreferences));
      }
    } catch (e) {
      // @sourcetheorem.com emails will return 404... we should keep the train moving regardless
    }
  });
}

function* patchNotificationPreferencesSaga(action) {
  yield* withProgressIndicator(function* () {
    const currentValue = yield select(state => state.preferences.notification);

    try {
      yield put(setNotificationPreferences(merge(currentValue, action.payload)));
      yield* callPromise(notification_preferences.patch, action.payload);

      yield call(tracking, dataCreators.preferencesNotificationEdited());
    } catch (e) {
      // Raven sees it all
      Raven.captureException(e);
      // Revert back optimistic ui because it failed
      yield put(setNotificationPreferences(currentValue));

      yield put(anErrorOccurred(`An error occurred while trying to update your notifications: ${e.message}`));
    }
  });
}

function* watchForNotificationPreferences() {
  yield all([
    takeEvery(getNotificationPreferences.toString(), getNotificationPreferencesSaga),
    takeEvery(patchNotificationPreferences.toString(), patchNotificationPreferencesSaga),
  ]);
}

export function* preferencesFlow() {
  yield spawn(watchForNotificationPreferences);
}

export const getNotificationPreferences = createAction('GET_CURRENT_NOTIFICATION_PREFERENCES');

export const patchNotificationPreferences = createAction(
  'PATCH_CURRENT_NOTIFICATION_PREFERENCES',
  notification_preferences => notification_preferences,
);
