import React from 'react';
import LockIcon from '@mui/icons-material/Lock';
import { Avatar, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { ExtLink, Text } from '@dt/material-components';

const UnauthorizedErrorComponent = function UnauthorizedError() {
  const { palette } = useTheme();
  return (
    <Box alignItems="center" display="flex" flexDirection="column" height="50vh" justifyContent="center" width="100%">
      <Avatar
        sx={{
          backgroundColor: palette.blue[50],
          color: palette.blue[10],
          height: 80,
          width: 80,
        }}
      >
        <LockIcon sx={{ height: 50, width: 45 }} />
      </Avatar>
      <Text variant="titleM">Unauthorized</Text>
      <Text variant="body">You aren&apos;t authorized to access this content.</Text>
      <Text variant="body">
        <span
          onClick={() => {
            history.back();
          }}
          style={{ cursor: 'pointer' }}
        >
          <Text variant="link">Go back </Text>
        </span>
        or head to the
        <ExtLink aria-label="Go back" target="_self" to="/">
          {' '}
          homepage{' '}
        </ExtLink>
        to find a new direction.
      </Text>
    </Box>
  );
};

export const UnauthorizedError = UnauthorizedErrorComponent;
