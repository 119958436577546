import React, { memo } from 'react';
import ApiIcon from '@mui/icons-material/DeviceHub';
import APIDomainIcon from '@mui/icons-material/Language';
import WebAppIcon from '@mui/icons-material/Web';
import { Avatar, Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AssetTypeEnumValues } from '@dt/graphql-support/enums';
import { palette } from '@dt/theme';
import { getDisplayName } from './AssetsDisplayName';

const useStyles = makeStyles({
  domain: props => ({
    background: props.bgColor || palette.teal,
  }),
  dtAssetAvatar: props => ({
    background: palette.gray20,
    height: props.iconSize ? props.iconSize : 36,
    width: props.iconSize ? props.iconSize : 36,
  }),
  materialIcon: props => ({
    color: palette.white,
    height: props.iconSize ? props.iconSize * 0.66 : 24,
    width: props.iconSize ? props.iconSize * 0.66 : 24,
  }),
});

const LookupAssetAvatarIcon = Object.freeze({
  [AssetTypeEnumValues.WEB_APPLICATION]: ({ classes }) => (
    <Avatar className={classes.dtAssetAvatar} style={{ background: palette.brown }}>
      <WebAppIcon className={classes.materialIcon} />
    </Avatar>
  ),
  [AssetTypeEnumValues.RESTFUL_API]: ({ classes }) => (
    <Avatar className={classes.dtAssetAvatar} style={{ background: palette.orange }}>
      <ApiIcon className={classes.materialIcon} />
    </Avatar>
  ),
  [AssetTypeEnumValues.DOMAIN_NAME]: ({ classes }) => (
    <Avatar className={`${classes.dtAssetAvatar} ${classes.domain}`}>
      <APIDomainIcon className={classes.materialIcon} />
    </Avatar>
  ),
  [AssetTypeEnumValues.NETWORK_SERVICE]: ({ classes }) => (
    <Avatar className={`${classes.dtAssetAvatar} ${classes.domain}`}>
      <APIDomainIcon className={classes.materialIcon} />
    </Avatar>
  ),
  [AssetTypeEnumValues.API_OPERATION]: () => null,
  [AssetTypeEnumValues.GRPC_METHOD]: () => null,
  [AssetTypeEnumValues.GRPC_SERVICE]: () => null,
  [AssetTypeEnumValues.KUBERNETES_CLUSTER]: () => null,
  [AssetTypeEnumValues.KUBERNETES_CLUSTER_COMPONENT]: () => null,
  [AssetTypeEnumValues.SOAP_API]: () => null,
  [AssetTypeEnumValues.SOAP_API_OPERATION]: () => null,
  [AssetTypeEnumValues.GRAPHQL_API]: () => null,
  [AssetTypeEnumValues.CLOUD_RESOURCE]: () => null,
  [AssetTypeEnumValues.MOBILE_APPLICATION]: () => null,
  [AssetTypeEnumValues.MOBILE_SDK]: () => null,
  [AssetTypeEnumValues.REPOSITORY]: () => null,
  [AssetTypeEnumValues.CLOUD_IDENTITY]: () => null,
});

const AssetTypeAvatarComponent = function AssetTypeAvatar(props) {
  const { asset_type } = props;
  const classes = useStyles(props);

  const icon = LookupAssetAvatarIcon[asset_type]({ classes });

  return (
    <Tooltip disableInteractive title={getDisplayName(asset_type)}>
      {/* Span is used for Tooltip to register content area. */}
      <span>{icon}</span>
    </Tooltip>
  );
};

export const AssetsTypeAvatar = memo(AssetTypeAvatarComponent);
