import React, { useState } from 'react';
import Box from '@mui/material/Box';
// @ts-ignore Ignore JS Files
import { InventoryTableCard, inventoryTableColumnEnum } from '../../../components';
// @ts-ignore
import { ShadowAssetsLayoutTriageButton } from '../../../layouts/shadow_assets/ShadowAssetsLayoutTriageButton';
const ApiShadowAssetsPageTable = ({ filter_by_named_filter }) => {
    const [checkedIds, setCheckedIds] = useState([]);
    const readOnlyFilters = {
        filter_by_is_shadow: true,
        filter_by_named_filter: filter_by_named_filter || null,
    };
    const handleChangeSelection = (ids) => {
        setCheckedIds(ids);
    };
    return (React.createElement(InventoryTableCard, { checkboxSelection: true, checkedIds: checkedIds, columns: [
            inventoryTableColumnEnum.asset_type_icon_url,
            inventoryTableColumnEnum.asset_name,
            inventoryTableColumnEnum.policy_violations,
            inventoryTableColumnEnum.hosted_on,
            inventoryTableColumnEnum.discovered_via,
            inventoryTableColumnEnum.discovered_date,
            inventoryTableColumnEnum.shadow_reasons,
        ], initialFilterValues: readOnlyFilters, isV2: true, onChangeSelection: handleChangeSelection, productBasePath: '/api', readOnlyFilters: readOnlyFilters, renderAction: React.createElement(Box, { ml: 1 },
            React.createElement(ShadowAssetsLayoutTriageButton, { checkedIds: checkedIds })) }));
};
export default ApiShadowAssetsPageTable;
