import React, { memo } from 'react';
import { Grid, Breadcrumbs as MuiBreadcumbs, Typography } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { fontFamilies } from '@dt/theme';
import { Link } from '@reach/router';
import ExtLink from './ExtLink';

/*
 * Allows the user to navigate to previous pages related to the current page.
 *
 * @param isLoading - Shows a loading state for the provided locations
 * @param locations - List of crumbs
 * @param locations.title - Crumb title to render
 * @param locations.currentPath - Crumb is the current path, usually the last crumb
 * @param locations.path - Crumb routing information to route the user to
 * @param locations.isExternalLink - Whether or not to use ExtLink or not.
 *                                   TODO@nw: When apps are merged this should be deprecated.
 */
const BreadcrumbsComponent = function Breadcrumbs({ isLoading, locations }) {
  return (
    <Grid container>
      <MuiBreadcumbs aria-label="breadcrumb">
        {locations.map((location, i) => {
          if (isLoading) {
            // Calculate the width the text will be after its done loading.
            // Assumes that the provided text will be the same after loading :)
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            context.font = `16px ${fontFamilies.primary}`;
            // Unable to calculate height here.
            // See https://stackoverflow.com/a/45789011/422312
            const size = context.measureText(location.title);

            return (
              <Skeleton
                animation="pulse"
                data-testid={`breadcrumbsLoading_${location.title}`}
                height={26}
                key={i}
                style={{ marginRight: 8 }}
                variant="text"
                width={size.width}
              />
            );
          }

          if (location.path) {
            return !location.isExternalLink ? (
              <Link key={i} to={location.path}>
                <Typography color="textSecondary">{location.title}</Typography>
              </Link>
            ) : (
              <ExtLink key={i} target="_self" to={location.path}>
                <Typography color="textSecondary">{location.title}</Typography>
              </ExtLink>
            );
          }

          return (
            <Typography color="textPrimary" key={i}>
              {location.title}
            </Typography>
          );
        })}
      </MuiBreadcumbs>
    </Grid>
  );
};

export const Breadcrumbs = memo(BreadcrumbsComponent);
