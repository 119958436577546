import React from 'react';
import CodeIcon from '@mui/icons-material/Code';
import PuzzleIcon from '@mui/icons-material/Extension';
import FontIcon from '@mui/icons-material/FontDownload';
import SocketIcon from '@mui/icons-material/ImportExport';
import DocumentIcon from '@mui/icons-material/InsertDriveFile';
import ImageIcon from '@mui/icons-material/InsertPhoto';
import XHRIcon from '@mui/icons-material/SettingsEthernet';
import StyleIcon from '@mui/icons-material/Style';
import { Typography } from '@mui/material';
import { WebRequestTypeEnum } from '@dt/horizon-api';
import { palette } from '@dt/theme';

export const WebApplicationNetworkActivityIcon = props => {
  const activity_type = props.network_activity_type;

  const label =
    {
      [WebRequestTypeEnum.FETCH]: 'Fetch calls',
      [WebRequestTypeEnum.IMAGE]: 'Images download',
      [WebRequestTypeEnum.FONT]: 'Fonts download',
      [WebRequestTypeEnum.SCRIPT]: 'Scripts download',
      [WebRequestTypeEnum.STYLESHEET]: 'Stylesheets download',
      [WebRequestTypeEnum.XHR]: 'XHR calls',
      [WebRequestTypeEnum.MANIFEST]: 'Manifest download',
      [WebRequestTypeEnum.TEXTTRACK]: 'Texts download',
      [WebRequestTypeEnum.WEBSOCKET]: 'Websocket connection',
      [WebRequestTypeEnum.MEDIA]: 'Media download',
      [WebRequestTypeEnum.OTHER]: 'Unknown',
      [WebRequestTypeEnum.DOCUMENT]: 'Documents download',
      [WebRequestTypeEnum.EVENTSOURCE]: 'Event source',
    }[activity_type] || 'Assets download';

  const Icon =
    {
      [WebRequestTypeEnum.FETCH]: XHRIcon,
      [WebRequestTypeEnum.SCRIPT]: CodeIcon,
      [WebRequestTypeEnum.STYLESHEET]: StyleIcon,
      [WebRequestTypeEnum.IMAGE]: ImageIcon,
      [WebRequestTypeEnum.FONT]: FontIcon,
      [WebRequestTypeEnum.XHR]: XHRIcon,

      [WebRequestTypeEnum.MANIFEST]: DocumentIcon,
      [WebRequestTypeEnum.TEXTTRACK]: DocumentIcon,
      [WebRequestTypeEnum.WEBSOCKET]: SocketIcon,
      [WebRequestTypeEnum.MEDIA]: ImageIcon,
      [WebRequestTypeEnum.OTHER]: PuzzleIcon,
      [WebRequestTypeEnum.DOCUMENT]: DocumentIcon,
      [WebRequestTypeEnum.EVENTSOURCE]: PuzzleIcon,
    }[activity_type] || ImageIcon;

  return (
    <div
      style={{
        alignItems: 'center',
        backgroundColor: palette.gray45,
        borderRadius: 5,
        color: palette.gray20,
        display: 'inline-flex',
        height: 24,
        margin: '8px 10px 5px 0',
        padding: '0 10px',
      }}
    >
      <Icon
        style={{
          color: palette.gray30,
          height: 18,
          marginRight: 5,
          width: 18,
        }}
      />
      <Typography component="span" variant="caption">
        {label}
      </Typography>
    </div>
  );
};
