import immer from 'immer';
import { reminderContents } from '../contents';
import { getSubscriptionEndDateInDays, isBillingAdminUser as isCurrentUserBilling, recentlyDismissed } from '../utls';
import {
  closeUploadPoComponentAction,
  createActiveSubscriptionAction,
  createDemoSubscriptionAction,
  createPastDueSubscriptionAction,
  dismissBillingReminderAlertAction,
  loadPurchaseQuoteAction,
  openUploadPoComponentAction,
  purchaseQuoteApiSecureSelectPlan,
  purchaseQuoteApiStatsFetchSucceed,
  purchaseQuoteAppListFetchSucceed,
  purchaseQuoteAppSecureDeselectAllApps,
  purchaseQuoteAppSecureSelectAllApps,
  purchaseQuoteAppSecureSelectApp,
  purchaseQuoteSelectDiscount,
  purchaseQuoteSelectSubscription,
} from './actions';

export const initialState = {
  isCurrentUserBillingAdmin: false,
  name: '',
  notifyBilling: {
    shouldShow: false,
  },
  payNow: {
    shouldShow: false,
  },
  period: {
    daysToExpire: 0,
    endDate: undefined,
    startDate: undefined,
  },
  poUpload: {
    shouldShow: false,
  },
  purchaseQuote: {
    api_secure: {
      plans: [
        {
          id: 1,
          name: 'Baseline',
          price: 4200,
          priceMoneyFormat: '$4,200',
          restfulApiOperations: 50,
          serverlessApps: 10,
          shadowApiDetection: true,
          storageDb: 100,
          webApps: 10,
        },
        {
          id: 2,
          name: 'Enterprise',
          price: 6250,
          priceMoneyFormat: '$6,250',
          restfulApiOperations: 100,
          serverlessApps: 15,
          shadowApiDetection: true,
          storageDb: 125,
          webApps: 20,
        },
        {
          id: 3,
          name: 'Prime',
          price: 8400,
          priceMoneyFormat: '$8,400',
          restfulApiOperations: 150,
          serverlessApps: 30,
          shadowApiDetection: true,
          storageDb: 150,
          webApps: 25,
        },
      ],
      recommended_plan_id: 0,
      selected_plan_id: null,
      stats: {
        api_ops: 0,
        dbs: 0,
        restful_apis: 0,
        serverless: 0,
        web_applications: 0,
      },
    },
    app_list: [],
    discounts: [
      {
        desc: 'CI/CD Integration Completed (e.g. Jenkins) during Trial.',
        discountPresentage: 2,
        example: null,
        id: 1,
        selected: false,
      },
      {
        desc: 'Bug Tracking Integration Completed (e.g. Jira) during Trial',
        discountPresentage: 2,
        example: null,
        id: 2,
        selected: false,
      },
      {
        desc: `Agree to Case Study`,
        discountPresentage: 4,
        example: 'https://www.datatheorem.com/customers/netflix/',
        id: 3,
        selected: false,
      },
      {
        desc: 'Countersigned Agreement within 30 days',
        discountPresentage: 5,
        example: null,
        id: 4,
        selected: false,
      },
      {
        desc: 'Obtain App Protection Score > 51%',
        discountPresentage: 2,
        example: null,
        id: 5,
        selected: false,
      },
    ],
    shouldShow: true,
    subscriptions: {
      list: [
        {
          desc: '1 year subscription',
          discountPresentage: 0,
          id: 1,
        },
        {
          desc: '2 year subscription',
          discountPresentage: 2.5,
          id: 2,
        },
        {
          desc: '3 year subscription',
          discountPresentage: 4,
          id: 3,
        },
      ],
      selected_id: 1,
    },
  },
  quoteUrl: {
    shouldShow: false,
  },
  reminder: {
    closed: false,
    description: '',
    isOpen: false,
    shouldShow: true,
    title: '',
  },
  shouldAlertBillingUser: false,
  shouldShowBillingReminder: false,
  status: 'unsubscribed',
};

export default immer((draft, action) => {
  if (action.type === createActiveSubscriptionAction.toString()) {
    const { accountInfo, currentUser } = action.payload;
    const { name, subscription_period, renewal_info, toggles, billing_contacts } = accountInfo;
    const { login_email } = currentUser;
    const { pay_by_credit_card_url, po_quote_url } = renewal_info || {};

    draft.name = name ? name : '';
    draft.status = 'active';

    const expirationDate = subscription_period ? subscription_period.end_date : '';
    const shouldShowRenewalReminder = toggles && toggles.renewal_reminders ? true : false;
    const daysToExpire = getSubscriptionEndDateInDays(expirationDate);
    draft.shouldShowBillingReminder = (() => {
      if (shouldShowRenewalReminder && expirationDate) {
        if ((daysToExpire || daysToExpire === 0) && daysToExpire <= 90) {
          if (!recentlyDismissed(currentUser)) {
            return true;
          }
        }
      }
      return false;
    })();
    draft.isCurrentUserBillingAdmin = isCurrentUserBilling(login_email, billing_contacts);
    draft.shouldAlertBillingUser = draft.isCurrentUserBillingAdmin && daysToExpire <= 90;
    draft.period = {
      daysToExpire,
      endDate: subscription_period?.end_date,
      startDate: subscription_period?.start_date,
    };

    if (draft.isCurrentUserBillingAdmin && daysToExpire <= 90 && pay_by_credit_card_url) {
      draft.payNow = {
        credit_card_url: pay_by_credit_card_url,
        shouldShow: true,
      };
    }

    if (draft.isCurrentUserBillingAdmin && daysToExpire <= 90 && po_quote_url) {
      draft.quoteUrl = {
        quote_url: po_quote_url,
        shouldShow: true,
      };
    }

    if (draft.isCurrentUserBillingAdmin && daysToExpire <= 90) {
      draft.poUpload = {
        isOpen: false,
        shouldShow: true,
      };
    }

    draft.reminder = (() =>
      draft.shouldShowBillingReminder
        ? {
            closed: draft.shouldShowBillingReminder && draft.reminder && draft.reminder.closed ? true : false,
            description:
              draft.payNow.shouldShow || draft.quoteUrl.shouldShow
                ? reminderContents.desc['beforeExp']['plural']
                : reminderContents.desc['beforeExp']['singular'],
            isOpen: draft.shouldShowBillingReminder,
            shouldShow: true,
            title: (() => {
              if (daysToExpire >= 60) return reminderContents.title['60_days'];
              if (daysToExpire >= 30) return reminderContents.title['30_days'];
              if (daysToExpire >= 15) return reminderContents.title['15_days'];
              if (daysToExpire === 1) {
                return reminderContents.title['beforeExp']
                  .replace('{number}', daysToExpire.toString())
                  .replace('days', 'day');
              }
              if (daysToExpire === 0) return reminderContents.title['today'];
              return reminderContents.title['beforeExp'].replace('{number}', daysToExpire.toString());
            })(),
          }
        : {
            shouldShow: false,
          })();

    draft.purchaseQuote = {
      shouldShow: false,
    };

    if (draft.shouldShowBillingReminder && !draft.isCurrentUserBillingAdmin) {
      draft.notifyBilling = {
        shouldShow: true,
      };
    }
  }

  if (action.type === createPastDueSubscriptionAction.toString()) {
    const { accountInfo, currentUser } = action.payload;
    const { name, subscription_period, renewal_info, toggles, billing_contacts } = accountInfo;
    const { login_email } = currentUser;
    const { pay_by_credit_card_url, po_quote_url } = renewal_info || {};

    draft.name = name ? name : '';
    draft.status = 'past-due';

    const expirationDate = subscription_period ? subscription_period.end_date : '';
    const shouldShowRenewalReminder = toggles && toggles.renewal_reminders ? true : false;
    const daysToExpire = getSubscriptionEndDateInDays(expirationDate);
    draft.shouldShowBillingReminder = shouldShowRenewalReminder ? true : false;
    draft.isCurrentUserBillingAdmin = isCurrentUserBilling(login_email, billing_contacts);
    draft.shouldAlertBillingUser = draft.isCurrentUserBillingAdmin && daysToExpire <= 90;
    draft.period = {
      daysToExpire,
      endDate: subscription_period?.end_date,
      startDate: subscription_period?.start_date,
    };

    if (draft.isCurrentUserBillingAdmin && daysToExpire <= 90 && pay_by_credit_card_url) {
      draft.payNow = {
        credit_card_url: pay_by_credit_card_url,
        shouldShow: true,
      };
    }

    if (draft.isCurrentUserBillingAdmin && daysToExpire <= 90 && po_quote_url) {
      draft.quoteUrl = {
        quote_url: po_quote_url,
        shouldShow: true,
      };
    }

    if (draft.isCurrentUserBillingAdmin && daysToExpire <= 90) {
      draft.poUpload = {
        isOpen: false,
        shouldShow: true,
      };
    }

    draft.reminder = (() =>
      draft.shouldShowBillingReminder
        ? {
            closed: draft.shouldShowBillingReminder && draft.reminder && draft.reminder.closed ? true : false,
            description:
              draft.payNow.shouldShow || draft.quoteUrl.shouldShow
                ? reminderContents.desc['beforeExp']['plural']
                : reminderContents.desc['beforeExp']['singular'],
            isOpen: draft.shouldShowBillingReminder,
            shouldShow: true,
            title: (() => {
              return reminderContents.title['beforeExp']
                .replace('days.', '')
                .replace('{number}', `${Math.abs(daysToExpire)} day(s) ago.`)
                .replace('expires in', 'expired');
            })(),
          }
        : {
            shouldShow: false,
          })();

    draft.purchaseQuote = {
      shouldShow: false,
    };

    if (draft.shouldShowBillingReminder && !draft.isCurrentUserBillingAdmin) {
      draft.notifyBilling = {
        shouldShow: true,
      };
    }
  }

  if (action.type === createDemoSubscriptionAction.toString()) {
    const { accountInfo, currentUser } = action.payload || {};
    const { name, last_login_date, renewal_info, toggles, billing_contacts } = accountInfo;
    const { login_email } = currentUser || {};
    const { po_quote_url } = renewal_info || {};

    draft.name = name ? name : '';
    draft.status = 'demo';

    const expirationDate = last_login_date ? last_login_date : '';
    const shouldShowRenewalReminder = toggles && toggles.renewal_reminders ? true : false;
    const daysToExpire = getSubscriptionEndDateInDays(expirationDate);
    draft.shouldShowBillingReminder = (() => {
      if (shouldShowRenewalReminder && expirationDate) {
        if ((daysToExpire || daysToExpire === 0) && daysToExpire <= 90) {
          if (!recentlyDismissed(currentUser)) {
            return true;
          }
        }
      }
      return false;
    })();
    draft.isCurrentUserBillingAdmin = isCurrentUserBilling(login_email, billing_contacts);
    draft.shouldAlertBillingUser = draft.isCurrentUserBillingAdmin && daysToExpire <= 90;
    draft.period = {
      daysToExpire,
      endDate: expirationDate,
      startDate: undefined,
    };

    if (draft.isCurrentUserBillingAdmin && daysToExpire <= 90 && po_quote_url) {
      draft.quoteUrl = {
        quote_url: po_quote_url,
        shouldShow: true,
      };
    }

    if (draft.isCurrentUserBillingAdmin && daysToExpire <= 90) {
      draft.poUpload = {
        isOpen: false,
        shouldShow: true,
      };
    }

    draft.reminder = (() =>
      draft.shouldShowBillingReminder
        ? {
            closed: draft.shouldShowBillingReminder && draft.reminder && draft.reminder.closed ? true : false,
            description: '',
            isOpen: draft.shouldShowBillingReminder,
            shouldShow: true,
            title: (() => {
              if (daysToExpire >= 60) return reminderContents.title['60_days'].replace('subscription', 'Trial');
              if (daysToExpire >= 30) return reminderContents.title['30_days'].replace('subscription', 'Trial');
              if (daysToExpire >= 15) return reminderContents.title['15_days'].replace('subscription', 'Trial');
              if (daysToExpire === 1) {
                return reminderContents.title['beforeExp']
                  .replace('{number}', daysToExpire.toString())
                  .replace('days', 'day')
                  .replace('subscription', 'Trial');
              }
              if (daysToExpire === 0) return reminderContents.title['today'];
              if (daysToExpire < 0)
                return reminderContents.title['beforeExp']
                  .replace('days.', '')
                  .replace('{number}', `${Math.abs(daysToExpire)} day(s) ago.`)
                  .replace('expires in', 'expired');
              return reminderContents.title['beforeExp']
                .replace('{number}', daysToExpire.toString())
                .replace('subscription', 'Trial');
            })(),
          }
        : {
            shouldShow: false,
          })();

    if (!draft.isCurrentUserBillingAdmin) {
      draft.purchaseQuote = {
        shouldShow: false,
      };
    }

    if (draft.shouldShowBillingReminder && !draft.isCurrentUserBillingAdmin) {
      draft.notifyBilling = {
        shouldShow: true,
      };
    }
  }

  if (action.type === dismissBillingReminderAlertAction.toString()) {
    draft.reminder = {
      shouldShow: false,
    };
  }

  if (action.type === loadPurchaseQuoteAction.toString()) {
    draft.reminder = {
      shouldShow: false,
    };
  }

  if (action.type === openUploadPoComponentAction.toString()) {
    if (draft.poUpload.shouldShow) {
      draft.poUpload.isOpen = true;
    }
  }

  if (action.type === closeUploadPoComponentAction.toString()) {
    if (draft.poUpload.shouldShow) {
      draft.poUpload.isOpen = false;
    }
  }

  if (action.type === purchaseQuoteAppListFetchSucceed.toString()) {
    const { appList } = action.payload;
    const newAppList = appList.filter(
      item =>
        draft.purchaseQuote.shouldShow &&
        !draft.purchaseQuote.app_list.filter(draftItem => draftItem.id === item.id).length,
    );
    if (draft.purchaseQuote.shouldShow) {
      draft.purchaseQuote.app_list = [...draft.purchaseQuote.app_list, ...newAppList];
    }
  }

  if (action.type === purchaseQuoteApiStatsFetchSucceed.toString()) {
    const { restful_apis, api_ops, web_applications, dbs, serverless } = action.payload.stats;
    if (draft.purchaseQuote.shouldShow) {
      draft.purchaseQuote.api_secure.stats = {
        api_ops,
        dbs,
        restful_apis,
        serverless,
        web_applications,
      };
      for (let index = 0; index < draft.purchaseQuote.api_secure.plans.length; index++) {
        const plan = draft.purchaseQuote.api_secure.plans[index];
        if (plan.restfulApiOperations >= api_ops && api_ops > 0) {
          draft.purchaseQuote.api_secure.recommended_plan_id = plan.id;
          break;
        }
      }
    }
  }

  if (action.type === purchaseQuoteSelectSubscription.toString()) {
    const { subscriptionId } = action.payload;
    if (draft.purchaseQuote.shouldShow) {
      draft.purchaseQuote.subscriptions.selected_id = subscriptionId;
    }
  }

  if (action.type === purchaseQuoteSelectDiscount.toString()) {
    const { discountId, selected } = action.payload;
    if (draft.purchaseQuote.shouldShow) {
      draft.purchaseQuote = {
        ...draft.purchaseQuote,
        discounts: draft.purchaseQuote.discounts.map(item => {
          if (item.id === discountId) {
            return {
              ...item,
              selected,
            };
          }
          return item;
        }),
      };
    }
  }

  if (action.type === purchaseQuoteAppSecureSelectApp.toString()) {
    const { appId, checked } = action.payload;
    if (draft.purchaseQuote.shouldShow) {
      draft.purchaseQuote = {
        ...draft.purchaseQuote,
        app_list: draft.purchaseQuote.app_list.map(item => {
          if (item.id === appId) {
            return {
              ...item,
              checked: checked ? true : false,
            };
          }
          return item;
        }),
      };
    }
  }

  if (action.type === purchaseQuoteAppSecureSelectAllApps.toString()) {
    if (draft.purchaseQuote.shouldShow) {
      draft.purchaseQuote = {
        ...draft.purchaseQuote,
        app_list: draft.purchaseQuote.app_list.map(item => {
          return {
            ...item,
            checked: true,
          };
        }),
      };
    }
  }

  if (action.type === purchaseQuoteAppSecureDeselectAllApps.toString()) {
    if (draft.purchaseQuote.shouldShow) {
      draft.purchaseQuote = {
        ...draft.purchaseQuote,
        app_list: draft.purchaseQuote.app_list.map(item => {
          return {
            ...item,
            checked: false,
          };
        }),
      };
    }
  }

  if (action.type === purchaseQuoteApiSecureSelectPlan.toString()) {
    const { plainId } = action.payload;
    if (draft.purchaseQuote.shouldShow) {
      draft.purchaseQuote.api_secure.selected_plan_id = plainId;
    }
  }
}, initialState);
