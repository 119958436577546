import React from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import Box from '@mui/material/Box';
import { AssetTypeEnumValues } from '@dt/graphql-support/enums';

export const PopoutFilterPluginAssetTypeDisplayLookup = {
  [AssetTypeEnumValues.DOMAIN_NAME]: 'Domain Name',
  [AssetTypeEnumValues.GRAPHQL_API]: 'GraphQL API',
  [AssetTypeEnumValues.RESTFUL_API]: 'RESTful API',
  [AssetTypeEnumValues.API_OPERATION]: 'API Operation',
  [AssetTypeEnumValues.CLOUD_RESOURCE]: 'Cloud Resource',
  [AssetTypeEnumValues.WEB_APPLICATION]: 'Web Application',
  [AssetTypeEnumValues.NETWORK_SERVICE]: 'Network Service',
  [AssetTypeEnumValues.MOBILE_APPLICATION]: 'Mobile Application',
  [AssetTypeEnumValues.MOBILE_SDK]: 'Mobile SDK',
  [AssetTypeEnumValues.GRPC_METHOD]: 'gRPC Method',
  [AssetTypeEnumValues.GRPC_SERVICE]: 'gRPC Service',
  [AssetTypeEnumValues.KUBERNETES_CLUSTER]: 'Kubernetes Cluster',
  [AssetTypeEnumValues.KUBERNETES_CLUSTER_COMPONENT]: 'Kubernetes Cluster Component',
  [AssetTypeEnumValues.SOAP_API]: 'SOAP API',
  [AssetTypeEnumValues.SOAP_API_OPERATION]: 'SOAP API Operation',
  [AssetTypeEnumValues.REPOSITORY]: 'Repository',
  [AssetTypeEnumValues.CLOUD_IDENTITY]: 'Cloud Identity',
};

/*
 * Popout filter plugin.
 * User can select asset type.
 *
 * @param value - Current state.
 * @param onChange - State change.
 */
const PopoutFilterPluginAssetTypeComponent = function PopoutFilterPluginAssetType({ value, onChange }) {
  return (
    <Box display="flex" flexDirection="column">
      {Object.keys(PopoutFilterPluginAssetTypeDisplayLookup).map(key => {
        const val = PopoutFilterPluginAssetTypeDisplayLookup[key];
        return (
          <FormControlLabel
            control={
              <Checkbox
                checked={!!value[key]}
                color="primary"
                inputProps={{
                  'aria-label': `${val}`,
                }}
                onChange={event => {
                  (value =>
                    onChange(prev => ({
                      ...prev,

                      [key]: value,
                    })))(event.target.checked);
                }}
              />
            }
            key={key}
            label={val}
          />
        );
      })}
    </Box>
  );
};

export const PopoutFilterPluginAssetType = PopoutFilterPluginAssetTypeComponent;
