import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import Attachment from '@mui/icons-material/Attachment';
import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import CloudUpload from '@mui/icons-material/CloudUpload';
import NoteAdd from '@mui/icons-material/NoteAdd';
import Backdrop from '@mui/material/Backdrop';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { closeUploadPoComponentAction, uploadPoFileAction } from '../redux/actions';
import { actions, button, container, dropzone, icon, input, link, modal, p, paper, root } from '../style';

const useStyles = makeStyles({
  actions,
  button,
  container,
  dropzone,
  icon,
  input,
  link,
  modal,
  p,
  paper,
  root,
});

// genrate a text file from po number
const generatePoFileFromPoNumber = poNumber => {
  const generatedFile = new Blob([`PO NUMBER: ${poNumber}`], {
    type: 'plain/text',
  });
  return new File([generatedFile], 'po_number.txt', {
    lastModified: Date.now(),
    type: 'plain/text',
  });
};

const UploadPo = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [poNumberValue, setPoNumberValue] = useState('');
  const [submitionStatus, submitAction] = useState({
    error: false,
    poFile: undefined,
    submited: false,
  });

  const handlePoNumberChange = event => {
    setPoNumberValue(event.target.value);
  };

  const [isDragEnter, onDragEnterHandler] = useState(false);
  const [isDragRejected, onDragRejectedHandler] = useState(false);

  const onDragOver = useCallback(() => {
    onDragEnterHandler(true);
  }, []);

  const onDragLeave = useCallback(() => {
    onDragEnterHandler(false);
  }, []);

  const onDrop = useCallback(() => {
    onDragEnterHandler(false);
  }, []);

  const onDropRejected = useCallback(() => {
    onDragRejectedHandler(true);
  }, []);

  const { acceptedFiles, getRootProps, getInputProps, isDragActive, isFocused } = useDropzone({
    accept: '.doc, .docx, .txt, .text, .xls, .xlsx, plain/txt, plain/text, text/html, application/pdf, application/zip',
    disabled: poNumberValue.length ? true : false,
    onDragLeave,
    onDragOver,
    onDrop,
    onDropRejected,
  });

  const style = useMemo(
    () => ({
      ...dropzone,
      backgroundColor: submitionStatus.submited && submitionStatus.error ? '#ffecea' : null,
      borderColor:
        isDragActive || isFocused ? '#2196f3' : submitionStatus.submited && submitionStatus.error ? 'red' : null,
      color: submitionStatus.submited && submitionStatus.error ? '#8c8c8c' : null,
      opacity: poNumberValue.length ? '0.2' : {},
    }),
    [isDragActive, isFocused, submitionStatus, poNumberValue],
  );

  const handleCloseHandler = () => {
    dispatch(closeUploadPoComponentAction());
  };

  const getPoFile = () => {
    let poFile = acceptedFiles.length > 0 ? acceptedFiles[acceptedFiles.length - 1] : undefined;
    if (!poFile && poNumberValue.length) {
      poFile = generatePoFileFromPoNumber(poNumberValue);
    }
    // IDK why! but we need to build a new File Object from
    // dragged file to get response from google storage api
    return poFile && new File([poFile], poFile.name);
  };

  useEffect(() => {
    if (submitionStatus.submited && !submitionStatus.poFile) {
      submitAction({
        ...submitionStatus,
        error: true,
      });
    }

    if (submitionStatus.submited && submitionStatus.poFile && !submitionStatus.error) {
      const name = submitionStatus.poFile.name;
      const desc = '*** renewal reminder ***';
      dispatch(uploadPoFileAction(submitionStatus.poFile, name, desc));
      dispatch(closeUploadPoComponentAction());
    }
  }, [submitionStatus, dispatch]);

  return (
    <div>
      <Modal
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        aria-describedby="spring-modal-description"
        aria-labelledby="spring-modal-title"
        className={classes.modal}
        closeAfterTransition
        onClose={handleCloseHandler}
        open={true}
      >
        <div className={classes.root}>
          <Grid className={classes.paper} container spacing={1}>
            <Grid item xs={12}>
              <Typography component="h5" variant="h5">
                Upload PO
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <section className="container">
                <div {...getRootProps({ style })}>
                  <input {...getInputProps()} />
                  {!isDragEnter && !(acceptedFiles.length > 0) && (
                    <p className={classes.p}>
                      <span className={classes.icon}>{isDragRejected ? <BrokenImageIcon /> : <NoteAdd />}</span>
                      {isDragRejected && <span>Your file is not an accepted PO file.</span>}
                      <span>Drag and drop or click to select your PO file or</span>
                      <span>input a PO number to continue</span>
                    </p>
                  )}
                  {isDragEnter && (
                    <p className={classes.p}>
                      <span className={classes.icon}>
                        <CloudUpload />
                      </span>
                      <span>Drop your PO file to upload</span>
                    </p>
                  )}
                  {acceptedFiles.length > 0 && !isDragEnter && (
                    <p className={classes.p}>
                      <span className={classes.icon}>
                        <Attachment />
                      </span>
                      <span>Your PO File is ready to upload.</span>
                    </p>
                  )}
                </div>
              </section>
            </Grid>
            <Grid item xs={12}>
              <Grid alignItems="center" container direction="row" justifyContent="center" spacing={0}>
                <Grid item xs={6}>
                  <TextField
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                    id="outlined-full-width"
                    label="PO Number"
                    margin="normal"
                    onChange={handlePoNumberChange}
                    placeholder="PO Number"
                    style={{ margin: 2 }}
                    value={poNumberValue}
                    variant="outlined"
                  />
                </Grid>
                <Grid item style={{ textAlign: 'right' }} xs={6}>
                  <Button
                    aria-label={'SUBMIT'}
                    className={classes.button}
                    color="primary"
                    onClick={() =>
                      submitAction({
                        ...submitionStatus,
                        poFile: getPoFile(),
                        submited: true,
                      })
                    }
                    variant="contained"
                  >
                    SUBMIT
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Modal>
    </div>
  );
};

export default React.memo(UploadPo);
