import React, { memo } from 'react';
import ShareIcon from '@mui/icons-material/Share';
import { Button } from '@mui/material';
import { shared_links } from '@dt/horizon-api';
import Banner from './Banner';
import { SharedLinksButtonCommon } from './SharedLinksButtonCommon';

/*
 * NOTE: Use the `SharedLinksButton` facade component.
 *
 * Shared links button used to share the onboarding workflow.
 *
 * @param useManagementPath - Used to provide the user the shared links management path if indicated, otherwise the
 *                            shared onboarding link.
 *
 * @example
 *     <SharedLinksButtonOnboarding useManagementPath />
 */
const SharedLinksButtonOnboardingComponent = function SharedLinksButtonOnboarding({
  onboardingVariant,
  useManagementPath,
  summaryText,
}) {
  const onboardingVariantParams = onboardingVariant ? `?cloud=${onboardingVariant}` : '';

  /*
   * Handle creating a shared link for mobile applications.
   */
  const handleOnCreate = async validity_duration => {
    // Create shared link.
    const response = await shared_links.create_for_authenticators({
      validity_duration,
    });

    // Error, let shared links button render error.
    if (response._type === 'error') {
      throw new Error(response.title);
    }

    // Success, let shared links button render page url.
    return useManagementPath
      ? `${window.location.origin}/management/share/${response.body.access_token}/onboard${onboardingVariantParams}`
      : `${window.location.origin}/api/share/${response.body.access_token}/onboard${onboardingVariantParams}`;
  };

  return (
    <SharedLinksButtonCommon
      manageShareLink={!useManagementPath ? '/api/shared-links' : null}
      onCreate={handleOnCreate}
      renderButton={
        useManagementPath
          ? (toggleDialog, disabled) => (
              <Button disabled={disabled} onClick={toggleDialog} startIcon={<ShareIcon />} variant="contained">
                {summaryText ? 'Secure Share' : 'Secure Share to Setup'}
              </Button>
            )
          : null
      }
      title="Create a Secure Link to Onboard your Cloud"
      useReachRouter
    >
      <Banner icon={<></>} inline status="warning">
        The secure link will allow temporary access to onboard your Cloud &amp; API gateways onto your Data Theorem
        portal.
      </Banner>
    </SharedLinksButtonCommon>
  );
};

export const SharedLinksButtonOnboarding = memo(SharedLinksButtonOnboardingComponent);
