import React, { PureComponent } from 'react';
import ManualUploadIcon from '@mui/icons-material/CloudUpload';
import MobileAppIcon from '@mui/icons-material/DeveloperMode';
import RESTfulApiIcon from '@mui/icons-material/DeviceHub';
import WebAppAnalyzerIcon from '@mui/icons-material/Dvr';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import WebsiteCrawlerIcon from '@mui/icons-material/Pageview';
import IpRangeIcon from '@mui/icons-material/Router';
import { DiscoveredVia } from '@dt/horizon-api';
import CloudFlareLogo from './svg/CF_logomark.svg';
import AkamaiLogo from './svg/ConfigurationsAkamai.svg';
import MobileProtectUsageLogo from './svg/MOBILE_PROTECT_USAGE.svg';
import ApigeeLogo from './svg/apigee_logo.svg';
import AwsLogo from './svg/aws_logo.svg';
import AxwayLogo from './svg/axway_logo.svg';
import AzureLogo from './svg/azure_logo.svg';
import BitbucketLogo from './svg/bitbucket_logo.svg';
import CertTransLogo from './svg/certif_transparency_logo.svg';
import GcpLogo from './svg/gcp_logo.svg';
import GithubLogo from './svg/git_hub_logo.svg';
import GitlabLogo from './svg/git_lab_logo.svg';
import KongLogo from './svg/kong_logo.svg';
import KubernetesLogo from './svg/kubernetes_logo.svg';
import MobileAppStoreLogo from './svg/mobile_app_store_logo.svg';
import MulesoftLogo from './svg/mulesoft_logo.svg';
import PostmanLogo from './svg/postman_logo.svg';
import ShadowRestfulApiDiscoverer from './svg/shadow_restful_api_discoverer.svg';

const SwaggerHubLogo = 'https://prod-horizon.static.securetheorem.com/assets/images/icons/SWAGGER_HUB.svg';

const CloudProtectLogo = 'https://prod-horizon.static.securetheorem.com/assets/images/icons/CLOUD_PROTECT.svg';

// prettier-ignore
const DiscoveredViaIconMap         
                                                                                    
  = {
  [DiscoveredVia.AMAZON_WEB_SERVICES]: ({ size }       , alt        ) =>  <img alt={alt} height={size} src={AwsLogo} width={size} />,
  [DiscoveredVia.APIGEE_ON_PREM]: ({ size }       , alt        ) => <img alt={alt} height={size} src={ApigeeLogo} width={size} />,
  [DiscoveredVia.APIGEE]: ({ size }       , alt        ) =>  <img alt={alt} height={size} src={ApigeeLogo} width={size} />,
  [DiscoveredVia.APPLICATION_FINGERPRINTING]: ({ size }       , alt        ) =>  <FingerprintIcon alt={alt} style={{ height: size, width: size }} />,
  [DiscoveredVia.AXWAY]: ({ size }       , alt        ) => <img alt={alt} height={size} src={AxwayLogo} width={size} />,
  [DiscoveredVia.AZURE]: ({ size }       , alt        ) => <img alt={alt} height={size} src={AzureLogo} width={size} />,
  [DiscoveredVia.BITBUCKET_INTEGRATION]: ({ size }       , alt        ) => <img alt={alt} height={size} src={BitbucketLogo} width={size} />,
  [DiscoveredVia.CERTIFICATE_TRANSPARENCY]: ({ size }       , alt        ) => <img alt={alt} height={size} src={CertTransLogo} width={size} />,
  [DiscoveredVia.CLOUD_PROTECT]: ({ size }       , alt        ) =>  <img alt={alt} height={size} src={MobileProtectUsageLogo} width={size} />,
  [DiscoveredVia.CLOUD_PROTECT]: ({ size }       , alt        ) => <img alt={alt} height={size} src={CloudProtectLogo} width={size} />,
  [DiscoveredVia.CLOUDFLARE]: ({ size }       , alt        ) => <img alt={alt} height={size} src={CloudFlareLogo} width={size} />,
  [DiscoveredVia.GITHUB_ENTERPRISE_SERVER_INTEGRATION]: ({ size }       , alt        ) => <img alt={alt} height={size} src={GithubLogo} width={size} />,
  [DiscoveredVia.GITHUB_INTEGRATION]: ({ size }       , alt        ) => <img alt={alt} height={size} src={GithubLogo} width={size} />,
  [DiscoveredVia.GITLAB_INTEGRATION]: ({ size }       , alt        ) => <img alt={alt} height={size} src={GitlabLogo} width={size} />,
  [DiscoveredVia.GOOGLE_CLOUD_PLATFORM]: ({ size }       , alt        ) =>  <img alt={alt} height={size} src={GcpLogo} width={size} />,
  [DiscoveredVia.KONG]: ({ size }       , alt        ) => <img alt={alt} height={size} src={KongLogo} width={size} />,
  [DiscoveredVia.KUBERNETES_CLUSTER_ANALYSIS]: ({ size }       , alt        ) => <img alt={alt} height={size} src={KubernetesLogo} width={size} />,
  [DiscoveredVia.MANUAL_IMPORT]: ({ size }       , alt        ) =>  <ManualUploadIcon alt={alt} style={{ height: size, width: size }} />,
  [DiscoveredVia.MOBILE_APP_SCANS]: ({ size }       , alt        ) =>  <MobileAppIcon alt={alt} style={{ height: size, width: size }} />,
  [DiscoveredVia.MOBILE_APP_STORE]: ({ size }       , alt        ) => <img alt={alt} height={size} src={MobileAppStoreLogo} width={size} />,
  [DiscoveredVia.MOBILE_PROTECT_USAGE]: ({ size }       , alt        ) => <img alt={alt} height={size} src={MobileProtectUsageLogo} width={size} />,
  [DiscoveredVia.MULESOFT]: ({ size }       , alt        ) => <img alt={alt} height={size} src={MulesoftLogo} width={size} />,
  [DiscoveredVia.POSTMAN_CLOUD]: ({ size }       , alt        ) => <img alt={alt} height={size} src={PostmanLogo} width={size} />,
  [DiscoveredVia.RESTFUL_API_DISCOVERER]: ({ size }       , alt        ) => <RESTfulApiIcon alt={alt} style={{ height: size, width: size }} />,
  [DiscoveredVia.REVERSE_IP_LOOKUP]: ({ size }       , alt        ) => <IpRangeIcon alt={alt} style={{ height: size, width: size }} />,
  [DiscoveredVia.SAN_EXTRACTION]: ({ size }       , alt        ) => <img alt={alt} height={size} src={CertTransLogo} width={size} />,
  [DiscoveredVia.SWAGGER_HUB]: ({ size }       , alt        ) => <img alt={alt} height={size} src={SwaggerHubLogo} width={size} />,
  [DiscoveredVia.WEB_APPLICATION_SCANS]: ({ size }       , alt        ) => <WebAppAnalyzerIcon alt={alt} style={{ height: size, width: size }} />,
  [DiscoveredVia.WEBSITE_CRAWLER]: ({ size }       , alt        ) => <WebsiteCrawlerIcon alt={alt} style={{ height: size, width: size }} />,
  [DiscoveredVia.AKAMAI]: ({ size }       , alt        ) => <AkamaiLogo alt={alt} style={{ height: size, width: size }} />,
  [DiscoveredVia.AZURE_DEVOPS_INTEGRATION]: ({ size }, alt) => <img alt={alt} height={size} src={AzureLogo} width={size} />,
  [DiscoveredVia.SHADOW_RESTFUL_API_DISCOVERER]: ({ size }, alt) => <img alt={alt} height={size} src={ShadowRestfulApiDiscoverer} width={size} />,
};

export default class extends PureComponent {
  render() {
    const { discovered_via } = this.props;

    if (!DiscoveredViaIconMap[discovered_via]) {
      throw new Error(`Missing discovered via icon for ${discovered_via}, DiscoveredViaType is likely out of date`);
    }

    return DiscoveredViaIconMap[discovered_via](this.props, `icon`);
  }
}
