import createReducerCreator from 'rezz/createReducerCreator';
import updateInList from 'rezz/updateInList';
import {
  contactPublisherRequestError,
  contactPublisherRequestLoading,
  contactPublisherRequestReceived,
  openscanMobileAppsRecieved,
  updateApp,
} from '../actions';

export default createReducerCreator({
  equalityCheck: (a, b) => a.id === b.id,
  initialState: [],
})(
  updateInList([
    updateApp.toString(),
    openscanMobileAppsRecieved.toString(),
    contactPublisherRequestReceived.toString(),
    contactPublisherRequestLoading.toString(),
    contactPublisherRequestError.toString(),
  ]),
);
