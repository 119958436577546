import config from '@dt/config';
import fetch, { parse } from '@dt/fetch';
import { byProperty, result } from './util';

export async function create(params) {
  return fetch(`${config.sevenhellApiBaseUrl}/v2/xcuitest_uploads`, {
    body: JSON.stringify(params),
    method: 'POST',
  })
    .then(parse)
    .then(result)
    .then(byProperty('upload_url'));
}
