import { finance } from '@dt/user-api/uploads';

export { renewal_reminder_dismissal } from '@dt/user-api/current_user';
export { default as notify_billing_contact } from '@dt/user-api/renewal_reminders';
export { inventory_stats } from '@dt/horizon-api';
export { list as mobile_apps_list } from '@dt/user-api/mobile_apps';

async function uploadToGoogleStorage(poUploadArgs) {
  const { path, file } = poUploadArgs;

  /**
   * there is problem with preflighted CORS request on chrome
   * even when file uploads on google storage successfully
   * it return CORS error
   */
  return fetch(path, {
    body: file,
    method: 'PUT',
  }).then(response => response.json());
}

export async function upload_po_file_to_google_storage(args) {
  const { file, name: filename, purpose, size, origin } = args;
  if (!file || !filename || !purpose || !size || !origin) {
    throw new Error('Invalid params supplied to create upload request');
  }
  try {
    const upload_url = await finance.create({
      filename,
      origin,
      purpose,
      size,
    });
    return uploadToGoogleStorage({ file, path: upload_url });
  } catch (error) {
    return Promise.reject(error);
  }
}
