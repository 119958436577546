import React from 'react';
import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  label: {
    height: 0,
    position: 'relative',
    textAlign: 'center',
    top: '-60%',
  },
});

const CircularChartComponent = function CircularChart({ total, completed, strokeWidth, size, children }) {
  const classes = useStyles();

  return (
    <Grid container justifyContent={'center'}>
      <Grid item style={{ maxHeight: size || 200 }} xs={12}>
        <svg height="100%" viewBox="0 0 42 42" width="100%">
          <circle
            aria-label={`total-${total.count}-${completed.color}`}
            cx="21"
            cy="21"
            fill="transparent"
            r="15.91549430918954"
            stroke={`${total.color}`}
            strokeWidth={strokeWidth || 7}
          ></circle>
          <circle
            aria-label={`completed-${total.count}-${completed.color}`}
            cx="21"
            cy="21"
            fill="transparent"
            r="15.91549430918954"
            stroke={`${completed.color}`}
            strokeDasharray={`${completed.count} 100 ${total.count}`}
            strokeWidth={strokeWidth || 7}
          ></circle>
        </svg>
      </Grid>
      <Grid className={classes.label} item xs={12}>
        {children}
      </Grid>
    </Grid>
  );
};

export default React.memo(CircularChartComponent);
