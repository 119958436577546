import React, { memo } from 'react';
import { Grid, Typography } from '@mui/material';
import { palette } from '@dt/theme';

export default memo(function HostPieChartTooltip({ children, urgent, important, proactive, resolved }) {
  return (
    <TooltipContainer>
      {typeof urgent === 'number' && <TooltipSwatchRow color={palette.red} label="High Severity" value={urgent} />}
      {typeof important === 'number' && (
        <TooltipSwatchRow color={palette.yellow} label="Medium Severity" value={important} />
      )}
      {typeof proactive === 'number' && (
        <TooltipSwatchRow color={palette.blue} label="Low Severity" value={proactive} />
      )}
      {typeof resolved === 'number' && <TooltipSwatchRow color={palette.green} label="no Security" value={resolved} />}
      {children}
    </TooltipContainer>
  );
});

function TooltipContainer({ children }) {
  return (
    <div
      style={{
        backgroundColor: palette.white,
        border: `1px solid ${palette.gray30}`,
        borderRadius: 5,
        padding: 10,
      }}
    >
      {children}
    </div>
  );
}

function TooltipSwatch({ color }) {
  return (
    <div
      style={{
        backgroundColor: color,
        borderRadius: 3,
        height: 24,
        marginRight: 5,
        width: 24,
      }}
    />
  );
}

function TooltipSwatchRow({ color, label, value }) {
  return (
    <Grid alignItems="center" container spacing={2}>
      <Grid item>
        <TooltipSwatch color={color} />
      </Grid>
      <Grid item>
        <Typography display="inline" style={{ marginRight: 5 }} variant="h4">
          {value}
        </Typography>{' '}
        <Typography display="inline" variant="body2">
          Asset{value !== 1 && 's'} with {label} Issues
        </Typography>
      </Grid>
    </Grid>
  );
}
