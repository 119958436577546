import React, { memo, useEffect } from 'react';
import { Waypoint } from 'react-waypoint';
import { Checkbox, ListItem } from '@mui/material';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { makeStyles } from '@mui/styles';
// @ts-ignore JS component
import { useLazyQuery } from '@dt/apollo-link-schema-rest';
// @ts-ignore JS component
import { ErrorState } from '@dt/components';
// @ts-ignore JS component
import asset_tags from '@dt/graphql-support/horizon/asset_tags';
// @ts-ignore JS component
import { Text } from '@dt/material-components';
const useStyles = makeStyles(() => ({
    item: {
        alignItems: 'center',
    },
}));
const TableFiltersAssetTagKeyValues = ({ assetTagKey, selectedAssetTags, onToggleAssetTagValue, setTotalValues, }) => {
    const classes = useStyles();
    const shouldSelectAllValues = selectedAssetTags[assetTagKey.name] === null;
    const [fetchAssetTagValuesQuery, { data, error, loading, called, fetchMore, pageNumber }] = useLazyQuery(asset_tags.values.list, {
        fetchPolicy: 'network-only',
    });
    useEffect(() => {
        fetchAssetTagValuesQuery({
            variables: {
                tag_uuid: assetTagKey.id,
            },
        });
    }, [fetchAssetTagValuesQuery, called, assetTagKey.id]);
    useEffect(() => {
        if (data?.asset_tag_value_list?.pagination_information?.total_count) {
            setTotalValues(Number(data?.asset_tag_value_list?.pagination_information?.total_count));
        }
    }, [data]);
    return (React.createElement(React.Fragment, null,
        error ? (React.createElement(ErrorState, { error: error })) : !pageNumber && loading ? ([0, 1, 2, 3, 4].map((_, key) => (React.createElement(ListItem, { button: true, dense: true, key: key },
            React.createElement(Skeleton, { animation: "wave", height: 40, width: `100%` }))))) : !data?.asset_tag_value_list?.asset_tags?.filter((assetTag) => assetTag.value).length ? (React.createElement(Box, { ml: 4 },
            React.createElement(Text, { component: 'div', noWrap: true }, "There is no value for this asset tag."))) : (data?.asset_tag_value_list?.asset_tags.map((assetTag, key) => assetTag?.value && (React.createElement(ListItem, { button: true, className: classes.item, dense: true, key: key, onClick: () => onToggleAssetTagValue(assetTag.tag, assetTag.value) },
            React.createElement(Checkbox, { checked: Boolean(selectedAssetTags[assetTag.tag] === null) ||
                    Boolean(selectedAssetTags[assetTag.tag]?.includes(assetTag.value)), color: shouldSelectAllValues ? 'default' : 'primary', style: { opacity: shouldSelectAllValues ? '0.4' : '1' } }),
            React.createElement(Text, { component: 'div', noWrap: true }, assetTag.value))))),
        fetchMore && (React.createElement(React.Fragment, null,
            React.createElement(Box, { component: 'div' },
                React.createElement(ListItem, { button: true, dense: true },
                    React.createElement(Skeleton, { animation: "wave", height: 40, width: `100%` })),
                React.createElement(ListItem, { button: true, dense: true },
                    React.createElement(Skeleton, { animation: "wave", height: 40, width: `100%` })),
                React.createElement(ListItem, { button: true, dense: true },
                    React.createElement(Skeleton, { animation: "wave", height: 40, width: `100%` }))),
            React.createElement(Waypoint, { onEnter: () => {
                    if (fetchMore) {
                        fetchMore();
                    }
                } })))));
};
export default memo(TableFiltersAssetTagKeyValues);
