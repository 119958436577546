import React, { memo } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import LabelIcon from '@mui/icons-material/Label';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import AssetTagsEditDialogAssetTagRowKey from './AssetTagsEditDialogAssetTagRowKey';
import AssetTagsEditDialogAssetTagRowValue from './AssetTagsEditDialogAssetTagRowValue';

const AssetTagsEditDialogAssetTagRow = ({ assetTag, validation, onUpdate, onRemove }) => {
  return (
    <Box alignItems={'flex-start'} display={'flex'}>
      <Box flexGrow={1} mr={1}>
        <AssetTagsEditDialogAssetTagRowKey
          assetTag={assetTag}
          assetTagValidationMessage={validation?.message}
          disabled={Boolean(assetTag.imported_from)}
          onUpdate={newAssetTag => onUpdate(assetTag, newAssetTag)}
        />
      </Box>

      <Box flexGrow={1} mr={1}>
        <AssetTagsEditDialogAssetTagRowValue
          assetTag={assetTag}
          disabled={Boolean(assetTag.imported_from) || !assetTag.tag.length}
          onUpdate={newAssetTag => onUpdate(assetTag, newAssetTag)}
        />
      </Box>
      {assetTag.imported_from && assetTag.imported_from_icon_url ? (
        <Box>
          <IconButton disabled>
            <img height={24} src={assetTag.imported_from_icon_url} width={24} />
          </IconButton>
        </Box>
      ) : assetTag.imported_from ? (
        <Box>
          <IconButton disabled>
            <LabelIcon />
          </IconButton>
        </Box>
      ) : (
        <Box>
          <IconButton aria-label={`Asset tag remove`} onClick={() => onRemove(assetTag.tag, assetTag.value)}>
            <DeleteIcon />
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

export default memo(AssetTagsEditDialogAssetTagRow);
