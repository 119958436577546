import { Actions as NotificationsActions } from '@dt/notifications';
import { select } from '@dt/redux-saga-wrapped-effects';
import { put, takeEvery } from 'redux-saga/effects';
import { openscanConfigReceived, openscanMobileAppsRecieved, policyViolationsReceived } from './../actions';
import { watchlistApps, config as watchlistConfig } from './../selectors/openscan';

export function* watchForOpenscanMobileAppsReceived() {
  yield takeEvery(openscanMobileAppsRecieved.toString(), function* (action) {
    const apps = action.payload;

    try {
      const config = yield* select(watchlistConfig, {});
      yield put(policyViolationsReceived(config, apps));
    } catch (err) {
      yield put(
        NotificationsActions.requestNotifyUser({
          text: `Unable to update policy violations.`,
        }),
      );
    }
  });
}

export function* watchForOpenscanConfigReceived() {
  yield takeEvery(openscanConfigReceived.toString(), function* (action) {
    const config = action.payload;

    try {
      const apps = yield* select(watchlistApps, {});
      yield put(policyViolationsReceived(config, apps));
    } catch (err) {
      yield put(
        NotificationsActions.requestNotifyUser({
          text: `Unable to update policy violations.`,
        }),
      );
    }
  });
}
