import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import { Text } from '@dt/material-components';

const PageTitle = ({ isLoading, title, style = {} }) => {
  // TODO@nw: Change the interface to *only* allow titles as strings.
  //          Then the following code can be used.
  // // Calculate the width the text will be after its done loading.
  // // Assumes that the provided text will be the same after loading :)
  // const canvas = document.createElement('canvas');
  // const context = canvas.getContext('2d');
  // context.font = `16px ${fontFamilies.primary}`;
  // // Unable to calculate height here.
  // // See https://stackoverflow.com/a/45789011/422312
  // const size = context.measureText(location.title);

  return isLoading ? (
    <Skeleton animation="pulse" height={50} style={{ marginRight: 8 }} variant="text" width={140} />
  ) : (
    <Text
      color="inherit"
      style={{
        fontSize: '1.5rem',

        fontWeight: 400,

        letterSpacing: '0em',

        lineHeight: '1.334',

        marginBottom: '0.35em',
        // TODO: Use correct design system variant for page titles.
        //       Keep "old" typography style from MUI until we can confirm that Text
        //       works for all pages.
        marginTop: 15,
        ...style,
      }}
      variant="titleL"
    >
      {title}
    </Text>
  );
};

export default PageTitle;
