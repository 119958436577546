import React from 'react';
import { SvgIcon, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import Text from '@dt/ahura/src/components/Text';
// @ts-ignore: #TODO-IMPLEMENT-TYPES-THEME
import { palette } from '@dt/theme';
const currentRouteStyle = (isCurrent, disabled) => ({
    backgroundColor: disabled ? '#ccc' : isCurrent ? palette.white : 'initial',
    borderRadius: 12,
    color: disabled ? '#666' : isCurrent ? palette.brand30 : palette.gray30,
    fill: isCurrent ? palette.brand30 : palette.gray30,
    height: 38,
    margin: 0,
    padding: '8px 12px',
});
const NavigationItemContent = ({ Icon, isCurrent, label, isSvg, disabled = false, tooltip = false }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement(Tooltip, { disableInteractive: true, role: "group", title: tooltip ? label : '' }, !Icon ? (React.createElement(Text, { style: currentRouteStyle(isCurrent, disabled), variant: "titleS" }, label)) : (React.createElement(Box, null, isSvg ? (React.createElement("div", { style: currentRouteStyle(isCurrent, disabled) },
            React.createElement(SvgIcon, null, Icon))) : (React.createElement(Box, { style: currentRouteStyle(isCurrent, disabled) },
            React.createElement(Icon, null))))))));
};
export default NavigationItemContent;
