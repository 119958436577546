import React, { memo, useEffect, useMemo, useState } from 'react';
import { Waypoint } from 'react-waypoint';
import SearchIcon from '@mui/icons-material/Search';
import { Grid, List, ListItem, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
// @ts-ignore JS component
import { useLazyQuery } from '@dt/apollo-link-schema-rest';
// @ts-ignore JS component
import { ErrorState } from '@dt/components';
import asset_tags from '@dt/graphql-support/horizon/ts/asset_tags';
import debounce from 'lodash/fp/debounce';
import TableFiltersAssetTagKeyItem from './TableFiltersAssetTagKeyItem';
const InventoryTableFilterAssetTagsComponent = function InventoryTableFilterAssetTags({ selectedAssetTags, onToggleAssetTagKey, onToggleAssetTagValue, }) {
    const [fetchAssetTagKeysQuery, { data, error, loading, called, fetchMore, pageNumber }] = useLazyQuery(asset_tags.list, {
        fetchPolicy: 'network-only',
    });
    useEffect(() => {
        if (!called) {
            fetchAssetTagKeysQuery({});
        }
    }, [fetchAssetTagKeysQuery, called]);
    const [filter, setFilter] = useState('');
    const handleChange = useMemo(() => debounce(500)((filter) => fetchAssetTagKeysQuery(filter
        ? {
            variables: {
                filter_by_text: filter,
            },
        }
        : {})), [fetchAssetTagKeysQuery]);
    return (React.createElement(Grid, { container: true, spacing: 1 },
        React.createElement(Grid, { item: true, xs: 12 },
            React.createElement(TextField, { InputProps: {
                    endAdornment: React.createElement(SearchIcon, null),
                }, fullWidth: true, id: "asset-keys-filter", label: 'Filter', onChange: e => {
                    setFilter(e.target.value);
                    handleChange(e.target.value);
                }, value: filter })),
        React.createElement(Grid, { item: true, xs: 12 },
            React.createElement(List, { component: "nav" },
                error ? (React.createElement(ErrorState, { error: error })) : !pageNumber && loading ? ([0, 1, 2, 3, 4].map((_, key) => (React.createElement(ListItem, { button: true, dense: true, key: key },
                    React.createElement(Skeleton, { animation: "wave", height: 40, width: `100%` }))))) : (data?.asset_tag_key_list.asset_tag_keys.map((assetTagKey, key) => assetTagKey && (React.createElement(TableFiltersAssetTagKeyItem, { assetTagKey: assetTagKey, key: key, onToggleAssetTagKey: onToggleAssetTagKey, onToggleAssetTagValue: onToggleAssetTagValue, selectedAssetTags: selectedAssetTags })))),
                fetchMore && (React.createElement(React.Fragment, null,
                    React.createElement(Box, { component: 'div' },
                        React.createElement(ListItem, { button: true, dense: true },
                            React.createElement(Skeleton, { animation: "wave", height: 40, width: `100%` })),
                        React.createElement(ListItem, { button: true, dense: true },
                            React.createElement(Skeleton, { animation: "wave", height: 40, width: `100%` })),
                        React.createElement(ListItem, { button: true, dense: true },
                            React.createElement(Skeleton, { animation: "wave", height: 40, width: `100%` }))),
                    React.createElement(Waypoint, { onEnter: () => {
                            if (fetchMore) {
                                fetchMore();
                            }
                        } })))))));
};
export default memo(InventoryTableFilterAssetTagsComponent);
