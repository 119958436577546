const DefaultResolvers = {
  Date: () => new Date(),
  PaginationInformation: () => ({
    next_cursor: () => undefined,
  }),
};
const getMockResolvers = mocks =>
  process.env.NODE_ENV === 'test'
    ? {
        ...DefaultResolvers,
        ...mocks,
      }
    : null;

export default getMockResolvers;
