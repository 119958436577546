import { createAction } from 'redux-actions';

export const dashboardOverviewMounted = createAction('mounted/dashboard/overview', () => ({
  key: 'dashboard_overview',
}));

export const actionItemViewerMounted = createAction('mounted/policy_violations/action_items', () => ({
  key: 'action_items',
}));

export const globalStatsMounted = createAction('mounted/global-stats', () => ({ key: 'global_stats' }));

export const requestInventoryStats = createAction('request/inventory-stats', () => void 0);

export const inventoryStatsReceived = createAction('received/inventory-stats', statsMessage => statsMessage);

export const inventoryStatsRemoveShadowAssetClicked = createAction(
  'request/inventory-stats/remove-asset',
  number => number,
);

export const environmentMapMounted = createAction('mounted/dashboard/environment-map', () => ({
  key: 'environment-map',
}));

export const hostPieChartMounted = createAction('mounted/dashboard/host-pie-chart', () => ({ key: 'host-pie-chart' }));

export const hostAssetVisualizationListMounted = createAction('mounted/host-asset-visualization-list', () => void 0);

export const hostAssetVisualizationListUnmounted = createAction(
  'unmounted/host-asset-visualization-list',
  () => void 0,
);

export const dashboardShadowAssetCounterMounted = createAction('mounted/dashboard/shadow-asset-counter', () => ({
  key: 'dashboard_shadow_asset_counter',
}));
