import config from '@dt/config';
import fetch, { parse } from '@dt/fetch';
import qs from 'query-string';
import { array, object, string } from 'yup';
import { TargetSchema } from './_targets';
import { check, result } from './util';

const NoteSchema = object().shape({
  author_email: string(),
  date: string(),
  date_created: string(),
  date_updated: string(),
  id: string(),
  security_finding_id: string(),
  text: string(),
});

const SecurityFindingSchema = object().shape({
  id: string().required(),
  notes: array().of(NoteSchema).ensure(),
  targets: array().of(TargetSchema).required(),
});

async function validate(finding) {
  return SecurityFindingSchema.validate(finding);
}

async function validateList(container) {
  return object()
    .shape({
      security_findings: array().of(SecurityFindingSchema),
    })
    .validate(container);
}

export async function get(id) {
  if (!id || !id.length) {
    throw new Error('Invalid security finding ID provided');
  }

  return fetch(`${config.sevenhellApiBaseUrl}/v2/security_findings/${id}`).then(parse).then(result).then(validate);
}

export async function list(params) {
  return fetch(
    `${config.sevenhellApiBaseUrl}/v2/security_findings${
      params && qs.stringify(params) ? `?${qs.stringify(params)}` : ''
    }`,
  )
    .then(parse)
    .then(result)
    .then(validateList);
}

export async function patch(id, params) {
  if (!id || !id.length) {
    throw new Error('Invalid finding ID provided');
  }

  if (!params) {
    throw new Error('A priority, aggregated_status or is_permanently_closed param is required');
  }

  // These values have to be removed from the patch params if null for now otherwise leading to corrupt data.
  // Sevenhell will be updated to ignore null values.
  if (typeof params.is_permanently_closed === 'undefined' || params.is_permanently_closed === null) {
    delete params['is_permanently_closed'];
  }

  if (typeof params.aggregated_status === 'undefined' || params.aggregated_status === null) {
    delete params['aggregated_status'];
  }

  if (typeof params.priority === 'undefined' || params.priority === null) {
    delete params['priority'];
  }

  if (params.priority != null) {
    return fetch(
      `${config.sevenhellApiBaseUrl}/v2/security_findings/${id}${
        params && qs.stringify(params) ? `?${qs.stringify(params)}` : ''
      }`,
      {
        method: 'PATCH',
      },
    )
      .then(parse)
      .then(result);
  } else {
    return fetch(`${config.sevenhellApiBaseUrl}/v2/security_findings/${id}`, {
      body: JSON.stringify(params),
      method: 'PATCH',
    })
      .then(parse)
      .then(result);
  }
}

export const targets = {
  jira_ticket: {
    async create(security_finding_id, target_id) {
      if (!security_finding_id || !security_finding_id.length) {
        throw new Error('Invalid security finding ID provided');
      }
      if (!target_id || !target_id.length) {
        throw new Error('Invalid target ID provided');
      }

      return fetch(
        `${config.sevenhellApiBaseUrl}/v2/security_findings/${security_finding_id}/targets/${target_id}/jira_ticket`,
        {
          method: 'POST',
        },
      )
        .then(parse)
        .then(result);
    },
  },
  statuses: {
    async create(security_finding_id, id, params) {
      if (!security_finding_id || !security_finding_id.length) {
        throw new Error('Invalid security finding ID provided');
      }

      if (!id || !id.length) {
        throw new Error('Invalid target IDs provided');
      }

      if (!params || !params.status) {
        throw new Error('A valid status is required');
      }

      return fetch(
        `${config.sevenhellApiBaseUrl}/v2/security_findings/${security_finding_id}/targets/${id}/statuses${
          params && qs.stringify(params) ? `?${qs.stringify(params)}` : ''
        }`,
        {
          method: 'POST',
        },
      )
        .then(parse)
        .then(result);
    },
  },
};

export const notes = {
  async create(security_finding_id, params) {
    if (!security_finding_id || !security_finding_id.length) {
      throw new Error('Invalid security finding ID provided');
    }
    if (!params || !params.text) {
      throw new Error('Note text is required');
    }

    return fetch(`${config.sevenhellApiBaseUrl}/v2/security_findings/${security_finding_id}/notes`, {
      body: JSON.stringify(params),
      method: 'POST',
    })
      .then(parse)
      .then(result);
  },

  async del(security_finding_id, id) {
    if (!security_finding_id || !security_finding_id.length) {
      throw new Error('Invalid security finding ID provided');
    }
    if (!id || !id.length) {
      throw new Error('Invalid note ID provided');
    }

    return fetch(`${config.sevenhellApiBaseUrl}/v2/security_findings/${security_finding_id}/notes/${id}`, {
      method: 'DELETE',
    })
      .then(parse)
      .then(check);
  },
};
