import React, { memo } from 'react';
import { useMutation } from '@apollo/client';
import scoped_access_tokens from '@dt/graphql-support/sevenhell/scoped_access_tokens';
import Banner from './Banner';
import { SharedLinksButtonCommon } from './SharedLinksButtonCommon';

/*
 * NOTE: Use the `SharedLinksButton` facade component.
 *
 * Shared links button used to share a mobile application security finding.
 *
 * @param mobile_application_id - Used with the api when creating a shared link
 * @param security_finding_id - Used with the api when creating a shared link
 *
 * @example
 *     <SharedLinksButtonSecurityFinding
 *       mobile_application_id={mobile_application.id}
 *       security_finding={security_finding.id} />
 */
const SharedLinksButtonSecurityFindingComponent = function SharedLinksButtonSecurityFinding({
  mobile_application_id,
  security_finding_id,
}) {
  const [createSharedLink, { loading, error }] = useMutation(scoped_access_tokens.create_for_finding_id);

  const handleOnCreate = async validity_duration => {
    const { data } = await createSharedLink({
      variables: {
        body: {
          validity_duration: validity_duration,
        },
        finding_id: security_finding_id,
      },
    });

    const access_token = data?.scoped_access_token_for_finding_id?.access_token;
    return access_token
      ? `${window.location.origin}/mobile/share/${access_token}/app/${mobile_application_id}/single-finding/${security_finding_id}`
      : '';
  };

  return (
    <SharedLinksButtonCommon
      error={error}
      loading={loading}
      manageShareLink="/shared-links"
      onCreate={handleOnCreate}
      title={`Create a Secure Link to Finding #${security_finding_id}`}
    >
      <Banner icon={<></>} inline status="warning">
        The secure link will allow temporary access only to this issue.
      </Banner>
    </SharedLinksButtonCommon>
  );
};

export const SharedLinksButtonSecurityFinding = memo(SharedLinksButtonSecurityFindingComponent);
