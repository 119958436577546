import React, { PureComponent } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';

class Notifier extends PureComponent {
  render() {
    const { notifications } = this.props;
    const notification = notifications.length ? notifications[notifications.length - 1] : null;

    if (!notification) {
      return null;
    }

    return notification.severity ? (
      <Snackbar
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        autoHideDuration={5000}
        onClose={(e, snackbarCloseReason) => {
          if (snackbarCloseReason === 'timeout') {
            this.handleClickDismiss(notification);
          }
        }}
        open={true}
      >
        <Alert
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              onClick={() => this.handleClickDismiss(notification)}
              size="small"
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          severity={notification.severity}
          variant="filled"
        >
          {notification.text}
        </Alert>
      </Snackbar>
    ) : (
      <Snackbar
        action={
          <Button color="secondary" onClick={() => this.handleClickDismiss(notification)}>
            Dismiss
          </Button>
        }
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
        autoHideDuration={5000}
        message={notification.text}
        onClose={() => {}}
        open={true}
      />
    );
  }

  handleClickDismiss = notification => {
    if (!notification) {
      throw new Error('Tried to dismiss nonexistant notification');
    }

    const { onDismissClick } = this.props;
    if (onDismissClick) {
      onDismissClick(notification);
    }
  };
}

export default Notifier;
