import { handleActions } from 'redux-actions';
import createReducerCreator from 'rezz/createReducerCreator';
import set from 'rezz/set';
import Actions from './actions';

function progressIndicatorReducer(state = 0, action) {
  const act = action.type;

  switch (act) {
    case Actions.startProgressIndicator.toString():
      return state + 1;
    case Actions.stopProgressIndicator.toString():
      return state > 0 ? state - 1 : 0;
    default:
      return state;
  }
}

const initialState = {};
function createFirstRequestReducer(actions) {
  return createReducerCreator({
    initialState: initialState,
    keyStrategy: action => action.payload,
    payloadStrategy: () => true,
  })(set('PROGRESS_INDICATOR_STOP'), handleActions(actions || {}, initialState));
}

export default {
  createFirstRequestReducer: createFirstRequestReducer,
  progressIndicatorReducer: progressIndicatorReducer,
};
