import React, { isValidElement, memo } from 'react';
import ChatBubbleOutline from '@mui/icons-material/ChatBubbleOutline';
import HardwareSecurity from '@mui/icons-material/Security';
import MediawatchPresentIcon from '@mui/icons-material/Whatshot';
import Paper from '@mui/material/Paper';
import SvgIcon from '@mui/material/SvgIcon';
import { makeStyles } from '@mui/styles';
import { palette } from '@dt/theme';
import classnames from 'classnames';
import chunk from 'lodash/fp/chunk';
import Image from './Image';
import PriorityBadge from './PriorityBadge';
import CompliancePolicyBanner from './compliance/CompliancePolicyBanner';

const visibleTargetLimit = 3;

const useStyles = makeStyles({
  CardWithTargets: {
    cursor: ({ noPointer }) => !noPointer && 'pointer',
    position: 'relative',
  },

  appProtectionBadgeIcon: {
    height: '5px !important',
    verticalAlign: '-10px !important',
    width: '5px !important',
  },

  appProtectionIcon: {
    color: palette.green20,
    height: '24px !important',
    marginRight: '4px !important',
    verticalAlign: '-2px !important',
    width: '24px !important',
  },

  badge: {
    backgroundColor: palette.gray50,
    borderColor: palette.gray50,
    borderRadius: 2,
    borderStyle: 'solid',
    marginRight: 4,
    padding: '0 2px',
  },

  childAndIconContainer: {
    alignItems: 'flex-end',
    display: 'flex',
    justifyContent: 'space-between',
  },

  childrenContainer: {
    color: palette.gray,
    overflow: 'hidden',
    paddingTop: 10,
  },

  contentContainer: {
    marginLeft: ({ logo }) => (logo ? 100 : 55),
    marginRight: 10,
    padding: '8px 0px',
  },

  iconContainer: {
    alignItems: 'center',
    color: palette.gray,
    display: 'flex',
    flexGrow: 2,
    flexShrink: 0,
    fontSize: 12,
    justifyContent: 'flex-end',
    paddingBottom: 5,
    paddingTop: 5,
    whiteSpace: 'nowrap',
  },

  left: {
    color: palette.gray,
    fontSize: 12,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },

  logo: {
    padding: 20,
    position: 'absolute',
    top: 0,
    width: 100,
  },

  mediawatchIcon: {
    color: palette.gray,
    height: '16px !important',
    marginRight: '4px !important',
    verticalAlign: '-2px !important',
    width: '16px !important',
  },

  notesBubble: {
    color: palette.gray,
    height: '12px !important',
    marginLeft: '4px',
    marginRight: '4px !important',
    verticalAlign: '-2px !important',
    width: '12px !important',
  },

  priority: {
    left: 20,
    position: 'absolute',
    top: 10,
  },

  right: {
    color: palette.gray,
    flexShrink: 1,
    fontSize: 12,
    whiteSpace: 'nowrap',
  },

  row: {
    alignItems: 'end',
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: 3,
  },

  statusIndicator: {
    backgroundColor: ({ indicator }) => {
      if (indicator === 'good') {
        return palette.green;
      } else if (indicator === 'bad') {
        return palette.red;
      } else if (indicator === 'neutral') {
        return palette.gray35;
      } else if (indicator === 'attention') {
        return palette.red;
      } else if (indicator === 'warning') {
        return palette.yellow;
      } else {
        return 'transparent';
      }
    },
    borderRadius: '2px 0px 0px 2px',
    bottom: 0,
    left: 0,
    position: 'absolute',
    top: 0,
    width: 7,
  },

  title: {
    color: palette.gray20,
    fontSize: 16,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
});

export default memo(function CardWithTargets(props) {
  const {
    text,
    priority,
    commentCount,
    compliance_policy_references,
    logo,
    mediawatchPresent,
    children,
    indicator,
    badge,
    appProtectionBadgeTitle,
    appProtectionBadgeAchieved,
  } = props;
  const classes = useStyles(props);

  const texts = Array.isArray(text) ? text : [text];

  const hasComplianceImplications = compliance_policy_references && compliance_policy_references.length > 0;

  return (
    <Paper className={classes.CardWithTargets}>
      <div className={classes.contentContainer}>
        {chunk(2)(texts).map((pair, i) => (
          <div className={classes.row} key={i}>
            <span className={classnames(classes.left, i === 0 && classes.title)}>{pair[0]}</span>
            <div className={classes.right}>{pair[1]}</div>
          </div>
        ))}
        <div className={classes.childAndIconContainer}>
          <div className={classes.childrenContainer}>{children}</div>
          <div className={classes.iconContainer}>
            {badge && (
              <div className={classes.badge} key="badge">
                {badge}
              </div>
            )}
            {appProtectionBadgeTitle && (
              <SvgIcon
                className={classes.appProtectionIcon}
                htmlColor={appProtectionBadgeAchieved ? palette.green20 : palette.gray}
              >
                <title>{appProtectionBadgeTitle}</title>
                <HardwareSecurity htmlColor={appProtectionBadgeAchieved ? palette.green20 : palette.gray} />
              </SvgIcon>
            )}
            {/* Mediawatch Indicator */}
            {mediawatchPresent ? (
              <SvgIcon className={classes.mediawatchIcon} htmlColor={palette.gray}>
                <title>Media Watch</title>
                <MediawatchPresentIcon />
              </SvgIcon>
            ) : null}

            {/* Compliance Policy Indicators */}
            {hasComplianceImplications ? (
              <CompliancePolicyBanner compliance_policy_references={compliance_policy_references || []} />
            ) : null}

            {/* Comment Count Indicator */}
            {commentCount ? (
              <span>
                <SvgIcon className={classes.notesBubble} htmlColor={palette.gray}>
                  <title>Comments</title>
                  <ChatBubbleOutline />
                </SvgIcon>
                {commentCount}
              </span>
            ) : null}
          </div>
        </div>
      </div>
      {isValidElement(indicator) ? indicator : <div className={classes.statusIndicator} />}
      <div className={classes.priority}>
        <PriorityBadge type={priority} />
      </div>
      {logo && (
        <div className={classes.logo}>
          <Image src={logo} />
        </div>
      )}
    </Paper>
  );
});

export function getRowHeight(targetsCount, findingsCount = 1, sdkMode = false) {
  const baseHeight = 70;

  if (!targetsCount) {
    return baseHeight;
  }

  if (sdkMode) {
    return baseHeight + targetsCount * 25 + findingsCount * 25;
  } else {
    return baseHeight + (targetsCount > visibleTargetLimit ? visibleTargetLimit : targetsCount) * 25;
  }
}
