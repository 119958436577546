import React, { memo } from 'react';
import { makeStyles } from '@mui/styles';
import { MobileAppsPlatformIcon } from '@dt/material-components';

const useStyles = makeStyles({
  IconLabel: {},

  icon: {
    height: 16,
    verticalAlign: 'middle',
    width: 16,
  },

  label: {
    marginLeft: 5,
    verticalAlign: 'middle',
  },
});

export default memo(function IconLabel(props) {
  const { children, platform } = props;
  const classes = useStyles();

  return (
    <span className={classes.IconLabel}>
      <MobileAppsPlatformIcon className={classes.icon} platform={platform} />
      <span className={classes.label}>{children}</span>
    </span>
  );
});
