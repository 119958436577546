import React from 'react';
import { Button, DialogActions, DialogContent, Grid } from '@mui/material';
import Box from '@mui/material/Box';
// @ts-ignore: #TODO-IMPLEMENT-TYPES-MATERIAL-COMPONENTS
import { Text } from '@dt/material-components';
// @ts-ignore: #TODO-IMPLEMENT-TYPES-REACH-ROUTER
import { useNavigate } from '@reach/router';
import PolicyViolationExportToJiraDialogHeader from './PolicyViolationExportToJiraDialogHeader';
const PolicyViolationExportToJiraDialogStepNoIntegrations = ({ handleClose }) => {
    const navigate = useNavigate();
    return (React.createElement(React.Fragment, null,
        React.createElement(PolicyViolationExportToJiraDialogHeader, { handleClose: handleClose, title: "No Jira integration has been configured yet." }),
        React.createElement(DialogContent, null,
            React.createElement(Grid, { container: true, spacing: 1 },
                React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(Box, { mb: 2, mt: 2 },
                        React.createElement(Text, { component: "span", variant: "body" }, "To export a policy violation to Jira, a Jira instance must be configured first."))))),
        React.createElement(DialogActions, null,
            React.createElement(Button, { onClick: handleClose }, "Cancel"),
            React.createElement(Button, { color: "primary", onClick: () => {
                    navigate && navigate(`/devsecops/integrations/jira/configure`);
                }, variant: "contained" }, "Onboard Jira Instance"))));
};
export default PolicyViolationExportToJiraDialogStepNoIntegrations;
