import React, { PureComponent } from 'react';
import logo from '@dt/brand/logo-white.png';

const getStyles = (style = {}) => ({
  img: {
    width: 194,
    ...style.img,
  },

  logo: {
    height: 24,
    ...style.logo,
  },

  wrapper: {
    display: 'flex',
    ...style.wrapper,
  },
});

export default class Logo extends PureComponent {
  render() {
    const { style, onClick } = this.props;
    const styles = getStyles(style);
    return (
      <div onClick={onClick} style={styles.wrapper}>
        <div style={styles.logo}>
          <img src={logo} style={styles.img} />
        </div>
      </div>
    );
  }
}
