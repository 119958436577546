import gql from 'graphql-tag';
const list = gql `
  query AlertsIntegrationsList($filter_by_integration_type: [String], $cursor: String) {
    alerts_integrations_list(filter_by_integration_type: $filter_by_integration_type, cursor: $cursor) {
      pagination_information {
        next_cursor
        total_count
      }
      alerts_integrations {
        id
        name
        integration_type
        status
        most_recent_attempt_date
      }
    }
  }
`;
const getAsmAssetTypeValues = gql `
  query GetAsmAssetTypeValues {
    alerts_integration_asm_asset_type_values {
      alerts_integration_asm_asset_type_values {
        name
        value_category
        value
      }
    }
  }
`;
export default {
    getAsmAssetTypeValues,
    list,
};
