import { createAction } from 'redux-actions';

export const updateAppLinkingStarted = createAction(
  'UPDATE_APP_LINKING_STARTED',
  (app, isEnterpriseInternal, linkedAppStoreAppId) => ({
    app,
    isEnterpriseInternal,
    linkedAppStoreAppId,
  }),
);

export const updateAppLinkingFailure = createAction('UPDATE_APP_LINKING_FAILURE', message => ({
  message,
}));

export const updateAppLinkingSuccess = createAction('UPDATE_APP_LINKING_SUCCESS', updatedApp => ({
  updatedApp,
}));
