import { all, call } from 'redux-saga/effects';
import { takePatternAndFetch, watchForLoadMoreAndFetchNextPage } from '../resource_fetch/sagas';
import { policyViolationDetailsMounted } from './actions';
import resourceType from './resourceType';
import { getPolicyViolationDetails, getPolicyViolationList } from './resource_fetch.sagas';

export default function* watchForResourceFetching() {
  yield all([
    call(takePatternAndFetch, 'mounted/policy_violations/*'.toString(), function* () {
      yield all([call(getPolicyViolationList, {})]);
    }),
    call(takePatternAndFetch, policyViolationDetailsMounted.toString(), function* (action) {
      const policyViolationId = action.payload.policyViolationId;
      yield all([call(getPolicyViolationDetails, policyViolationId)]);
    }),
    call(watchForLoadMoreAndFetchNextPage, resourceType, function* ({ cursor }) {
      yield call(getPolicyViolationList, { cursor });
    }),
  ]);
}
