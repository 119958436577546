import React, { memo } from 'react';
import { Button } from '@mui/material';

const AssetTagsEditDialogAddTag = ({ disabled, onClick }) => {
  return (
    <Button disabled={disabled} onClick={onClick} variant="contained">
      Add Tag
    </Button>
  );
};

export default memo(AssetTagsEditDialogAddTag);
