import React, { memo, useMemo } from 'react';
import LaunchIcon from '@mui/icons-material/Launch';
import { Grid } from '@mui/material';
import { Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { makeStyles } from '@mui/styles';
import {
  AssetTypeEnumValues,
  HostedOnValues,
  HttpMethodValues,
  SpecialScanStatusValues,
} from '@dt/graphql-support/enums';
import { palette } from '@dt/theme';
import { dateFormats } from '../../../apps/ahura/src/util/dateFormats';
import { formatDateDefault } from '../../../apps/ahura/src/util/formatDateDefault';
import TagHostedOn from '../TagHostedOn';
import Text from '../Text';
import ToolkitTableLastScanStatus from './ToolkitAssetTableLastScanStatus';
import ToolkitAssetTableLastTested from './ToolkitAssetTableLastTested';
import ToolkitTableSelectedRowScanProgress from './ToolkitAssetTableSelectedRowScanProgress';

const useStyles = makeStyles({
  cell: {
    padding: 8,
  },
  row: props => ({
    '&:hover': {
      backgroundColor: palette.gray50,
    },
    backgroundColor: props.backgroundColor,
    cursor: 'pointer',
  }),
  scanHistoryBlock: {
    "&[data-color='green']": {
      background: palette.green30,
    },
    "&[data-color='red']": {
      background: palette.red30,
    },
    borderRadius: 2,
    height: 14,
    margin: 2,
    maxHeight: 14,
    maxWidth: 14,
    minHeight: 14,
    minWidth: 14,
    width: 14,
  },
  text: {
    maxWidth: 300,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
});

function getAssetDetails(asset) {
  // api operation asset
  if (asset.http_method) {
    return {
      description: `API Operation at ${
        asset.http_method === HttpMethodValues.X_HORIZON_UNDEFINED_HTTP_METHOD ? 'ANY' : asset.http_method
      } ${asset.path || ''}`,
      id: asset.id,
    };
  }
  //cloud resource asset
  return {
    description: `${asset.asset_type_name || ''} at ${asset.name || ''}`,
    id: asset.id,
  };
}

const assetTypeToPath = Object.freeze({
  [AssetTypeEnumValues.CLOUD_RESOURCE]: 'cloud-resources',
  [AssetTypeEnumValues.NETWORK_SERVICE]: 'network-services',
  [AssetTypeEnumValues.RESTFUL_API]: 'assets',
  [AssetTypeEnumValues.WEB_APPLICATION]: 'web-applications',
  [AssetTypeEnumValues.API_OPERATION]: 'api-operations',
  [AssetTypeEnumValues.GRPC_SERVICE]: 'assets',
  [AssetTypeEnumValues.GRPC_METHOD]: 'assets',
  [AssetTypeEnumValues.KUBERNETES_CLUSTER]: 'assets',
  [AssetTypeEnumValues.KUBERNETES_CLUSTER_COMPONENT]: 'assets',
  [AssetTypeEnumValues.SOAP_API]: 'assets',
  [AssetTypeEnumValues.SOAP_API_OPERATION]: 'assets',
  [AssetTypeEnumValues.REPOSITORY]: 'assets',
});

function ToolkitAssetTableRow({ asset, assetType, scans, openRowId, onClick, portalPath, isV2 = false }) {
  const { id, description } = useMemo(() => getAssetDetails(asset), [asset]);

  const isRowOpen = Boolean(asset.id === openRowId);

  const classes = useStyles({
    backgroundColor: isRowOpen ? palette.gray50 : null,
  });

  const scanHistory = scans
    .slice(0, 5)
    .filter(scan => formatDateDefault({ date: scan.date_created }) >= formatDateDefault({ date: asset.date_created }))
    .map(scan => ({
      color: 'green',
      description: `
        ${formatDateDefault({ date: scan.date_created, formatStr: dateFormats.yyyyMMdd })} |
        ${'Protected'}
      `,
    }));

  let authScheme = '';
  if (asset.authentication_scheme) {
    authScheme = asset.authentication_scheme;
  }

  return (
    <TableRow classes={{ root: classes.row }} onClick={() => onClick(asset)}>
      <TableCell classes={{ root: classes.cell }}>
        <Grid alignContent="center" alignItems="center" container spacing={1}>
          <Grid item xs={6}></Grid>
          <Grid item xs={6}>
            {id && (
              <Grid alignContent="center" alignItems="center" container justifyContent="center">
                <a
                  href={`${portalPath}/${isV2 ? 'v2/' : ''}${assetTypeToPath[assetType]}/${id}`}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <Text variant={'link'}>
                    <LaunchIcon />
                  </Text>
                </a>
              </Grid>
            )}
          </Grid>
        </Grid>
      </TableCell>
      <TableCell align="left" classes={{ root: classes.cell }}>
        <Box alignItems="center" display="flex">
          <TagHostedOn hosted_on={asset.hosted_on || HostedOnValues.UNKNOWN} />
          <Text className={classes.text} component="div" variant="body">
            {description}
          </Text>
        </Box>
      </TableCell>
      <TableCell align="left" classes={{ root: classes.cell }}>
        <Grid container justifyContent="flex-start">
          {scans[scans.length - 1].current_status === SpecialScanStatusValues.ONGOING
            ? '-'
            : scanHistory?.map(({ description, color }, key) => (
                <Grid item key={key}>
                  <Tooltip aria-label={description} disableInteractive title={description}>
                    <div className={classes.scanHistoryBlock} data-color={color}></div>
                  </Tooltip>
                </Grid>
              ))}
        </Grid>
      </TableCell>

      <TableCell align="left" classes={{ root: classes.cell }}>
        <Text
          style={{
            color: authScheme === 'Basic Auth' ? palette.red : 'initial',
          }}
          variant="code"
        >
          {authScheme}
        </Text>
      </TableCell>

      <TableCell align="center" classes={{ root: classes.cell }}>
        <Box display="flex" justifyContent="center">
          {scans[scans.length - 1].current_status === SpecialScanStatusValues.FINISHED ? (
            <ToolkitTableLastScanStatus lastScanStatus={'Protected'} />
          ) : scans[scans.length - 1].current_status === SpecialScanStatusValues.ONGOING ? (
            <ToolkitTableSelectedRowScanProgress offset={Math.floor(Math.random() * 20)} />
          ) : (
            '-'
          )}
          <ToolkitAssetTableLastTested
            discoveredDate={asset.date_created}
            lastScanDateCreated={scans[scans.length - 1].date_created}
            lastScanStatus={'Protected'}
          />
        </Box>
      </TableCell>
    </TableRow>
  );
}

export default memo(ToolkitAssetTableRow);
