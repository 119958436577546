import { useQuery as useApolloQuery } from '@apollo/client';
import useBaseQuery from './useBaseQuery';

export default function useQuery(query, apolloOptions, options = { ...null }) {
  const apolloResult = useApolloQuery(query, {
    notifyOnNetworkStatusChange: true,
    ...apolloOptions,
  });

  const resultsWithFetchMore = useBaseQuery(apolloResult, options);

  return resultsWithFetchMore;
}
