import React, { memo } from 'react';
import { makeStyles } from '@mui/styles';
import { palette } from '@dt/theme';
import { formatDateDefault } from '../../../apps/ahura/src/util/formatDateDefault';

function ifHasStuffBesidesChildren(value) {
  return ({ indicator, date }) => (indicator || date ? value : null);
}

const useStyles = makeStyles({
  CardLine: {
    alignItems: 'baseline',
    display: 'flex',
  },

  date: {
    color: palette.faded,
    fontSize: 12,
    marginLeft: 5,
    whiteSpace: 'nowrap',
  },

  indicator: {
    whiteSpace: 'nowrap',
  },

  text: {
    borderLeft: ifHasStuffBesidesChildren(`1px solid #ccc`),
    flex: '0 1 auto',
    marginLeft: ifHasStuffBesidesChildren(10),
    marginRight: 5,
    overflow: 'hidden',
    paddingLeft: 10,
    paddingRight: 5,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
});

export default memo(function CardLine(props) {
  const { indicator, date, children } = props;
  const classes = useStyles(props);

  return (
    <div className={classes.CardLine}>
      {indicator && <span className={classes.indicator}>{indicator}</span>}
      {date && <span className={classes.date}>{formatDateDefault({ date })}</span>}
      <span className={classes.text}>{children}</span>
    </div>
  );
});
