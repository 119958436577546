import React from 'react';
import { Link, Match } from '@reach/router';
import MenuItem from './Item';

export const MenuLinkItemReachRouter = props => {
  const { path, exact, strict, tooltip, replace, hasSubMenu, isSubMenu, isChildApp, ...appMenuItemProps } = props;

  return (
    <Match path={path || ''}>
      {({ match }) => {
        if (path) {
          return (
            <Link replace={replace} style={{ display: 'block' }} to={path}>
              <MenuItem
                active={!!match}
                hasSubMenu={hasSubMenu}
                isChildApp={isChildApp}
                isSubMenu={isSubMenu}
                tooltip={tooltip}
                {...appMenuItemProps}
              />
            </Link>
          );
        }

        return <MenuItem {...appMenuItemProps} />;
      }}
    </Match>
  );
};
