import { PolicyViolationException } from '@dt/horizon-api';

// Status is the current state of a policy violation.
// If it has an exception, the exception will be the current status.
// Otherwise, it will be either resolved or unresolved
export const policyViolationStatus = {
  ...PolicyViolationException,
  RESOLVED: 'RESOLVED',
  UNRESOLVED: 'UNRESOLVED',
};

export function affectedComponentDescription({
  affected_api_operation,
  affected_cloud_resource,
  affected_network_service,
  affected_web_application,
}) {
  if (affected_api_operation) {
    return `API operation at ${affected_api_operation.http_method} ${affected_api_operation.restful_api.base_url}${affected_api_operation.path}`;
  } else if (affected_cloud_resource) {
    return `Asset ${affected_cloud_resource.asset_type_name} ${
      affected_cloud_resource.url ? `at ${affected_cloud_resource.url}` : ''
    }`;
  } else if (affected_network_service) {
    return `Web Server at ${affected_network_service.domain_name.name}`;
  } else if (affected_web_application) {
    return `Web Application at ${affected_web_application.base_url}`;
  }
  // TODO: Should we throw here? Sentry?
  return `Unknown Asset Affected`;
}
