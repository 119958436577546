import React from 'react';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { Text } from '@dt/material-components';
import { PolicyViolationsAffectedAssetLinkText } from './PolicyViolationsAffectedAssetLinkText';

export const PolicyViolationsAffectedAssetLinkBanner = props => {
  if (props.isLoading) {
    return (
      <Box>
        <Skeleton animation="pulse" height={60} variant="text" width={40} />
        <PolicyViolationsAffectedAssetLinkText isLoading />
      </Box>
    );
  }

  return (
    <>
      {props.affected_asset?.url && (
        <Text style={{ margin: 0 }} variant="titleXS">
          Affects:{' '}
          <PolicyViolationsAffectedAssetLinkText
            affected_asset={props.affected_asset}
            isMobileSecure={props.isMobileSecure}
            isV2={props.isV2}
            productBasePath={props.productBasePath}
          />
        </Text>
      )}
    </>
  );
};
