import React, { memo, useMemo, useState } from 'react';
import { Waypoint } from 'react-waypoint';
import { ClickAwayListener, MenuItem, MenuList, Popover, TextField } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { useLazyQuery } from '@dt/apollo-link-schema-rest';
import asset_tags from '@dt/graphql-support/horizon/asset_tags';
import debounce from 'lodash/fp/debounce';
import ErrorState from '../containers/ErrorState';

const AssetTagsEditDialogAssetTagRowValue = ({ assetTag, assetTagErrorMessage, onUpdate, disabled }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleActionClose = () => setAnchorEl(null);

  const [fetchAssetTagValuesQuery, { data, error, loading, fetchMore }] = useLazyQuery(asset_tags.values.list, {
    fetchPolicy: 'network-only',
  });

  const onTextFieldChange = useMemo(
    () =>
      debounce(500)(text => {
        assetTag.id &&
          fetchAssetTagValuesQuery({
            variables: {
              filter_by_text: text,
              tag_uuid: assetTag.id,
            },
          });
      }),
    [fetchAssetTagValuesQuery, assetTag.id],
  );

  const onSelectAssetTagValue = assetTagValue => {
    onUpdate(assetTagValue);
    setAnchorEl(null);
  };

  return (
    <>
      <TextField
        InputLabelProps={{
          shrink: true,
        }}
        disabled={disabled}
        error={Boolean(assetTagErrorMessage)}
        fullWidth
        helperText={assetTagErrorMessage || null}
        label="value"
        margin="dense"
        onChange={e => {
          onUpdate({
            ...assetTag,
            value: e.target.value,
          });
          if (e.target.value) {
            setAnchorEl(e.currentTarget);
            onTextFieldChange(e.target.value);
          }
        }}
        placeholder="Enter asset tag value"
        value={assetTag.value || ''}
        variant="outlined"
      />
      <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
        <Popover
          anchorEl={anchorEl}
          anchorOrigin={{
            horizontal: 'left',
            vertical: 'bottom',
          }}
          disableAutoFocus={true}
          disableEnforceFocus={true}
          id={'inventory-edit-action'}
          onClose={handleActionClose}
          open={(loading || error || Boolean(data)) && Boolean(anchorEl)}
          transformOrigin={{
            horizontal: 'left',
            vertical: 'top',
          }}
        >
          <MenuList
            style={{
              maxHeight: 400,
              overflow: 'auto',
              paddingBottom: 0,
              width: 250,
            }}
          >
            {error ? (
              <ErrorState error={error} />
            ) : loading ? (
              [0, 1, 2, 3, 4, 5].map((_, key) => (
                <MenuItem dense key={key}>
                  <Skeleton animation="wave" height={30} width={`100%`} />
                </MenuItem>
              ))
            ) : (
              data?.asset_tag_value_list.asset_tags.map((assetTagValue, idx) => (
                <MenuItem
                  key={idx}
                  onClick={() =>
                    onSelectAssetTagValue({
                      id: assetTag.id,
                      tag: assetTag.tag,
                      tag_id: assetTag.tag_id,
                      value: assetTagValue.value,
                    })
                  }
                >
                  {assetTag.value}
                </MenuItem>
              ))
            )}
            {!loading &&
            !error &&
            data &&
            !data?.asset_tag_value_list.asset_tags.find(tag => tag.value === assetTag.value) ? (
              <MenuItem onClick={() => onSelectAssetTagValue(assetTag)}>{assetTag.value} (new tag value)</MenuItem>
            ) : null}
            {fetchMore && (
              <Waypoint
                onEnter={() => {
                  if (fetchMore) {
                    fetchMore();
                  }
                }}
              >
                <MenuItem dense>
                  <Skeleton animation="wave" height={40} width={`100%`} />
                </MenuItem>
              </Waypoint>
            )}
          </MenuList>
        </Popover>
      </ClickAwayListener>
    </>
  );
};

export default memo(AssetTagsEditDialogAssetTagRowValue);
