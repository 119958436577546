import React, { useEffect, useRef, useState } from 'react';
import { Grid, Skeleton } from '@mui/material';
import { isAfter } from 'date-fns';
import { PolicyViolationsCommentsItem } from './PolicyViolationsCommentsItem';
import { PolicyViolationsCommentsListEmpty } from './PolicyViolationsCommentsListEmpty';

const sortComments = function sortComments(a, b) {
  if (!a.date) {
    return 1;
  }

  if (!b.date) {
    return -1;
  }

  if (isAfter(a.date, b.date)) {
    return 1;
  } else {
    return -1;
  }
};

/*
 * Lists out policy violation comments for the user.
 *
 * @param comments - Comment data to render.
 */
function PolicyViolationsCommentsListImplementation({ comments }) {
  // Tracks whether the comments were scrolled on mount.
  // Used to start the user viewing the latest comments.
  const [hasStartedScrolled, setHasStartedScrolled] = useState(false);

  // Tracks the container scroll context and bottom of container.
  // Used to automatically scroll the comments when new ones become available.
  const containerRef = useRef(null);
  const lastCommentItemRef = useRef(null);

  // Make sure comments are sorted.
  const sortedComments = comments ? comments.slice().sort(sortComments) : [];

  // Scroll *immediately* to the bottom of the comments when comments are upddated
  // and the user *was* at the bottom.
  // If the user wasn't at the bottom they want to be viewing latest comments.
  useEffect(() => {
    // Do we have refs yet?
    if (!lastCommentItemRef.current) return;
    if (!containerRef.current) return;

    // scrollIntoView is not mocked in JSDOM.
    if (!lastCommentItemRef.current.scrollIntoView) return;

    // Enable smooth scrolling, after we've scrolled to the bottom the first time.
    if (!hasStartedScrolled) {
      lastCommentItemRef.current.scrollIntoView(false);
      setHasStartedScrolled(true);
    } else {
      // Don't be auto scrolling unless the user expects new comments?
      // Only scroll to the bottom if the user is viewing the last comment.
      if (
        containerRef.current.scrollTop + containerRef.current.offsetHeight >
        containerRef.current.scrollHeight - (lastCommentItemRef.current.offsetHeight + 40)
      ) {
        lastCommentItemRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [comments, hasStartedScrolled]);

  return (
    <>
      {/*
          This container div is here and expanded to force a scroll area to this level
          and not to the parent element.

          DO NOT update the `overflow` to something other than `auto`.
          Scroll area behavior is implemented differently per browser.
      */}
      <div ref={containerRef} style={{ height: '100%', overflow: 'auto', width: '100%' }}>
        {sortedComments.length <= 0 ? (
          <PolicyViolationsCommentsListEmpty gutterBottom variant="center" />
        ) : (
          <>
            {sortedComments.map(comment => (
              <PolicyViolationsCommentsItem
                author={comment.author_email}
                date={comment.date}
                is_internal_comment={comment.is_internal_comment}
                key={comment.id}
                ref={element => {
                  lastCommentItemRef.current = element;
                }}
              >
                {comment.text}
              </PolicyViolationsCommentsItem>
            ))}
          </>
        )}
      </div>
    </>
  );
}

function PolicyViolationsCommentsListSkeleton() {
  return (
    <Grid container direction="row" spacing={1}>
      <Grid item xs={12}>
        <Skeleton animation="pulse" height={80} variant="rectangular" width={'100%'} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton animation="pulse" height={80} variant="rectangular" width={'100%'} />
      </Grid>
      <Grid item xs={12}>
        <Skeleton animation="pulse" height={80} variant="rectangular" width={'100%'} />
      </Grid>
    </Grid>
  );
}

const PolicyViolationsCommentsListComponent = function PolicyViolationsCommentsList(props) {
  if (props.isLoading) {
    return <PolicyViolationsCommentsListSkeleton {...props} />;
  }

  return <PolicyViolationsCommentsListImplementation {...props} />;
};

export const PolicyViolationsCommentsList = PolicyViolationsCommentsListComponent;
