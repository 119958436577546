/**
 * truncate a given number and adds "K", 'M", etc to end of it
 * some examples => 10000 -> 10K, 3293604 -> 3.3M
 * @param {given number} num
 * @param {decimal point} fixed
 */
export function truncateNumber(_num, fixed) {
    if (!_num) {
        return '0';
    }
    const num = _num?.valueOf();
    const fixedValue = !fixed || fixed < 0 ? 0 : fixed; // number of decimal places to show
    const b = num.toPrecision(2).split('e'); // get power
    const k = b.length === 1 ? 0 : Math.floor(Math.min(Number(String(b[1]).slice(1)), 14) / 3); // floor at decimals, ceiling at trillions
    const c = Number(k < 1 ? num.toFixed(0 + fixedValue) : (num / Math.pow(10, k * 3)).toFixed(1 + fixedValue)); // divide by power
    const d = c < 0 ? c : Math.abs(c); // enforce -0 is 0
    const e = d + ['', 'K', 'M', 'B', 'T'][k]; // append power
    return e;
}
