import gql from 'graphql-tag';

const list = gql`
  query PolicyRulesList(
    $cursor: String
    $policy_rule_type_id: String
    $filter_by_has_open_policy_violations: Boolean
    $is_eligible_for_auto_remediation: Boolean
  ) {
    policy_rules_list(
      cursor: $cursor
      policy_rule_type_id: $policy_rule_type_id
      filter_by_has_open_policy_violations: $filter_by_has_open_policy_violations
      is_eligible_for_auto_remediation: $is_eligible_for_auto_remediation
    ) {
      pagination_information {
        next_cursor
        total_count
      }
      policy_rules {
        id
        belongs_to_policy_id
        relevance
        created_by_user_id
        deleted_by_user_id
        date_created
        policy_rule_type_id
        deletion_explanation
        relevance
        configuration {
          nb_of_days_before_cert_expiration
          http_methods_to_scan
          weekdays_when_to_scan
          time_when_to_scan
          enabled_google_pii_types
        }
        belongs_to_policy_name
        open_policy_violations_count
        resolved_policy_violations_count
        policy_rule_type {
          id
          title
        }
      }
    }
  }
`;

const v2_list = gql`
  query V2PolicyRulesList(
    $cursor: String
    $include: PolicyRulesIncludeEnum
    $filter_by_policy_rule_type_id: String
    $filter_by_has_open_policy_violations: Boolean
    $filter_by_asset_group_id: [String]
    $filter_by_no_asset_group: Boolean
    $filter_by_text: String
    $filter_by_relevance: [PolicyRuleTypeRelevance!]
    $filter_by_asset_type: [AssetTypeEnum!]
    $filter_by_compliance_standard: [PolicyComplianceStandardCriteria!]
    $is_eligible_for_auto_remediation: Boolean
    $filter_by_asset_type_group: [AssetTypeGroup]
    $filter_by_asset_ownership: String
    $filter_by_has_policy_violations_with_status: [String]
    $filter_by_asset_vendor_id: [String]
    $filter_by_has_policy_violations_with_statuses: Boolean
    $filter_by_severity: [FindingSeverity]
    $filter_by_policy_violation_named_filter: String
    $filter_by_kspm_named_filter: String
    $filter_by_belongs_to_products: [String]
    $filter_by_cnapp_category: [String]
    $filter_by_related_first_party_asset_id: [ID]
    $filter_by_has_policy_violations_created_on_month: String
    $filter_by_sast_issue_type: [String]
    $filter_by_asset_discovered_via: [String]
    $filter_by_sast_named_filter: String
    $filter_by_asset_id: [String]
  ) {
    policy_rules_list_v2(
      cursor: $cursor
      include: $include
      filter_by_policy_rule_type_id: $filter_by_policy_rule_type_id
      filter_by_has_open_policy_violations: $filter_by_has_open_policy_violations
      filter_by_asset_group_id: $filter_by_asset_group_id
      filter_by_no_asset_group: $filter_by_no_asset_group
      filter_by_text: $filter_by_text
      filter_by_relevance: $filter_by_relevance
      filter_by_asset_type: $filter_by_asset_type
      filter_by_compliance_standard: $filter_by_compliance_standard
      is_eligible_for_auto_remediation: $is_eligible_for_auto_remediation
      filter_by_asset_type_group: $filter_by_asset_type_group
      filter_by_asset_ownership: $filter_by_asset_ownership
      filter_by_severity: $filter_by_severity
      filter_by_policy_violation_named_filter: $filter_by_policy_violation_named_filter
      filter_by_kspm_named_filter: $filter_by_kspm_named_filter
      filter_by_has_policy_violations_with_status: $filter_by_has_policy_violations_with_status
      filter_by_asset_vendor_id: $filter_by_asset_vendor_id
      filter_by_has_policy_violations_with_statuses: $filter_by_has_policy_violations_with_statuses
      filter_by_belongs_to_products: $filter_by_belongs_to_products
      filter_by_cnapp_category: $filter_by_cnapp_category
      filter_by_related_first_party_asset_id: $filter_by_related_first_party_asset_id
      filter_by_has_policy_violations_created_on_month: $filter_by_has_policy_violations_created_on_month
      filter_by_sast_issue_type: $filter_by_sast_issue_type
      filter_by_asset_discovered_via: $filter_by_asset_discovered_via
      filter_by_sast_named_filter: $filter_by_sast_named_filter
      filter_by_asset_id: $filter_by_asset_id
    ) {
      pagination_information {
        next_cursor
        total_count
      }
      policy_rules {
        id
        relevance
        included_policy_violation_stats {
          open_violations_with_ongoing_triaging_effort_count
          open_violations_without_ongoing_triaging_effort_count
          resolved_violations_count
          wont_fix_violations_count
          affected_assets_count
        }
        policy_rule_type {
          id
          description
          recommendation
          title
        }
      }
    }
  }
`;

export const by_policy_id_list = gql`
  query PolicyRulesByPolicyIdList($cursor: String, $policyId: ID!) {
    policy_rules_by_policy_id_list(cursor: $cursor, policyId: $policyId) {
      pagination_information {
        next_cursor
        total_count
      }
      policy_rule_types {
        compliance_policy_references {
          compliance_standard
          compliance_standard_criteria
          description
          link
        }
        configuration_default_value {
          nb_of_days_before_cert_expiration
          http_methods_to_scan
          weekdays_when_to_scan
          time_when_to_scan
          enabled_google_pii_types
        }
        default_relevance
        description
        group
        id
        recommendation
        requires_configuration
        title
      }
      policy_rules {
        id
        belongs_to_policy_id
        relevance
        created_by_user_id
        deleted_by_user_id
        date_created
        policy_rule_type_id
        deletion_explanation
        relevance
        configuration {
          nb_of_days_before_cert_expiration
          http_methods_to_scan
          weekdays_when_to_scan
          time_when_to_scan
          enabled_google_pii_types
        }
        open_policy_violations_count
        belongs_to_policy_name
      }
      users {
        date_created
        first_name
        id
        last_name
        login_email
        notification_email
      }
    }
  }
`;

export const search_filter_values = gql`
  query PolicyRulesSearchFilterValues {
    policy_rules_search_filter_values {
      policy_rules_search_filter_values {
        belongs_to_filter
        icon_url
        name
        value
        value_category
      }
    }
  }
`;

export const create = gql`
  mutation PolicyRulesCreate($id: ID, $body: PolicyRulesCreateParams) {
    policy_rules_create(id: $id, body: $body) {
      id
      belongs_to_policy_id
      relevance
      created_by_user_id
      deleted_by_user_id
      date_created
      policy_rule_type_id
      deletion_explanation
      configuration {
        nb_of_days_before_cert_expiration
        http_methods_to_scan
        weekdays_when_to_scan
        time_when_to_scan
        enabled_google_pii_types
      }
      open_policy_violations_count
      resolved_policy_violations_count
      belongs_to_policy_name
    }
  }
`;

export const remove = gql`
  mutation PolicyRulesRemove($policyId: ID!, $ruleId: ID!) {
    policy_rules_remove(policyId: $policyId, ruleId: $ruleId) {
      nothing
    }
  }
`;

const update = gql`
  mutation PolicyRulesUpdate($id: ID, $policy_rule_id: ID, $body: PolicyRulesUpdateParams) {
    policy_rules_update(id: $id, policy_rule_id: $policy_rule_id, body: $body) {
      policy_rules {
        id
        belongs_to_policy_id
        relevance
        created_by_user_id
        deleted_by_user_id
        date_created
        policy_rule_type_id
        deletion_explanation
        relevance
        configuration {
          nb_of_days_before_cert_expiration
          http_methods_to_scan
          weekdays_when_to_scan
          time_when_to_scan
          enabled_google_pii_types
        }
        open_policy_violations_count
        resolved_policy_violations_count
      }
      policy_rule_types {
        id
        title
        default_relevance
      }
    }
  }
`;

/*
 * Creates a policy rule configuration from a default config.
 * This default is usually provided by a policy rule type.
 */
export function createPolicyRuleConfig(configuration_default_value) {
  if (!configuration_default_value) return null;

  const config = {};
  if (configuration_default_value.http_methods_to_scan) {
    config.http_methods_to_scan = [...configuration_default_value.http_methods_to_scan];
  }

  if (configuration_default_value.time_when_to_scan) {
    config.time_when_to_scan = configuration_default_value.time_when_to_scan;
  }

  if (configuration_default_value.weekdays_when_to_scan) {
    config.weekdays_when_to_scan = [...configuration_default_value.weekdays_when_to_scan];
  }

  return config;
}

export default {
  by_policy_id_list,
  create,
  list,
  remove,
  search_filter_values,
  update,
  v2_list,
};
