import { createSelector } from 'reselect';
import { certificates } from '../certificates/selectors';

export const certificate_chains = ({ certificate_chains }) => certificate_chains;

export const getAllCertificateChainsDecorated = createSelector(
  certificate_chains,
  certificates,
  (certificate_chains, certificates) => {
    return certificate_chains
      .filter(cert => !cert.date_removed)
      .map(certificate_chain => decorate(certificate_chain, certificates))
      .filter(Boolean);
  },
);

export const decorate = (certificate_chain, certificates) => {
  const own_certificates = certificate_chain.certificate_ids
    .map(certId => certificates.find(certificate => certificate.id === certId))
    .filter(Boolean);

  if (!own_certificates.length) return null;

  return {
    ...certificate_chain,
    certificates: own_certificates,
  };
};
