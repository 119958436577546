import { values } from '@dt/functions';
import { createSelector } from 'reselect';
import { createObjectSelector } from 'reselect-map';
import { domain_names } from '../domain_names/selectors';
import { getCurrentSearchId, getResultsOfSearch } from '../inventory/search/selectors';
import { getAllTasksWithAffectedComponents } from '../policy_violations/selectors';
import { tls_scan_results } from '../tls_scan_results/selectors';
import { decorate, network_services } from './util';

const idFromProps = (_, props = {}) => props.id;

export const getNetworkServiceDecoratedFromId = createSelector(
  network_services,
  idFromProps,
  domain_names,
  getAllTasksWithAffectedComponents,
  tls_scan_results,

  (network_services, id, domain_names, policy_violations_decorated, tls_scan_results) => {
    if (!id) return null;
    const network_service = network_services.id[id];
    if (!network_service) return null;
    return decorate(network_service, values(domain_names.id), policy_violations_decorated, tls_scan_results);
  },
);

const getAllNetworkServicesObj = createObjectSelector(
  state => state.network_services.id,
  domain_names,
  getAllTasksWithAffectedComponents,
  tls_scan_results,

  (network_service, domain_names, policy_violations_decorated, tls_scan_results) => {
    return decorate(network_service, values(domain_names.id), policy_violations_decorated, tls_scan_results);
  },
);

// @todo: add Decorated to the name
export const getAllNetworkServices = createSelector(
  getAllNetworkServicesObj,

  network_services => values(network_services).filter(Boolean),
);

export const getAllNetworkServicesForInventory = createSelector(
  network_services,
  getCurrentSearchId,
  domain_names,
  getAllTasksWithAffectedComponents,
  tls_scan_results,

  (network_services_state, searchId, domain_names, policy_violations_decorated, tls_scan_results) => {
    const network_services = getResultsOfSearch(searchId, network_services_state);

    return values(network_services)
      .map(network_service =>
        decorate(network_service, values(domain_names.id), policy_violations_decorated, tls_scan_results),
      )
      .filter(Boolean);
  },
);
