import { HostedOnEnum } from '@dt/horizon-api';
import { AssetTypeDict } from '../inventory/types';
import {
  getAggregatedRelevance,
  getHistoricAggregatedRelevance,
  getUnresolvedPolicyViolationPointValue,
} from '../policy_violations/util';

export const graphql_apis = ({ graphql_apis }) => graphql_apis;

export const decorate = (graphql_api, policy_violations_decorated) => {
  const related_policy_violations_decorated = policy_violations_decorated.filter(pv =>
    graphql_api.policy_violation_ids.includes(pv.id),
  );

  return {
    ...graphql_api,
    aggregated_relevance: getAggregatedRelevance({
      policy_violations_decorated: related_policy_violations_decorated,
    }),
    asset_type: AssetTypeDict.RESTFUL_API,
    historic_aggregated_relevance: getHistoricAggregatedRelevance({
      policy_violations_decorated: related_policy_violations_decorated,
    }),
    hosted_on: HostedOnEnum.UNKNOWN,
    name: graphql_api.url,
    policy_violations_decorated: [],
    unresolved_policy_violations_point_value: getUnresolvedPolicyViolationPointValue({
      policy_violations_decorated: related_policy_violations_decorated,
    }),
    url: graphql_api.url,
  };
};
