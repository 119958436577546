import gql from 'graphql-tag';
export const web_changes_search_filter_values = gql `
  query WebChangesSearchFilterValues {
    web_changes_search_filter_values {
      web_changes_search_filter_values {
        belongs_to_filter
        icon_url
        name
        value
        value_category
      }
    }
  }
`;
export const web_changes_stats = gql `
  query GetWebChangesStats {
    web_changes_stats {
      web_changes_total_count
      web_changes_stats {
        web_change_name
        web_change_count
        web_change_type
        web_change_meta_type
      }
    }
  }
`;
export const web_assets_changes = gql `
  query GetWebAssetsChanges(
    $cursor: String
    $filter_by_web_secure_named_filter: String
    $filter_by_web_change_type: [String]
  ) {
    web_assets_changes(
      cursor: $cursor
      filter_by_web_secure_named_filter: $filter_by_web_secure_named_filter
      filter_by_web_change_type: $filter_by_web_change_type
    ) {
      web_changes {
        date_created
        asset_name
        asset_type_icon_url
        asset_url
        discovered_via
        discovered_via_icon_url
        discovered_via_name
        is_compliant_to_security_policy
        is_internet_acccessible
        issues_count
        web_app_type
        web_change_description
        web_change_icon_url
        web_change_name
        web_change_type
      }
      included_web_changes_stats {
        changes_with_issues_count
        last_30_days_changes_count
        last_30_days_changes_with_issues_count
        last_7_days_changes_count
        last_year_changes_count
      }
      pagination_information {
        next_cursor
        total_count
        exact_total_count
        hint_for_total_count
        is_approximate_total_count_enabled
      }
    }
  }
`;
