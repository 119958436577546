import { useEffect, useState } from 'react';
import { useLocation } from '@reach/router';
const PRODUCT_PATHS = {
    api: 'api/v2',
    cloud: 'cloud/v2',
    dashboard: 'dashboard/v2',
    devsecops: 'devsecops/v2',
    management: 'management/v2',
    mobile: 'mobile-secure/v2',
    openscan: 'openscan/v2',
    policies: 'policies/v2',
    web: 'web/v2',
};
const checkPath = (pathname, path, product) => {
    if (product === 'dashboard') {
        return (['/dashboard/', '/dashboard'].includes(pathname.match(/dashboard/)?.input ?? '') ||
            !!pathname.match(/dashboard\/v2/));
    }
    return !!pathname.match(path);
};
const useGetBaseProductUrl = () => {
    const location = useLocation();
    const [baseProduct, setBaseProduct] = useState('api');
    const [baseUrl, setBaseUrl] = useState('/api/v2');
    const [currentProduct, setCurrentProduct] = useState();
    useEffect(() => {
        const product = Object.keys(PRODUCT_PATHS).find(key => checkPath(location.pathname, PRODUCT_PATHS[key], key));
        setCurrentProduct(product);
        if (!product)
            return;
        // we set the base url based on the current path(product)
        // but if current path is not one of the products,
        // we set the base url based on the base product
        if (product === 'mobile' || baseProduct === 'mobile') {
            setBaseUrl(PRODUCT_PATHS.mobile);
        }
        else if (product === 'web' || baseProduct === 'web') {
            setBaseUrl(PRODUCT_PATHS.web);
        }
        else if (product === 'cloud' || baseProduct === 'cloud') {
            setBaseUrl(PRODUCT_PATHS.cloud);
        }
        else if (product === 'openscan' || baseProduct === 'openscan') {
            setBaseUrl(PRODUCT_PATHS.openscan);
        }
        else if (product === 'dashboard' || baseProduct === 'dashboard') {
            // Redirect to API Secure v2 by default
            setBaseUrl(PRODUCT_PATHS.api);
        }
        else {
            // TMP Workaround when we don't have baseProduct in browser memory (e.g. when we refresh the page)
            setBaseUrl(PRODUCT_PATHS.api);
        }
        // we don't want to set the base product for these products
        if (product === 'devsecops' || product === 'management' || product === 'policies') {
            return;
        }
        setBaseProduct(product);
    }, [baseProduct, location.pathname]);
    return { baseProduct, baseUrl, currentProduct };
};
export default useGetBaseProductUrl;
