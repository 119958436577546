import gql from 'graphql-tag';
export const sast_integrations_status = gql `
  query SastIntegrationStatus {
    sast_integrations_status {
      integrations {
        icon_url
        large_icon_url
        is_onboarded
        detail_link
        integration_name
        detail
        documentation_link
      }
    }
  }
`;
export const sast_issues_search_filter_values = gql `
  query SastIssuesSearchFilterValues {
    sast_issues_search_filter_values {
      sast_issues_search_filter_values {
        belongs_to_filter
        icon_url
        name
        value
        value_category
      }
    }
  }
`;
export const sast_files_with_issues = gql `
  query SastFilesWithIssues(
    $cursor: String
    $repoId: String!
    $filter_by_type: [String]
    $filter_by_severity: [String]
  ) {
    sast_files_with_issues(
      cursor: $cursor
      repoId: $repoId
      filter_by_type: $filter_by_type
      filter_by_severity: $filter_by_severity
    ) {
      pagination_information {
        next_cursor
        total_count
      }
      files_with_issues {
        detected_file_type
        detected_file_type_icon_url
        file_name
        id
        total_issues_count
      }
    }
  }
`;
export const sast_repository_issues = gql `
  query SastRepositoryIssues(
    $cursor: String
    $repoId: String!
    $filter_by_present_in_file_id: String!
    $filter_by_present_in_branch_name: String
    $filter_by_status: String
    $filter_by_type: [String]
    $filter_by_severity: [String]
  ) {
    sast_repository_issues(
      cursor: $cursor
      repoId: $repoId
      filter_by_present_in_file_id: $filter_by_present_in_file_id
      filter_by_present_in_branch_name: $filter_by_present_in_branch_name
      filter_by_status: $filter_by_status
      filter_by_type: $filter_by_type
      filter_by_severity: $filter_by_severity
    ) {
      pagination_information {
        next_cursor
        total_count
      }
      issues {
        issue_type_display_name
        detected_in_branch_name
        detected_in_branch_url
        detected_in_file_name
        detected_on_line
        issue_description
        issue_recommendation
        issue_secure_code
        issue_title
        see_issue_in_platform_url
        status
        issue_code_snippet
        severity
        discovered_date
        issue_type
        id
      }
    }
  }
`;
export const sast_list_repositories = gql `
  query SastListRepositories(
    $cursor: String
    $filter_by_text: String
    $filter_by_platform: String
    $filter_by_visibility: String
    $filter_by_named_filter: String
  ) {
    sast_list_repositories(
      cursor: $cursor
      filter_by_text: $filter_by_text
      filter_by_platform: $filter_by_platform
      filter_by_visibility: $filter_by_visibility
      filter_by_named_filter: $filter_by_named_filter
    ) {
      pagination_information {
        next_cursor
        total_count
      }
      repositories {
        total_scans_last_seven_days
        code_owner_email
        enabled_rule_packs {
          color
          title
        }
        active_developers_count
        dependencies_count
        files_with_issues_count
        first_party_issues_count
        has_sast_scans_enabled
        sca_issues_count
        total_code_lines_count
        total_fixed_issues_count
        total_issues_count
        visibility
        visibility_display_name
        platform
        platform_display_name
        platform_icon_url
        full_name
        html_url
        name
        id
        enabled_rule_packs {
          color
          title
        }
        dt_analyzer_config {
          lines {
            content
            last_modified_by_user
            last_modified_date
          }
        }
      }
    }
  }
`;
export const sast_stats = gql `
  query SastStatsQuery {
    sast_stats {
      first_party_issues_count
      sca_issues_count
      total_code_lines_count
      total_dependencies_count
      total_issues_count
      total_repositories_count
      total_fixed_issues_count
    }
  }
`;
export const sast_stats_v2 = gql `
  query SastStatsQueryV2 {
    sast_stats_v2 {
      metrics_cards {
        name
        count
        icon_url
        badges {
          name
          count
        }
      }
    }
  }
`;
export const sast_stats_metrics_v2 = gql `
  query SastMetricsQueryV2 {
    sast_stats_metrics_v2 {
      cards {
        title
        items {
          title
          value
          icon_url
        }
      }
    }
  }
`;
export const sast_named_filters = gql `
  query SastNamedFilters($filter_by_text: String) {
    sast_named_filters(filter_by_text: $filter_by_text) {
      repositories_findings_named_filters {
        name
        value
        icon_url
        has_children
        children {
          name
          value
          icon_url
          has_data
        }
      }
    }
  }
`;
export const sast_repository_issues_issue_by_id = gql `
  query SastRepositoryIssuesIssuesById($repoId: String!, $issueId: String!) {
    sast_repository_issues_issue_by_id(repoId: $repoId, issueId: $issueId) {
      repository_name
      detected_in_branch_icon_url
      issue_type_display_name
      status
      detected_in_file_icon_url
      detected_in_file_name
      detected_in_file_path
      repository_html_url
      repository_platform
      repository_platform_display_name
      repository_platform_icon_url
      discovered_date
      detected_in_branch_name
      detected_in_branch_url
      issue_type
      issue_code_snippet
      issue_description
      issue_recommendation
      issue_secure_code
      issue_title
      detected_on_line
      id
      see_issue_in_platform_url
      severity
    }
  }
`;
export const sast_named_filters_children = gql `
  query SastNamedFiltersChildren($name: String!, $filter_by_text: String, $cursor: String) {
    sast_named_filters_children(name: $name, filter_by_text: $filter_by_text, cursor: $cursor) {
      pagination_information {
        next_cursor
        total_count
      }
      children {
        children {
          icon_url
          name
          value
          is_active
        }
        has_children
        icon_url
        name
        value
        is_active
      }
    }
  }
`;
export const sast_repository_patch_scans = gql `
  mutation SastRepositoryPatchScans($id: String!, $body: SastRepositoryPatchScanParams!) {
    sast_repository_patch_scans(id: $id, body: $body) {
      dependencies_count
      files_with_issues_count
      first_party_issues_count
      has_sast_scans_enabled
      sca_issues_count
      total_code_lines_count
      total_fixed_issues_count
      total_issues_count
      visibility
      visibility_display_name
      platform
      platform_display_name
      platform_icon_url
      full_name
      html_url
      name
      id
    }
  }
`;
export const sast_commits_issues_issue_by_id = gql `
  query SastCommitsIssuesIssuesById($scanId: String!, $issueId: String!) {
    sast_commits_issues_issue_by_id(scanId: $scanId, issueId: $issueId) {
      detected_in_file_icon_url
      repository_full_name
      repository_html_url
      repository_name
      repository_platform
      repository_platform_display_name
      repository_platform_icon_url
      sast_issue {
        repository_name
        detected_in_branch_icon_url
        issue_type_display_name
        status
        detected_in_file_icon_url
        detected_in_file_name
        detected_in_file_path
        repository_html_url
        repository_platform
        repository_platform_display_name
        repository_platform_icon_url
        discovered_date
        detected_in_branch_name
        detected_in_branch_url
        issue_type
        issue_code_snippet
        issue_description
        issue_recommendation
        issue_secure_code
        issue_title
        detected_on_line
        id
        see_issue_in_platform_url
        severity
      }
    }
  }
`;
export const sast_get_configuration = gql `
  query SastGetConfiguration {
    sast_get_configuration {
      default_rule_packs
      enable_sast_scans_on_new_repositories
    }
  }
`;
export const sast_get_rule_pack_values = gql `
  query SastGetRulePackValues {
    sast_get_rule_pack_values {
      rule_packs {
        description
        name
        value
      }
    }
  }
`;
export const sast_integrations_patch_configuration = gql `
  mutation SastIntegrationsPatchConfiguration($body: SastConfigurationPatchRequest!) {
    sast_integrations_patch_configuration(body: $body) {
      default_rule_packs
      enable_sast_scans_on_new_repositories
    }
  }
`;
export const sast_gitlab_create = gql `
  mutation SastGitlabCreate($body: SastGitlabCreateBody!) {
    sast_gitlab_create(body: $body) {
      nothing
    }
  }
`;
