import React, { memo } from 'react';
import { NavLink, Route } from 'react-router-dom';
import ExtLink from './ExtLink';

function MobileNavLink({
  children,
  activeStyle,
  to,
  exact = false,
  redirect = false,
  target = '_blank',
  strict,
  replace,
  ...rest
}) {
  return redirect ? (
    <ExtLink {...rest} target={redirect ? target : '_self'} to={to}>
      {children}
    </ExtLink>
  ) : (
    <Route exact={exact} path={to} strict={strict}>
      {() => {
        if (to) {
          return (
            <NavLink {...rest} replace={replace} style={{ display: 'block' }} to={to}>
              {children}
            </NavLink>
          );
        }

        return <>{children}</>;
      }}
    </Route>
  );
}

export default memo(MobileNavLink);
