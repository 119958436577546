import gql from 'graphql-tag';

const assets_summary_list = gql`
  query AssetGroupsAssetsSummaryList($cursor: String, $id: ID!) {
    asset_group_assets_summary_list(cursor: $cursor, id: $id) {
      pagination_information {
        next_cursor
        total_count
      }

      assets {
        id
        name
        asset_type
        asset_type_icon_url
        asset_type_name
        open_urgent_policy_violations_count
        open_important_policy_violations_count
        open_proactive_policy_violations_count
        discovered_via
        hosted_on
        date_created
      }
    }
  }
`;

const list = gql`
  query AssetGroupsList($cursor: String) {
    asset_group_list(cursor: $cursor) {
      pagination_information {
        next_cursor
        total_count
      }

      asset_groups {
        id
        date_created
        name
        is_read_only
        description
        attached_policy_id
        created_by_user_id
        created_by_user {
          date_created
          first_name
          id
          last_name
          login_email
          notification_email
        }
        created_by_actor_name
        asset_group_memberships_overview {
          restful_apis_count
          api_operations_count
          web_applications_count
          network_services_count
          cloud_resources_count
        }
      }

      users {
        first_name
        id
        last_name
        login_email
        notification_email
      }
    }
  }
`;

const v2_list = gql`
  query V2AssetGroupsList(
    $cursor: String
    $include: AssetGroupsSearchIncludeEnum
    $page_size: Float
    $filter_by_named_filter: String
  ) {
    asset_group_list_v2(
      cursor: $cursor
      include: $include
      page_size: $page_size
      filter_by_named_filter: $filter_by_named_filter
    ) {
      pagination_information {
        next_cursor
        total_count
      }

      asset_groups {
        id
        name
        date_created
        created_by_actor_id
        created_by_actor_name
        included_policy_violation_stats {
          assets_count
          open_urgent_violations_count
          open_important_violations_count
          open_proactive_violations_count
        }
        included_assets_overview {
          restful_apis_count
          api_operations_count
          web_applications_count
          network_services_count
          cloud_resources_count
          graphql_apis_count
        }
      }
    }
  }
`;

const get = gql`
  query AssetGroupDetails($id: ID!) {
    asset_group_details(id: $id) {
      asset_groups {
        id
        date_created
        name
        is_read_only
        description
        attached_policy_id
        created_by_user_id
        created_by_actor_name
        created_by_user {
          date_created
          first_name
          id
          last_name
          login_email
          notification_email
        }
        asset_group_memberships_overview {
          restful_apis_count
          api_operations_count
          web_applications_count
          network_services_count
          cloud_resources_count
        }
      }

      policies {
        id
        name
        created_by_user_id
        created_by_actor_id
        created_by_actor_name
        is_special_dt_policy
        date_created
        protected_asset_groups_count
        enabled_policy_rules_count
        policy_rule_ids
        description
        created_by_user {
          date_created
          first_name
          id
          last_name
          login_email
          notification_email
        }
      }

      policy_rule_types {
        id
        title
        description
        recommendation
        default_relevance
        requires_configuration
        group
      }

      policy_rules {
        id
        belongs_to_policy_id
        belongs_to_policy_name
        created_by_user_id
        deleted_by_user_id
        date_created
        policy_rule_type_id
        deletion_explanation
        relevance
        open_policy_violations_count
      }

      users {
        date_created
        first_name
        id
        last_name
        login_email
        notification_email
      }
    }
  }
`;

const create = gql`
  mutation AssetGroupCreate($body: AssetGroupCreateParams!) {
    asset_group_create(body: $body) {
      asset_groups {
        id
        date_created
        name
        is_read_only
        description
        attached_policy_id
        created_by_user_id
        created_by_user {
          date_created
          first_name
          id
          last_name
          login_email
          notification_email
        }
        asset_group_memberships_overview {
          restful_apis_count
          api_operations_count
          web_applications_count
          network_services_count
          cloud_resources_count
        }
      }
    }
  }
`;

const patch = gql`
  mutation AssetGroupPatch($id: ID!, $body: AssetGroupUpdateParams!) {
    asset_group_patch(id: $id, body: $body) {
      asset_groups {
        id
        date_created
        name
        is_read_only
        description
        attached_policy_id
        created_by_user_id
        created_by_user {
          date_created
          first_name
          id
          last_name
          login_email
          notification_email
        }
        asset_group_memberships_overview {
          restful_apis_count
          api_operations_count
          web_applications_count
          network_services_count
          cloud_resources_count
        }
      }
    }
  }
`;

const remove = gql`
  mutation AssetGroupDelete($id: ID!) {
    asset_group_delete(id: $id) {
      nothing
    }
  }
`;

const userGrantAccess = gql`
  mutation UserGrantAccess($userId: ID!, $body: AssetGroupGrantUserAccessParams!) {
    asset_group_user_grant_access(userId: $userId, body: $body) {
      nothing
    }
  }
`;

const userDenyAccess = gql`
  mutation UserDenyAccess($userId: ID!, $assetGroupId: ID!) {
    asset_group_user_deny_access(userId: $userId, assetGroupId: $assetGroupId) {
      nothing
    }
  }
`;

export const UNGROUPED_ASSET_GROUP_ID = '539369f4-e609-48aa-a302-54ba9264c1e1';
export const ALL_ASSET_GROUP_ID = 'a0e1bbdd-7f6a-42b5-a7fb-186ed15d7c84';

// Use only if you need a fake ID
export const ALL_ASSET_GROUP_FAKE_ID = 'xxxxxxxx-7f6a-42b5-a7fb-186ed15d7c84';

export default {
  assets_summary_list,
  create,
  get,
  list,
  patch,
  remove,
  userDenyAccess,
  userGrantAccess,
  v2_list,
};
