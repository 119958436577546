import gql from 'graphql-tag';

const create = gql`
  mutation PoliciesCreate($body: PoliciesCreateParams!) {
    policies_create(body: $body) {
      policies {
        id
        name
        created_by_user_id
        created_by_user {
          id
          first_name
          last_name
        }
        is_special_dt_policy
        date_created
        enabled_policy_rules_count
        policy_rule_ids
        description
        protected_asset_groups_count
      }
    }
  }
`;

const list = gql`
  query PoliciesList(
    $cursor: String
    $filter_by_named_filter: String
    $filter_by_text: String
    $include_scs_policies_only: Boolean
  ) {
    policies_list(
      cursor: $cursor
      filter_by_named_filter: $filter_by_named_filter
      filter_by_text: $filter_by_text
      include_scs_policies_only: $include_scs_policies_only
    ) {
      pagination_information {
        next_cursor
      }
      policies {
        id
        name
        created_by_user_id
        created_by_actor_id
        created_by_actor_name
        created_by_user {
          id
          first_name
          last_name
          login_email
          date_created
          notification_email
        }
        is_special_dt_policy
        date_created
        enabled_policy_rules_count
        policy_rule_ids
        description
        protected_asset_groups_count
      }
    }
  }
`;

const v2_list = gql`
  query PoliciesListV2(
    $cursor: String
    $filter_by_text: String
    $include_scs_policies_only: Boolean
    $filter_by_web_secure_named_filter: String
  ) {
    policies_list_v2(
      cursor: $cursor
      filter_by_text: $filter_by_text
      include_scs_policies_only: $include_scs_policies_only
      filter_by_web_secure_named_filter: $filter_by_web_secure_named_filter
    ) {
      pagination_information {
        next_cursor
      }
      policies {
        id
        name
        created_by_actor_id
        created_by_actor_name
        is_special_dt_policy
        date_created
        enabled_policy_rules_count
        protected_asset_groups_count
      }
    }
  }
`;

export const get = gql`
  query PoliciesGet($policyId: ID, $filter_by_named_filter: String) {
    policy_by_id(id: $policyId, filter_by_named_filter: $filter_by_named_filter) {
      policies {
        id
        name
        created_by_user_id
        created_by_actor_id
        created_by_actor_name
        created_by_user {
          id
          first_name
          last_name
          login_email
          date_created
          notification_email
        }
        is_special_dt_policy
        date_created
        protected_asset_groups_count
        enabled_policy_rules_count
        policy_rule_ids
        description
      }
      policy_rules {
        id
        belongs_to_policy_id
        created_by_user_id
        deleted_by_user_id
        date_created
        policy_rule_type_id
        deletion_explanation
        relevance
        configuration {
          nb_of_days_before_cert_expiration
          http_methods_to_scan
          weekdays_when_to_scan
          time_when_to_scan
          enabled_google_pii_types
        }
        open_policy_violations_count
        resolved_policy_violations_count
        belongs_to_policy_name
      }
      asset_groups {
        id
        date_created
        name
        is_read_only
        description
        attached_policy_id
        created_by_user_id
        created_by_actor_name
        asset_group_memberships_overview {
          restful_apis_count
          web_applications_count
          network_services_count
          cloud_resources_count
          api_operations_count
        }
        created_by_user {
          date_created
          first_name
          id
          last_name
          login_email
          notification_email
        }
      }
      users {
        first_name
        id
        last_name
        login_email
        notification_email
      }
    }
  }
`;

export const remove = gql`
  mutation PoliciesRemove($id: ID!) {
    policies_remove(id: $id) {
      nothing
    }
  }
`;

export default {
  create,
  get,
  list,
  remove,
  v2_list,
};
