import immer from 'immer';
import { webApplicationsReceived, webApplicationsReceivedIdsForAssetSearch } from './actions';

const initialState = {
  for_policy_violation_id: {},
  for_search_id: {},
  id: {},
};

export default immer((draft, action) => {
  if (action.type === webApplicationsReceived.toString()) {
    for (const web_application of action.payload.web_applications) {
      draft.id[web_application.id] = web_application;
      for (const policy_violation_id of web_application.policy_violation_ids) {
        draft.for_policy_violation_id[policy_violation_id] = web_application.id;
      }
    }
  }

  if (action.type === webApplicationsReceivedIdsForAssetSearch.toString()) {
    const { searchId, ids } = action.payload;
    draft.for_search_id[searchId] = (draft.for_search_id[searchId] || []).concat(ids);
  }
}, initialState);
