import immer from 'immer';
import { sharedLinksDeleteSuccess, sharedLinksReceived } from './actions';

const initialState = {
  id: {},
};

export default immer((draft, action) => {
  if (action.type === sharedLinksReceived.toString()) {
    for (const shared_link of action.payload) {
      draft.id[shared_link.id] = shared_link;
    }
  }

  if (action.type === sharedLinksDeleteSuccess.toString()) {
    draft.id[action.payload] = void 0;
  }
}, initialState);
