import React from 'react';
import { OfflineIndicatorBanner } from '@dt/material-components';

export const AssetsOfflineBanner = ({ isLoading, asset }) => {
  if (isLoading) {
    // Banner is optional, no loadingg state necassary.
    return null;
  }

  if (!asset) {
    // Should not happen.
    // Asset should be available when not loading.
    return null;
  }

  return (
    asset.status !== 'ONLINE' && (
      <OfflineIndicatorBanner
        assetTypeName={asset.asset_type_name}
        lastUpdated={asset.date_no_longer_accessible}
        url={asset.url}
      />
    )
  );
};
