import React from 'react';
import { ClickAwayListener, Grid, Paper, Popper } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  popover: {
    borderRadius: 4,
    margin: 8,
    padding: 8,
  },
}));

export default React.memo(({ container, width, height, children }) => {
  const classes = useStyles();

  // popover
  const [anchorForPopOver, setAnchorForPopOver] = React.useState(null);
  const handleOpenPopperToggle = event => {
    setAnchorForPopOver(anchorForPopOver ? null : event.currentTarget);
  };
  const isPopOverOpen = Boolean(anchorForPopOver);
  const assetPopperId = isPopOverOpen ? `asset-popper` : null;

  return (
    <>
      <Grid container justifyContent="center" onClick={handleOpenPopperToggle}>
        {children}
      </Grid>
      {/* popover container */}
      <Popper anchorEl={anchorForPopOver} id={assetPopperId} open={isPopOverOpen} placement={'bottom-start'}>
        <ClickAwayListener onClickAway={handleOpenPopperToggle}>
          <Paper
            className={classes.popover}
            style={{
              height: height ? height : 'initial',
              width: width ? width : 'initial',
            }}
          >
            {container}
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  );
});
