import { createAction } from 'redux-actions';
import createReducerCreator from 'rezz/createReducerCreator';
import updateInList from 'rezz/updateInList';

export const updateApp = createAction('APPS_UPDATE', app => app);

export default createReducerCreator({
  equalityCheck: (a, b) => a.id === b.id,
  initialState: [],
})(updateInList(updateApp.toString()));
