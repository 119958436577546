import React, { memo } from 'react';
import { useTheme } from '@mui/material';
import ImportantWarningIcon from '@mui/icons-material/Flag';
import InformationalProactiveIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';

const MiniRelevanceIcon = ({ relevance }) => {
  const { palette } = useTheme();
  const Icon =
    relevance === 'URGENT'
      ? WarningIcon
      : relevance === 'IMPORTANT'
        ? ImportantWarningIcon
        : InformationalProactiveIcon;
  const backgroundColor =
    relevance === 'URGENT' ? palette.red[50] : relevance === 'IMPORTANT' ? palette.yellow[50] : palette.blue[40];
  const iconColor =
    relevance === 'URGENT' ? palette.red.main : relevance === 'IMPORTANT' ? palette.yellow.main : palette.blue[50];

  return (
    <div
      style={{
        backgroundColor,
        borderRadius: 2,
        display: 'inline-block',
        fontSize: 0,
        height: 20,
        padding: 4,
        width: 20,
      }}
    >
      <Icon fontSize="small" style={{ color: iconColor, fontSize: 12 }} />
    </div>
  );
};

export default memo(MiniRelevanceIcon);
