import { useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import mobile_applications from '@dt/graphql-support/horizon/mobile_applications';

export default function useGetHorizonAssetIdFromAppId(appId) {
  const [mobileAppDetailsQuery, { error, loading, data }] = useLazyQuery(mobile_applications.v2_get);

  useEffect(() => {
    if (!loading && appId) {
      mobileAppDetailsQuery({
        variables: {
          id: appId,
        },
      });
    }
  }, [mobileAppDetailsQuery, loading, appId]);

  if (!appId) {
    return null;
  }

  return {
    error,
    id: data?.mobile_app_details_v2.id,
    loading,
  };
}
