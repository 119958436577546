const compliancePolicy = {
  CALOPPA: 'CALOPPA',
  CCPA: 'CCPA',
  COPPA: 'COPPA',
  FTC: 'FTC',
  GDPR: 'GDPR',
  HIPAA: 'HIPAA',
  MITRE: 'MITRE',
  OWASP: 'OWASP',
  OWASP_ASVS: 'OWASP_ASVS',
  OWASP_MSTG: 'OWASP_MSTG',
  PCI: 'PCI',
  VPPA: 'VPPA',
};
export default compliancePolicy;
