import React from 'react';
import { Container } from '@mui/material';

const PageLayoutComponent = function PageLayout({ children, fullWidth, classes, style = {} }) {
  return (
    <Container
      classes={classes}
      disableGutters={fullWidth}
      maxWidth={fullWidth ? false : 'lg'}
      style={{
        paddingBottom: 16,
        paddingTop: fullWidth ? 0 : 8,
        ...style,
      }}
    >
      {children}
    </Container>
  );
};

export default PageLayoutComponent;
