import {
  apigee_authenticators,
  aws_authenticators,
  axway_authenticators,
  azure_authenticators,
  certificate_transparency_domains,
  gcp_authenticators,
  kong_authenticators,
  mulesoft_authenticators,
} from '@dt/horizon-api';
import { callPromise } from '@dt/redux-saga-wrapped-effects';
import { call, put } from 'redux-saga/effects';
import { handleNormalizedResponse, withCache } from './../resource_fetch/sagas';
import { certificateTransparencyReceived } from './actions';

export const getAwsAuthenticators = withCache('aws_authenticators_list', function* getAwsAuthenticators() {
  const response = yield* callPromise(aws_authenticators.list);
  if (response._type === 'error') {
    throw new Error(response.title);
  }

  yield call(handleNormalizedResponse, response.body);
});

export const getGcpAuthenticators = withCache('gcp_authenticators_list', function* getGcpAuthenticators() {
  const response = yield* callPromise(gcp_authenticators.list);

  if (response._type === 'error') {
    throw new Error(response.title);
  }

  yield call(handleNormalizedResponse, response.body);
});

export const getAzureAuthenticators = withCache('azure_authenticators_list', function* getAzureAuthenticators() {
  const response = yield* callPromise(azure_authenticators.list);

  if (response._type === 'error') {
    throw new Error(response.title);
  }

  yield call(handleNormalizedResponse, response.body);
});

export const getAxwayAuthenticators = withCache('axway_authenticators_list', function* getAxwayAuthenticators() {
  const response = yield* callPromise(axway_authenticators.list);

  if (response._type === 'error') {
    throw new Error(response.title);
  }

  yield call(handleNormalizedResponse, response.body);
});

export const getMulesoftAuthenticators = withCache(
  'mulesoft_authenticators_list',
  function* getMulesoftAuthenticators() {
    const response = yield* callPromise(mulesoft_authenticators.list);

    if (response._type === 'error') {
      throw new Error(response.title);
    }

    yield call(handleNormalizedResponse, response.body);
  },
);

export const getApigeeAuthenticators = withCache('apigee_authenticators_list', function* getApigeeAuthenticators() {
  const response = yield* callPromise(apigee_authenticators.list);

  if (response._type === 'error') {
    throw new Error(response.title);
  }

  yield call(handleNormalizedResponse, response.body);
});

export const getKongAuthenticators = withCache('kong_authenticators_list', function* getKongAuthenticators() {
  const response = yield* callPromise(kong_authenticators.list);

  if (response._type === 'error') {
    throw new Error(response.title);
  }

  yield call(handleNormalizedResponse, response.body);
});

export const getCertificateTransparencyDomains = withCache(
  'certificate_transparency_domains',
  function* getCertificateTransparencyDomains() {
    const response = yield* callPromise(certificate_transparency_domains.get);

    if (response._type === 'error') {
      throw new Error(response.title);
    }

    if (response.body.domain_names) {
      yield put(certificateTransparencyReceived(response.body));
    }
  },
);
