import React from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import Box from '@mui/material/Box';
import { FindingSeverityValues } from '@dt/graphql-support/enums';
import { Text } from '@dt/material-components';

export const PopoutFilterPluginFindingSeverityDisplayLookup = {
  [FindingSeverityValues.HIGH]: 'HIGH',
  [FindingSeverityValues.MEDIUM]: 'MEDIUM',
  [FindingSeverityValues.LOW]: 'LOW',
  [FindingSeverityValues.INFORMATIONAL]: 'INFORMATIONAL',
};

/*
 * Popout filter plugin.
 * User can select finding severities.
 *
 * @param value - Current state.
 * @param onChange - State change.
 */
export const PopoutFilterPluginFindingSeverity = ({ value, onChange }) => {
  return (
    <Box display="flex" flexDirection="column">
      {Object.keys(PopoutFilterPluginFindingSeverityDisplayLookup).map(key => {
        const val = PopoutFilterPluginFindingSeverityDisplayLookup[key];
        return (
          <FormControlLabel
            control={
              <Checkbox
                checked={!!value[key]}
                color="primary"
                inputProps={{
                  'aria-label': `${val}`,
                }}
                onChange={e => {
                  (value =>
                    onChange(prev => ({
                      ...prev,

                      [key]: value,
                    })))(e.target.checked);
                }}
              />
            }
            key={key}
            label={<Text variant="body">{val}</Text>}
          />
        );
      })}
    </Box>
  );
};
