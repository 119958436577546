// @ts-ignore
import BrandIconSupplyChainSecurity from '@dt/brand/product-icon-supply-chain-security';
// @ts-ignore
import { getInvariantUserAccount } from '@dt/session';
const useScsMenuItem = ({ loading }) => {
    if (loading)
        return;
    const { accountInfo, currentUser } = getInvariantUserAccount();
    let scsMenuItem;
    if (currentUser?.can_access_vendor_supply_chain_security) {
        // if the current user can access Vendor SCS (regardless of whether they are
        // in their home account or a vendor-customer), then always send them to
        // the Supply Chain Secure main page
        scsMenuItem = {
            icon: BrandIconSupplyChainSecurity,
            name: accountInfo?.supply_chain_security_product_top_level_whitelabel_name || 'Supply Chain Secure',
            to: '/supply-chain',
        };
    }
    else if (accountInfo?.current_vendor) {
        // if the current user can't access Vendor SCS, but they are inside of a
        // vendor-customer, then send them to their own vendor/Supplier page.
        scsMenuItem = {
            icon: BrandIconSupplyChainSecurity,
            name: accountInfo?.vendor_supply_chain_security_whitelabeling_name || 'Supplier Trust',
            to: `/supply-chain/supplier-trust/suppliers/${accountInfo.vendor_id || ''}/`,
        };
    }
    else {
        // otherwise, send the user to either normal SCS or OpenScan
        scsMenuItem =
            accountInfo.supply_chain_security_product_enabled || accountInfo.openscan_subscription == 'NO_SUBSCRIPTION'
                ? {
                    icon: BrandIconSupplyChainSecurity,
                    name: accountInfo?.supply_chain_security_product_top_level_whitelabel_name || 'Supply Chain Secure',
                    to: '/supply-chain',
                }
                : {
                    icon: BrandIconSupplyChainSecurity,
                    name: accountInfo?.supply_chain_security_product_top_level_whitelabel_name || 'Supply Chain Security',
                    to: '/openscan/v2',
                };
    }
    return scsMenuItem;
};
export default useScsMenuItem;
