import '@dt/horizon-api';
import { createAction } from 'redux-actions';

export const authenticatorSectionMounted = createAction('mounted/configuration/authenticator_section', () => ({
  key: 'authenticator_section',
}));

export const securityRoadmapMounted = createAction('mounted/security-roadmap/', () => ({
  key: 'security-roadmap',
}));

export const cloudAccessMounted = createAction('mounted/configuration/', () => ({
  key: 'configuration',
}));

export const awsAuthenticatorsReceived = createAction(
  'aws_authenticators/list/received',
  authenticators => authenticators,
);

export const gcpAuthenticatorsReceived = createAction(
  'gcp_authenticators/list/received',
  authenticators => authenticators,
);

export const azureAuthenticatorsReceived = createAction(
  'azure_authenticators/list/received',
  authenticators => authenticators,
);

export const axwayAuthenticatorsReceived = createAction(
  'axway_authenticators/list/received',
  authenticators => authenticators,
);

export const mulesoftAuthenticatorsReceived = createAction(
  'mulesoft_authenticators/list/received',
  authenticators => authenticators,
);

export const apigeeAuthenticatorsReceived = createAction(
  'apigee_authenticators/list/received',
  authenticators => authenticators,
);

export const certificateTransparencyReceived = createAction(
  'certificate_transparency/get/received',
  certificateTransparency => certificateTransparency,
);

export const kongAuthenticatorsReceived = createAction(
  'kong_authenticators/list/received',
  authenticators => authenticators,
);

export const onboardingCloudAccessMounted = createAction('mounted/onboarding/', () => ({
  key: 'onboarding',
}));
