import React, { memo } from 'react';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import { palette } from '@dt/theme';
import { dateFormats } from '../../apps/ahura/src/util/dateFormats';
import { formatDateDefault } from '../../apps/ahura/src/util/formatDateDefault';
import Banner from './Banner';

export default memo(function OfflineIndicator({ assetTypeName, url, lastUpdated }) {
  return (
    <Banner icon={<NotificationImportantIcon />} iconColor={palette.red30} inline status="critical">
      {`This ${assetTypeName} was unreachable ${url ? `at ${url}` : ''} ${
        lastUpdated ? `on ${formatDateDefault({ date: lastUpdated, formatStr: dateFormats.MMMddyyyy })}` : ''
      }. It may have been removed from the environment.`}
    </Banner>
  );
});
