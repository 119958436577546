export const downloadFile = ({ fileName, url, openLinkInNewTab }) => {
    const link = document.createElement('a');
    if (link.download !== undefined) {
        link.setAttribute('href', url);
        link.setAttribute('download', fileName);
        openLinkInNewTab && link.setAttribute('target', '_blank');
        link.style.visibility = 'hidden';
        document.body?.appendChild(link);
        link.click();
        document.body?.removeChild(link);
    }
};
