import { handleActions } from 'redux-actions';
import {
  hostAssetVisualizationListMounted,
  hostAssetVisualizationListUnmounted,
  inventoryStatsReceived,
  inventoryStatsRemoveShadowAssetClicked,
} from './actions';

const NotLoaded = 'NotLoaded';

export const Periods = {
  MONTH: 'MONTH',
  WEEK: 'WEEK',
};

function calculateTrendingCount(count, pastCount, period) {
  return {
    count,
    trend: {
      amount: count - pastCount,
      period,
    },
  };
}

function calculateAssetStats(current, month) {
  return {
    api_ops: calculateTrendingCount(
      current.restful_apis_stats.api_operations_total_count,
      month.restful_apis_stats.api_operations_total_count,
      Periods.MONTH,
    ),
    dbs: calculateTrendingCount(
      current.cloud_resources_stats.assets_total_count,
      month.cloud_resources_stats.assets_total_count,
      Periods.MONTH,
    ),
    domains: calculateTrendingCount(
      current.network_services_stats.assets_total_count,
      month.network_services_stats.assets_total_count,
      Periods.MONTH,
    ),
    restful_apis: calculateTrendingCount(
      current.restful_apis_stats.assets_total_count,
      month.restful_apis_stats.assets_total_count,
      Periods.MONTH,
    ),
    serverless: calculateTrendingCount(
      current.cloud_resources_stats.serverless_assets_count,
      month.cloud_resources_stats.serverless_assets_count,
      Periods.MONTH,
    ),
    web_applications: calculateTrendingCount(
      current.web_applications_stats.assets_total_count,
      month.web_applications_stats.assets_total_count,
      Periods.MONTH,
    ),
  };
}

function calculateStatsForHost(current, month) {
  return {
    ...calculateAssetStats(current, month),
    assetCount:
      current.cloud_resources_stats.assets_total_count +
      current.network_services_stats.assets_total_count +
      current.restful_apis_stats.assets_total_count +
      current.web_applications_stats.assets_total_count,
    importantAssetCount:
      current.cloud_resources_stats.important_assets_count +
      current.network_services_stats.important_assets_count +
      current.restful_apis_stats.important_assets_count +
      current.web_applications_stats.important_assets_count,
    proactiveAssetCount:
      current.cloud_resources_stats.proactive_assets_count +
      current.network_services_stats.proactive_assets_count +
      current.restful_apis_stats.proactive_assets_count +
      current.web_applications_stats.proactive_assets_count,
    resolvedAssetCount:
      current.cloud_resources_stats.assets_total_count -
      current.cloud_resources_stats.urgent_assets_count -
      current.cloud_resources_stats.important_assets_count -
      current.cloud_resources_stats.proactive_assets_count +
      (current.network_services_stats.assets_total_count -
        current.network_services_stats.urgent_assets_count -
        current.network_services_stats.important_assets_count -
        current.network_services_stats.proactive_assets_count) +
      (current.restful_apis_stats.assets_total_count -
        current.restful_apis_stats.urgent_assets_count -
        current.restful_apis_stats.important_assets_count -
        current.restful_apis_stats.proactive_assets_count) +
      (current.web_applications_stats.assets_total_count -
        current.web_applications_stats.urgent_assets_count -
        current.web_applications_stats.important_assets_count -
        current.web_applications_stats.proactive_assets_count),
    urgentAssetCount:
      current.cloud_resources_stats.urgent_assets_count +
      current.network_services_stats.urgent_assets_count +
      current.restful_apis_stats.urgent_assets_count +
      current.web_applications_stats.urgent_assets_count,
  };
}

export default handleActions(
  {
    [inventoryStatsReceived.toString()]: (state, { payload: { current, week, month } }) => {
      return {
        environmentStats: {
          ...calculateAssetStats(current.all_assets_stats, month.all_assets_stats),
          eventCount: calculateTrendingCount(current.events_total_count, week.events_total_count, Periods.WEEK),
          newViolationCount: calculateTrendingCount(
            current.unresolved_policy_violations_total_count,
            week.unresolved_policy_violations_total_count,
            Periods.WEEK,
          ),
          resolvedViolationCount: calculateTrendingCount(
            current.resolved_policy_violations_total_count,
            week.resolved_policy_violations_total_count,
            Periods.WEEK,
          ),
          scanCount: calculateTrendingCount(current.scans_total_count, week.scans_total_count, Periods.WEEK),
        },
        hostStats: {
          aws: calculateStatsForHost(current.aws_assets_stats, month.aws_assets_stats),
          azure: calculateStatsForHost(current.azure_assets_stats, month.azure_assets_stats),
          gcp: calculateStatsForHost(current.gcp_assets_stats, month.gcp_assets_stats),
          mulesoft: calculateStatsForHost(current.mulesoft_assets_stats, month.mulesoft_assets_stats),
        },
        isViewingVisualization: state.isViewingVisualization,
        shadowAssetStats: {
          network_services: current.all_assets_stats.network_services_stats.shadow_assets_count,
          restful_apis: current.all_assets_stats.restful_apis_stats.shadow_assets_count,
          serverless_resources: current.all_assets_stats.cloud_resources_stats.shadow_serverless_assets_count,
          storage_dbs:
            current.all_assets_stats.cloud_resources_stats.shadow_assets_count -
            current.all_assets_stats.cloud_resources_stats.shadow_serverless_assets_count,
          totalRemoved: state.shadowAssetStats === 'NotLoaded' ? 0 : state.shadowAssetStats.totalRemoved,
          web_applications: current.all_assets_stats.web_applications_stats.shadow_assets_count,
        },
      };
    },
    [hostAssetVisualizationListMounted.toString()]: state => ({
      ...state,
      isViewingVisualization: true,
    }),
    [hostAssetVisualizationListUnmounted.toString()]: state => ({
      ...state,
      isViewingVisualization: false,
    }),
    [inventoryStatsRemoveShadowAssetClicked.toString()]: (state, actions) => ({
      ...state,
      shadowAssetStats:
        state.shadowAssetStats === 'NotLoaded'
          ? state.shadowAssetStats
          : {
              ...state.shadowAssetStats,
              totalRemoved: state.shadowAssetStats.totalRemoved + actions.payload,
            },
    }),
  },
  {
    environmentStats: NotLoaded,
    hostStats: NotLoaded,
    isViewingVisualization: false,
    shadowAssetStats: NotLoaded,
  },
);
