import gql from 'graphql-tag';
export const invalidate_session = gql `
  mutation InvalidateSession {
    invalidate_session {
      nothing
    }
  }
`;
export const delete_user = gql `
  mutation DeleteUser($userId: ID!) {
    delete_user(userId: $userId) {
      nothing
    }
  }
`;
export const users_list = gql `
  query UserLists($cursor: String) {
    users_list(cursor: $cursor) {
      pagination_information {
        next_cursor
        total_count
      }
      users {
        allowed_app_ids
        auth_strategy
        can_access_app_search
        can_access_app_secure
        can_access_api_inspect
        can_access_brand_protect
        can_invite_users
        can_download_reports
        can_access_vendor_supply_chain_security
        date_created
        date_updated
        first_name
        id
        last_login_date
        last_name
        login_email
        notification_email
        renewal_reminder_last_dismissed
        role
        effective_permissions {
          can_access_all_asset_groups
          can_access_all_mobile_apps
          can_access_api_secure
          can_access_asset_group_ids
          can_access_cloud_secure
          can_access_mobile_app_ids
          can_access_mobile_phishing
          can_access_mobile_secure
          can_access_supply_chain_secure
          can_access_web_secure
        }
      }
    }
  }
`;
export const user_by_id = gql `
  query UserById($id: ID!) {
    user_by_id(id: $id) {
      allowed_app_ids
      auth_strategy
      can_access_app_search
      can_access_app_secure
      can_access_api_inspect
      can_access_brand_protect
      can_invite_users
      can_download_reports
      can_access_vendor_supply_chain_security
      date_created
      date_updated
      first_name
      id
      last_login_date
      last_name
      login_email
      notification_email
      renewal_reminder_last_dismissed
      role
      effective_permissions {
        can_access_all_asset_groups
        can_access_all_mobile_apps
        can_access_api_secure
        can_access_asset_group_ids
        can_access_cloud_secure
        can_access_mobile_app_ids
        can_access_mobile_phishing
        can_access_mobile_secure
        can_access_supply_chain_secure
        can_access_web_secure
      }
    }
  }
`;
export const create = gql `
  mutation UsersCreate($body: UserCreateParams!) {
    users_create(body: $body) {
      id
      allowed_app_ids
      auth_strategy
      can_access_app_search
      can_access_app_secure
      can_access_api_inspect
      can_access_brand_protect
      can_invite_users
      can_download_reports
      can_access_vendor_supply_chain_security
      first_name
      last_name
      login_email
      notification_email
      role
      renewal_reminder_last_dismissed
      last_login_date
      date_updated
      date_created
    }
  }
`;
export const update = gql `
  mutation UsersUpdate($id: ID!, $body: UserPatchParams!) {
    users_update(id: $id, body: $body) {
      id
      allowed_app_ids
      auth_strategy
      can_access_app_search
      can_access_app_secure
      can_access_api_inspect
      can_access_brand_protect
      can_invite_users
      can_download_reports
      can_access_vendor_supply_chain_security
      first_name
      last_name
      login_email
      notification_email
      role
      renewal_reminder_last_dismissed
      last_login_date
      date_updated
      date_created
    }
  }
`;
export const get_current_user = gql `
  query GetCurrentUser {
    current_user {
      id
      allowed_app_ids
      auth_strategy
      can_access_app_search
      can_access_app_secure
      can_access_api_inspect
      can_access_brand_protect
      can_invite_users
      can_download_reports
      can_access_vendor_supply_chain_security
      first_name
      last_name
      login_email
      notification_email
      role
      renewal_reminder_last_dismissed
      last_login_date
      date_updated
      date_created
    }
  }
`;
