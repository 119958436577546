import React, { PureComponent } from 'react';
import { palette, spacing } from '@dt/theme';

const getStyles = ({ style = {} }) => {
  const styles = {
    wrapper: {
      alignItems: 'center',
      backgroundColor: palette.brightBg,
      borderBottom: `1px solid ${palette.accent}`,
      display: 'flex',
      height: spacing.headerHeight,
      justifyContent: 'space-between',
      minHeight: spacing.headerHeight,
      zIndex: 5,
      ...style.wrapper,
    },
  };

  return {
    ...styles,
    primaryNavigationWrapper: {
      alignItems: 'stretch',
      display: 'flex',
      flex: 1,
      height: styles.wrapper.height,
      ...style.primaryNavigationWrapper,
    },
    secondaryNavigationWrapper: {
      alignItems: 'center',
      display: 'flex',
      right: 0,
      ...style.secondaryNavigationWrapper,
    },
  };
};

/*
 * Generic header to standardize navigation items.
 *
 * @deprecated - Don't use this compoennt for a header - its too generic - instead write JSX to support your use case.
 */
export default class Header extends PureComponent {
  render() {
    const { primaryNavigationItems, secondaryNavigationItems, children } = this.props;

    const styles = getStyles(this.props);

    return (
      <div style={styles.wrapper}>
        {children}
        {primaryNavigationItems && <div style={styles.primaryNavigationWrapper}>{primaryNavigationItems}</div>}
        <div style={styles.secondaryNavigationWrapper}>{secondaryNavigationItems}</div>
      </div>
    );
  }
}
