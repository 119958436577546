import gql from 'graphql-tag';
export const get_top_risky_device_table = gql `
  query GetTopRiskyDeviceTable(
    $appId: String!
    $order_by: String
    $filter_by_countries: [String]
    $filter_by_fraud_indicators: [String]
    $filter_by_app_versions: [String]
    $filter_by_fraud_score: [String]
    $filter_by_total_events: [String]
    $filter_by_fraud_activity: [String]
    $filter_by_connections: [String]
  ) {
    get_top_risky_device_table(
      appId: $appId
      order_by: $order_by
      filter_by_countries: $filter_by_countries
      filter_by_fraud_indicators: $filter_by_fraud_indicators
      filter_by_app_versions: $filter_by_app_versions
      filter_by_fraud_score: $filter_by_fraud_score
      filter_by_total_events: $filter_by_total_events
      filter_by_fraud_activity: $filter_by_fraud_activity
      filter_by_connections: $filter_by_connections
    ) {
      pagination_information {
        next_cursor
        total_count
      }
      risky_devices {
        device_id
        app_version
        total_events
        fraud_events
        connections
        country
        ip_address
        fraud_indicators
        fraud_score
        mobile_protect_version
      }
    }
  }
`;
export const per_country_fraud_devices = gql `
  query PerCountryFraudDevices($id: String!) {
    per_country_fraud_devices(id: $id) {
      top_fraud_devices_by_country {
        country_name
        country_color
        device_count
        country_flag_url
      }
    }
  }
`;
export const fraud_activity_timeline = gql `
  query FraudActivityTimeline($appId: String!, $hour_day_month_or_year: String) {
    fraud_activity_timeline(appId: $appId, hour_day_month_or_year: $hour_day_month_or_year) {
      stats_per_date {
        date
        total_activity
        fraud_activity
      }
    }
  }
`;
export const anti_fraud_devices_stats = gql `
  query AntiFraudDevicesStats($id: String!) {
    anti_fraud_devices_stats(id: $id) {
      all_devices_summary {
        total_devices
        trend_value
      }
    }
  }
`;
export const anti_fraud_connections_stats = gql `
  query AntiFraudConnectionsStats($id: String!) {
    anti_fraud_connections_stats(id: $id) {
      all_connections_summary {
        total_connections
        trend_value
      }
    }
  }
`;
export const risky_devices_search_filter_values = gql `
  query RiskyDevicesSearchFilterValuesQuery($appId: String!) {
    risky_devices_search_filter_values(appId: $appId) {
      risky_devices_search_filter_values {
        belongs_to_filter
        icon_url
        name
        value
        value_category
      }
    }
  }
`;
export const fraud_stats_per_date = gql `
  query FraudStatsPerDate($appId: String!, $deviceId: String!) {
    fraud_stats_per_date(appId: $appId, deviceId: $deviceId) {
      stats_per_date {
        date
        total_activity
        fraud_activity
      }
    }
  }
`;
export const per_country_fraud_connections = gql `
  query PerCountryFraudConnections($id: String!) {
    per_country_fraud_connections(id: $id) {
      top_fraud_connections_by_country {
        country_name
        country_color
        connection_count
        country_flag_url
      }
    }
  }
`;
export const fraud_event_logs = gql `
  query FraudEventLogs($id: String!, $order_by: String, $cursor: String) {
    fraud_event_logs(id: $id, order_by: $order_by, cursor: $cursor) {
      pagination_information {
        next_cursor
        total_count
      }
      event_logs {
        timestamp
        formatted_timestamp
        fraud_indicator
        device_id
        status
        app_version
        country
        country_code
        country_flag_url
        ip_address
        mobile_protect_version
      }
    }
  }
`;
export const fraud_device_logs = gql `
  query FraudDeviceLogs($id: String!, $order_by: String, $cursor: String) {
    fraud_device_logs(id: $id, order_by: $order_by, cursor: $cursor) {
      pagination_information {
        next_cursor
        total_count
      }
      device_logs {
        timestamp
        formatted_timestamp
        fraud_indicators
        device_id
        status
        country
        country_code
        country_flag_url
        ip_address
        mobile_protect_version
      }
    }
  }
`;
export const fraud_device_log_timeline = gql `
  query FraudDeviceLogTimeline($id: String!, $device_id: String!) {
    fraud_device_log_timeline(id: $id, device_id: $device_id) {
      total_events_count
      unique_indicators_count
      events_per_date {
        date
        indicator_metrics {
          indicator_name
          indicator_event_count
        }
      }
    }
  }
`;
export const fraud_devices_stats = gql `
  query FraudDevicesStats($id: String!) {
    fraud_devices_stats(id: $id) {
      fraud_devices_summary {
        average_score
        total_devices
        trend_value
      }
    }
  }
`;
export const fraud_indicator_stats = gql `
  query FraudIndicatorStats($id: String!) {
    fraud_indicator_stats(id: $id) {
      fraud_indicators_summary {
        total_fraud_indicators
        trend_value
        total_activities
      }
      indicator_breakdown {
        indicator_name
        affected_devices
        trend_percent
        color
      }
    }
  }
`;
export const fraud_connections_stats = gql `
  query FraudConnectionsStats($id: String!) {
    fraud_connections_stats(id: $id) {
      fraud_connections_summary {
        total_connections
        trend_value
      }
      fraud_connections_breakdown {
        connected_domain
        connection_count
        color
      }
    }
  }
`;
