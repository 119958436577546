import React from 'react';
import TooltipIcon from '@mui/icons-material/HelpOutline';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import { fontSizes, palette } from '@dt/theme';
import Markdown from './Markdown';
import AndroidImage from './platform_images/android_icon.png';
import IOSImage from './platform_images/ios_icon.png';

const useStyles = makeStyles({
  InfoCard: {
    backgroundSize: 'contain !important',
    fontSize: fontSizes.medium,
    height: '100%',
    minHeight: 110,
    minWidth: 110,
    position: 'relative !important',
    wordBreak: 'break-all !important',
  },

  // Following class acts as an overlay.
  heading: {
    alignItems: 'center',
    backgroundColor: ({ attention }) => (attention ? palette.red20 : palette.white),
    borderBottom: `1px solid ${palette.gray45}`,
    borderTopLeftRadius: 'inherit',
    borderTopRightRadius: 'inherit',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '16px 24px',
  },

  headingIcon: {
    cursor: 'pointer',
    height: '24px',
    width: '24px',
  },

  headingText: {
    color: ({ attention }) => (attention ? palette.white : null),
    verticalAlign: 'middle',
    whiteSpace: 'pre-wrap',
    wordBreak: 'keep-all',
  },

  icon: {
    maxHeight: '24px',
    maxWidth: '24px',
  },

  img: {
    maxWidth: '100%',
  },

  inner: {
    position: 'relative',
  },

  primaryText: {
    fontSize: ({ fontSize, primaryText }) =>
      fontSize || (primaryText && primaryText.length > 15 ? fontSizes.medium : fontSizes.large),
    padding: 10,
  },

  tooltip: {
    borderRadius: '5px !important',
    maxWidth: '300px !important',
    padding: '20px !important',
  },

  tooltipIcon: {
    color: ({ attention }) => (attention ? `${palette.white} !important` : `${palette.gray30} !important`),
  },
});

export default React.memo(function InfoCard(props) {
  const { heading, primaryText, list, android, ios, tooltip, children } = props;
  const classes = useStyles(props);

  return (
    <Paper className={classes.InfoCard}>
      <div className={classes.heading}>
        <div className={classes.headingText}>{heading}</div>
        <div className={classes.headingIcon}>
          {tooltip && !ios && !android && (
            <Tooltip disableInteractive title={tooltip}>
              <span className={classes.tooltipIcon}>
                <TooltipIcon className={classes.tooltipIcon} color="inherit" />
              </span>
            </Tooltip>
          )}
          {(ios || android) && (
            <Tooltip disableInteractive title={ios ? 'iOS' : 'Android'}>
              <div className={classes.icon}>
                <img className={classes.img} src={ios ? IOSImage : AndroidImage} />
              </div>
            </Tooltip>
          )}
        </div>
      </div>
      <div className={classes.inner}>
        {primaryText && (
          <div className={classes.primaryText}>
            <Markdown inline text={primaryText} />
          </div>
        )}
        {children}
        {list && (
          <List disablePadding>
            {list.map(item => (
              <div key={item}>
                <Divider />
                <ListItem>
                  <ListItemText>
                    <Markdown inline text={item} />
                  </ListItemText>
                </ListItem>
              </div>
            ))}
          </List>
        )}
      </div>
    </Paper>
  );
});
