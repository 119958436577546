import { assets_searches, restful_apis } from '@dt/horizon-api';
import { paginationBeginRequest, paginationEndRequest } from '@dt/pagination';
import { callPromise } from '@dt/redux-saga-wrapped-effects';
import { call, put } from 'redux-saga/effects';
import { handleNormalizedResponse, withCache } from './../resource_fetch/sagas';
import resourceType from './resourceType';

export const getRestfulAPIsList = withCache('restfulapi_list', function* getRestfulAPIs(params) {
  yield put(paginationBeginRequest(resourceType, params));

  const { search_id, ...urlParams } = params;
  const response = search_id
    ? yield* callPromise(assets_searches['restful_apis'].list, search_id, urlParams)
    : yield* callPromise(restful_apis.list, params);
  if (response._type === 'error') {
    throw new Error(response.title);
  }

  yield call(handleNormalizedResponse, response.body);

  if (response.body.pagination_information) {
    yield put(paginationEndRequest(resourceType, params, response.body.pagination_information));
  }
});

export const getRestfulAPIDetails = withCache(
  restfulAPIId => `restful_api_${restfulAPIId}`,
  function* (restfulAPIId) {
    const response = yield* callPromise(restful_apis.details, restfulAPIId);

    if (response._type === 'error') {
      throw new Error(response.title);
    }

    yield call(handleNormalizedResponse, response.body);
  },
);
