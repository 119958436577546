//@flow
import gql from 'graphql-tag';
export const get_status = gql `
  query ShadowAssetsGetStatus($id: String!) {
    shadow_assets_check_status(id: $id) {
      action_type
      status
      id
      completed_tasks_count
      total_tasks_count
      date_created
      date_completed
    }
  }
`;
export const get = gql `
  query ConfigurationsShadowGet {
    configurations_shadow_get {
      bulk_action_id
      configuration {
        approved_asset_types
        approved_cloud_providers
        approved_app_stores
        public_asm_enabled
        should_mark_new_apis_not_going_through_gateway
        should_mark_new_assets_except_on_approved_asset_types
        should_mark_new_cloud_resources_except_on_approved_cloud_providers
        should_mark_new_mobile_apps_on_3rd_party_stores
        should_mark_new_mobile_apps_on_unofficial_app_stores
        should_mark_new_mobile_apps_except_on_approved_app_stores
        should_mark_new_mobile_apps_on_official_stores
        should_mark_new_network_services_with_dev_in_url
        should_mark_new_network_services_with_preprod_in_url
        should_mark_new_network_services_with_uat_in_url
      }
    }
  }
`;
export const put = gql `
  mutation ConfigurationsShadowPut($body: ConfigurationsShadowPutParams!) {
    configurations_shadow_put(body: $body) {
      bulk_action_id
    }
  }
`;
