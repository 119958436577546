import React from 'react';
import { useContext } from 'react';
import { useParams } from '@reach/router';

export const PublicAppContext = React.createContext(false);

function usePublicApp() {
  const isPublicApp = useContext(PublicAppContext);

  const { token } = useParams();

  return {
    isPublicApp: isPublicApp,
    token: token,
  };
}

export default usePublicApp;
