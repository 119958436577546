import immer from 'immer';
import { domainNamesReceived } from './actions';

const initialState = {
  id: {},
};

export default immer((draft, action) => {
  if (action.type === domainNamesReceived.toString()) {
    for (const domain_name of action.payload) {
      draft.id[domain_name.id] = domain_name;
    }
  }
}, initialState);
