import React from 'react';
import UnknownIcon from '@mui/icons-material/HelpOutline';
import { HostedOnEnum } from '@dt/horizon-api';
import { palette } from '@dt/theme';

const AssetHostedOnIcon = ({ size = 14, iconUrl, name }) => {
  if (iconUrl === HostedOnEnum.UNKNOWN) {
    return <UnknownIcon style={{ color: palette.gray, height: size, width: size }} />;
  }
  return <img alt={`hosted-on-icon-${name}`} height={size} src={iconUrl} width={size} />;
};

export default React.memo(AssetHostedOnIcon);
