import { HostedOnEnum } from '@dt/horizon-api';
import { AssetTypeDict } from '../inventory/types';
import {
  getAggregatedRelevance,
  getHistoricAggregatedRelevance,
  getUnresolvedPolicyViolationPointValue,
} from '../policy_violations/util';

export const web_applications = ({ web_applications }) => web_applications;

export const decorate = (
  web_application,
  policy_violations_decorated,
  restful_apis_decorated,
  network_service_decorated,
) => {
  const related_policy_violations_decorated = policy_violations_decorated.filter(pv =>
    web_application.policy_violation_ids.includes(pv.id),
  );

  const analysis_result = web_application.most_recent_analysis_result;
  const { detected_restful_api_ids, title, screenshot_url } = analysis_result;

  const detected_restful_apis_decorated = detected_restful_api_ids
    ? restful_apis_decorated.filter(r => detected_restful_api_ids.includes(r.id))
    : [];

  const hosted_on = (network_service_decorated && network_service_decorated.hosted_on) || HostedOnEnum.UNKNOWN;

  return {
    ...web_application,
    aggregated_relevance: getAggregatedRelevance({
      policy_violations_decorated: related_policy_violations_decorated,
    }),
    asset_type: AssetTypeDict.SINGLE_PAGE_WEB_APP,
    detected_restful_apis_decorated,
    historic_aggregated_relevance: getHistoricAggregatedRelevance({
      policy_violations_decorated: related_policy_violations_decorated,
    }),
    hosted_on,
    name: title || web_application.base_url,
    policy_violations_decorated: related_policy_violations_decorated,
    screenshot_url: screenshot_url,
    unresolved_policy_violations_point_value: getUnresolvedPolicyViolationPointValue({
      policy_violations_decorated: related_policy_violations_decorated,
    }),
    url: web_application.base_url,
  };
};
