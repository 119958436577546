import Cookies from 'js-cookie';

/* Hook for working with cookies within the portal
 *  The hook expects a key of type string, to read or set the cookie.
 *
 * Usage:
 * ```
 * import useCookie from '@dt/material-components/hooks/use_cookie'
 *
 * function MyFunction() {
 *  let myCookieKey = fnToGetOrCreateCookieOnInitialRender();
 *
 *  let [value, setter] = useCookie(myCookieKey);
 * }

 * ```
 * */

function useCookie(key) {
  const getCookie = k => {
    return Cookies.get(k);
  };

  const setCookie = (k, v, opts) => {
    if (k.length >= 0) {
      opts ? Cookies.set(k, v, opts) : Cookies.set(k, v);
    }
  };

  return [getCookie(key), setCookie];
}

export default useCookie;
