import React, { memo } from 'react';
import DropDownIcon from '@mui/icons-material/ArrowDropDown';
import { makeStyles } from '@mui/styles';
import { palette } from '@dt/theme';

const useStyles = makeStyles({
  container: {
    cursor: ({ onClick }) => (onClick ? 'pointer' : 'unset'),
    justifyContent: 'space-between',
    width: ({ width }) => width || 'auto',
  },

  dropdownContainer: {
    '& > *': {
      'flex-grow': 0,
      'flex-shrink': 0,
    },
    alignItems: 'center',
    color: 'white',
    display: 'flex',

    fontSize: '13px',
    fontWeight: 'bold',
    height: 12,
    justifyContent: 'center',
    padding: '10px 15px',
    textTransform: 'uppercase',

    width: 'auto',
  },

  icon: {
    marginRight: -8,
    position: 'relative',
  },
});

function Indicator(props) {
  const { label, onClick, backgroundColor, color, radius } = props;
  const classes = useStyles(props);

  return (
    <div className={classes.container} onClick={onClick}>
      <div
        className={classes.dropdownContainer}
        style={{
          backgroundColor: backgroundColor || palette.navColor,
          borderRadius: radius,
          color: color || palette.white,
        }}
      >
        <span>{label}</span>
        {onClick && (
          <DropDownIcon
            className={classes.icon}
            htmlColor={color || palette.white}
            style={{
              height: 20,
              width: 18,
            }}
          />
        )}
      </div>
    </div>
  );
}

Indicator.defaultProps = {
  radius: 2,
};

export default memo(Indicator);
