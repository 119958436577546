// We need to split this substring into multiple parts because of Webpack issues. Webpack seems to be
// auto-generating a similar string, 3ce37867, then auto-replacing all occurrences. This has the unintended
// effect of replacing this instance. We therefore break up the substring such that it doesn't result in a
// collision with the webpack generated version.
const LEAKY_PART_1 = '3ce3';
const LEAKY_PART_2 = '7867';
export const LEAKY_APIS_POLICY_RULE_TYPE_ID = `${LEAKY_PART_1}${LEAKY_PART_2}-3680-5094-86bc-d4e7eb3265ef`;

// We alphabetize the list of checks by alphabetical order, but when these ids
// are present they should be sorted to first and second. Ask Alban/HD.
const policyRuleTypeIdFirst = LEAKY_APIS_POLICY_RULE_TYPE_ID; // API operation has authentication disabled
const policyRuleTypeIdSecond = '67599de3-2914-54af-a177-7ee9a4241ce0'; // API operation is declared unauthenticated

export const sortPoliciesRulesType = policy_rule_types => {
  const alphabeticalOrderGroupRules = policy_rule_types
    .filter(rule_type => rule_type.id !== policyRuleTypeIdFirst && rule_type.id !== policyRuleTypeIdSecond)
    .sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase()));

  const orderedGroupRules = [
    policy_rule_types.find(rule_type => rule_type.id === policyRuleTypeIdFirst),
    policy_rule_types.find(rule_type => rule_type.id === policyRuleTypeIdSecond),
    ...alphabeticalOrderGroupRules,
  ].filter(Boolean);

  return orderedGroupRules;
};
