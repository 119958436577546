import React from 'react';
import ApiIcon from '@mui/icons-material/DeviceHub';
import { Avatar, Grid } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { PageTitle } from '@dt/components';
import { AssetTypeEnumValues } from '@dt/graphql-support/enums';
import { ExtLink } from '@dt/material-components';
import { palette } from '@dt/theme';

export const AssetsPageTitle = ({ asset, isLoading }) => {
  const isNotRestApi = asset?.asset_type !== AssetTypeEnumValues.RESTFUL_API;

  return (
    <Grid alignItems="center" container spacing={1}>
      <Grid item>
        {isLoading ? (
          <Skeleton height={40} variant="circular" width={40} />
        ) : (
          <>
            {isNotRestApi ? (
              <Avatar
                src={asset?.asset_type_icon_url}
                style={{
                  backgroundColor: palette.gray45,
                  padding: 4,
                }}
                variant="circular"
              />
            ) : (
              <Avatar
                style={{
                  background: palette.orange,
                }}
                variant="circular"
              >
                <ApiIcon fontSize="large" style={{ padding: 2 }} />
              </Avatar>
            )}
          </>
        )}
      </Grid>

      <Grid item>
        {asset ? (
          <>
            {asset.url ? (
              <ExtLink to={asset.url}>
                <PageTitle title={asset.name} />
              </ExtLink>
            ) : (
              <PageTitle title={asset.name} />
            )}
          </>
        ) : (
          <PageTitle isLoading={isLoading} title="Asset" />
        )}
      </Grid>
    </Grid>
  );
};
