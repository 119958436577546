import React, { memo, useState } from 'react';
import { Button, FormControlLabel, Radio, RadioGroup, TextField, TextareaAutosize } from '@mui/material';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import { validateEmail } from '@dt/functions';
import { fontFamilies, palette } from '@dt/theme';
import Text from './Text';

const useStyles = makeStyles({
  commentForm: {
    '&:focus': {
      border: `solid 1px ${palette.gray35}`,
      outline: 'none',
    },
    border: `solid 1px ${palette.gray40}`,
    borderRadius: 5,
    fontFamily: fontFamilies.primary,
    fontSize: 14,
    minWidth: '100%',

    overflowY: 'auto',

    padding: 9,

    // Only allow for vertical resizing of the comment form.
    resize: 'vertical',
  },
  commentFormRadioGroup: {
    marginBottom: '4px',
    paddingLeft: '7px',
  },
  commentFormSubmitContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: 8,
  },
  emailForm: {
    background: palette.white,
  },
  root: {
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
});

/*
 * Allows the user to comment on a policy violation.
 *
 * @param hideSendOptions - Hide the send options.
 *                          Used primarily by horizon since "internal notes" aren't currently supported.
 * @param disabled - Whether or not the user can interact with this component.
 * @param onSubmit - Triggered event when the user submits/completes the form.
 * @param onCancel - Triggered event when the user cancels the form.
 */
const PolicyViolationsCommentsFormComponent = function PolicyViolationsCommentsForm({
  hideSendOptions,
  disabled,
  onSubmit,
  onCancel,
  isEmailRequired,
}) {
  const classes = useStyles();

  const [text, setText] = useState('');
  const [email, setEmail] = useState('');
  const [sendTo, setSendTo] = useState('sendMsgInternally');

  const handleSubmit = () => {
    onSubmit &&
      onSubmit({
        author_email: email,
        is_internal_comment: sendTo === 'sendMsgInternally',
        is_question_for_datatheorem: sendTo === 'sendMsgToDataTheorem',
        text,
      });
    setText('');
  };

  return (
    <div className={classes.root}>
      {!hideSendOptions && (
        <RadioGroup
          className={classes.commentFormRadioGroup}
          name="sendToRadioGroup"
          onChange={e => {
            setSendTo(e.target.value);
          }}
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
          value={sendTo}
        >
          <FormControlLabel
            control={<Radio color="primary" />}
            disabled={disabled}
            label="Log Internal Note"
            value="sendMsgInternally"
          />
          <FormControlLabel
            control={<Radio color="primary" />}
            disabled={disabled}
            label="Receive AppSec Advice from Data Theorem"
            value="sendMsgToDataTheorem"
          />
        </RadioGroup>
      )}

      {isEmailRequired && (
        <TextField
          InputLabelProps={{ shrink: true }}
          aria-label="email"
          className={classes.emailForm}
          error={Boolean(email) && !validateEmail(email)}
          fullWidth
          label="Email"
          margin="dense"
          onChange={e => setEmail(e.target.value)}
          placeholder={'user@email.com'}
          style={{
            marginBottom: 10,
          }}
          value={email}
          variant={'outlined'}
        />
      )}

      <TextareaAutosize
        className={classes.commentForm}
        id="comment-form"
        maxRows={6}
        onChange={e => {
          setText(e.currentTarget.value);
        }}
        onKeyPress={e => {
          if (e.shiftKey && e.key === 'Enter') {
            handleSubmit();
          }
        }}
        placeholder="Technical questions and/or comments? Insert them here."
        rows={6}
        value={text}
      />

      <div className={classes.commentFormSubmitContainer}>
        <Box mr={1}>
          <Button
            aria-label="Save Comment"
            color="primary"
            disabled={
              disabled ||
              (!isEmailRequired && text.length <= 0) ||
              (isEmailRequired && (text.length <= 0 || email.length <= 0 || !validateEmail(email)))
            }
            onClick={handleSubmit}
            variant="contained"
          >
            <Text color="inherit" variant="button">
              Submit
            </Text>
          </Button>
        </Box>

        <Button aria-label="Cancel" color="primary" onClick={onCancel} variant="text">
          <Text color="inherit" variant="button">
            Cancel
          </Text>
        </Button>
      </div>
    </div>
  );
};

export const PolicyViolationsCommentsForm = memo(PolicyViolationsCommentsFormComponent);
