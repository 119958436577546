import React from 'react';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import { WeekdaysEnum } from '@dt/horizon-api';
import capitalize from 'lodash/capitalize';

const WeekdaysCheckbox = ({ selectedWeekdays, onChange, variant = 'two-columns' }) => {
  return (
    <Grid container justifyContent={'flex-start'} spacing={1}>
      {Object.keys(WeekdaysEnum).map((weekday, index) => (
        <Grid item key={index} xs={variant === 'two-columns' ? 6 : null}>
          <FormControlLabel
            control={
              <Checkbox
                checked={selectedWeekdays.includes(weekday)}
                color="primary"
                onChange={e => {
                  const weekdays = selectedWeekdays.filter(item => item !== weekday);
                  const isChecked = e.target.checked;
                  onChange(isChecked ? weekdays.concat(weekday) : weekdays);
                }}
                style={{ paddingBottom: 3, paddingTop: 3 }}
                value={weekday}
              />
            }
            label={capitalize(weekday)}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default React.memo(WeekdaysCheckbox);
