import { handleActions } from 'redux-actions';
import {
  apigeeAuthenticatorsReceived,
  awsAuthenticatorsReceived,
  axwayAuthenticatorsReceived,
  azureAuthenticatorsReceived,
  certificateTransparencyReceived,
  gcpAuthenticatorsReceived,
  kongAuthenticatorsReceived,
  mulesoftAuthenticatorsReceived,
} from './actions';

const initialState = {
  apigee_authenticators_list: [],

  aws_authenticators_list: [],

  axway_authenticators_list: [],

  azure_authenticators_list: [],

  certificate_transparency: null,

  gcp_authenticators_list: [],

  kong_authenticators_list: [],

  mulesoft_authenticators_list: [],
};

export default handleActions(
  {
    [awsAuthenticatorsReceived.toString()]: (state, action) => {
      const newIds = action.payload.map(({ id }) => id);
      const newList = [
        ...state.aws_authenticators_list.filter(({ id }) => !newIds.includes(id)),
        ...action.payload.map(authenticator => ({
          ...authenticator,
          description: authenticator.role_arn,
          type: 'aws',
        })),
      ];
      return {
        ...state,
        aws_authenticators_list: newList,
      };
    },
    [gcpAuthenticatorsReceived.toString()]: (state, action) => {
      const newIds = action.payload.map(({ id }) => id);
      const newList = [
        ...state.gcp_authenticators_list.filter(({ id }) => !newIds.includes(id)),
        ...action.payload.map(authenticator => ({
          ...authenticator,
          description: authenticator.client_email,
          type: 'gcp',
        })),
      ];
      return {
        ...state,
        gcp_authenticators_list: newList,
      };
    },
    [azureAuthenticatorsReceived.toString()]: (state, action) => {
      const newIds = action.payload.map(({ id }) => id);
      const newList = [
        ...state.azure_authenticators_list.filter(({ id }) => !newIds.includes(id)),
        ...action.payload.map(authenticator => ({
          ...authenticator,
          description: authenticator.client_id,
          type: 'azure',
        })),
      ];
      return {
        ...state,
        azure_authenticators_list: newList,
      };
    },
    [axwayAuthenticatorsReceived.toString()]: (state, action) => {
      const newIds = action.payload.map(({ id }) => id);
      const newList = [
        ...state.axway_authenticators_list.filter(({ id }) => !newIds.includes(id)),
        ...action.payload.map(authenticator => ({
          ...authenticator,
          description: authenticator.organization_id,
          type: 'axway',
        })),
      ];
      return {
        ...state,
        axway_authenticators_list: newList,
      };
    },
    [mulesoftAuthenticatorsReceived.toString()]: (state, action) => {
      const newIds = action.payload.map(({ id }) => id);
      const newList = [
        ...state.mulesoft_authenticators_list.filter(({ id }) => !newIds.includes(id)),
        ...action.payload.map(authenticator => ({
          ...authenticator,
          description: authenticator.organization_id,
          type: 'mulesoft',
        })),
      ];
      return {
        ...state,
        mulesoft_authenticators_list: newList,
      };
    },
    [kongAuthenticatorsReceived.toString()]: (state, action) => {
      const newIds = action.payload.map(({ id }) => id);
      const newList = [
        ...state.kong_authenticators_list.filter(({ id }) => !newIds.includes(id)),
        ...action.payload.map(authenticator => ({
          ...authenticator,
          description: authenticator.kong_admin_subdomain,
          type: 'kong',
        })),
      ];
      return {
        ...state,
        kong_authenticators_list: newList,
      };
    },
    [apigeeAuthenticatorsReceived.toString()]: (state, action) => {
      const newIds = action.payload.map(({ id }) => id);
      const newList = [
        ...state.apigee_authenticators_list.filter(({ id }) => !newIds.includes(id)),
        ...action.payload.map(authenticator => ({
          ...authenticator,
          description: authenticator.organization,
          type: 'apigee',
        })),
      ];
      return {
        ...state,
        apigee_authenticators_list: newList,
      };
    },
    [certificateTransparencyReceived.toString()]: (state, action) => ({
      ...state,
      certificate_transparency: action.payload,
    }),
  },
  initialState,
);
