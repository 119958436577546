import React, { useEffect, useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { TextField } from '@mui/material';
import { Checkbox, Grid, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import Box from '@mui/material/Box';
import { Message } from '@components';
import { useQuery } from '@dt/apollo-link-schema-rest';
import compliance_standard_criteria, {
  ComplianceCategories,
} from '@dt/graphql-support/horizon/compliance_standard_criteria';
import { ComplianceTag, Text } from '@dt/material-components';

const ComplianceCategorySections = {
  [ComplianceCategories.OWASP_A]: 'OWASP API:2019',
  [ComplianceCategories.OWASP_M]: 'OWASP Mobile:2019',
  [ComplianceCategories.OWASP_W]: 'OWASP Web:2017',
  [ComplianceCategories.OWASP_R]: 'OWASP Cloud:2011',
  [ComplianceCategories.OWASP_MSTG]: 'OWASP MASTG',
  [ComplianceCategories.OWASP_ASVS]: 'OWASP ASVS',
  [ComplianceCategories.PCI_DSS]: 'PCI Data Security Standard (v.3.2.1)',
  [ComplianceCategories.NIST_800_53]: 'NIST 800-53',
  [ComplianceCategories.FFIEC_VC2]: 'FFIEC Compliance – V.C.2(c)',
  [ComplianceCategories.CIS_BENCHMARK]: 'CIS Benchmark',
  [ComplianceCategories.FEDRAMP_V5_API_SECURITY]: 'FedRAMPv5 (API Security)',
};

/*
 * Popout filter plugin.
 * User can select compliance standard criterion.
 *
 * @param value - Current state.
 * @param onChange - State change.
 */
const PopoutFilterPluginComplianceStandardComponent = function PopoutFilterPluginComplianceStandard({
  value,
  onChange,
}) {
  const [searchKeywords, setSearchKeywords] = useState('');

  // Fetch compliance standard criteria.
  const {
    data: complianceStandardCriteriaListData,
    fetchMore: complianceStandardCriteriaFetchMore,
    error: complianceStandardCriteriaListError,
    loading: complianceStandardCriteriaListLoading,
  } = useQuery(compliance_standard_criteria.list);

  // Fetch all.
  useEffect(() => {
    complianceStandardCriteriaFetchMore && complianceStandardCriteriaFetchMore();
  }, [complianceStandardCriteriaFetchMore]);

  // Error State.
  if (complianceStandardCriteriaListError) {
    return <Message message={complianceStandardCriteriaListError.message} variant="error" />;
  }

  return (
    <Box display="flex" flexDirection="column" style={{ height: '100%' }}>
      <TextField
        InputProps={{
          startAdornment: <SearchIcon />,
        }}
        autoFocus
        fullWidth
        label="Enter Keyword"
        onChange={e => setSearchKeywords(e.target.value)}
        value={searchKeywords}
      />

      <div style={{ flex: '1 1 0', overflow: 'auto' }}>
        {complianceStandardCriteriaListLoading ? (
          // Loading State.
          <>{null}</>
        ) : (
          // Happy State.
          <Grid container>
            {Object.keys(ComplianceCategorySections)
              .filter(section => section)
              .map(complianceCategorySection => {
                const subCriterion =
                  complianceStandardCriteriaListData?.compliance_standard_criteria_list.compliance_standard_criteria.filter(
                    complianceStandard => complianceStandard.criterion.includes(complianceCategorySection),
                  ) || [];

                const allSelected = subCriterion.every(s => !!value[s.criterion]);
                const someSelected = subCriterion.some(s => !!value[s.criterion]);

                return (
                  <Grid item key={complianceCategorySection} xs={12}>
                    <List>
                      {/* Compliance Header */}
                      <ListItem
                        button
                        dense
                        key={complianceCategorySection}
                        onClick={() => {
                          onChange(existing => {
                            const newValue = !allSelected && !someSelected;
                            const newSelection = subCriterion.reduce((aggregate, compliance_standard_criteria) => {
                              aggregate[compliance_standard_criteria.criterion] = newValue;
                              return aggregate;
                            }, {});

                            return {
                              ...existing,
                              ...newSelection,
                            };
                          });
                        }}
                        role={undefined}
                      >
                        <ListItemIcon>
                          <Checkbox
                            checked={allSelected || someSelected}
                            disableRipple
                            indeterminate={!allSelected && someSelected}
                            tabIndex={-1}
                          />
                        </ListItemIcon>
                        <ListItemText
                          disableTypography
                          primary={
                            <Text
                              style={{
                                margin: 0,
                                padding: 0,
                              }}
                              variant="titleXS"
                            >
                              {ComplianceCategorySections[complianceCategorySection]}
                            </Text>
                          }
                        />
                      </ListItem>

                      {/* Compliance SubSections */}
                      <Box display="flex" flexDirection="column">
                        {complianceStandardCriteriaListData?.compliance_standard_criteria_list.compliance_standard_criteria
                          .filter(complianceStandard =>
                            complianceStandard.criterion.includes(complianceCategorySection),
                          )
                          // Search by:
                          // - title
                          // - criterion
                          .filter(complianceStandard => {
                            const splitSearchKeywords = searchKeywords.toLowerCase().split(' ');

                            const splitTitle = complianceStandard.title.toLowerCase().split(' ');
                            if (splitSearchKeywords.every(k => splitTitle.some(s => s.includes(k)))) {
                              return true;
                            }

                            const splitCriterion = complianceStandard.criterion.toLowerCase().split('_');
                            if (splitSearchKeywords.every(k => splitCriterion.some(s => s.includes(k)))) {
                              return true;
                            }

                            return false;
                          })
                          .map((compliance_standard_criteria, i) => {
                            return (
                              <ListItem
                                button
                                dense
                                key={i}
                                onClick={() => {
                                  const c = compliance_standard_criteria.criterion;
                                  onChange(v => ({
                                    ...v,
                                    [c]: !v[c],
                                  }));
                                }}
                                style={{ paddingLeft: 8 * 6 }}
                              >
                                <ListItemIcon>
                                  <Checkbox
                                    checked={!!value[compliance_standard_criteria.criterion]}
                                    color="primary"
                                    disableRipple
                                    tabIndex={-1}
                                  />
                                </ListItemIcon>
                                <ComplianceTag
                                  compliance_standard={compliance_standard_criteria.compliance_standard}
                                  compliance_standard_criteria={compliance_standard_criteria.criterion}
                                  description={compliance_standard_criteria.description}
                                />
                              </ListItem>
                            );
                          })}
                      </Box>
                    </List>
                  </Grid>
                );
              })}
          </Grid>
        )}
      </div>
    </Box>
  );
};

export const PopoutFilterPluginComplianceStandard = PopoutFilterPluginComplianceStandardComponent;
