import { useMutation } from '@apollo/client';
import { AssetOrderByValues } from '@dt/graphql-support/enums';
import asset_groups from '@dt/graphql-support/horizon/asset_groups';
import assets from '@dt/graphql-support/horizon/assets';
import bulk_actions from '@dt/graphql-support/horizon/bulk_actions';
import mobile_applications from '@dt/graphql-support/horizon/mobile_applications';
import gql from 'graphql-tag';
import { orderBy } from 'lodash/fp';

export default function useBulkActionMoveToAssetGroup(
  assetIds,
  assetGroupId,
  onCompleted,
  onError,
  mobileAppsToUpdate,
  updateCache,
) {
  return useMutation(bulk_actions.assets.move_to_asset_group_actions.create, {
    onCompleted: () => {
      onCompleted && onCompleted();
    },
    onError: () => {
      onError && onError();
    },
    update: (cache, result) => {
      if (result?.errors) {
        return;
      }

      // get list of
      const asset_list_queries = Object.keys(cache.data.data.ROOT_QUERY).filter(key => key.includes('asset_list'));
      const asset_list_query_variables = asset_list_queries.map(key =>
        JSON.parse(key.match(/\((.*)\)/)?.pop() || '{}'),
      );

      // remove Ids from all asset list queries with 'filter_by_asset_group_id'
      // selected assets will be removed from all asset groups.
      asset_list_query_variables
        .filter(variables => variables.filter_by_asset_group_id)
        .forEach(variables => {
          const q = cache.readQuery({
            query: assets.list,
            variables: {
              ...variables,
            },
          });
          cache.writeQuery({
            data: {
              asset_list: {
                ...q?.asset_list,
                assets: q?.asset_list?.assets.filter(asset => !assetIds.includes(asset.id)),
                pagination_information: {
                  ...q?.asset_list.pagination_information,
                  total_count:
                    q?.asset_list.pagination_information.total_count -
                    q?.asset_list?.assets.filter(asset => assetIds.includes(asset.id)),
                },
              },
            },
            query: assets.list,
            variables: {
              ...variables,
            },
          });
        });

      //reset belongs_to_asset_group for selected assets
      assetIds.map(assetId => {
        cache.writeFragment({
          data: {
            belongs_to_asset_group: null,
          },
          fragment: gql`
            fragment ResetAssetGroup on AssetSummaryField {
              belongs_to_asset_group {
                id
                name
              }
            }
          `,
          id: `AssetSummaryField:${assetId}`,
        });
      });

      if (updateCache) {
        const mobile_application_queries = Object.keys(cache.data.data.ROOT_QUERY).filter(key =>
          key.includes('mobile_app_list'),
        );
        const mobile_application_query_variables = mobile_application_queries.map(key =>
          JSON.parse(key.match(/\((.*)\)/)?.pop() || '{}'),
        );

        const assetGroupsQuery = cache.readQuery({
          query: asset_groups.v2_list,
          variables: {
            page_size: 10,
          },
        });

        const newAssetGroup = assetGroupsQuery.asset_group_list_v2.asset_groups.find(item => item.id === assetGroupId);

        mobile_application_query_variables.forEach(variables => {
          const q = cache.readQuery({
            query: mobile_applications.list,
            variables,
          });

          cache.writeQuery({
            data: {
              mobile_app_list: {
                ...q?.mobile_app_list,
                mobile_applications: q?.mobile_app_list?.mobile_applications.map(mobileApp => {
                  const exists = mobileAppsToUpdate?.find(item => item.id === mobileApp.id);
                  if (exists) {
                    return {
                      ...mobileApp,
                      asset_group: assetGroupId ? newAssetGroup : null,
                    };
                  }
                  return mobileApp;
                }),
              },
            },
            query: mobile_applications.list,
            variables,
          });
        });
      }

      // add Ids to asset group
      if (assetGroupId) {
        //update belongs_to_asset_group for each selected assets
        assetIds.map(assetId => {
          cache.writeFragment({
            data: {
              belongs_to_asset_group: {
                id: assetGroupId,
              },
            },
            fragment: gql`
              fragment LinkAssetGroup on AssetSummaryField {
                belongs_to_asset_group {
                  id
                }
              }
            `,
            id: `AssetSummaryField:${assetId}`,
          });
        });

        // get selected asset objects
        const selectedAssets = assetIds.map(assetId => {
          return cache.readFragment({
            fragment: gql`
              fragment GetAssetGroup on AssetSummaryField {
                id
                name
                url
                status
                asset_type
                asset_type_icon_url
                asset_type_name
                belongs_to_asset_group {
                  id
                  name
                }
                date_created
                date_no_longer_accessible
                discovered_via
                hosted_on
                open_important_policy_violations_count
                open_proactive_policy_violations_count
                open_urgent_policy_violations_count
              }
            `,
            id: `AssetSummaryField:${assetId}`,
          });
        });

        // find the right cached query and add assets with the right order
        asset_list_query_variables
          .filter(variables => variables.filter_by_asset_group_id?.includes(assetGroupId))
          .forEach(variables => {
            const q = cache.readQuery({
              query: assets.list,
              variables: {
                ...variables,
              },
            });

            const newAssetList = [
              ...q?.asset_list?.assets.filter(asset => !assetIds.includes(asset.id)),
              ...selectedAssets,
            ];

            cache.writeQuery({
              data: {
                asset_list: {
                  ...q?.asset_list,
                  assets:
                    variables.order_by === AssetOrderByValues.policy_violations_count_desc
                      ? orderBy(
                          newAssetList,
                          [
                            'open_urgent_policy_violations_count',
                            'open_important_policy_violations_count',
                            'open_proactive_policy_violations_count',
                          ],
                          ['desc', 'desc', 'desc'],
                        )
                      : variables.order_by === AssetOrderByValues.policy_violations_count_asc
                        ? orderBy(
                            newAssetList,
                            [
                              'open_urgent_policy_violations_count',
                              'open_important_policy_violations_count',
                              'open_proactive_policy_violations_count',
                            ],
                            ['asc', 'asc', 'asc'],
                          )
                        : orderBy(newAssetList, ['date_created'], ['desc']),
                  pagination_information: {
                    ...q?.asset_list.pagination_information,
                    total_count: q?.asset_list.pagination_information.total_count + assetIds.length,
                  },
                },
              },
              query: assets.list,
              variables: {
                ...variables,
              },
            });
          });
      }
    },
  });
}
