// sort-imports-ignore
import { Raven } from '@dt/global';
import { takeEvery, put, call, take, delay, spawn } from 'redux-saga/effects';
import {
  paginationLoadMoreAction,
  actionIsForType,
  createSelectorForPaginationInfo,
  paginationRequestFailed,
} from '@dt/pagination';
import { select, callSaga } from '@dt/redux-saga-wrapped-effects';
import { setStatusesForKeyResourceFetch, setCacheStatus } from '../resource_fetch/actions';
import { getContract } from '../resource_fetch/util';
import { getCacheStatusFromKeySelector } from './selectors';
import { StatusesTypeEnum } from './types';
import { certificateChainsReceived } from '../certificate_chains/actions';
import { certificateReceived } from '../certificates/actions';
import { cloudResourcesReceived } from '../cloud_resources/actions';
import { webApplicationsReceived } from '../web_applications/actions';
import { apiOperationsReceived } from '../api_operations/actions';
import { graphqlApisReceived } from '../graphql_apis/actions';
import {
  awsAuthenticatorsReceived,
  gcpAuthenticatorsReceived,
  azureAuthenticatorsReceived,
  axwayAuthenticatorsReceived,
  mulesoftAuthenticatorsReceived,
  apigeeAuthenticatorsReceived,
  kongAuthenticatorsReceived,
} from '../configurations/actions';
import { domainNamesReceived } from '../domain_names/actions';
import { eventsReceived } from '../events/actions';
import { networkServicesReceived } from '../network_services/actions';
import { openAPIDefinitionsReceived } from '../openapi_definitions/actions';
import { policiesReceived } from '../policies/actions';
import { policyRuleTypesReceived } from '../policy_rule_types/actions';
import { policyRulesReceived } from '../policy_rules/actions';
import { policyViolationsReceived } from '../policy_violations/actions';
import { commentsReceived } from '../policy_violations/comments/actions';
import { restfulAPIsReceived } from '../restful_apis/actions';
import { tlsScanResultsReceived } from '../tls_scan_results/actions';
import { usersReceived } from '../users/actions';
import { ipRangesReceived } from '../ip_ranges/actions';
import { assetGroupsReceived } from '../asset_groups/actions';
import { assetGroupsMembershipReceived } from '../asset_groups/memberships/actions';
import { specialScanRequestReceived, piiReportsInApiResponsesReceived } from '../special_scans/actions';
import { sharedLinksReceived } from '../shared_links/actions';

function runResourceFetch(fetchCb) {
  return function* (action) {
    const key = getContract(action.payload);
    try {
      yield put(
        // TODO: Remove type-cast after this is fixed https://github.com/facebook/flow/pull/7298
        setStatusesForKeyResourceFetch(key, {
          status: StatusesTypeEnum.LOADING,
        }),
      );
      yield call(fetchCb, action);
      yield put(
        // TODO: Remove type-cast after this is fixed https://github.com/facebook/flow/pull/7298
        setStatusesForKeyResourceFetch(key, { status: StatusesTypeEnum.DONE }),
      );
    } catch (e) {
      let message = e.message;
      if (e.status) {
        message = e.statusText;
      }
      yield put(
        // TODO: Remove type-cast after this is fixed https://github.com/facebook/flow/pull/7298
        setStatusesForKeyResourceFetch(key, {
          error: message,
          status: StatusesTypeEnum.ERROR,
        }),
      );
      throw e;
    }
  };
}

export function* takePatternAndFetch(
  patternOrTrigger,

  cb,
) {
  yield takeEvery(action => {
    if (typeof patternOrTrigger !== 'string') {
      return patternOrTrigger(action);
    }
    if (!patternOrTrigger.includes('*')) return action.type === patternOrTrigger;
    // yuck?
    return new RegExp(`^${patternOrTrigger.split('*').join('.*')}$`).test(action.type);
  }, runResourceFetch(cb));
}

function serializeAnything(anything) {
  if (
    typeof anything === 'number' ||
    typeof anything === 'string' ||
    typeof anything === 'boolean' ||
    Array.isArray(anything)
  ) {
    return JSON.stringify(anything);
  } else {
    return JSON.stringify(anything, Object.keys(anything).sort());
  }
}

export function withCache(key, saga) {
  return function* (params, options) {
    // This saga relies on the redux store being up to date. It both sets and
    // gets its own cache state. This means there's a possibility that two
    // sagas kick off the same cached request. Due to the nature of redux-saga
    // scheduling, this means that the put effect of the first one, to indicate
    // that the request has started, will not take effect in redux until after
    // the second one checks the state. Adding a delay here puts this saga on a
    // different thread, hence guaranteeing that the state will always be up to
    // date by the time it runs. For more detail, here is the sequence without
    // the delay:
    // 1. Saga1 calls request saga withCache(request)
    // 2. withCache(request) sees that no request state exists and kicks it off
    // 3. withCache(request) puts an effect to indicate that the request is in
    //    progress (PUT TO EXECUTION QUEUE)
    // 4. Saga2 calls request saga withCache(request) also
    // 5. withCache(request) sees that no request state exists and kicks it off
    //    (the previous put has not yet gone through since its in queue)
    // 6. Same as step 3, PUT TO EXECUTION QUEUE again
    // EXECUTION QUEUE:
    // 7. withCache(request)'s put of the cache state goes through
    // EXECUTION QUEUE:
    // 8. withCache(request)'s duplicate put of the cache state goes though too
    //
    // With the delay, this happens instead:
    // 1. Saga1 calls request saga withCache(request)
    // 2. withCache(request) delays into queue (DELAY EXECUTION QUEUE)
    // 3. Saga2 calls request saga withCache(request) also
    // 4. withCache(request) delays into queue, again (DELAY EXECUTION QUEUE)
    // EXECUTION QUEUE:
    // 5. withCache(request) sees that no request state exists and kicks it off
    // 6. withCache(request) puts an effect to indicate that the request is in
    //    progress (PUT EXECUTION QUEUE)
    // EXECUTION QUEUE:
    // 7. withCache(request)'s put of the cache state goes through
    // EXECUTION QUEUE:
    // 8. withCache(request) sees that the state shows the request has started
    //    and does not kick anything off
    //
    // You can test whether this is working like so: have two components that
    // dispatch an action when they mount. Have one render the other. Take
    // these actions in two different sagas, each of which react by kicking off
    // the same withCache'ed request. If the delay is working, only one request
    // will be kicked off. If it's broken, then two requests will be made.
    yield delay(0);

    const ARBITRARY_CACHE_EXPIRATION_INTERVAL = 15 * 60 * 1000; // Mins * seconds * milliseconds
    /*
     * In order to force a data update
     * pass {forceUpdate: true} as an additional argument when you call the action
     * ex: yield call( myCoolAction, {forceUpdate: true, });
     */
    let forceUpdate = options ? options.forceUpdate : false;

    let cacheKey;
    if (typeof key === 'function') cacheKey = key(params);
    else cacheKey = `${key}-${serializeAnything(params) || ''}`;

    const cache = yield* select(getCacheStatusFromKeySelector, {
      key: cacheKey,
    });

    // If we are not forcing te update
    if (!forceUpdate) {
      // If this request is on flight, wait for it to complete
      if (cache.started && !cache.ended) {
        // @todo: continuously check for time running and proceed in case it takes too long
        yield take(action => {
          return (
            action.type === setCacheStatus.toString() &&
            !!action.payload &&
            action.payload.key === cacheKey &&
            (!!action.payload.done || (typeof action.payload.error === 'boolean' && action.payload.error))
          );
        });
        return;
      }
      // If there is already a cached request
      if (cache.ended) {
        const now = new Date();
        // Check how old it is
        if (now - cache.ended < ARBITRARY_CACHE_EXPIRATION_INTERVAL) {
          // It hasn't been that long - return as complete
          return;
        } else {
          // it has been a while. let's refresh cache
          forceUpdate = true;
        }
      }
    }
    // If it hasn't started or the previous was error, then try again
    try {
      yield put(setCacheStatus(cacheKey, { start: true }));
      yield call(saga, params);
      yield put(setCacheStatus(cacheKey, { done: true }));
    } catch (e) {
      yield put(setCacheStatus(cacheKey, { error: true }));
      // Next saga should handle it
      throw e;
    }
  };
}

export function* paginateToEnd(request, resourceType, params) {
  // prime the cursor
  yield call(request, params);
  let next_cursor;
  do {
    const pagination_information = yield* select(createSelectorForPaginationInfo(resourceType, { ...params }));
    next_cursor = pagination_information.next_cursor;
    if (next_cursor) {
      yield call(request, { ...params, cursor: next_cursor });
    }
  } while (next_cursor);
}

export function* paginateWhile(request, resourceType, params, check) {
  // prime the cursor
  yield call(request, params);
  let next_cursor,
    checkResult = true;
  do {
    const pagination_information = yield* select(createSelectorForPaginationInfo(resourceType, {}));
    next_cursor = pagination_information.next_cursor;
    if (next_cursor && checkResult) {
      yield call(request, { ...params, cursor: next_cursor });
      checkResult = yield* callSaga(check);
    }
  } while (next_cursor && checkResult);
}

export function* paginateToEndLazy(request, resourceType, params, actionToTake) {
  try {
    yield call(request, params);
    yield spawn(function* () {
      try {
        let next_cursor;
        do {
          yield take(actionToTake);
          const pagination_information = yield* select(createSelectorForPaginationInfo(resourceType, { ...params }));
          next_cursor = pagination_information.next_cursor;
          if (next_cursor) {
            yield call(request, { ...params, cursor: next_cursor });
          }
        } while (next_cursor);
      } catch (error) {
        yield put(paginationRequestFailed(resourceType, { ...params }));
      }
    });
  } catch (error) {
    yield put(paginationRequestFailed(resourceType, { ...params }));
  }
}

export function* watchForLoadMoreAndFetchNextPage(resourceType, saga) {
  yield call(
    takePatternAndFetch,
    action => action.type === paginationLoadMoreAction.toString() && actionIsForType(action, resourceType),
    function* (action) {
      const { next_cursor, loadedInitial, loading } = yield* select(
        createSelectorForPaginationInfo(resourceType, action.payload),
      );

      if (loadedInitial && !next_cursor) {
        // no more pages!
        return;
      } else if (loading) {
        // wait until the current request finishes before calling again
        console.warn('paginationLoadMoreAction action was called while a call was already loading');
        return;
      } else if (!next_cursor) {
        console.warn('paginationLoadMoreAction action was called before the initial call for data was made.');
      }

      yield call(
        saga,
        next_cursor
          ? {
              ...action.payload,
              cursor: next_cursor,
            }
          : { ...action.payload },
      );
    },
  );
}

export function* handleNormalizedResponse(response) {
  if (!response) {
    throw new Error('response error');
  }

  if (response.certificate_chains) {
    yield put(certificateChainsReceived(response.certificate_chains));
  }

  if (response.certificates) {
    yield put(certificateReceived(response.certificates));
  }

  if (response.cloud_resources) {
    yield put(cloudResourcesReceived(response.cloud_resources));
  }

  if (response.events) {
    yield put(eventsReceived(response.events));
  }

  if (response.aws_authenticators) {
    yield put(awsAuthenticatorsReceived(response.aws_authenticators));
  }

  if (response.gcp_authenticators) {
    yield put(gcpAuthenticatorsReceived(response.gcp_authenticators));
  }

  if (response.azure_authenticators) {
    yield put(azureAuthenticatorsReceived(response.azure_authenticators));
  }

  if (response.axway_authenticators) {
    yield put(axwayAuthenticatorsReceived(response.axway_authenticators));
  }

  if (response.mulesoft_authenticators) {
    yield put(mulesoftAuthenticatorsReceived(response.mulesoft_authenticators));
  }

  if (response.apigee_authenticators) {
    yield put(apigeeAuthenticatorsReceived(response.apigee_authenticators));
  }

  if (response.network_services) {
    yield put(
      networkServicesReceived(
        response.network_services.map(service => {
          if (service.hosted_on) return service;
          Raven.captureException(new Error(`Network service ${service.id} is missing hosted_on data`), {});
          return { ...service, hosted_on: 'UNKNOWN' };
        }),
      ),
    );
  }

  if (response.openapi_definitions) {
    yield put(openAPIDefinitionsReceived(response.openapi_definitions));
  }

  if (response.restful_apis && response.network_services) {
    yield put(restfulAPIsReceived(response.restful_apis, response.network_services));
  }

  if (response.web_applications) {
    yield put(webApplicationsReceived(response.web_applications));
  }

  if (response.graphql_apis) {
    yield put(graphqlApisReceived(response.graphql_apis));
  }

  if (response.policies) {
    yield put(policiesReceived(response.policies));
  }

  if (response.policy_violations) {
    yield put(policyViolationsReceived(response.policy_violations));
  }

  if (response.policy_rules) {
    yield put(policyRulesReceived(response.policy_rules));
  }
  if (response.policy_rule_types) {
    yield put(policyRuleTypesReceived(response.policy_rule_types));
  }

  if (response.comments) {
    yield put(commentsReceived(response.comments));
  }

  if (response.domain_names) {
    yield put(domainNamesReceived(response.domain_names));
  }

  if (response.tls_scan_results) {
    yield put(tlsScanResultsReceived(response.tls_scan_results));
  }

  if (response.users) {
    yield put(usersReceived(response.users));
  }

  if (response.asset_groups) {
    yield put(assetGroupsReceived(response.asset_groups));
  }

  if (response.asset_group_memberships) {
    yield put(assetGroupsMembershipReceived(response.asset_group_memberships));
  }

  if (response.special_scan_requests) {
    yield put(specialScanRequestReceived(response.special_scan_requests));
  }

  if (response.api_operations) {
    yield put(apiOperationsReceived(response.api_operations));
  }

  if (response.kong_authenticators) {
    yield put(kongAuthenticatorsReceived(response.kong_authenticators));
  }

  if (response.shared_links) {
    yield put(sharedLinksReceived(response.shared_links));
  }
  if (response.pii_reports_in_api_responses) {
    yield put(piiReportsInApiResponsesReceived(response.pii_reports_in_api_responses));
  }

  if (response.ip_ranges) {
    yield put(ipRangesReceived(response.ip_ranges));
  }
}
