import config from '@dt/config';
import MobileAppCategoryEnum from '@dt/enums/MobileAppCategoryEnum';
import MobileAppPlatformEnum from '@dt/enums/MobileAppPlatformEnum';
import fetch, { parse } from '@dt/fetch';
import { encodeURIFormComponent } from '@dt/string';
import qs from 'query-string';
import { array, boolean, number, object, string } from 'yup';
import { byProperty, noResult, result } from './util';

// TODO@nw: These props should be readonly.

const OpenscanMobileAppSchema = object().shape({
  app_description: string(),
  app_price_in_cents: string(),
  app_protection_score: string(),
  app_protection_score_ratio: number(),
  bundle_id: string().required(),
  category: string().required().oneOf(Object.keys(MobileAppCategoryEnum)),
  date_created: string().required(),
  date_updated: string().required(),
  favorite: boolean(),
  has_in_app_purchases: boolean(),
  has_results: boolean(),
  id: string().required(),
  inappropriate_for_the_enterprise: boolean(),
  initial_release_date: string(),
  insights: object()
    .required()
    .shape({
      android_minimum_sdk_version: string(),
      android_permissions: array().of(string()),
      android_target_sdk_version: string(),
      communicates_with_countries: array().of(string()),
      communicates_with_hostnames: array().of(string()),
      communicates_with_ip_addresses: array().of(string()),
      data_at_rest_exposure: boolean(),
      ios_app_extensions: array().of(string()),
      ios_base_sdk: string(),
      ios_minimum_sdk: string(),
      ios_permissions: array().of(string()),
      ios_supports_apple_watch: boolean(),
      ios_supports_face_id: boolean(),
      ios_supports_imessage: boolean(),
      sdk_count: string().required(),
    }),
  last_scanned_date: string(),
  last_scanned_version: string(),
  name: string().required(),
  platform: string().required().oneOf(Object.keys(MobileAppPlatformEnum)),
  publisher_name: string(),
  publisher_website: string(),
  store_url: string(),
  watchlist_groups: array().of(string()).ensure(),
});

async function validate(result) {
  return OpenscanMobileAppSchema.validate(result);
}

async function validateList(result) {
  return object()
    .shape({
      openscan_mobile_apps: array().of(OpenscanMobileAppSchema),
    })
    .validate(result);
}

export const favorite_apps = {
  async add(mobile_app_id, watchlist_group) {
    if (!mobile_app_id) {
      throw new Error('mobile_app_id required by openscan.favorite_apps.add');
    }

    if (!watchlist_group) {
      throw new Error('watchlist_group required by openscan.favorite_apps.add');
    }

    return fetch(`${config.sevenhellApiBaseUrl}/v2/openscan/favorite_apps/${watchlist_group}/${mobile_app_id}`, {
      method: 'PUT',
    })
      .then(parse)
      .then(result)
      .then(validate);
  },

  async list(params) {
    return fetch(
      `${config.sevenhellApiBaseUrl}/v2/openscan/favorite_apps${
        params && qs.stringify(params) ? `?${qs.stringify(params)}` : ''
      }`,
    )
      .then(parse)
      .then(result)
      .then(validateList);
  },

  async remove(mobile_app_id, watchlist_group) {
    if (!mobile_app_id || !mobile_app_id.length) {
      throw new Error('mobile_app_id required by openscan.favorite_apps.remove');
    }

    if (watchlist_group && !watchlist_group.trim()) {
      throw new Error('watchlist_group cannot be empty for openscan.favorite_apps.remove');
    }

    const removeUrl = watchlist_group ? `${watchlist_group}/${mobile_app_id}` : `${mobile_app_id}`;

    return fetch(`${config.sevenhellApiBaseUrl}/v2/openscan/favorite_apps/${removeUrl}`, {
      method: 'DELETE',
    })
      .then(parse)
      .then(result)
      .then(validate);
  },
};

const SearchResultSchema = object().shape({
  app_protection_score: string(),
  app_protection_score_ratio: number(),
  has_results: boolean(),
  icon_url: string(),
  id: string().required(),
  name: string().required(),
  platform: string().required().oneOf(Object.keys(MobileAppPlatformEnum)),
  store_url: string(),
});

export const mobile_apps = {
  async get(id) {
    if (!id || !id.length) {
      throw new Error('id required by openscan.mobile_apps.get');
    }

    return fetch(`${config.sevenhellApiBaseUrl}/v2/openscan/mobile_apps/${id}`).then(parse).then(result).then(validate);
  },

  async search(params) {
    // The query is formatted like a form input, so it needs '+' where a space should be.
    // See: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/encodeURIComponent
    return fetch(
      `${config.sevenhellApiBaseUrl}/v2/openscan/mobile_apps/search?query=${encodeURIFormComponent(params.query)}`,
    )
      .then(parse)
      .then(result)
      .then(byProperty('openscan_mobile_app_search_results'))
      .then(response => array().of(SearchResultSchema).validate(response));
  },
};

const OpenScanAlertsFilterConfigSchema = object().shape({
  communicates_with_countries: array().of(string()),
  data_at_rest_exposure: boolean(),

  date_created: string(),
  date_updated: string(),
  has_android_permissions: array().of(string()),
  has_ios_permissions: array().of(string()),
  lacks_all_communication_encrypted: boolean(),
  lacks_does_not_offload_data_to_third_parties: boolean(),
  lacks_enhances_password_security: boolean(),
  lacks_full_protection_on_untrusted_networks: boolean(),
  lacks_leverages_secure_browser_settings: boolean(),
  lacks_leverages_secure_system_libraries: boolean(),

  lacks_leverages_secure_system_sdk: boolean(),

  lacks_leverages_secure_system_settings: boolean(),
  lacks_protects_data_on_screens: boolean(),
  lacks_protects_the_keyboard: boolean(),
  lacks_removes_data_from_shared_device_locations: boolean(),
  lacks_requires_android_device_security: boolean(),
  lacks_requires_android_malware_protection: boolean(),
  lacks_server_enforces_https: boolean(),
  lacks_supports_two_factor_authentication: boolean(),

  minimum_android_sdk_version: string().required(),
  minimum_app_protection_score: string(),
  minimum_ios_sdk_version: string().required(),
  supports_apple_watch: boolean(),
  supports_face_id: boolean(),
  supports_imessage: boolean(),
  threshold_apple_app_store_blocker_count: string(),

  threshold_binary_size: string(),
  threshold_days_since_last_release: string(),
  threshold_google_play_blocker_count: string(),
  threshold_high_severity_issues_count: string(),
  threshold_low_severity_issue_count: string(),
  threshold_medium_severity_issues_count: string(),
  threshold_sdk_count: string(),
  threshold_sdk_with_issues_count: string(),

  threshold_security_p1_count: string(),

  // TODO@nw: Needs backend work first.
  // configurations_touched_by_user: object().shape({
  //   threshold_security_p1_count: boolean().required(),
  //   threshold_apple_app_store_blocker_count: boolean().required(),
  //   threshold_google_play_blocker_count: boolean().required(),
  //   threshold_sdk_count: boolean().required(),
  //   threshold_sdk_with_issues_count: boolean().required(),
  //   threshold_low_severity_issue_count: boolean().required(),
  //   threshold_medium_severity_issues_count: boolean().required(),
  //   threshold_high_severity_issues_count: boolean().required(),
  //   threshold_days_since_last_release: boolean().required(),
  //   threshold_binary_size: boolean().required(),

  //   data_at_rest_exposure: boolean().required(),

  //   supports_apple_watch: boolean().required(),
  //   supports_imessage: boolean().required(),
  //   supports_face_id: boolean().required(),
  //   has_android_permissions: boolean().required(),
  //   has_ios_permissions: boolean().required(),
  //   minimum_ios_sdk_version: boolean().required(),
  //   minimum_android_sdk_version: string().required(),
  //   communicates_with_countries: boolean().required(),

  //   lacks_all_communication_encrypted: boolean().required(),
  //   lacks_supports_two_factor_authentication: boolean().required(),
  //   lacks_full_protection_on_untrusted_networks: boolean().required(),
  //   lacks_protects_data_on_screens: boolean().required(),
  //   lacks_requires_android_device_security: boolean().required(),
  //   lacks_requires_android_malware_protection: boolean().required(),
  //   lacks_server_enforces_https: boolean().required(),

  //   lacks_does_not_offload_data_to_third_parties: boolean().required(),
  //   lacks_enhances_password_security: boolean().required(),
  //   lacks_leverages_secure_browser_settings: boolean().required(),
  //   lacks_leverages_secure_system_libraries: boolean().required(),
  //   lacks_leverages_secure_system_sdk: boolean().required(),
  //   lacks_leverages_secure_system_settings: boolean().required(),
  //   lacks_protects_the_keyboard: boolean().required(),
  //   lacks_removes_data_from_shared_device_locations: boolean().required(),

  //   minimum_app_protection_score: boolean().required(),
  // })
});

async function validateAlertsFilterConfig(result) {
  return OpenScanAlertsFilterConfigSchema.validate(result);
}

export const alerts_filter_config = {
  async get() {
    return fetch(`${config.sevenhellApiBaseUrl}/v2/openscan/alerts_filter_config`)
      .then(parse)
      .then(result)
      .then(validateAlertsFilterConfig);
  },

  async replace(body) {
    if (!body) {
      throw new Error('body is required for openscan.alerts_filter_config.replace');
    }

    return fetch(`${config.sevenhellApiBaseUrl}/v2/openscan/alerts_filter_config`, {
      body: JSON.stringify(body),
      method: 'PUT',
    })
      .then(parse)
      .then(result)
      .then(validateAlertsFilterConfig);
  },
};

const ExternalSearchResultSchema = object().shape({
  name: string().required(),
  platform: string(),
  store_url: string().required(),
});

export const external_app_search = {
  async get(params) {
    // The query is formatted like a form input, so it needs '+' where a space should be.
    // See: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/encodeURIComponent
    return fetch(
      `${config.sevenhellApiBaseUrl}/v2/openscan/external_app_search?query=${encodeURIFormComponent(params.query)}`,
    )
      .then(parse)
      .then(result)
      .then(byProperty('apps'))
      .then(response => array().of(ExternalSearchResultSchema).validate(response));
  },
};

export const scan_requests = {
  async create(params) {
    return fetch(
      `${config.sevenhellApiBaseUrl}/v2/openscan/scan_requests${
        params && qs.stringify(params) ? `?${qs.stringify(params)}` : ''
      }`,
      {
        method: 'POST',
      },
    )
      .then(parse)
      .then(noResult);
  },
};

export const contact_publisher_requests = {
  async create(params) {
    return fetch(
      `${config.sevenhellApiBaseUrl}/v2/openscan/contact_publisher_requests${
        params && qs.stringify(params) ? `?${qs.stringify(params)}` : ''
      }`,
      {
        method: 'POST',
      },
    )
      .then(parse)
      .then(noResult);
  },
};
