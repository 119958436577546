import React, { PureComponent } from 'react';
import Button from '@mui/material/Button';

export default class PreviewButton extends PureComponent {
  render() {
    return (
      <Button aria-label="Preview" color="primary" onClick={this.props.onPreviewClick} variant="outlined">
        Preview
      </Button>
    );
  }
}
