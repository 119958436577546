import React, { memo } from 'react';
import { Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { palette } from '@dt/theme';
import MobileNavLink from './MobileNavLink';
import NavLink from './NavLink';
import NavWithoutLink from './NavWithoutLink';

const menuOuterContent = {
  alignItems: 'center',
  borderRadius: 4,
  color: palette.white,
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'flex-start',
  marginBottom: 8,
  marginLeft: 16,
  marginRight: 16,
  paddingBottom: 0,
  paddingLeft: 0,
  paddingRight: 0,
  paddingTop: 0,
};

const useStyles = makeStyles({
  menuIconBox: {
    alignItems: 'center',
    display: 'flex',
    height: 40,
    justifyContent: 'center',
    marginRight: '16px',
    maxHeight: 40,
    maxWidth: 40,
    minHeight: 40,
    minWidth: 40,
    width: 40,
  },

  menuInner: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-start',
  },

  menuOuter: {
    ...menuOuterContent,
    '&.active': {
      backgroundColor: palette.brand35,
    },

    '&.bottom': {
      marginTop: 'auto',
    },

    '&:hover': {
      backgroundColor: palette.brand10,
    },
  },

  menuOuterNoLink: {
    ...menuOuterContent,
    '&.bottom': {
      marginTop: 'auto',
    },
  },

  menuText: {
    '&.title': {
      color: palette.white,
      fontSize: 20,
    },
    alignItems: 'center',

    fontSize: 15,

    height: '100%',

    // display: 'flex', // Ellipsis doesn't work if set
    maxWidth: 165,

    overflow: 'hidden',

    textOverflow: 'ellipsis',

    // Prevent cutting off 'Hacker Toolkits (Attack)'
    whiteSpace: 'nowrap',
  },
});

function NavItem({
  to,
  exact = false,
  redirect = false,
  drawerOpen = false,
  target = '_blank',
  text,
  children,
  isUsingMobileRouting = false,
}) {
  const styles = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    if (!drawerOpen) {
      setOpen(true);
    }
  };

  return to ? (
    isUsingMobileRouting ? (
      <MobileNavLink
        activeStyle={{ backgroundColor: palette.brand35 }}
        className={styles.menuOuter}
        exact={exact}
        redirect={redirect}
        target={target}
        to={to}
      >
        {/* Controlled tooltip only renders when drawer is closed */}
        <Tooltip onClose={handleClose} onOpen={handleOpen} open={open} placement={'top'} title={text}>
          <div className={styles.menuInner}>
            <div className={styles.menuIconBox}>{children}</div>
            <div className={styles.menuText}>{text}</div>
          </div>
        </Tooltip>
      </MobileNavLink>
    ) : (
      <NavLink
        activeStyle={{ backgroundColor: palette.brand35 }}
        className={styles.menuOuter}
        exact={exact}
        redirect={redirect}
        target={target}
        text={text}
        to={to}
      >
        {/* Controlled tooltip only renders when drawer is closed */}
        <Tooltip onClose={handleClose} onOpen={handleOpen} open={open} placement={'top'} title={text}>
          <div className={styles.menuInner}>
            <div className={styles.menuIconBox}>{children}</div>
            <div className={styles.menuText}>{text}</div>
          </div>
        </Tooltip>
      </NavLink>
    )
  ) : (
    <NavWithoutLink
      activeStyle={{ backgroundColor: palette.brand35, cursor: 'none' }}
      className={styles.menuOuterNoLink}
    >
      {/* Controlled tooltip only renders when drawer is closed */}
      <Tooltip onClose={handleClose} onOpen={handleOpen} open={open} placement={'top'} title={text}>
        <div className={styles.menuInner}>
          <div className={styles.menuIconBox}>{children}</div>
          <div className={styles.menuText}>{text}</div>
        </div>
      </Tooltip>
    </NavWithoutLink>
  );
}

export default memo(NavItem);
