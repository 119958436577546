import createKeyable from '@dt/keyable';
import {
  addCommentClicked,
  addCommentRequestFailed,
  addCommentRequestSucceeded,
  apiKeyCreateFormSubmitted,
  apiKeyCreateResponseReceived,
  apiKeyDeleteButtonClicked,
  apiKeyDeleteResponseReceived,
  apiKeyEditFormSubmitted,
  apiKeyEditResponseReceived,
  apiKeyErrorReceived,
  updateFindingError,
  updateFindingStarted,
  updateFindingSuccess,
} from '../actions';
import { updateAppLinkingFailure, updateAppLinkingStarted, updateAppLinkingSuccess } from '../actions/mobileApps';
import {
  linkedIssuesRoutine,
  securityFindingsFetchAllFinish,
  securityFindingsFetchAllStart,
  updateStatusFailure,
  updateStatusStarted,
  updateStatusSuccess,
} from '../actions/securityFindings';

const targetStatusReducerCreator =
  isLoading =>
  (state, { payload }) => {
    const { findingId, targetId } = payload;
    const arrayState = state || [];
    const currentIndex = arrayState.findIndex(({ targetId: id }) => id === targetId);
    if (isLoading) {
      return currentIndex !== -1 ? arrayState : [...arrayState, { findingId, targetId }];
    } else {
      return currentIndex !== -1 ? arrayState.filter((_, i) => i !== currentIndex) : arrayState;
    }
  };

export default createKeyable({
  keys: {
    addComment: {
      [addCommentClicked.toString()]: true,
      [addCommentRequestSucceeded.toString()]: false,
      [addCommentRequestFailed.toString()]: false,
    },
    allSecurityFindings: {
      [securityFindingsFetchAllStart.toString()]: true,
      [securityFindingsFetchAllFinish.toString()]: false,
    },
    apiKeyCreate: {
      [apiKeyCreateFormSubmitted.toString()]: true,
      [apiKeyErrorReceived.toString()]: false,
      [apiKeyCreateResponseReceived.toString()]: false,
    },
    apiKeyDelete: {
      [apiKeyDeleteButtonClicked.toString()]: true,
      [apiKeyErrorReceived.toString()]: false,
      [apiKeyDeleteResponseReceived.toString()]: false,
    },
    apiKeyEdit: {
      [apiKeyEditFormSubmitted.toString()]: true,
      [apiKeyErrorReceived.toString()]: false,
      [apiKeyEditResponseReceived.toString()]: false,
    },
    findingPatch: {
      [updateFindingStarted.toString()]: true,
      [updateFindingSuccess.toString()]: false,
      [updateFindingError.toString()]: false,
    },
    linkedIssues: {
      [linkedIssuesRoutine.REQUEST.toString()]: true,
      [linkedIssuesRoutine.FULFILL.toString()]: false,
    },
    mobileAppPatch: {
      [updateAppLinkingSuccess.toString()]: false,
      [updateAppLinkingStarted.toString()]: true,
      [updateAppLinkingFailure.toString()]: false,
    },
    targetStatus: {
      [updateStatusStarted.toString()]: targetStatusReducerCreator(true),
      [updateStatusSuccess.toString()]: targetStatusReducerCreator(false),
      [updateStatusFailure.toString()]: targetStatusReducerCreator(false),
    },
  },
});
