import createKeyable from '@dt/keyable';
import {
  addAppToOpenscanWatchListGroup,
  openscanConfigReceived,
  openscanErrorOccurred,
  openscanMobileAppsRecieved,
  openscanRuleUpdated,
  openscanWatchlistLoading,
  receivedApp,
  receivedSearchResults,
  removeAppFromOpenscanWatchList,
  removeAppFromOpenscanWatchListGroup,
  requestApp,
  requestSearchResults,
  updateApp,
} from '../actions';

export default createKeyable({
  keys: {
    application: {
      [requestApp.toString()]: true,
      [receivedApp.toString()]: false,
    },
    openscanConfig: {
      [openscanRuleUpdated.toString()]: true,
      [openscanConfigReceived.toString()]: false,
      [openscanErrorOccurred.toString()]: false,
    },
    openscanSearchResults: {
      [requestSearchResults.toString()]: true,
      [receivedSearchResults.toString()]: false,
      [openscanErrorOccurred.toString()]: false,
    },
    openscanWatchlist: {
      [removeAppFromOpenscanWatchList.toString()]: true,
      [removeAppFromOpenscanWatchListGroup.toString()]: true,
      [addAppToOpenscanWatchListGroup.toString()]: true,
      [openscanMobileAppsRecieved.toString()]: false,
      [openscanWatchlistLoading.toString()]: true,
      [openscanErrorOccurred.toString()]: false,
      [updateApp.toString()]: false,
    },
  },
});
