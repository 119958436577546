import React, { createContext, useState } from 'react';
import { Grid } from '@mui/material';
// @ts-ignore: #TODO-IMPLEMENT-TYPES-COMPONENTS
import { CenteredCircularProgress } from '@dt/material-components';
// @ts-ignore Ignore JS Files
import { UnauthorizedError } from '../../../components/errors';
// @ts-ignore Ignore JS Files
import { useAuthorization, useSession } from '../../../session';
import ApiShadowAssetsPageChartContainer from './ApiShadowAssetsPageChartContainer';
import ApiShadowAssetsPageTable from './ApiShadowAssetsPageTable';
export const ShadowAssetsContext = createContext({
    bulkId: '',
    filter_by_named_filter: '',
    isPolling: false,
    latestRefetchDate: new Date(),
    setBulkId: (a) => { }, // eslint-disable-line
    setLatestRefetchDate: (a) => { }, // eslint-disable-line
    setIsPolling: (a) => { }, // eslint-disable-line
});
const ApiShadowAssetsPage = ({ filter_by_named_filter }) => {
    const [bulkId, setBulkId] = useState('');
    const [isPolling, setIsPolling] = useState(false);
    const [latestRefetchDate, setLatestRefetchDate] = useState(new Date());
    const { loading: sessionLoading, data: sessionData } = useSession();
    const { isAuthorized } = useAuthorization(sessionData, ['can_access_api_inspect']);
    // Loading State.
    if (sessionLoading) {
        return React.createElement(CenteredCircularProgress, null);
    }
    // Unauthorized State.
    if (!isAuthorized) {
        return React.createElement(UnauthorizedError, null);
    }
    return (React.createElement(ShadowAssetsContext.Provider, { value: {
            bulkId,
            filter_by_named_filter,
            isPolling,
            latestRefetchDate,
            setBulkId,
            setIsPolling,
            setLatestRefetchDate,
        } },
        React.createElement(Grid, { container: true, spacing: 2 },
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(ApiShadowAssetsPageChartContainer, null)),
            React.createElement(Grid, { item: true, xs: 12 },
                React.createElement(ApiShadowAssetsPageTable, { filter_by_named_filter: filter_by_named_filter })))));
};
export default ApiShadowAssetsPage;
