import React from 'react';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import { Avatar, Divider, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import { AssetTypeDict } from '@dt/material-components';
import { palette } from '@dt/theme';
import { AssetsTypeIcon } from '../../components/assets/AssetsTypeIcon';
import { AssetsLink } from './AssetsLink';

const useStyles = makeStyles({
  avatar: { backgroundColor: palette.gray45, color: palette.gray20 },
});

/*
 * Shows the user the relationship between multiple assets.
 * An asset's connected assets.
 *
 * @param assets - List of connected assets.
 * @param productBasePath - Used to maintain navigational context for the user.
 */
const AssetsConnectedAssetsComponent = function AssetsConnectedAssets({ assets, productBasePath }) {
  const classes = useStyles();

  if (assets.length > 0) {
    return (
      <>
        {assets.map((a, i) => {
          const location = (a.asset_type === AssetTypeDict.CLOUD_RESOURCE ? a.name : a.url) || '';

          return (
            <Box key={a.id}>
              <Box alignItems="center" display="flex" key={a.id} p="4px 12px">
                <AssetsTypeIcon
                  asset_type_icon_url={a.asset_type_icon_url}
                  asset_type_name={a.asset_type_name}
                  key={a.id}
                />
                <Box p="12px">
                  <Typography>{`${a.asset_type_name} - ${location}`}</Typography>
                </Box>
                <Box color={palette.gray20} display="flex" ml="auto" p="12px">
                  <AssetsLink asset_id={a.id} asset_type={a.asset_type} productBasePath={productBasePath}>
                    <OpenInNewIcon color="inherit" />
                  </AssetsLink>
                </Box>
              </Box>
              {i < assets.length - 1 && <Divider />}
            </Box>
          );
        })}
      </>
    );
  }

  return (
    <Box alignItems="center" display="flex" flexDirection="row">
      <Box mr="12px">
        <Avatar className={classes.avatar}>
          <TrackChangesIcon color="inherit" />
        </Avatar>
      </Box>
      <Typography color="textPrimary" variant="body1">
        This asset doesn&apos;t seem to be connected to any other assets
      </Typography>
    </Box>
  );
};

export const AssetsConnectedAssets = AssetsConnectedAssetsComponent;
