import gql from 'graphql-tag';
export const security_findings_search_filter_values = gql `
  query SecurityFindingsSearchFilter {
    security_findings_search_filter_values {
      security_findings_search_filter_values {
        belongs_to_filter
        icon_url
        name
        value
        value_category
      }
    }
  }
`;
