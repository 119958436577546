// @ts-nocheck
import { lazy } from 'react';
import routeUtils from './routesUtils';
const { chunk } = routeUtils;
// prettier-ignore
const SettingsV2Components = {
    AcheronSharedLinks: lazy(() => chunk(import('./pages/management/shared_links/ManagementSharedLinks'))),
    ErrorPage: lazy(() => chunk(import('./pages/management/share/ShareLinksErrorPage'))),
    ManagementAlerts: lazy(() => chunk(import('./pages/management/alerts/ManagementAlertsPage'))),
    SettingsActivity: lazy(() => chunk(import('./pages/settings/v2/SettingsActivity'))),
    SettingsCompliance: lazy(() => chunk(import('./pages/settings/v2/SettingsComplianceReports'))),
    SettingsManageUsers: lazy(() => chunk(import('./pages/settings/v2/SettingsManageUsers'))),
    SettingsManageUsersEdit: lazy(() => chunk(import('./pages/settings/v2/SettingsManageUsersEdit'))),
    SettingsProfile: lazy(() => chunk(import('./pages/settings/v2/SettingsProfile'))),
};
// prettier-ignore
const ApiV2Components = {
    ApiInventoryV2Page: lazy(() => chunk(import('./pages/api/v2/ApiInventoryV2Page'))),
    ApiPoliciesV2Page: lazy(() => chunk(import('./pages/api/v2/ApiPoliciesV2Page'))),
    ApiPoliciesV2UuidPage: lazy(() => chunk(import('./pages/api/v2/ApiPoliciesV2UuidPage'))),
    ApiProtectSecurityV2Page: lazy(() => chunk(import('./pages/api/v2/ApiProtectSecurityPage'))),
    ApiProtectV2AssetGroupsUuidPage: lazy(() => chunk(import('./pages/api/v2/ApiProtectV2AssetGroupsUuidPage'))),
    ApiProtectV2Dashboard: lazy(() => chunk(import('./pages/api/v2/ApiProtectDashboard'))),
    ApiProtectV2Page: lazy(() => chunk(import('./pages/api/v2/ApiProtectPage'))),
    ApiV2AssetsUuidPage: lazy(() => chunk(import('./pages/api/v2/ApiV2AssetsUuidPage'))),
    ApiV2GraphqlApisUuidPage: lazy(() => chunk(import('./pages/api/v2/ApiV2GraphqlApisUuidPage'))),
    ApiV2NetworkServicesUuidPage: lazy(() => chunk(import('./pages/api/v2/ApiV2NetworkServicesUuidPage'))),
    ApiV2OperationDetailsContainer: lazy(() => chunk(import('./pages/api/v2/ApiV2OperationDetailsContainer'))),
    ApiV2PolicyViolationsPage: lazy(() => chunk(import('./pages/api/v2/ApiV2PolicyViolationsPage'))),
    ApiV2PolicyViolationsUuidPage: lazy(() => chunk(import('./pages/api/v2/ApiV2PolicyViolationsUuidPage'))),
    ApiV2ProtectUuidPage: lazy(() => chunk(import('./pages/api/v2/ApiV2ProtectUuidPage'))),
    ApiV2WebApplicationsUuidPage: lazy(() => chunk(import('./pages/api/v2/ApiV2WebApplicationsUuidPage'))),
};
// prettier-ignore
const MobileV2Components = {
    MobileProtectV2ActiveProtectionPage: lazy(() => chunk(import('./pages/mobile/v2/MobileProtectActiveProtectionPage'))),
    MobileProtectV2Reports: lazy(() => chunk(import('./pages/mobile/v2/MobileProtectReports'))),
    MobileSecureV2Dashboard: lazy(() => chunk(import('./pages/mobile/v2/MobileSecureDashboard'))),
    MobileSecureV2InventoryPage: lazy(() => chunk(import('./pages/mobile/v2/MobileSecureInventoryPage'))),
    MobileSecureV2SecurityPage: lazy(() => chunk(import('./pages/mobile/v2/MobileSecureSecurityPage'))),
    MobileSecureV2SubscriptionStatusPage: lazy(() => chunk(import('./pages/mobile/v2/MobileSecureV2SubscriptionStatusPage'))),
    MobileV2PolicyViolationsUuidPage: lazy(() => chunk(import('./pages/mobile/v2/MobileV2PolicyViolationsUuidPage'))),
};
// prettier-ignore
const WebV2Components = {
    WebProtectV2ActiveProtectionPage: lazy(() => chunk(import('./pages/web/v2/WebProtectActiveProtectionPage'))),
    WebSecureV2AssetGroupsPage: lazy(() => chunk(import('./pages/web/v2/WebSecureInventoryAssetGroupUuidPage'))),
    WebSecureV2AssetsUuidPage: lazy(() => chunk(import('./pages/web/v2/WebV2AssetsUuidPage'))),
    WebSecureV2DashboardPage: lazy(() => chunk(import('./pages/web/v2/WebSecureDashboardPage'))),
    WebSecureV2InventoryPage: lazy(() => chunk(import('./pages/web/v2/WebSecureInventoryPage'))),
    WebSecureV2SecurityPage: lazy(() => chunk(import('./pages/web/v2/WebSecureSecurityPage')))
};
// prettier-ignore
const cloudV2Components = {
    CloudSecureV2AssetsUuidPage: lazy(() => chunk(import('./pages/cloud/v2/CloudSecureAssetsUuidPage'))),
    cloudSecureV2ActiveProtectionPage: lazy(() => chunk(import('./pages/cloud/v2/CloudSecureActiveProtectionPage'))),
    cloudSecureV2AssetGroupsPage: lazy(() => chunk(import('./pages/cloud/v2/CloudSecureInventoryAssetGroupUuidPage'))),
    cloudSecureV2DashboardPage: lazy(() => chunk(import('./pages/cloud/v2/CloudSecureDashboardPage'))),
    cloudSecureV2InventoryPage: lazy(() => chunk(import('./pages/cloud/v2/CloudSecureInventoryPage'))),
    cloudSecureV2SecurityPage: lazy(() => chunk(import('./pages/cloud/v2/CloudSecureSecurityPage'))),
};
// prettier-ignore
const OpenScanV2Components = {
    OpenScanV2AppPage: lazy(() => chunk(import('./pages/supply_chain/v2/open_scan/OpenScanAppPage'))),
    OpenScanV2AppPublicPage: lazy(() => chunk(import('./pages/supply_chain/v2/open_scan/OpenScanAppPublicViewPage'))),
    OpenScanV2DashboardPage: lazy(() => chunk(import('./pages/supply_chain/v2/open_scan/OpenScanDashboardPage'))),
    OpenScanV2SearchPage: lazy(() => chunk(import('./pages/supply_chain/v2/open_scan/OpenScanSearchPage'))),
};
// prettier-ignore
const DevSecOpsV2Components = {
    AwsSecurityHubPage: lazy(() => chunk(import('./pages/devsecops/v2/AwsSecurityHubPage'))),
    DevSecOpsApiKeysCreatePage: lazy(() => chunk(import('./pages/devsecops/v2/DevSecOpsApiKeysCreatePage'))),
    DevSecOpsApiKeysEditPage: lazy(() => chunk(import('./pages/devsecops/v2/DevSecOpsApiKeysEditPage'))),
    DevSecOpsDatadogConfigurePage: lazy(() => chunk(import('./pages/devsecops/v2/DevSecOpsDatadogConfigurePage'))),
    DevSecOpsDatadogLandingPage: lazy(() => chunk(import('./pages/devsecops/v2/DevSecOpsDatadogLandingPage'))),
    DevSecOpsGithubOnPremConfiguration: lazy(() => chunk(import('./pages/devsecops/v2/DevSecOpsGithubOnPremConfiguration'))),
    DevSecOpsJiraConfigurePage: lazy(() => chunk(import('./pages/devsecops/v2/DevSecOpsJiraConfigurePage'))),
    DevSecOpsJiraLandingPage: lazy(() => chunk(import('./pages/devsecops/v2/DevSecOpsJiraLandingPage'))),
    DevSecOpsJiraMobileAppsPage: lazy(() => chunk(import('./pages/devsecops/v2/DevSecOpsJiraMobileAppsPage'))),
    DevSecOpsJiraMobileFormPage: lazy(() => chunk(import('./pages/devsecops/v2/DevSecOpsJiraMobileFormPage'))),
    DevSecOpsJiraOptions: lazy(() => chunk(import('./pages/devsecops/v2/DevSecOpsJiraOptionsPage'))),
    DevSecOpsSASTCodeAnalysis: lazy(() => chunk(import('./pages/devsecops/v2/DevSecOpsSASTCodeAnalysis'))),
    DevSecOpsSASTCodeAnalysisCreateIntegration: lazy(() => chunk(import('./pages/devsecops/v2/DevSecOpsSASTCodeAnalysisCreateIntegration'))),
    DevSecOpsSaml: lazy(() => chunk(import('./pages/devsecops/v2/DevSecOpsSaml'))),
    DevSecOpsSamlSecureSharePage: lazy(() => chunk(import('./pages/devsecops/v2/DevSecOpsSamlSecureSharePage'))),
    DevSecOpsSastConfigurationPage: lazy(() => chunk(import('./pages/devsecops/v2/DevSecOpsSastConfigurationPage'))),
    DevSecOpsScanCICD: lazy(() => chunk(import('./pages/devsecops/v2/DevSecOpsScanCICD'))),
    DevSecOpsServiceNowConfigurePage: lazy(() => chunk(import('./pages/devsecops/v2/DevSecOpsServiceNowConfigurePage'))),
    DevSecOpsServiceNowLandingPage: lazy(() => chunk(import('./pages/devsecops/v2/DevSecOpsServiceNowLandingPage'))),
    DevSecOpsSlackMicrosoftTeamsIntegrationPage: lazy(() => chunk(import('./pages/devsecops/v2/DevSecOpsSlackMicrosoftTeamsIntegrationPage'))),
    DevSecOpsUploadSourceCode: lazy(() => chunk(import('./pages/devsecops/v2/DevSecOpsUploadSourceCode'))),
    DevSecOpsWebhookIntegrationPage: lazy(() => chunk(import('./pages/devsecops/v2/DevSecOpsWebhookIntegrationPage'))),
    DevSecopsV2Page: lazy(() => chunk(import('./pages/devsecops/v2/DevsecopsPage'))),
    MobileAppUploadPage: lazy(() => chunk(import('./pages/devsecops/v2/MobileAppUploadPage'))),
    ResultsApiAccessPage: lazy(() => chunk(import('./pages/devsecops/v2/DevSecOpsResultsAPIAccessPage'))),
    StanfordDishUniHomeV2: lazy(() => chunk(import('./pages/devsecops/v2/StanfordDishUniHome'))),
    StanfordDishUniViewAllMaterialsV2: lazy(() => chunk(import('./pages/devsecops/v2/StanfordDishUniViewAllMaterials'))),
};
// prettier-ignore
const ApiComponents = {
    APINetworkServicesUuidPage: lazy(() => chunk(import('./pages/api/network_services/uuid/APINetworkServicesUuidPage.js'))),
    ApiAsmSetupPage: lazy(() => chunk(import('./pages/api/asm_setup/ApiAsmSetupPage'))),
    ApiAssetGroupsUuidPage: lazy(() => chunk(import('./pages/api/asset_groups/uuid/ApiAssetGroupsUuidPage'))),
    ApiAssetsUuidPage: lazy(() => chunk(import('./pages/api/assets/uuid/ApiAssetsUuidPage'))),
    ApiCloudResourcesUuidPage: lazy(() => chunk(import('./pages/api/cloud_resources/uuid/ApiCloudResourcesUuidPage'))),
    ApiCustomChecksPage: lazy(() => chunk(import('./pages/api/toolkits/custom-checks/ApiCustomChecksPage'))),
    ApiGraphqlApisUuidPage: lazy(() => chunk(import('./pages/api/graphql_apis/uuid/ApiGraphqlApisUuidPage'))),
    ApiInventoryPage: lazy(() => chunk(import('./pages/api/inventory/ApiInventoryPage'))),
    ApiMobileApplicationLoadingPage: lazy(() => chunk(import('./pages/api/mobile_applications/ApiMobileApplicationLoadingPage'))),
    ApiPoliciesPage: lazy(() => chunk(import('./pages/api/policies/ApiPoliciesPage'))),
    ApiPoliciesUuidPage: lazy(() => chunk(import('./pages/api/policies/uuid/ApiPoliciesUuidPage'))),
    ApiPolicyViolationsPage: lazy(() => chunk(import('./pages/api/policy_violations/ApiPolicyViolationsPage'))),
    ApiPolicyViolationsUuidPage: lazy(() => chunk(import('./pages/api/policy_violations/uuid/ApiPolicyViolationsUuidPage'))),
    ApiProtectUuidPage: lazy(() => chunk(import('./pages/api/toolkits/api_protect/ApiProtectUuidPage'))),
    ApiProtectionPage: lazy(() => chunk(import('./pages/api/toolkits/api_protect/ApiProtectPage'))),
    ApiShadowAssetsPage: lazy(() => chunk(import('./pages/api/shadow_assets/ApiShadowAssetsPage'))),
    ApiWebApplicationsUuidPage: lazy(() => chunk(import('./pages/api/web_applications/uuid/ApiWebApplicationsUuidPage'))),
};
// prettier-ignore
const WebComponents = {
    WebApplicationsUuidPage: lazy(() => chunk(import('./pages/web/applications/uuid/WebApplicationsUuidPage'))),
    WebAsmSetupPage: lazy(() => chunk(import('./pages/web/asm_setup/WebAsmSetupPage'))),
    WebAssetGroupsUuidPage: lazy(() => chunk(import('./pages/web/v2/WebInventoryAssetGroupsUuidPage'))),
    WebAssetsUuidPage: lazy(() => chunk(import('./pages/web/assets/uuid/WebAssetsUuidPage'))),
    WebCloudResourcesUuidPage: lazy(() => chunk(import('./pages/web/cloud_resources/uuid/WebCloudResourcesUuidPage'))),
    WebCustomChecksPage: lazy(() => chunk(import('./pages/web/security_toolkits/custom-checks/WebCustomChecksPage'))),
    WebGraphqlApisUuidPage: lazy(() => chunk(import('./pages/web/graphql_apis/uuid/WebGraphqlApisUuidPage'))),
    WebInventoryPage: lazy(() => chunk(import('./pages/web/inventory/WebInventoryPage'))),
    WebNetworkServicesUuidPage: lazy(() => chunk(import('./pages/web/network_services/uuid/WebNetworkServicesUuidPage.js'))),
    WebPage: lazy(() => chunk(import('./pages/web/WebPage'))),
    WebPoliciesPage: lazy(() => chunk(import('./pages/web/policies/WebPoliciesPage'))),
    WebPoliciesUuidPage: lazy(() => chunk(import('./pages/web/policies/uuid/WebPoliciesUuidPage'))),
    WebPolicyViolationsPage: lazy(() => chunk(import('./pages/web/policy_violations/WebPolicyViolationsPage'))),
    WebPolicyViolationsUuidPage: lazy(() => chunk(import('./pages/web/policy_violations/uuid/WebPolicyViolationsUuidPage'))),
    WebSecurityToolkitsDetectInjectPage: lazy(() => chunk(import('./pages/web/security_toolkits/sql_injection/WebSecurityToolkitsSqlInjectionPage'))),
    WebSecurityToolkitsPage: lazy(() => chunk(import('./pages/web/security_toolkits/WebSecurityToolkitsPage'))),
    WebSecurityToolkitsWebProtectPage: lazy(() => chunk(import('./pages/web/security_toolkits/web_protect/WebSecurityToolkitsWebProtectPage'))),
    WebSecurityToolkitsXssAttackPage: lazy(() => chunk(import('./pages/web/security_toolkits/xss_attack/WebSecurityToolkitsXssAttackPage'))),
    WebSecurityToolkitsXssProtectionPage: lazy(() => chunk(import('./pages/web/security_toolkits/xss_protection/WebSecurityToolkitsXssProtectionPage'))),
    WebShadowAssetsPage: lazy(() => chunk(import('./pages/web/shadow_assets/WebShadowAssetsPage'))),
    WebToxicTokensPage: lazy(() => chunk(import('./pages/web/toxic_tokens/WebToxicTokensPage'))),
};
// prettier-ignore
const SupplyChainComponents = {
    SupplierTrustPage: lazy(() => chunk(import('./pages/supply_chain/supplier_trust/SupplierTrustPage'))),
    SupplyChainAppUuidMobilePage: lazy(() => chunk(import('./pages/supply_chain/my_apps/uuid/mobile/SupplyChainAppUuidMobilePage'))),
    SupplyChainAppUuidWebPage: lazy(() => chunk(import('./pages/supply_chain/my_apps/uuid/web/SupplyChainAppUuidWebPage'))),
    SupplyChainAsmSetupPage: lazy(() => chunk(import('./pages/supply_chain/asm_setup/SupplyChainAsmSetupPage'))),
    SupplyChainMyAppsPage: lazy(() => chunk(import('./pages/supply_chain/my_apps/MyAppsPage'))),
    SupplyChainPage: lazy(() => chunk(import('./pages/supply_chain/supplyChainPage'))),
    SupplyChainPolicyPage: lazy(() => chunk(import('./pages/supply_chain/policy/SupplyChainPolicyPage'))),
    SupplyChainSBOMPage: lazy(() => chunk(import('./pages/supply_chain/sbom/SBOMPage'))),
    SupplyChainSbomSecurityIssuesPage: lazy(() => chunk(import('./pages/supply_chain/security_issues/SupplyChainSecurityIssuesPage'))),
    SupplyChainVendorsPage: lazy(() => chunk(import('./pages/supply_chain/vendors/VendorsPage'))),
    VendorUuidPage: lazy(() => chunk(import('./pages/supply_chain/supplier_trust/uuid/SupplierTrustUuidPage'))),
    VendorUuidPublicViewPage: lazy(() => chunk(import('./pages/supply_chain/supplier_trust/uuid/SupplierTrustUuidPublicViewPage'))),
};
// prettier-ignore
const CloudComponents = {
    ApolloCorrectAndProjectContainer: lazy(() => chunk(import('./pages/cloud/toolkits/correct_protect/CorrectAndProtectPage'))),
    CloudActivityPage: lazy(() => chunk(import('./pages/cloud/activity/CloudActivityPage'))),
    CloudAsmSetupPage: lazy(() => chunk(import('./pages/cloud/asm_setup/CloudAsmSetupPage'))),
    CloudAssetGroupsUuidPage: lazy(() => chunk(import('./pages/cloud/asset_groups/uuid/CloudAssetGroupsUuidPage'))),
    CloudAssetsUuidPage: lazy(() => chunk(import('./pages/cloud/assets/uuid/CloudAssetsUuidPage'))),
    CloudCIEMPage: lazy(() => chunk(import('./pages/cloud/toolkits/ciem/CloudCIEMPage'))),
    CloudCIEMPageDemo: lazy(() => chunk(import('./pages/cloud/toolkits/ciem/CloudCIEMPageDemo'))),
    CloudCloudResourcesUuidPage: lazy(() => chunk(import('./pages/cloud/cloud_resources/uuid/CloudCloudResourcesUuidPage'))),
    CloudGraphqlApisUuidPage: lazy(() => chunk(import('./pages/cloud/graphql_apis/uuid/CloudGraphqlApisUuidPage'))),
    CloudIacScanPage: lazy(() => chunk(import('./pages/cloud/iac_scan/CloudIacScanPage'))),
    CloudInventoryPage: lazy(() => chunk(import('./pages/cloud/inventory/CloudInventoryPage'))),
    CloudNetworkServicesUuidPage: lazy(() => chunk(import('./pages/cloud/network_services/uuid/CloudNetworkServicesUuidPage.js'))),
    CloudPage: lazy(() => chunk(import('./pages/cloud/CloudPage'))),
    CloudPoliciesPage: lazy(() => chunk(import('./pages/cloud/policies/CloudPoliciesPage'))),
    CloudPoliciesUuidPage: lazy(() => chunk(import('./pages/cloud/policies/uuid/CloudPoliciesUuidPage'))),
    CloudPolicyViolationsPage: lazy(() => chunk(import('./pages/cloud/policy_violations/CloudPolicyViolationsPage'))),
    CloudPolicyViolationsUuidPage: lazy(() => chunk(import('./pages/cloud/policy_violations/uuid/CloudPolicyViolationsUuidPage'))),
    CloudShadowAssetsPage: lazy(() => chunk(import('./pages/cloud/shadow_assets/CloudShadowAssetsPage'))),
    CloudToolkitsCodeRedPage: lazy(() => chunk(import('./pages/cloud/toolkits/code_red/CodeRedPage'))),
    CloudToolkitsDlpPage: lazy(() => chunk(import('./pages/cloud/toolkits/dlp/CloudToolkitsDlpPage'))),
    CloudToolkitsGotchasPage: lazy(() => chunk(import('./pages/cloud/toolkits/gotchas/CloudToolkitsGotchasPage'))),
    CloudToolkitsLeaksPage: lazy(() => chunk(import('./pages/cloud/toolkits/leaks/CloudToolkitsLeaksPage'))),
    CloudToolkitsPage: lazy(() => chunk(import('./pages/cloud/toolkits/CloudToolkitsPage'))),
    CloudToolkitsRansomwarePage: lazy(() => chunk(import('./pages/cloud/toolkits/ransomware/RansomwarePage'))),
    CloudWebApplicationsUuid: lazy(() => chunk(import('./pages/cloud/web_applications/uuid/CloudWebApplicationsUuidPage'))),
    CloudXDRPage: lazy(() => chunk(import('./pages/cloud/toolkits/xdr/CloudXDRPage'))),
    MajorMalfunctionPage: lazy(() => chunk(import('./pages/cloud/toolkits/major_malfunction/MajorMalfunctionPage'))),
};
// prettier-ignore
const ManagementComponents = {
    ManagementActivity: lazy(() => chunk(import('./pages/management/activity/ActivityPage'))),
    ManagementSharePolicyViolationPage: lazy(() => chunk(import('./pages/management/share/ManagementSharePolicyViolationPage'))),
    OnboardingMobileDastPage: lazy(() => chunk(import('./pages/management/onboarding_wizard/OnboardingMobileDastPage'))),
    OnboardingWizardPage: lazy(() => chunk(import('./pages/management/onboarding_wizard/OnboardingWizardPage'))),
    OnboardingWizardPublicViewPage: lazy(() => chunk(import('./pages/management/onboarding_wizard/OnboardingWizardPublicViewPage'))),
    SamlIntegrationPage: lazy(() => chunk(import('./pages/management/sdlc/SamlIntegrationPage'))),
    SastIssuePublicViewPage: lazy(() => chunk(import('./pages/management/v2/share/sast_issue/SastIssuePublicViewPage'))),
    SharedPolicyViolations: lazy(() => chunk(import('./pages/management/policy_violations/SharedPolicyViolations'))),
    SlackMicrosoftTeamsIntegrationPage: lazy(() => chunk(import('./pages/management/sdlc/slack_msft/SlackMicrosoftTeamsIntegrationPage'))),
    WebhookIntegrationPage: lazy(() => chunk(import('./pages/management/sdlc/webhooks/WebhookIntegrationListPage'))),
};
// prettier-ignore
const ReduxComponents = {
    ApolloApiOperationDetailsContainer: lazy(() => chunk(import('./redux/api_operations/ApiOperationDetailsContainer'))),
    ApolloApiProtectContainer: lazy(() => chunk(import('./redux/protect/ApiProtectContainer'))),
    ApolloDetectInjectContainer: lazy(() => chunk(import('./redux/detect_inject/DetectInjectContainer'))),
    ApolloHackExtractContainer: lazy(() => chunk(import('./redux/hack_extract/HackExtractContainer'))),
    ApolloLeakyApisContainer: lazy(() => chunk(import('./redux/leaky_apis/LeakyApisContainer'))),
    ApolloNetworkServiceDetailsContainer: lazy(() => chunk(import('./redux/network_services/NetworkServiceDetailsContainer'))),
    ApolloOverview: lazy(() => chunk(import('./redux/dashboard/Overview'))),
    ApolloOverviewDetail: lazy(() => chunk(import('./redux/dashboard/OverviewDetail'))),
    ApolloPolicyRuleWithViolationsAndDetails: lazy(() => chunk(import('./redux/policy_rules/PolicyRuleWithViolationsAndDetails'))),
    ApolloRestfulApiDetailsContainer: lazy(() => chunk(import('./redux/restful_apis/RestfulAPIDetailsContainer'))),
    ApolloSharedLinks: lazy(() => chunk(import('./redux/shared_links/SharedLinks'))),
    ApolloSsrfSploitsContainer: lazy(() => chunk(import('./redux/ssrf_sploits/SsrfSploitsContainer'))),
    AtlasApplicationView: lazy(() => chunk(import('./redux/atlas/components/ApplicationView'))),
    AtlasConfiguration: lazy(() => chunk(import('./redux/atlas/components/Configuration'))),
    AtlasPublicAppSecurity: lazy(() => chunk(import('./redux/atlas/components/SharedLinks/PublicAppSecurity'))),
    AtlasSearchView: lazy(() => chunk(import('./redux/atlas/components/SearchView'))),
    ManagementComplianceReports: lazy(() => chunk(import('./redux/compliance_reports/ComplianceReports'))),
    ManagementProductOnboardingContainer: lazy(() => chunk(import('./redux/onboarding_wizard/ProductOnboardingContainer'))),
    ManagementProfile: lazy(() => chunk(import('./redux/profile/Profile'))),
    ManagementUserDashboard: lazy(() => chunk(import('./redux/users/UserDashboard'))),
    ManagementUserEdit: lazy(() => chunk(import('./redux/users/UserEdit'))),
    ManagementUserInvite: lazy(() => chunk(import('./redux/users/UserInvite'))),
    PolicyRuleWithViolationsAndDetailsCorrectAndProtect: lazy(() => chunk(import('./redux/policy_rules/PolicyRuleWithViolationsAndDetailsCorrectAndProtect'))),
    ShareCloudResourcePublicView: lazy(() => chunk(import('./redux/shared_links/public_app/CloudResourcePublicView'))),
    ShareNetworkServicesPublicView: lazy(() => chunk(import('./redux/shared_links/public_app/NetworkServicePublicView'))),
    ShareOnboardingPublicView: lazy(() => chunk(import('./redux/shared_links/public_app/OnboardingPublicView'))),
    ShareProductOnboardingWizardPublicView: lazy(() => chunk(import('./redux/shared_links/public_app/ProductOnboardingWizardPublicView'))),
    ShareRestfulAPIPublicView: lazy(() => chunk(import('./redux/shared_links/public_app/RestfulAPIPublicView'))),
    ShareWebAppPublicView: lazy(() => chunk(import('./redux/shared_links/public_app/WebAppPublicView'))),
};
// prettier-ignore
const MobileComponents = {
    MobileAssetGroupsUuidPage: lazy(() => chunk(import('./pages/mobile/asset_groups/uuid/MobileAssetGroupsUuidPage'))),
    MobileInventoryPage: lazy(() => chunk(import('./pages/mobile/inventory/MobileInventoryPage'))),
    MobilePoliciesPage: lazy(() => chunk(import('./pages/mobile/policies/MobilePoliciesPage'))),
    MobilePoliciesUuidPage: lazy(() => chunk(import('./pages/mobile/policies/uuid/MobilePoliciesUuidPage'))),
};
// prettier-ignore
const DevSecOpsComponents = {
    DevSecOpsGithubOnPremConfigure: lazy(() => chunk(import('./pages/devsecops/jira_integration/api_cloud_web/ApiCloudWebConfigure'))),
    DevSecOpsJiraApiCloudWebConfigure: lazy(() => chunk(import('./pages/devsecops/jira_integration/api_cloud_web/ApiCloudWebConfigure'))),
    DevSecOpsJiraApiCloudWebLandingPage: lazy(() => chunk(import('./pages/devsecops/jira_integration/api_cloud_web/ApiCloudWebLandingPage'))),
    DevSecOpsPage: lazy(() => chunk(import('./pages/devsecops/DevSecOpsHome'))),
    StanfordDishUniHome: lazy(() => chunk(import('./pages/devsecops/stanford_dish_university/StanfordDishUniHome'))),
    StanfordDishUniViewAllMaterials: lazy(() => chunk(import('./pages/devsecops/stanford_dish_university/StanfordDishUniViewAllMaterials'))),
};
// prettier-ignore
const PoliciesComponents = {
    PoliciesOpenscanRedirectPage: lazy(() => chunk(import('./pages/policies/v2/PoliciesOpenscanRedirectPage'))),
    PoliciesPage: lazy(() => chunk(import('./pages/policies/PoliciesPage'))),
    PoliciesUuidPage: lazy(() => chunk(import('./pages/policies/uuid/PoliciesUuidPage'))),
    PoliciesV2Page: lazy(() => chunk(import('./pages/policies/v2/PoliciesV2Page'))),
    PoliciesV2UuidPage: lazy(() => chunk(import('./pages/policies/v2/uuid/PoliciesV2UuidPage'))),
};
// prettier-ignore
const DashboardComponents = {
    DashboardLandingPage: lazy(() => chunk(import('./pages/dashboard/LandingPage'))),
    DashboardPage: lazy(() => chunk(import('./pages/dashboard/DashboardPage'))),
};
// prettier-ignore
export default {
    ...SettingsV2Components,
    ...DevSecOpsV2Components,
    ...MobileV2Components,
    ...WebV2Components,
    ...cloudV2Components,
    ...ApiComponents,
    ...ApiV2Components,
    ...CloudComponents,
    ...WebComponents,
    ...SupplyChainComponents,
    ...OpenScanV2Components,
    ...ManagementComponents,
    ...ReduxComponents,
    ...MobileComponents,
    ...DevSecOpsComponents,
    ...PoliciesComponents,
    ...DashboardComponents
};
