import React, { memo, useMemo, useState } from 'react';
import { Waypoint } from 'react-waypoint';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { Drawer, Grid, Tooltip, styled, useTheme } from '@mui/material';
import { useLazyQuery as useLazyPaginatedQuery } from '@dt/apollo-link-schema-rest';
import horizon_notifications from '@dt/graphql-support/horizon/notification_events';
import sevenhell_notifications from '@dt/graphql-support/sevenhell/portal_notifications';
import { differenceInDays } from 'date-fns';
import { dateFormats } from '../../../apps/ahura/src/util/dateFormats';
import { formatDateDefault } from '../../../apps/ahura/src/util/formatDateDefault';
import Text from '../Text';
import NotificationItem from './NotificationItem';
import NotificationItemError from './NotificationItemError';
import NotificationItemLoadingSkeleton from './NotificationItemLoadingSkeleton';
import NotificationItemZeroState from './NotificationItemZeroState';

const MenuIconBox = styled('div')({
  alignItems: 'center',
  display: 'flex',
  height: 40,
  justifyContent: 'center',
  marginRight: '16px',
  maxHeight: 40,
  maxWidth: 40,
  minHeight: 40,
  minWidth: 40,
  width: 40,
});

const MenuInner = styled('div')({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'flex-start',
});

const MenuOuter = styled('div')(({ theme }) => ({
  '&.active': {
    backgroundColor: theme.palette.brand[35],
  },
  '&.bottom': {
    marginTop: 'auto',
  },
  '&:hover': {
    backgroundColor: theme.palette.brand[20],
  },
  alignItems: 'center',
  borderRadius: 4,
  color: theme.palette.white.main,
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'flex-start',
  marginBottom: 8,
  marginLeft: 16,
  marginRight: 16,
  paddingBottom: 0,
  paddingLeft: 0,
  paddingRight: 0,
  paddingTop: 0,
}));

const MenuText = styled('div')(({ theme }) => ({
  '&.title': {
    color: theme.palette.white.main,
    fontSize: 20,
  },
  alignItems: 'center',
  display: 'flex',
  height: '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}));

export default memo(function NotificationsDrawer({ isSidebarExpanded }) {
  const theme = useTheme();
  const [
    fetchHorizonNotifications,
    {
      called: horizonNotificationsCalled,
      data: horizonNotificationsData,
      loading: horizonNotificationsLoading,
      error: horizonNotificationsError,
      fetchMore: horizonNotificationsFetchMore,
    },
  ] = useLazyPaginatedQuery(horizon_notifications.list);

  const [
    fetchSevenhellNotifications,
    {
      called: sevenhellNotificationsCalled,
      data: sevenhellNotificationsData,
      loading: sevenhellNotificationsLoading,
      error: sevenhellNotificationsError,
      fetchMore: sevenhellNotificationsFetchMore,
    },
  ] = useLazyPaginatedQuery(sevenhell_notifications.list);

  const [anchorEl, setanchorEl] = useState(null);
  const handleOpen = event => {
    setanchorEl(event.currentTarget);
    !horizonNotificationsCalled && fetchHorizonNotifications();
    !sevenhellNotificationsCalled && fetchSevenhellNotifications();
  };
  const handleClose = () => setanchorEl(null);

  const styles = {
    notificationsContainer: {
      paddingLeft: isSidebarExpanded ? 256 : 72,
    },
  };

  const horizonNotifications = horizonNotificationsData?.notification_events_list?.events
    ? horizonNotificationsData.notification_events_list.events
    : [];

  const sevenhellNotifications = sevenhellNotificationsData?.portal_notifications_list?.portal_notifications
    ? sevenhellNotificationsData.portal_notifications_list.portal_notifications
    : [];

  const notificationsLoading = sevenhellNotificationsLoading || horizonNotificationsLoading;

  const notificationsError = sevenhellNotificationsError || horizonNotificationsError;

  let currentDate = new Date(Date.now());
  let hasTodayBeenChecked = false;

  if (horizonNotificationsError) {
    console.error('horizonNotificationsError:', horizonNotificationsError);
  }
  if (sevenhellNotificationsError) {
    console.error('sevenhellNotificationsError:', sevenhellNotificationsError);
  }

  const notifications = useMemo(
    () =>
      [...horizonNotifications, ...sevenhellNotifications].sort((a, b) => {
        if (a.date_created && b.date_created) {
          if (a.date_created > b.date_created) {
            return -1;
          }
          return 1;
        }
        return 0;
      }),
    [horizonNotifications, sevenhellNotifications],
  );

  return (
    <>
      <MenuOuter
        aria-haspopup="true"
        aria-label="Notifications Drawer"
        onClick={event => {
          anchorEl ? handleClose() : handleOpen(event);
        }}
        type="button"
      >
        <Tooltip disableInteractive title="Notifications">
          <MenuInner>
            <MenuIconBox label="notifications-menu-icon">
              <NotificationsIcon color="inherit" />
            </MenuIconBox>
            <MenuText>Notifications</MenuText>
          </MenuInner>
        </Tooltip>
      </MenuOuter>
      <Drawer
        PaperProps={{
          style: {
            overflowX: 'hidden',
            width: 488 + (isSidebarExpanded ? 256 : 72),
          },
        }}
        aria-labelledby={'Notifications Drawer'}
        onClose={handleClose}
        open={Boolean(anchorEl)}
        style={{ zIndex: 1100 }}
      >
        <Grid
          alignContent="center"
          alignItems="flex-start"
          container
          direction="column"
          spacing={2}
          style={styles.notificationsContainer}
        >
          <Grid item>
            <Text style={{ marginBottom: '8px' }} variant="titleS">
              Notifications
            </Text>
          </Grid>
          {!notifications && !notificationsLoading && !notificationsError && <NotificationItemZeroState />}
          {notifications
            ? notifications.map(notification => {
                let dateHeader = null;

                if (differenceInDays(new Date(), new Date(notification.date_created)) === 0 && !hasTodayBeenChecked) {
                  dateHeader = 'Today';
                  hasTodayBeenChecked = true;
                }

                if (differenceInDays(new Date(), new Date(currentDate)) > 0) {
                  dateHeader = formatDateDefault({
                    date: new Date(notification.date_created),
                    formatStr: dateFormats.MMMddyyyy,
                  });
                  currentDate = notification.date_created;
                }

                return (
                  <Grid item key={notification.id}>
                    {Boolean(dateHeader) && (
                      <Text style={{ color: theme.palette.gray[35], margin: 0 }} variant="titleXS">
                        {dateHeader}
                      </Text>
                    )}
                    <NotificationItem notification={notification} />
                  </Grid>
                );
              })
            : null}
          {notificationsLoading &&
            [0, 1, 2, 3, 4, 5, 6].map(i => {
              return (
                <Grid item key={i}>
                  <NotificationItemLoadingSkeleton />
                </Grid>
              );
            })}
          {!sevenhellNotificationsLoading &&
            sevenhellNotificationsData?.portal_notifications_list?.pagination_information?.next_cursor && (
              <Grid item>
                <Waypoint
                  onEnter={() => {
                    sevenhellNotificationsFetchMore && sevenhellNotificationsFetchMore();
                  }}
                />
              </Grid>
            )}
          {!horizonNotificationsLoading &&
            horizonNotificationsData?.notification_events_list?.pagination_information?.next_cursor && (
              <Grid item>
                <Waypoint
                  onEnter={() => {
                    horizonNotificationsFetchMore && horizonNotificationsFetchMore();
                  }}
                />
              </Grid>
            )}
          {(horizonNotificationsError || sevenhellNotificationsError) && <NotificationItemError />}
        </Grid>
      </Drawer>
    </>
  );
});
