import React, { memo } from 'react';
import { NaNOrInfinityAsZero } from '@dt/functions';
import { dateFormats } from '../../apps/ahura/src/util/dateFormats';
import { formatDateDefault } from '../../apps/ahura/src/util/formatDateDefault';

const findDateInDateTimes = (date, dateTime) => {
  let timeLineLocation = 0;
  const dateTimePeriod = dateTime[0].getTime() - dateTime[1].getTime();
  dateTime.forEach((_, idx) => {
    if (dateTime[idx] <= date && dateTime[idx + 1] && dateTime[idx + 1] > date) {
      const startDateTimeStamp = dateTime[idx].getTime();
      const bubbleTimeStamp = date.getTime();
      timeLineLocation = idx + (bubbleTimeStamp - startDateTimeStamp) / dateTimePeriod;
    }
  });
  return timeLineLocation;
};

const BubbleChart = ({ data, dateTime, height = 100, width = 500, precision = 1 }) => {
  const FONT_SIZE = width / 70;
  const MAX_CIRCLE_RADIUS = 32;
  const maximumYFromData = Math.max(...data.map(item => item.value || 0));
  const maximumXFromData = dateTime.length - 1;

  const digits = parseFloat(maximumYFromData.toString()).toFixed(precision).length + 1;

  const padding = (FONT_SIZE + digits) * 3;
  const chartWidth = width - padding * 2;
  const chartHeight = height - padding * 2;

  const BubblePoints = () => {
    const radiusRatio = MAX_CIRCLE_RADIUS / maximumYFromData;
    return data
      .filter(data => data.value)
      .map(({ date, value, color, title }, index) => {
        const x = (findDateInDateTimes(date, dateTime) / maximumXFromData) * chartWidth + padding;
        const y = chartHeight - (value / maximumYFromData) * chartHeight + padding;
        return (
          <g key={index}>
            <circle cx={x} cy={y} fill={color} opacity="0.5" r={value * radiusRatio}></circle>
            <text dy=".3em" fill="black" fontFamily="Rubik" fontSize={4} textAnchor="middle" x={x} y={y}>
              {title}
            </text>
          </g>
        );
      });
  };

  const LabelsXAxis = () => {
    const y = height - padding + FONT_SIZE * 2;

    return dateTime.map((date, index) => {
      const x = (index / maximumXFromData) * chartWidth + padding;
      const label = formatDateDefault({ date, formatStr: dateFormats.MMMyyyy });
      return (
        <text
          key={index}
          style={{
            fill: '#808080',
            fontFamily: 'Helvetica',
            fontSize: FONT_SIZE,
          }}
          x={x - (label.length * FONT_SIZE) / 4}
          y={y}
        >
          {label}
        </text>
      );
    });
  };

  const LabelsYAxis = () => {
    const PARTS = 4;
    return new Array(PARTS + 1).fill(0).map((_, index) => {
      const x = FONT_SIZE;
      const ratio = index / 4;
      const yCoordinate = chartHeight - chartHeight * ratio + padding + FONT_SIZE / 2;

      if (!Number.isFinite(maximumYFromData)) {
        return (
          <text
            key={index}
            style={{
              fill: '#808080',
              fontFamily: 'Helvetica',
              fontSize: FONT_SIZE,
            }}
            x={x}
            y={yCoordinate}
          >
            {(index * 100).toFixed(precision)}
          </text>
        );
      }

      return (
        <text
          key={index}
          style={{
            fill: '#808080',
            fontFamily: 'Helvetica',
            fontSize: FONT_SIZE,
          }}
          x={x}
          y={yCoordinate}
        >
          {NaNOrInfinityAsZero(parseFloat(maximumYFromData * (index / PARTS))).toFixed(precision)}
        </text>
      );
    });
  };

  return (
    <svg viewBox={`0 0 ${width} ${height}`}>
      <LabelsXAxis />
      <LabelsYAxis />
      <BubblePoints />
    </svg>
  );
};

export default memo(BubbleChart);
