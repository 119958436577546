import React from 'react';
import { AssetTypeEnumValues } from '@dt/graphql-support/enums';
import { Link } from '@reach/router';

// prettier-ignore
const AssetTypePathLookup = Object.freeze({
  [AssetTypeEnumValues.API_OPERATION]:      ({ asset_id, isV2 }) =>`${isV2 ? '/api/v2' : '/api'}/api-operations/${asset_id}`,
  [AssetTypeEnumValues.CLOUD_RESOURCE]:     ({ productBasePath, asset_id }) => `${productBasePath}/cloud-resources/${asset_id}`,
  [AssetTypeEnumValues.DOMAIN_NAME]:        ({ productBasePath, asset_id }) =>`${productBasePath}/assets/${asset_id}`,
  [AssetTypeEnumValues.GRAPHQL_API]:        ({ productBasePath, asset_id }) =>`${productBasePath}/graphql-apis/${asset_id}`,
  [AssetTypeEnumValues.MOBILE_APPLICATION]: ({ asset_id }) =>`/api/mobile_applications/${asset_id}`,
  [AssetTypeEnumValues.NETWORK_SERVICE]:    ({ productBasePath, asset_id }) =>`${productBasePath}/network-services/${asset_id}`,
  [AssetTypeEnumValues.RESTFUL_API]:        ({ productBasePath, asset_id }) =>`${productBasePath}/assets/${asset_id}`,
  [AssetTypeEnumValues.WEB_APPLICATION]:    ({ productBasePath, asset_id }) =>`${productBasePath}/web-applications/${asset_id}`,
  [AssetTypeEnumValues.GRPC_SERVICE]:       ({ productBasePath, asset_id }) =>`${productBasePath}/assets/${asset_id}`,
  [AssetTypeEnumValues.GRPC_METHOD]:        ({ productBasePath, asset_id }) =>`${productBasePath}/assets/${asset_id}`,
  [AssetTypeEnumValues.KUBERNETES_CLUSTER]: ({productBasePath, asset_id}) => `${productBasePath}/assets/${asset_id}`,
  [AssetTypeEnumValues.KUBERNETES_CLUSTER_COMPONENT]: ({productBasePath, asset_id}) => `${productBasePath}/assets/${asset_id}`,
  [AssetTypeEnumValues.SOAP_API]:           ({ productBasePath, asset_id }) =>`${productBasePath}/assets/${asset_id}`,
  [AssetTypeEnumValues.SOAP_API_OPERATION]: ({ productBasePath, asset_id }) =>`${productBasePath}/assets/${asset_id}`,
  [AssetTypeEnumValues.MOBILE_SDK]:         () => '',
  [AssetTypeEnumValues.REPOSITORY]:         () => '',
  [AssetTypeEnumValues.CLOUD_IDENTITY]:     ({ productBasePath, asset_id }) =>`${productBasePath}/assets/${asset_id}`
});

/*
 * Generates a `Link` to the corresponding asset type details page in the current product.
 *
 * Some assets don't have a corresponding details page.
 * In these cases no `Link` is generated but the content is.
 *
 * If the current product that the user is using doesn't have that asset type
 * the user is redirected to the corresponding asset in another product.
 *
 * @param productBasePath - Used to maintain navigational context for the user. Represents the "current product".
 * @param asset_type - Asset type to generate the link for.
 * @param asset_id - Asset id to generate the link for.
 * @param chlidren - Content of the link.
 */
const AssetsLinkComponent = function AssetsLink({ productBasePath, asset_type, asset_id, children, isV2 = false }) {
  const updatedProductBasePath = isV2 ? `${productBasePath}/v2` : productBasePath;
  const linkBuilder = AssetTypePathLookup[asset_type];

  return typeof linkBuilder !== 'undefined' ? (
    <Link
      style={{ color: 'inherit', display: 'inline-flex' }}
      to={linkBuilder({ asset_id, isV2, productBasePath: updatedProductBasePath })}
    >
      {children}
    </Link>
  ) : (
    children
  );
};

export const AssetsLink = AssetsLinkComponent;
