import React, { memo } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment, Paper, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';

const withStyles = makeStyles(() => ({
  root: {
    '& .MuiInput-underline:after': {
      border: 'none',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: 'none',
      },
      '&.Mui-focused fieldset': {
        border: 'none',
      },
      '&:hover fieldset': {
        border: 'none',
      },
    },
  },
}));

// TODO: The caller for this component needs to debounce the onChange function. This needs a bit of rework.
/*
 * @deprecated Use 'KeyWordSearchInput' instead.
 */
export default memo(function KeywordSearch({
  onChange,
  id,
  ariaLabel,
  placeholder,
  fullWidth,
  value,
  autoFocus,
  size,
  isV2 = false,
}) {
  const classes = withStyles({ isV2 });
  return (
    <Paper component="form" elevation={isV2 ? 0 : 1} style={{ borderRadius: isV2 ? 8 : 4 }}>
      <TextField
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
        aria-label={ariaLabel}
        autoFocus={autoFocus}
        className={isV2 ? classes.root : ''}
        defaultValue={value}
        fullWidth={fullWidth}
        id={id}
        onChange={e => onChange(e.target.value)}
        placeholder={placeholder ? placeholder : 'Keyword Search'}
        size={size}
        type="search"
        variant="outlined"
      />
    </Paper>
  );
});
