import { useState } from 'react';
const useTableSort = (defaultSortField = '', defaultSortDirection = 'asc') => {
    const [sortBy, setSortBy] = useState(defaultSortField);
    const [sortDirection, setSortDirection] = useState(defaultSortDirection);
    const handleSortChange = (field, direction) => {
        if (field === sortBy) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
            return;
        }
        setSortBy(field);
        setSortDirection(direction || defaultSortDirection);
    };
    return {
        handleSortChange,
        order_by: sortBy ? `${sortBy}_${sortDirection}` : null,
        sortBy,
        sortDirection,
    };
};
export default useTableSort;
