import { createAction, handleActions } from 'redux-actions';
import createReducerCreator from 'rezz/createReducerCreator';
import set from 'rezz/set';
import {
  addCommentClicked,
  addCommentRequestFailed,
  addCommentRequestSucceeded,
  anErrorOccurred,
  appFileAccepted,
  appFileRejected,
  appFileSubmitted,
  appXCUITestFileAccepted,
  appXCUITestFileRejected,
  appXCUITestFileSubmit,
  dismissError,
  whatsNewSeen,
  whatsNewUpdate,
} from '../actions';

export const setString = createAction('SET_STRING', (key, value) => ({ key, value }));

export default createReducerCreator({
  initialState: {},
  keyStrategy: action => action.payload.key,
  payloadStrategy: action => action.payload.value,
})(
  set(setString.toString()),
  handleActions(
    {
      [appFileAccepted.toString()]: state => ({
        ...state,
        fileUploadResponse: 'true',
      }),
      [appFileRejected.toString()]: (state, action) => ({
        ...state,
        fileUploadError: action.payload,
      }),
      [appFileSubmitted.toString()]: state => ({
        ...state,
        apps: null,
        fileUploadError: null,
        fileUploadResponse: null,
      }),
      [appXCUITestFileSubmit.toString()]: state => ({
        ...state,
        appXCUITestUploadError: null,
        appXCUITestUploadSubmitting: 'true',
      }),
      [appXCUITestFileAccepted.toString()]: state => ({
        ...state,
        appXCUITestUploadError: null,
        appXCUITestUploadSubmitting: null,
      }),
      [appXCUITestFileRejected.toString()]: state => ({
        ...state,
        appXCUITestUploadError: 'true',
        appXCUITestUploadSubmitting: null,
      }),
      [anErrorOccurred.toString()]: (state, action) => ({
        ...state,
        unknownErrorCode: action.payload.code,
      }),
      [dismissError.toString()]: state => ({
        ...state,
        unknownErrorCode: null,
      }),
      [addCommentClicked.toString()]: state => ({
        ...state,
        commentBeingAdded: 'true',
      }),
      [addCommentRequestSucceeded.toString()]: state => ({
        ...state,
        commentBeingAdded: null,
      }),
      [addCommentRequestFailed.toString()]: state => ({
        ...state,
        commentBeingAdded: null,
      }),
      [whatsNewUpdate.toString()]: (state, action) => ({
        ...state,
        whatsNewSeenAt: action.payload,
      }),
      [whatsNewSeen.toString()]: state => ({
        ...state,
        whatsNewSeen: 'true',
      }),
    },
    {},
  ),
);
