import config from '@dt/config';
import fetch, { parse } from '@dt/fetch';

const api = config.horizonApiBaseUrl;
const version = 'v1';

export const SpecialScanRequestTypeEnum = {
  DETECT_AND_INJECT: 'DETECT_AND_INJECT',
  HACK_AND_EXTRACT: 'HACK_AND_EXTRACT',
  LEAKY_APIS: 'LEAKY_APIS',
  SPA_DETECT_AND_INJECT: 'SPA_DETECT_AND_INJECT',
  XSS_PROTECTION: 'XSS_PROTECTION',
};

export const SpecialScanRequestStatusEnum = {
  FINISHED: 'FINISHED',
  NOT_STARTED: 'NOT_STARTED',
  ONGOING: 'ONGOING',
};

export async function get(type) {
  return fetch(`${api}/${version}/special_scan_requests/${type}`).then(response => {
    if (response.status === 404) {
      // This endpoint is special as when this type hasn't been created yet,
      // it returns 404
      return {
        _type: 'response',
        body: {
          special_scan_requests: [],
          users: [],
        },
        ok: true,
        status: response.status,
      };
    }

    return parse(response);
  });
}

export async function list(type) {
  return fetch(`${api}/${version}/special_scan_requests?scan_type=${type}`).then(response => {
    if (response.status === 404) {
      // This endpoint is special as when this type hasn't been created yet,
      // it returns 404
      return {
        _type: 'response',
        body: {
          special_scan_requests: [],
          users: [],
        },
        ok: true,
        status: response.status,
      };
    }

    return parse(response);
  });
}

export async function create(params) {
  return fetch(`${api}/${version}/special_scan_requests`, {
    body: JSON.stringify(params),
    method: 'POST',
  }).then(parse);
}

export async function list_pii_reports_in_api_responses() {
  return fetch(`${api}/${version}/special_scan_requests/hack_and_extract/pii_reports_in_responses`).then(parse);
}
