import { default as CompliancePolicyEnumValues } from '@dt/enums/CompliancePolicyEnum';
import { assoc, dissoc, flow, toPath } from 'lodash/fp';
import { ActionEnum } from '../actions/filterActions';
import { getCustomRange } from '../util/filtersUtil';

export const initialState = {
  findingsCriteria: {
    description: '',
    id: null,
    isDisabledLoading: false,
    isOwner: false,
    isPrivate: false,
    minAge: '',
    selectedCompliancePolicies: [],
    selectedCurrentStatuses: [],
    selectedKeywords: '',
    selectedMobileAppIds: [],
    selectedPriorities: [],
    selectedReleaseTypes: [],
    selectedSeverities: [],
    selectedStatuses: {
      dateRange: { type: 'ANY_TIME' },
      statuses: [],
    },
    selectedStoreBlockers: [],
    sortBy: '',
  },
  open: false,
  savedFilters: {
    1519929559762: {
      name: 'The most critical flaws that didn’t make it into production',
      value: {
        description: '',
        id: '1519929559762',
        isDisabledLoading: false,
        isOwner: false,
        isPrivate: true,
        minAge: '',
        selectedCompliancePolicies: [],
        selectedCurrentStatuses: [],
        selectedKeywords: '',
        selectedMobileAppIds: [],
        selectedPriorities: ['P1'],
        selectedReleaseTypes: ['PRE_PROD'],
        selectedSeverities: [],
        selectedStatuses: {
          dateRange: { type: 'ANY_TIME' },
          statuses: [],
        },
        selectedStoreBlockers: [],
        sortBy: '',
      },
    },
    1520282902332: {
      name: 'P1 Issues/App & Play Store Blockers over "Last 90" days',
      value: {
        description: '',
        id: '1520282902332',
        isDisabledLoading: true,
        isOwner: false,
        isPrivate: true,
        minAge: '',
        selectedCompliancePolicies: [],
        selectedCurrentStatuses: [],
        selectedKeywords: '',
        selectedMobileAppIds: [],
        selectedPriorities: ['P1'],
        selectedReleaseTypes: [],
        selectedSeverities: [],
        selectedStatuses: {
          dateRange: {
            type: 'LAST_90_DAYS',
          },
          statuses: [],
        },
        selectedStoreBlockers: [],
        sortBy: '',
      },
    },
    1520282960256: {
      name: 'Closed vulnerabilities over "Last 90" days',
      value: {
        description: '',
        id: '1520282960256',
        isDisabledLoading: true,
        isOwner: false,
        isPrivate: true,
        minAge: '',
        selectedCompliancePolicies: [],
        selectedCurrentStatuses: [],
        selectedKeywords: '',
        selectedMobileAppIds: [],
        selectedPriorities: [],
        selectedReleaseTypes: [],
        selectedSeverities: [],
        selectedStatuses: {
          dateRange: {
            type: 'LAST_90_DAYS',
          },
          statuses: ['CLOSED_FIXED', 'CLOSED_ITEM_NOT_FOUND'],
        },
        selectedStoreBlockers: [],
        sortBy: '',
      },
    },
    1520283108612: {
      name: 'Vulnerabilities exceeding 90-day aging over "Last 90" days',
      value: {
        description: '',
        id: '1520283108612',
        isDisabledLoading: true,
        isOwner: false,
        isPrivate: true,
        minAge: 90,
        selectedCompliancePolicies: [],
        selectedCurrentStatuses: ['NEW', 'OPEN'],
        selectedKeywords: '',
        selectedMobileAppIds: [],
        selectedPriorities: [],
        selectedReleaseTypes: [],
        selectedSeverities: [],
        selectedStatuses: {
          dateRange: {
            type: 'LAST_90_DAYS',
          },
          statuses: [],
        },
        selectedStoreBlockers: [],
        sortBy: '',
      },
    },
    1520283382146: {
      name: 'Top *New* vulnerabilities found over "Last 90" days',
      value: {
        description: '',
        id: '1520283382146',
        isDisabledLoading: true,
        isOwner: false,
        isPrivate: true,
        minAge: '',
        selectedCompliancePolicies: [],
        selectedCurrentStatuses: [],
        selectedKeywords: '',
        selectedMobileAppIds: [],
        selectedPriorities: [],
        selectedReleaseTypes: [],
        selectedSeverities: [],
        selectedStatuses: {
          dateRange: {
            type: 'LAST_90_DAYS',
          },
          statuses: ['NEW'],
        },
        selectedStoreBlockers: [],
        sortBy: '',
      },
    },
    1534050811968: {
      name: 'P1 Issues Marked as Risk Accepted/CC',
      value: {
        description: '',
        id: '1534050811968',
        isDisabledLoading: false,
        isOwner: false,
        isPrivate: true,
        minAge: '',
        selectedCompliancePolicies: [],
        selectedCurrentStatuses: ['CLOSED_RISK_ACCEPTED', 'CLOSED_COMPENSATING_CONTROL'],
        selectedKeywords: '',
        selectedMobileAppIds: [],
        selectedPriorities: ['P1'],
        selectedReleaseTypes: [],
        selectedSeverities: [],
        selectedStatuses: {
          dateRange: {
            type: 'ANY_TIME',
          },
          statuses: [],
        },
        selectedStoreBlockers: [],
        sortBy: '',
      },
    },
    1534050910267: {
      name: 'Open Regulatory Compliance Issues',
      value: {
        description: '',
        id: '1534050910267',
        isDisabledLoading: false,
        isOwner: false,
        isPrivate: true,
        minAge: '',
        selectedCompliancePolicies: Object.keys(CompliancePolicyEnumValues),
        selectedCurrentStatuses: ['OPEN', 'NEW'],
        selectedKeywords: '',
        selectedMobileAppIds: [],
        selectedPriorities: [],
        selectedReleaseTypes: [],
        selectedSeverities: [],
        selectedStatuses: {
          dateRange: {
            type: 'ANY_TIME',
          },
          statuses: [],
        },
        selectedStoreBlockers: [],
        sortBy: '',
      },
    },
    1534050948893: {
      name: 'Regulatory Compliance Issues Marked as Risk Accepted/CC',
      value: {
        description: '',
        id: '1534050948893',
        isDisabledLoading: false,
        isOwner: false,
        isPrivate: true,
        minAge: '',
        selectedCompliancePolicies: Object.keys(CompliancePolicyEnumValues),
        selectedCurrentStatuses: ['CLOSED_RISK_ACCEPTED', 'CLOSED_COMPENSATING_CONTROL'],
        selectedKeywords: '',
        selectedMobileAppIds: [],
        selectedPriorities: [],
        selectedReleaseTypes: [],
        selectedSeverities: [],
        selectedStatuses: {
          dateRange: {
            type: 'ANY_TIME',
          },
          statuses: [],
        },
        selectedStoreBlockers: [],
        sortBy: '',
      },
    },
    1534050984757: {
      name: 'Total Closed Issues',
      value: {
        description: '',
        id: '1534050984757',
        isDisabledLoading: false,
        isOwner: false,
        isPrivate: true,
        minAge: '',
        selectedCompliancePolicies: [],
        selectedCurrentStatuses: ['CLOSED_FIXED', 'CLOSED_ITEM_NOT_FOUND'],
        selectedKeywords: '',
        selectedMobileAppIds: [],
        selectedPriorities: [],
        selectedReleaseTypes: [],
        selectedSeverities: [],
        selectedStatuses: {
          dateRange: {
            type: 'ANY_TIME',
          },
          statuses: [],
        },
        selectedStoreBlockers: [],
        sortBy: '',
      },
    },
  },
};

const statusPath = toPath(['findingsCriteria', 'selectedStatuses']);

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionEnum.SET_KEYWORDS_FILTER:
      return assoc(['findingsCriteria', 'selectedKeywords'], action.payload, state);
    case ActionEnum.SET_MOBILE_APP_IDS_FILTER:
      return assoc(['findingsCriteria', 'selectedMobileAppIds'], action.payload, state);
    case ActionEnum.SET_PRIORITY_FILTER:
      return assoc(['findingsCriteria', 'selectedPriorities'], action.payload, state);
    case ActionEnum.SET_STORE_BLOCKERS_FILTER:
      return assoc(['findingsCriteria', 'selectedStoreBlockers'], action.payload, state);
    case ActionEnum.SET_SEVERITY_FILTER:
      return assoc(['findingsCriteria', 'selectedSeverities'], action.payload, state);
    case ActionEnum.SET_COMPLIANCE_POLICY_FILTER:
      return assoc(['findingsCriteria', 'selectedCompliancePolicies'], action.payload, state);
    case ActionEnum.SET_STATUS_FILTER:
      return assoc([...statusPath, 'statuses'], action.payload, state);
    case ActionEnum.SET_DATE_FILTER:
      return action.payload === 'CUSTOM'
        ? assoc([...statusPath, 'dateRange'], getCustomRange(), state)
        : assoc([...statusPath, 'dateRange', 'type'], action.payload, state);
    case ActionEnum.SET_DATE_FROM_FILTER:
      return assoc([...statusPath, 'dateRange', 'from'], action.payload, state);
    case ActionEnum.SET_DATE_TO_FILTER:
      return assoc([...statusPath, 'dateRange', 'to'], action.payload, state);
    case ActionEnum.SET_CURRENT_STATUS_FILTER:
      return assoc(['findingsCriteria', 'selectedCurrentStatuses'], action.payload, state);
    case ActionEnum.SET_RELEASE_TYPE_FILTER:
      return assoc(['findingsCriteria', 'selectedReleaseTypes'], action.payload, state);
    case ActionEnum.SET_MIN_AGE_FILTER:
      return assoc(['findingsCriteria', 'minAge'], action.payload, state);
    case ActionEnum.SET_FINDINGS_SORT:
      return assoc(['findingsCriteria', 'sortBy'], action.payload, state);
    case ActionEnum.TOGGLE_SHOWING:
      return assoc(['showing'], action.payload, state);
    case ActionEnum.ADD_FILTER:
      return state;
    case ActionEnum.ADDED_FILTER:
      if (!action.payload.value.id) {
        return state;
      }

      return assoc(['savedFilters', action.payload.value.id], action.payload, state);
    case ActionEnum.REMOVE_FILTER:
      return state;
    case ActionEnum.REMOVED_FILTER:
      return dissoc(['savedFilters', action.payload], state);
    case ActionEnum.SET_AS_CURRENT_FILTER:
      return assoc(['findingsCriteria'], action.payload, state);
    case ActionEnum.RESET_FILTER:
      return assoc(['findingsCriteria'], initialState.findingsCriteria, state);
    case ActionEnum.FILTERS_LOADED_FROM_SERVER:
      return assoc(['savedFilters'], { ...action.payload, ...state.savedFilters }, state);
    case ActionEnum.OPEN_BUTTON_CLICKED:
      return assoc(['open'], !state.open, state);
    case ActionEnum.REQUEST_FILTERS_FROM_SERVER:
      return state;
    case ActionEnum.SET_GLOBAL_FILTER:
      return flow(assoc(['savedFilters', action.payload.id, 'value', 'isPrivate'], action.payload.value))(state);

    default:
      action;
      return state;
  }
};

export default reducer;
