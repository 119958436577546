import { handleActions } from 'redux-actions';
import { createReducerCreator, updateInList } from 'rezz';
import {
  commentDelete,
  commentDeleteError,
  commentDeleteSuccess,
  commentInsert,
  commentInsertError,
  commentInsertSuccess,
  commentsReceived,
} from './actions';

export const initialState = {
  deleteErrors: [],
  deleteLoadings: [],
  deleteSuccess: [],
  insertError: null,
  insertSuccess: false,
  list: [],
};

const replaceWithId = (
  state,

  id,
  value,
) => {
  return [
    ...state.filter(l => l.id !== id),
    {
      id,
      value,
    },
  ];
};

export default createReducerCreator({
  equalityCheck: (a, b) => a.id === b.id,
  initialState: initialState,
})(
  handleActions(
    {
      [commentInsert.toString()]: state => ({
        ...state,
        insertError: null,
        insertSuccess: false,
      }),
      [commentInsertSuccess.toString()]: state => ({
        ...state,
        insertError: null,
        insertSuccess: true,
      }),
      [commentInsertError.toString()]: (state, action) => ({
        ...state,
        insertError: action.payload,
        insertSuccess: false,
      }),
      [commentDelete.toString()]: (state, action) => ({
        ...state,
        deleteErrors: replaceWithId(state.deleteErrors, action.payload.commentId, null),
        deleteLoadings: replaceWithId(state.deleteLoadings, action.payload.commentId, true),
        deleteSuccess: replaceWithId(state.deleteSuccess, action.payload.commentId, false),
      }),
      [commentDeleteSuccess.toString()]: (state, action) => ({
        ...state,
        deleteErrors: replaceWithId(state.deleteErrors, action.payload.commentId, null),
        deleteLoadings: replaceWithId(state.deleteLoadings, action.payload.commentId, false),
        deleteSuccess: replaceWithId(state.deleteSuccess, action.payload.commentId, true),
      }),
      [commentDeleteError.toString()]: (state, action) => ({
        ...state,
        deleteErrors: replaceWithId(state.deleteErrors, action.payload.commentId, action.payload.error),
        deleteLoadings: replaceWithId(state.deleteLoadings, action.payload.commentId, false),
        deleteSuccess: replaceWithId(state.deleteSuccess, action.payload.commentId, false),
      }),
    },
    initialState,
  ),
  (state = initialState, action, rezzOptions) => {
    return action.type === commentsReceived.toString()
      ? {
          ...state,
          list: updateInList(commentsReceived.toString())(
            state.list,
            action,
            // reez typings proxies reducers types,
            rezzOptions,
          ),
        }
      : state;
  },
);
