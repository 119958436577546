import React, { memo } from 'react';

const Log4ShellDiagram = props => {
  return (
    <svg fill="none" viewBox="0 0 1600 500" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g>
        <path d="M0 0h1500v474H0z" fill="#fff" />
        <path d="M0 0h1061v700H0z" fill="#F5F7FA" />
        <text fill="#616E7C" fontFamily="Rubik" fontSize="13" letterSpacing="0">
          <tspan x="525" y="392.45">
            log4shell.scans.securetheorem.com:80
          </tspan>
        </text>
        <rect fill="#fff" height="63" rx="7.5" width="230.97" x="531.5" y="144.5" />
        <path
          d="M582.97 187.81a8.19 8.19 0 0 1-8.18 8.19h-23.6a8.19 8.19 0 0 1-8.19-8.19v-23.6a8.2 8.2 0 0 1 8.19-8.21h23.6a8.2 8.2 0 0 1 8.18 8.21v23.6Z"
          fill="#123770"
        />
        <path
          d="M571.17 172.9v5.14c0 1.4.5 2.63 1.51 3.64a5 5 0 0 0 3.64 1.48v3.85c-2.5 0-4.6-.86-6.37-2.63a8.63 8.63 0 0 1-2.62-6.34v-14.11h3.84v5.12h5.15v3.84h-5.15ZM559.43 181.65a4.87 4.87 0 0 0 1.5-3.61c0-1.43-.49-2.63-1.5-3.64a4.96 4.96 0 0 0-3.62-1.5c-1.43 0-2.62.51-3.63 1.5a5.02 5.02 0 0 0-1.51 3.64c0 1.4.52 2.63 1.5 3.61a4.92 4.92 0 0 0 3.64 1.51c1.4 0 2.63-.5 3.62-1.5Zm5.35-3.61c0 2.5-.86 4.6-2.63 6.34a8.57 8.57 0 0 1-6.34 2.63c-2.5 0-4.6-.86-6.36-2.63a8.63 8.63 0 0 1-2.63-6.34c0-2.5.88-4.6 2.63-6.37a8.73 8.73 0 0 1 6.36-2.62c1.93 0 3.64.54 5.12 1.58v-6.7h3.85v14.11Z"
          fill="#fff"
        />
        <text fill="#3E4C59" fontFamily="Rubik" fontSize="16" letterSpacing="0em">
          <tspan x="594.97" y="181.48">
            API Secure Backend
          </tspan>
        </text>
        <rect height="63" rx="7.5" stroke="#616E7C" width="230.97" x="531.5" y="144.5" />
        <rect fill="#fff" height="63" rx="7.5" width="235.97" x="525.5" y="300.5" />
        <path
          d="M576.97 343.81a8.19 8.19 0 0 1-8.18 8.19h-23.6a8.19 8.19 0 0 1-8.19-8.19v-23.6a8.2 8.2 0 0 1 8.19-8.21h23.6a8.2 8.2 0 0 1 8.18 8.21v23.6Z"
          fill="#123770"
        />
        <path
          d="M565.17 328.9v5.14c0 1.4.5 2.63 1.51 3.64a5 5 0 0 0 3.64 1.48v3.85c-2.5 0-4.6-.86-6.37-2.63a8.63 8.63 0 0 1-2.62-6.34v-14.11h3.84v5.12h5.15v3.84h-5.15ZM553.43 337.65a4.87 4.87 0 0 0 1.5-3.61c0-1.43-.49-2.63-1.5-3.64a4.96 4.96 0 0 0-3.62-1.5c-1.43 0-2.62.51-3.63 1.5a5.02 5.02 0 0 0-1.51 3.64c0 1.4.52 2.63 1.5 3.61a4.92 4.92 0 0 0 3.64 1.51c1.4 0 2.63-.5 3.62-1.5Zm5.35-3.61c0 2.5-.86 4.6-2.63 6.34a8.57 8.57 0 0 1-6.34 2.63c-2.5 0-4.6-.86-6.36-2.63a8.63 8.63 0 0 1-2.63-6.34c0-2.5.88-4.6 2.63-6.37a8.73 8.73 0 0 1 6.36-2.62c1.93 0 3.64.54 5.12 1.58v-6.7h3.85v14.11Z"
          fill="#fff"
        />
        <text fill="#3E4C59" fontFamily="Rubik" fontSize="16" letterSpacing="0em">
          <tspan x="588.97" y="337.48">
            Custom LDAP Server
          </tspan>
        </text>
        <rect height="63" rx="7.5" stroke="#616E7C" width="235.97" x="525.5" y="300.5" />
        <rect fill="#fff" height="147" rx="7.5" width="200" x="1196.5" y="94.5" />
        <path
          clipRule="evenodd"
          d="M1272.5 114h48c2.93 0 5.33 2.4 5.33 5.33v32c0 2.94-2.4 5.34-5.33 5.34h-13.33V162h-21.34v-5.33h-13.33a5.35 5.35 0 0 1-5.33-5.34v-32c0-2.93 2.4-5.33 5.33-5.33Zm0 37.33h48v-32h-48v32Zm42.67-24h-29.34v5.34h29.34v-5.34ZM1285.83 138h29.34v5.33h-29.34V138Zm-2.66-10.67h-5.34v5.34h5.34v-5.34Zm-5.34 10.67h5.34v5.33h-5.34V138Z"
          fill="#3E4C59"
          fillRule="evenodd"
        />
        <text fill="#3E4C59" fontFamily="Rubik" fontSize="16" letterSpacing="0em">
          <tspan x="1217" y="202.48">
            Assets monitored by
          </tspan>
          <tspan x="1208.25" y="224.48">
            API/Web/Cloud Secure
          </tspan>
        </text>
        <rect height="147" rx="7.5" stroke="#616E7C" width="200" x="1196.5" y="94.5" />
        <path
          d="M1302 335v1.5h1.5V335h-1.5Zm-532.06-1.06a1.5 1.5 0 0 0 0 2.12l9.54 9.55a1.5 1.5 0 1 0 2.13-2.13l-8.49-8.48 8.49-8.49a1.5 1.5 0 0 0-2.13-2.12l-9.54 9.55ZM1300.5 262v73h3v-73h-3Zm1.5 71.5H771v3h531v-3Z"
          fill="#000"
        />
        <text fill="#356DAF" fontFamily="Rubik" fontSize="32" letterSpacing="1">
          <tspan x="795" y="60.96">
            1
          </tspan>
        </text>
        <text fill="#3E4C59" fontFamily="Rubik" fontSize="14" letterSpacing="0">
          <tspan x="830" y="44.8">
            Send HTTP requests to:
          </tspan>
          <tspan x="851" y="64.8">
            Web Servers
          </tspan>
          <tspan x="851" y="84.8">
            RESTful APIs
          </tspan>
          <tspan x="851" y="104.8">
            GraphQL APIs
          </tspan>
          <tspan x="830" y="124.8">
            with a JNDI payload that contains
          </tspan>
          <tspan x="830" y="144.79">
            a unique marker for each asset
          </tspan>
        </text>
        <text fill="#356DAF" fontFamily="Rubik" fontSize="32" letterSpacing="1">
          <tspan x="1125" y="390.96">
            2
          </tspan>
        </text>
        <text fill="#981B1E" fontFamily="Rubik" fontSize="14" letterSpacing="0em">
          <tspan x="1159" y="375.8">
            If the asset is vulnerable,
          </tspan>
        </text>
        <text fill="#3E4C59" fontFamily="Rubik" fontSize="14" letterSpacing="0">
          <tspan x="1159" y="398.8">
            it will perform the JNDI lookup and make
          </tspan>
          <tspan x="1159" y="418.8">
            a request to our LDAP server that just logs
          </tspan>
          <tspan x="1159" y="438.8">
            the request and returns an error code
          </tspan>
        </text>
        <text fill="#356DAF" fontFamily="Rubik" fontSize="32" letterSpacing="1">
          <tspan x="63" y="254.96">
            3
          </tspan>
        </text>
        <text fill="#3E4C59" fontFamily="Rubik" fontSize="14" letterSpacing="0">
          <tspan x="100" y="238.79">
            API Secure opens a policy{' '}
          </tspan>
          <tspan x="100" y="260.8">
            violation for{' '}
          </tspan>
          <tspan x="247.59" y="260.8">
            {' '}
            on{' '}
          </tspan>
          <tspan x="100" y="280.8">
            the affected asset
          </tspan>
        </text>
        <text fill="#356DAF" fontFamily="Rubik" fontSize="14" letterSpacing="0em">
          <tspan x="180.15" y="260.8">
            Log4Shell
          </tspan>
        </text>
        <path
          d="M1183.06 175.06a1.5 1.5 0 0 0 0-2.12l-9.55-9.55a1.5 1.5 0 0 0-2.12 2.13l8.49 8.48-8.49 8.49a1.5 1.5 0 0 0 2.12 2.12l9.55-9.55ZM773 175.5h409v-3H773v3ZM295 339h-1.5v1.5h1.5V339Zm0-165v-1.5h-1.5v1.5h1.5Zm221.06 1.06a1.5 1.5 0 0 0 0-2.12l-9.54-9.55a1.5 1.5 0 0 0-2.13 2.13l8.49 8.48-8.49 8.49a1.5 1.5 0 0 0 2.13 2.12l9.54-9.55ZM515 337.5H295v3h220v-3ZM296.5 339V174h-3v165h3ZM295 175.5h220v-3H295v3Z"
          fill="#000"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path d="M0 0h1500v474H0z" fill="#fff" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default memo(Log4ShellDiagram);
