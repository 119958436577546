import tracking, { dataCreators } from '@dt/analytics';
import { Actions as NotificationsActions } from '@dt/notifications';
import { withProgressIndicator } from '@dt/progress-indicator';
import { callPromise, callSaga } from '@dt/redux-saga-wrapped-effects';
import { api_keys } from '@dt/user-api/api_keys';
import { tryAndParseErrorMessage } from '@dt/user-api/util';
import { call, put, spawn, takeEvery } from 'redux-saga/effects';
import {
  ResultsApiAccessPageClicked,
  UploadApiAccessPageClicked,
  apiKeyClicked,
  apiKeyCreateFormSubmitted,
  apiKeyCreateResponseReceived,
  apiKeyDeleteButtonClicked,
  apiKeyDeleteResponseReceived,
  apiKeyEditFormSubmitted,
  apiKeyEditResponseReceived,
  apiKeyErrorReceived,
  apiKeyPageMounted,
  apiKeysReceived,
  createAPIKeyButtonClicked,
} from '../actions';
import { setString } from '../reducers/strings';
import { browserHistory } from '../util/history';
import { API_KEYS_ERROR_STRING_KEY, API_KEYS_TYPE, UPLOAD_API_STRING_KEY } from '../util/strings';
import paginate, { paginateToEnd } from './util/paginate';

const apiKeysPageGetter = function* (params) {
  return yield* callPromise(api_keys.list, params || { cursor: '' });
};
function* getAPIKeys() {
  /* get the api keys and dispatch new action for APIKeysReceived */
  try {
    const results = yield* callSaga(paginate, API_KEYS_TYPE, {}, apiKeysPageGetter);

    if (results) {
      yield put(apiKeysReceived(results.api_keys || []));
      yield put(setString(UPLOAD_API_STRING_KEY, results.upload_api_key || ''));
      return results.api_keys;
    } else {
      yield put(setString(API_KEYS_ERROR_STRING_KEY, 'There was a problem fetching the API keys.'));
    }
  } catch (err) {
    yield put(
      setString(API_KEYS_ERROR_STRING_KEY, tryAndParseErrorMessage(err, 'There was a problem fetching the API keys.')),
    );
  }
}

function* loadAllAPIKeys() {
  return yield* withProgressIndicator(function* () {
    yield* callSaga(paginateToEnd, getAPIKeys, API_KEYS_TYPE, {}, {});
  }, API_KEYS_TYPE);
}

function* apiKeyUpdateSaga(action) {
  const {
    id,
    name,
    results_api,
    management_api,
    brand_protect_public_api,
    api_inspect_public_api,
    features_specific_api,
  } = action.payload;

  const params = {
    api_inspect_public_api,
    brand_protect_public_api,
    features_specific_api: { access: features_specific_api.access, features: features_specific_api.features },
    management_api,
    name,
    results_api,
  };
  try {
    const updatedAPIKey = yield* callPromise(api_keys.patch, id, params);
    if (updatedAPIKey) {
      yield put(apiKeysReceived([updatedAPIKey]));
      yield put(apiKeyEditResponseReceived(updatedAPIKey));
      yield put(
        NotificationsActions.requestNotifyUser({
          text: `${updatedAPIKey.name} has been updated.`,
        }),
      );
      yield call(tracking, dataCreators.apiKeyEdited());
    } else {
      yield put(
        apiKeyErrorReceived({
          _error: 'A problem occurred when trying to edit this api key',
        }),
      );
    }
  } catch (err) {
    yield put(
      apiKeyErrorReceived({
        _error: tryAndParseErrorMessage(err, 'A problem occurred'),
      }),
    );
  }
}

function* apiKeyDeleteSaga(action) {
  const { id, name } = action.payload.apiKey;
  try {
    yield* callPromise(api_keys.delete, id);
    yield put(apiKeyDeleteResponseReceived(action.payload.apiKey));
    yield put(
      NotificationsActions.requestNotifyUser({
        text: `${name} has been removed.`,
      }),
    );
    yield call(tracking, dataCreators.apiKeyDeleted());
    action.payload.navigate(`/devsecops/v2/results_api_access`);
  } catch (err) {
    yield put(apiKeyErrorReceived({ _error: tryAndParseErrorMessage(err) }));
    yield put(
      NotificationsActions.requestNotifyUser({
        text: `Could not remove ${name}`,
      }),
    );
  }
}

function* apiKeyCreateSaga(action) {
  try {
    const apiKey = yield* callPromise(api_keys.create, action.payload.apiKey);
    if (apiKey) {
      yield put(apiKeysReceived([apiKey]));
      yield put(apiKeyCreateResponseReceived(apiKey));
      yield put(
        NotificationsActions.requestNotifyUser({
          text: `${apiKey.name} has been created`,
        }),
      );
      yield call(tracking, dataCreators.apiKeyCreated());
      action.payload.navigate(`/devsecops/v2/api_keys/${apiKey.id}`);
    } else {
      yield put(
        NotificationsActions.requestNotifyUser({
          text: 'A problem occurred when trying to create the API Key',
        }),
      );
    }
  } catch (err) {
    yield put(apiKeyErrorReceived({ _error: tryAndParseErrorMessage(err) }));
    yield put(
      NotificationsActions.requestNotifyUser({
        text: 'Could not create API Key',
      }),
    );
  }
}

function* watchForAPIKeyUpdates() {
  yield takeEvery(apiKeyEditFormSubmitted.toString(), apiKeyUpdateSaga);
}

function* watchForAPIKeyDelete() {
  yield takeEvery(apiKeyDeleteButtonClicked.toString(), apiKeyDeleteSaga);
}

function* apiKeyClickedSaga(action) {
  const apiKey = action.payload;
  window.location.assign(`/devsecops/v2/api_keys/${apiKey.id === '0' ? 'upload-api-key' : apiKey.id}`);
  yield call(browserHistory.push, `/api_keys/${apiKey.id === '0' ? 'upload-api-key' : apiKey.id}`);
}

function* watchForAPIKeyClicked() {
  yield takeEvery(apiKeyClicked.toString(), apiKeyClickedSaga);
}

function* createAPIKeyButtonClickedSaga() {
  window.location.assign('/devsecops/v2/api_keys/create');

  yield* withProgressIndicator(function* () {});
}

function* watchForCreateAPIKeyButtonClicked() {
  yield takeEvery(createAPIKeyButtonClicked.toString(), createAPIKeyButtonClickedSaga);
}

function* watchForAPIKeyCreate() {
  yield takeEvery(apiKeyCreateFormSubmitted.toString(), apiKeyCreateSaga);
}

function* watchForUploadAPIKeyPageClicked() {
  yield takeEvery(UploadApiAccessPageClicked.toString(), function* () {
    window.location.assign('/devsecops/v2/api_access');
    yield* withProgressIndicator(function* () {});
  });
}

function* watchForResultsAPIKeyPageClicked() {
  yield takeEvery(ResultsApiAccessPageClicked.toString(), function* () {
    window.location.assign('/devsecops/v2/results_api_access');
    yield* withProgressIndicator(function* () {});
  });
}

function* watchForAPIKeyPageMounted() {
  yield takeEvery(apiKeyPageMounted.toString(), loadAllAPIKeys);
}

export default function* apiKeysFlow() {
  yield spawn(watchForResultsAPIKeyPageClicked);
  yield spawn(watchForUploadAPIKeyPageClicked);
  yield spawn(watchForAPIKeyClicked);
  yield spawn(watchForCreateAPIKeyButtonClicked);
  yield spawn(watchForAPIKeyPageMounted);
  yield spawn(watchForAPIKeyUpdates);
  yield spawn(watchForAPIKeyDelete);
  yield spawn(watchForAPIKeyCreate);
}
