const category = {
  BOOKS: 'BOOKS',
  BUSINESS: 'BUSINESS',
  CATALOGS: 'CATALOGS',
  CUSTOMIZATIONS: 'CUSTOMIZATIONS',
  EDUCATION: 'EDUCATION',
  ENTERTAINMENT: 'ENTERTAINMENT',
  FINANCE: 'FINANCE',
  FOOD_AND_DRINK: 'FOOD_AND_DRINK',
  GAMES: 'GAMES',
  HEALTH_AND_FITNESS: 'HEALTH_AND_FITNESS',
  KIDS: 'KIDS',
  LIBRARIES_AND_DEMOS: 'LIBRARIES_AND_DEMOS',
  LIFESTYLE: 'LIFESTYLE',
  MEDICAL: 'MEDICAL',
  MUSIC: 'MUSIC',
  NAVIGATION: 'NAVIGATION',
  NEWS: 'NEWS',
  PHOTO_AND_VIDEO: 'PHOTO_AND_VIDEO',
  PRODUCTIVITY: 'PRODUCTIVITY',
  REFERENCE: 'REFERENCE',
  SHOPPING: 'SHOPPING',
  SOCIAL_NETWORKING: 'SOCIAL_NETWORKING',
  SPORTS: 'SPORTS',
  SYSTEM: 'SYSTEM',
  TRAVEL: 'TRAVEL',
  UNKNOWN: 'UNKNOWN',
  UTILITIES: 'UTILITIES',
  WEATHER: 'WEATHER',
};

export default category;
