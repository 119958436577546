import React from 'react';
import { Grid, Typography, Box, useTheme } from '@mui/material';
import { Image } from '@dt/components';
import { PolicyViolationStatusEnumValues } from '@dt/graphql-support/enums';
import { ExtLink, PolicyViolationLabel, RelevanceTag } from '@dt/material-components';
import { palette } from '@dt/theme';
import { dateFormats } from '../../util/dateFormats';
import { formatDateDefault } from '../../util/formatDateDefault';

/*
 * Provides the user metdata information for a policy violation.
 *
 * @param policy_violation - Policy violation to render.
 * @param policy_rule - Policy rule to render.
 */
const PolicyViolationsMetadataBarComponent = function PolicyViolationsMetadataBar({ policy_violation, policy_rule }) {
  const jiraIntegration = policy_violation.external_integrations?.find(integ => integ?.integration_type === 'JIRA');
  const { palette } = useTheme();
  return (
    <Grid alignItems="center" container direction="row" spacing={1}>
      <Grid item lg={4} xl={3} xs={6}>
        <Typography>Violation ID: {policy_violation.id.substr(0, 8)}</Typography>
      </Grid>

      <Grid item lg={4} xl={3} xs={6}>
        <Typography>
          {'Discovered: '}
          {formatDateDefault({
            date: policy_violation.date_created || new Date(),
            formatStr: dateFormats.MMMddyyyy,
          })}{' '}
        </Typography>
      </Grid>

      <Grid item lg={4} xl={3} xs={6}>
        <Box alignItems="center" display="flex">
          <Typography component="div" style={{ marginRight: 8 }}>
            Severity:
          </Typography>
          <RelevanceTag relevance={policy_rule.relevance} size="small" />
        </Box>
      </Grid>

      <Grid item lg={4} xl={3} xs={6}>
        <Box alignItems="center" display="flex">
          <Typography component="div" style={{ marginRight: 8 }}>
            Status:
          </Typography>
          <PolicyViolationLabel status={policy_violation.status} />
        </Box>
      </Grid>

      {policy_violation.status === PolicyViolationStatusEnumValues.RESOLVED && (
        <Grid item lg={4} xl={3} xs={6}>
          <Box alignItems="center" display="flex">
            <Typography component="div" style={{ marginRight: 8 }}>
              {'Resolved: '}
              {formatDateDefault({
                date: policy_violation.date_resolved || new Date(),
                formatStr: dateFormats.MMMddyyyy,
              })}
            </Typography>
          </Box>
        </Grid>
      )}

      {policy_violation.status === PolicyViolationStatusEnumValues.OPEN &&
        !policy_violation.exception_date_created &&
        policy_violation.date_last_checked && (
          <Grid item lg={4} xl={3} xs={6}>
            <Box alignItems="center" display="flex">
              <Typography component="div" style={{ marginRight: 8 }}>
                {'Last Checked: '}
                {formatDateDefault({ date: policy_violation.date_last_checked, formatStr: dateFormats.MMMddyyyy })}
              </Typography>
            </Box>
          </Grid>
        )}

      {policy_violation.status === PolicyViolationStatusEnumValues.WONT_FIX &&
        policy_violation.exception_date_created && (
          <Grid item lg={4} xl={3} xs={6}>
            <Box alignItems="center" display="flex">
              <Typography component="div" style={{ marginRight: 8 }}>
                {'Closed: '}
                {formatDateDefault({
                  date: policy_violation.exception_date_created || new Date(),
                  formatStr: dateFormats.MMMddyyyy,
                })}
              </Typography>
            </Box>
          </Grid>
        )}

      {jiraIntegration && (
        <Grid item lg={4} xl={3} xs={6}>
          <Box alignItems="center" display="flex">
            <Box mr={1}>
              <Image
                alt={`integration-${jiraIntegration.id}`}
                src={jiraIntegration.integration_icon_url}
                style={{ height: 24 }}
              />
            </Box>
            <Typography component="div" style={{ marginRight: 8 }}>
              Jira Ticket ID:
            </Typography>
            <ExtLink style={{ color: palette.blue[20] }} to={jiraIntegration.external_url}>
              {jiraIntegration.external_key}
            </ExtLink>
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export const PolicyViolationsMetadataBar = PolicyViolationsMetadataBarComponent;
