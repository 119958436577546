import React, { memo } from 'react';
import ErrorIcon from '@mui/icons-material/Error';
import { Card, CardContent, Grid } from '@mui/material';
import { palette } from '@dt/theme';
import Text from './Text';

const CardEmptyComponent = function CardEmpty({ message }) {
  return (
    <Card>
      <CardContent>
        <Grid alignItems="center" container spacing={2} wrap="nowrap">
          <Grid item>
            <ErrorIcon style={{ height: 50, width: 50 }} />
          </Grid>
          <Grid container direction="row" item spacing={2} wrap="nowrap">
            <Grid item>
              <Text color={palette.gray20} style={{ whiteSpace: 'pre-line' }} variant="body">
                {message}
              </Text>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export const CardEmpty = memo(CardEmptyComponent);
