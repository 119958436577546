import gql from 'graphql-tag';

const list = gql`
  query ScopedAccessTokenList {
    scoped_access_token_list {
      pagination_information {
        next_cursor
        total_count
      }
      scoped_access_tokens {
        access_granted_by
        app_secure_access_properties {
          can_access_all_apps
          can_access_app_ids
          can_access_scope
          can_access_app_secure_security_finding_id
        }
        app_search_access_properties {
          can_access_all_apps
          can_access_app_ids
          can_access_scope
        }
        access_token
        date_created
        date_expires
        date_updated
        id
      }
    }
  }
`;

const remove = gql`
  mutation ScopedAccessTokenDelete($scoped_access_token_id: ID!) {
    scoped_access_token_delete(scoped_access_token_id: $scoped_access_token_id) {
      nothing
    }
  }
`;

const create_for_finding_id = gql`
  mutation ScopedAccessTokenForFindingIdCreate($finding_id: String!, $body: ScopedAccessTokenParams!) {
    scoped_access_token_for_finding_id(finding_id: $finding_id, body: $body) {
      access_granted_by
      app_secure_access_properties {
        can_access_all_apps
        can_access_app_ids
        can_access_scope
        can_access_app_secure_security_finding_id
      }
      app_search_access_properties {
        can_access_all_apps
        can_access_app_ids
        can_access_scope
      }
      access_token
      date_created
      date_expires
      date_updated
      id
    }
  }
`;

const create_for_mobile_app_id = gql`
  mutation ScopedAccessTokenForMobileAppIdCreate($mobile_app_id: String!, $body: ScopedAccessTokenParams!) {
    scoped_access_token_for_mobile_app_id(mobile_app_id: $mobile_app_id, body: $body) {
      access_granted_by
      app_secure_access_properties {
        can_access_all_apps
        can_access_app_ids
        can_access_scope
        can_access_app_secure_security_finding_id
      }
      app_search_access_properties {
        can_access_all_apps
        can_access_app_ids
        can_access_scope
      }
      access_token
      date_created
      date_expires
      date_updated
      id
    }
  }
`;

const create_for_openscan_app_id = gql`
  mutation ScopedAccessTokenForOpenscanAppIdCreate($openscan_app_id: String!, $body: ScopedAccessTokenParams!) {
    scoped_access_token_for_openscan_app_id(mobile_app_id: $openscan_app_id, body: $body) {
      access_granted_by
      app_secure_access_properties {
        can_access_all_apps
        can_access_app_ids
        can_access_scope
        can_access_app_secure_security_finding_id
      }
      app_search_access_properties {
        can_access_all_apps
        can_access_app_ids
        can_access_scope
      }
      access_token
      date_created
      date_expires
      date_updated
      id
    }
  }
`;

const create_for_onboarding = gql`
  mutation ScopedAccessTokenForOnboardingCreate($body: ScopedAccessTokenOnboardingParams!) {
    scoped_access_token_for_onboarding(body: $body) {
      access_granted_by
      app_secure_access_properties {
        can_access_all_apps
        can_access_app_ids
        can_access_scope
        can_access_app_secure_security_finding_id
      }
      app_search_access_properties {
        can_access_all_apps
        can_access_app_ids
        can_access_scope
      }
      access_token
      date_created
      date_expires
      date_updated
      id
    }
  }
`;

export default {
  create_for_finding_id,
  create_for_mobile_app_id,
  create_for_onboarding,
  create_for_openscan_app_id,
  list,
  remove,
};
