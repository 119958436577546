import { assets_searches, cloud_resources } from '@dt/horizon-api';
import { paginationBeginRequest, paginationEndRequest } from '@dt/pagination';
import { callPromise } from '@dt/redux-saga-wrapped-effects';
import { call, put } from 'redux-saga/effects';
import { handleNormalizedResponse, withCache } from './../resource_fetch/sagas';
import resourceType from './resourceType';

export const getCloudResourcesList = withCache('cloud_resources_list', function* getCloudResources(params) {
  yield put(paginationBeginRequest(resourceType, params));

  const { search_id, ...urlParams } = params;
  const response = search_id
    ? yield* callPromise(assets_searches['cloud_resources'].list, search_id, urlParams)
    : yield* callPromise(cloud_resources.list, params);

  if (response._type === 'error') {
    throw new Error(response.title);
  }

  yield call(handleNormalizedResponse, response.body);

  if (response.body.pagination_information) {
    yield put(paginationEndRequest(resourceType, params, response.body.pagination_information));
  }
});

export const getCloudResourceDetails = withCache(
  cloudResourceId => `cloud_resource_${cloudResourceId}`,
  function* (cloudResourceId) {
    const response = yield* callPromise(cloud_resources.details, cloudResourceId);

    if (response._type === 'error') {
      if (typeof response.title === 'string') {
        throw new Error(response.title);
      }
      throw new Error(`'getCloudResourceDetails' error occured.`);
    }

    yield call(handleNormalizedResponse, response.body);
  },
);
