import CompliancePolicyEnum from '@dt/enums/CompliancePolicyEnum';
import owaspMstgImage from './images/OWASP-MSTG.png';
import caloppaImage from './images/caloppa.jpg';
import caprivacyImage from './images/caprivacy.png';
import coppaImage from './images/coppa.svg';
import ftcImage from './images/ftc.png';
import gdprImage from './images/gdpr.svg';
import hipaaImage from './images/hipaa.svg';
import owaspAsvsImage from './images/logo_owasp_asvs.svg';
import mitreImage from './images/mitre.png';
import owaspMstgImageSmall from './images/owasp-mstg-small.png';
import owaspImage from './images/owasp.png';
import pciImage from './images/pci.png';
import vppaImage from './images/vppa.svg';

export default {
  [CompliancePolicyEnum.CALOPPA]: {
    label: 'CalOPPA',
    largeHeight: 82,
    largeWidth: 82,
    smallHeight: 20,
    smallWidth: 20,
    src: caloppaImage,
    title: 'California Online Privacy Protection Act',
  },
  [CompliancePolicyEnum.GDPR]: {
    label: 'GDPR',
    largeHeight: 82,
    largeWidth: 82,
    smallHeight: 24,
    smallWidth: 24,
    src: gdprImage,
    title: 'General Data Protection Regulation',
  },
  [CompliancePolicyEnum.PCI]: {
    label: 'PCI',
    largeHeight: 82,
    largeWidth: 82,
    smallHeight: 20,
    smallWidth: 20,
    src: pciImage,
    title: 'Payment Card Industry',
  },
  [CompliancePolicyEnum.HIPAA]: {
    label: 'HIPAA',
    largeHeight: 82,
    largeWidth: 82,
    smallHeight: 20,
    smallWidth: 24,
    src: hipaaImage,
    title: 'Health Insurance Portability and Accountability Act',
  },
  [CompliancePolicyEnum.FTC]: {
    label: 'FTC',
    largeHeight: 82,
    largeWidth: 82,
    smallHeight: 20,
    smallWidth: 20,
    src: ftcImage,
    title: 'Federal Trade Commission',
  },
  [CompliancePolicyEnum.OWASP]: {
    label: 'OWASP',
    largeHeight: 82,
    largeWidth: 82,
    smallHeight: 20,
    smallWidth: 20,
    src: owaspImage,
    title: 'OWASP Mobile Security',
  },
  [CompliancePolicyEnum.OWASP_ASVS]: {
    label: 'OWASP-ASVS',
    largeHeight: 82,
    largeWidth: 82,
    smallHeight: 20,
    smallWidth: 20,
    src: owaspAsvsImage,
    title: 'OWASP ASVS: Application Security Verification Standard',
  },
  [CompliancePolicyEnum.COPPA]: {
    label: 'COPPA',
    largeHeight: 82,
    largeWidth: 82,
    smallHeight: 24,
    smallWidth: 24,
    src: coppaImage,
    title: "Children's Online Privacy Protection Act",
  },
  [CompliancePolicyEnum.OWASP_MSTG]: {
    label: 'OWASP-MSTG',
    largeHeight: 45,
    largeWidth: 120,
    smallHeight: 12,
    smallWidth: 35,
    src: owaspMstgImage,
    srcSmall: owaspMstgImageSmall,
    title: 'OWASP Mobile Security Testing Guide',
  },
  [CompliancePolicyEnum.VPPA]: {
    label: 'VPPA',
    largeHeight: 82,
    largeWidth: 82,
    smallHeight: 20,
    smallWidth: 24,
    src: vppaImage,
    title: 'Video Privacy Protection Act',
  },
  [CompliancePolicyEnum.CCPA]: {
    label: 'CCPA',
    largeHeight: 82,
    largeWidth: 82,
    smallHeight: 20,
    smallWidth: 20,
    src: caprivacyImage,
    title: 'California Consumer Privacy Act',
  },
  [CompliancePolicyEnum.MITRE]: {
    label: 'MITRE ATT&CK',
    largeHeight: 16,
    largeWidth: 82,
    smallHeight: 7,
    smallWidth: 36,
    src: mitreImage,
    title: 'MITRE ATT&CK',
  },
};
