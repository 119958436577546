import { select } from '@dt/redux-saga-wrapped-effects';
import { all, call } from 'redux-saga/effects';
import { getEventsList } from '../events/resource_fetch.sagas';
import { paginateWhile, takePatternAndFetch, watchForLoadMoreAndFetchNextPage } from '../resource_fetch/sagas';
import resourceType from './resourceType';
import { getDiscoveryEvents } from './selectors';

export default function* watchForResourceFetching() {
  yield all([
    call(takePatternAndFetch, 'mounted/events/dashboard', function* () {
      yield call(getEventsList, {});
    }),
    call(takePatternAndFetch, 'mounted/events/discovery', function* () {
      yield call(getDiscoveryEventsList);
    }),
    call(watchForLoadMoreAndFetchNextPage, resourceType, function* ({ cursor }) {
      yield call(getEventsList, { cursor });
    }),
  ]);
}

const getDiscoveryEventsList = function* () {
  yield call(paginateWhile, getEventsList, resourceType, {}, function* () {
    const events = yield* select(getDiscoveryEvents, {});
    return !events.length;
  });
};
