import React, { useContext, useEffect, useRef } from 'react';
import { useQuery } from '@apollo/client';
import Text from '@dt/ahura/src/components/Text';
import { shadow_assets_stats } from '@dt/graphql-support/horizon/ts/analytics';
import { formatDateDefault } from '@utils/formatDateDefault';
import useRefWidth from '../../../hooks/use_ref_width';
import { ShadowAssetsContext } from './ApiSecureShadowAssetsPage';
import ApiShadowAssetsPageChartDonut from './ApiShadowAssetsPageChartDonut';
const ApiShadowAssetsPageChartDonutContainer = () => {
    const divRef = useRef(null);
    const { width } = useRefWidth({ ref: divRef });
    const { isPolling, latestRefetchDate, filter_by_named_filter } = useContext(ShadowAssetsContext);
    const { data, loading: loadingQuery, error, refetch, } = useQuery(shadow_assets_stats, {
        variables: {
            filter_by_named_filter: filter_by_named_filter || null,
        },
    });
    const loading = loadingQuery || isPolling;
    useEffect(() => {
        refetch();
    }, [latestRefetchDate, refetch]);
    return (React.createElement("div", null,
        React.createElement(Text, { component: "div", style: { marginBottom: 8 }, variant: "titleXS" }, "Total shadow apps/APIs have been found in a variety of places:"),
        React.createElement(Text, { component: "div", style: { marginBottom: 24 }, variant: "helperText" }, `as of today import ${formatDateDefault({ date: new Date() })}`),
        React.createElement(ApiShadowAssetsPageChartDonut, { data: data, error: error, loading: loading, width: width })));
};
export default ApiShadowAssetsPageChartDonutContainer;
