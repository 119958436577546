import Cookies from 'js-cookie';
function useCookie(key) {
    const getCookie = () => {
        return Cookies.get(key);
    };
    const setCookie = (v, opts) => {
        Cookies.set(key, v, opts);
    };
    return [getCookie(), setCookie];
}
export default useCookie;
