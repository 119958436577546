import { createAction } from 'redux-actions';

export const commentsReceived = createAction('comments/received', comments => comments);

export const commentInsert = createAction('comment/insert', (policyViolationId, params) => ({
  params,
  policyViolationId,
}));

export const commentInsertError = createAction('comment/insert/error', error => error);

export const commentInsertSuccess = createAction('comment/insert/success', () => null);

export const commentDelete = createAction('comment/delete', (commentId, policyViolationId) => ({
  commentId,
  policyViolationId,
}));

export const commentDeleteError = createAction('comment/delete/error', (commentId, error) => ({
  commentId,
  error,
}));

export const commentDeleteSuccess = createAction('comment/delete/success', commentId => ({
  commentId,
}));
