import { select } from '@dt/redux-saga-wrapped-effects';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { assetGroupsMembershipReceived } from '../asset_groups/memberships/actions';
import { inventory_search } from '../inventory/search/selectors';
import { paginateToEndLazy, takePatternAndFetch, watchForLoadMoreAndFetchNextPage } from '../resource_fetch/sagas';
import {
  reachedEndOfWebApplicationsList,
  reachedEndOfWebApplicationsListForAssetSearchId,
  webApplicationDetailsMounted,
  webApplicationsReceived,
  webApplicationsReceivedIdsForAssetSearch,
} from './actions';
import resourceType from './resourceType';
import { getWebApplicationDetails, getWebApplicationsList } from './resource_fetch.sagas';

export default function* watchForResourceFetching() {
  yield all([
    call(takePatternAndFetch, webApplicationDetailsMounted.toString(), function* (action) {
      const webApplicationId = action.payload.webApplicationId;
      yield call(getWebApplicationDetails, webApplicationId);
    }),
    call(watchForLoadMoreAndFetchNextPage, resourceType, function* ({ cursor }) {
      yield call(getWebApplicationsList, { cursor });
    }),
    takeEvery(webApplicationsReceived.toString(), webApplicationsReceivedSaga),
  ]);
}

export function* getAllWebApplicationsLazily(params) {
  if (params.search_id) {
    yield call(
      paginateToEndLazy,
      getWebApplicationsList,
      resourceType,
      { ...params },
      action => reachedEndOfWebApplicationsListForAssetSearchId.toString() && params.search_id === action.payload,
    );
  } else {
    yield call(
      paginateToEndLazy,
      getWebApplicationsList,
      resourceType,
      { ...params },
      reachedEndOfWebApplicationsList.toString(),
    );
  }
}

function* webApplicationsReceivedSaga(action) {
  const { web_applications } = action.payload;
  const { lastQueryId, query } = yield* select(inventory_search, {});

  let assetGroupMembers = [];
  web_applications.map(item => {
    if (item.asset_group_memberships) {
      assetGroupMembers = assetGroupMembers.concat(item.asset_group_memberships);
    }
  });

  const collector = web_applications.reduce(
    (collector, item) => {
      if (item.asset_group_memberships) {
        collector.assetGroupMembers = collector.assetGroupMembers.concat(item.asset_group_memberships);
      }
      collector.idsForCurrentSearch.push(item.id);
      return collector;
    },
    {
      assetGroupMembers: [],
      idsForCurrentSearch: [],
    },
  );

  if (collector.assetGroupMembers) {
    yield put(assetGroupsMembershipReceived(collector.assetGroupMembers));
  }

  if (lastQueryId && (!query.asset_types.length || query.asset_types.includes('WEB_APPLICATION'))) {
    yield put(webApplicationsReceivedIdsForAssetSearch(lastQueryId, collector.idsForCurrentSearch));
  }
}
