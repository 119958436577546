import { Stack, Tooltip, Box } from '@mui/material';
import React from 'react';
import Text from '../Text';
const SastPageIssuesAssetDisplay = ({ assetTypeIconUrl, assetName, assetUrl }) => {
    return (React.createElement(Stack, { alignItems: "center", direction: "row", gap: "8px", overflow: "hidden" },
        React.createElement("img", { alt: "asset type icon", height: "24px", src: assetTypeIconUrl, width: "24px" }),
        React.createElement(Stack, { overflow: "hidden" },
            assetName && (React.createElement(Tooltip, { PopperProps: { sx: { zIndex: 9999 } }, title: assetName },
                React.createElement(Box, { overflow: "hidden", textOverflow: "ellipsis" },
                    React.createElement(Text, { component: "span", noWrap: true, style: { margin: 0 }, variant: "titleXS" }, assetName)))),
            assetUrl && (React.createElement(Tooltip, { PopperProps: { sx: { zIndex: 9999 } }, title: assetUrl },
                React.createElement(Box, { overflow: "hidden", textOverflow: "ellipsis" },
                    React.createElement(Text, { component: "span", noWrap: true, style: { margin: 0 }, variant: "helperText" }, assetUrl)))))));
};
export default SastPageIssuesAssetDisplay;
