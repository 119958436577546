import { useEffect, useState } from 'react';

const parseAssetTagStringToObject = filter_by_asset_tags => {
  if (!filter_by_asset_tags) {
    return null;
  }
  try {
    return JSON.parse(filter_by_asset_tags);
  } catch (e) {
    return null;
  }
};

const getAssetTagList = assetTagFilter => {
  if (!assetTagFilter) {
    return null;
  }
  return Object.keys(assetTagFilter).reduce((list, key) => {
    if (assetTagFilter[key] === null) {
      return list.concat({
        key,
        value: null,
      });
    }
    return list.concat(
      assetTagFilter[key].map(value => ({
        key,
        value,
      })),
    );
  }, []);
};

const stringify = assetTagFilter => {
  if (Object.keys(assetTagFilter || {}).length) {
    return JSON.stringify(assetTagFilter) || null;
  }
  return null;
};

export default function useParseAssetTagFilters(filter_by_asset_tags) {
  const [assetTagFilter, setAssetTagFilter] = useState(null);

  useEffect(() => {
    setAssetTagFilter(parseAssetTagStringToObject(filter_by_asset_tags));
  }, [filter_by_asset_tags]);

  const list = () => getAssetTagList(assetTagFilter);

  const remove = assetTag => {
    const newAssetTagFilter = assetTagFilter;
    if (newAssetTagFilter && assetTag.key && assetTag.value === null) {
      delete newAssetTagFilter[assetTag.key];
    }
    if (newAssetTagFilter && assetTag.key && assetTag.value) {
      if (newAssetTagFilter[assetTag.key]?.length === 1) {
        delete newAssetTagFilter[assetTag.key];
      } else {
        newAssetTagFilter[assetTag.key] = (newAssetTagFilter[assetTag.key] || [])?.filter(
          item => item !== assetTag.value,
        );
      }
    }
    return stringify(newAssetTagFilter);
  };

  return {
    list,
    remove,
  };
}
