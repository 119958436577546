import flatMap from 'lodash/fp/flatMap';
import createReducerCreator from 'rezz/createReducerCreator';
import updateInList from 'rezz/updateInList';
import { addCommentRequestSucceeded, updateFindings } from '../actions';
import { updateStatusSuccess } from '../actions/securityFindings';

export default createReducerCreator({
  equalityCheck: (a, b) => a.id === b.id,
  initialState: [],
})(
  updateInList(updateFindings.toString()),
  (state, action) => {
    if (!action || !action.payload || typeof action.payload !== 'object') {
      return state;
    }
    const { status, targetId } = action.payload;
    if (action.type === updateStatusSuccess.toString() && status && typeof status === 'object' && targetId) {
      const targetsToUpdate = flatMap(finding => finding.targets)(state).filter(target => targetId === target.id);

      if (!targetsToUpdate || !targetsToUpdate.length === 0) {
        return state;
      }

      const finding = state.find(finding => finding.id === targetsToUpdate[0].security_finding_id);

      if (!finding) {
        return state;
      }

      const updatedTargets = targetsToUpdate.map(target => ({
        ...target,
        statuses: [...target.statuses, status],
      }));

      const newFinding = {
        ...finding,
        aggregated_status: status.status,
        targets: finding.targets.filter(currentTarget => targetId !== currentTarget.id).concat(updatedTargets),
      };

      return state.filter(finding => finding.id !== newFinding.id).concat(newFinding);
    }

    return state;
  },

  (state = [], action) => {
    if (!action || !action.payload || typeof action.payload !== 'object') {
      return state;
    }
    const { comment } = action.payload;
    if (
      action.type === addCommentRequestSucceeded.toString() &&
      comment &&
      typeof comment === 'object' &&
      comment.security_finding_id
    ) {
      const finding = state.find(finding => finding.id === comment.security_finding_id);

      if (!finding) {
        return state;
      }

      const newFinding = {
        ...finding,
        notes: finding.notes && finding.notes.slice().concat(comment),
      };

      return [...state.filter(finding => finding.id !== newFinding.id), newFinding];
    }

    return state;
  },
);
