import React from 'react';
import { Card, CardContent, Grid } from '@mui/material';
import Text from './Text';

function CardWithHeading({ heading, children }) {
  return (
    <Grid container direction="row" spacing={1}>
      <Grid item xs={12}>
        <Text style={{ margin: 0, textTransform: 'uppercase' }} variant="titleXS">
          {heading}
        </Text>
      </Grid>

      <Grid item xs={12}>
        <Card>
          <CardContent>{children}</CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default React.memo(CardWithHeading);
