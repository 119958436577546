import config from '@dt/config';
import fetch, { parse } from '@dt/fetch';
import qs from 'query-string';

const api = config.horizonApiBaseUrl;
const version = 'v1';

export const AssetTypes = {
  CLOUD_RESOURCE: 'CLOUD_RESOURCE',
  NETWORK_SERVICE: 'NETWORK_SERVICE',
  RESTFUL_API: 'RESTFUL_API',
  WEB_APPLICATION: 'WEB_APPLICATION',
};

export async function create(params) {
  return fetch(`${api}/${version}/assets_searches`, {
    body: JSON.stringify({
      search_query: params,
    }),
    method: 'POST',
  }).then(parse);
}

export const network_services = {
  list: async function (id, params) {
    return fetch(`${api}/${version}/assets_searches/${id}/network_services?${qs.stringify(params || {})}`).then(parse);
  },
};

export const restful_apis = {
  list: async function (id, params) {
    return fetch(`${api}/${version}/assets_searches/${id}/restful_apis?${qs.stringify(params || {})}`).then(parse);
  },
};

export const web_applications = {
  list: async function (id, params) {
    return fetch(`${api}/${version}/assets_searches/${id}/web_applications?${qs.stringify(params || {})}`).then(parse);
  },
};

export const cloud_resources = {
  list: async function (id, params) {
    return fetch(`${api}/${version}/assets_searches/${id}/cloud_resources?${qs.stringify(params || {})}`).then(parse);
  },
};
