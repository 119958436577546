import { select } from '@dt/redux-saga-wrapped-effects';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { assetGroupsMembershipReceived } from '../asset_groups/memberships/actions';
import { isUserViewingVisualization } from '../dashboard/saga-isViewingVisualization';
import { inventory_search } from '../inventory/search/selectors';
import { paginateToEndLazy, paginateWhile, watchForLoadMoreAndFetchNextPage } from '../resource_fetch/sagas';
import {
  networkServicesReceived,
  networkServicesReceivedIdsForAssetSearch,
  reachedEndOfNetworkServicesList,
  reachedEndOfNetworkServicesListForAssetSearchId,
} from './actions';
import resourceType from './resourceType';
import { getNetworkServicesList } from './resource_fetch.sagas';

export default function* watchForResourceFetching() {
  yield all([
    call(watchForLoadMoreAndFetchNextPage, resourceType, function* ({ cursor }) {
      yield call(getNetworkServicesList, { cursor });
    }),
    takeEvery(networkServicesReceived.toString(), networkServicesReceivedSaga),
  ]);
}

export const getAllNetworkServicesWhileOnVisualization = function* () {
  yield call(paginateWhile, getNetworkServicesList, resourceType, {}, isUserViewingVisualization);
};

export const getNetworkServicesLazily = function* (params) {
  if (params.search_id) {
    yield call(
      paginateToEndLazy,
      getNetworkServicesList,
      resourceType,
      { ...params },
      action => reachedEndOfNetworkServicesListForAssetSearchId.toString() && params.search_id === action.payload,
    );
  } else {
    yield call(
      paginateToEndLazy,
      getNetworkServicesList,
      resourceType,
      { ...params },
      reachedEndOfNetworkServicesList.toString(),
    );
  }
};

function* networkServicesReceivedSaga(action) {
  const { network_services } = action.payload;

  const { lastQueryId, query } = yield* select(inventory_search, {});

  const collector = network_services.reduce(
    (collector, item) => {
      if (item.asset_group_memberships) {
        collector.assetGroupMembers = collector.assetGroupMembers.concat(item.asset_group_memberships);
      }
      collector.idsForCurrentSearch.push(item.id);
      return collector;
    },
    {
      assetGroupMembers: [],
      idsForCurrentSearch: [],
    },
  );

  if (collector.assetGroupMembers) {
    yield put(assetGroupsMembershipReceived(collector.assetGroupMembers));
  }

  if (lastQueryId && (!query.asset_types.length || query.asset_types.includes('NETWORK_SERVICE'))) {
    yield put(networkServicesReceivedIdsForAssetSearch(lastQueryId, collector.idsForCurrentSearch));
  }
}
