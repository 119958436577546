import { createAction } from 'redux-actions';

export const appFileAccepted = createAction('APP_FILE_ACCEPTED', response => response);

export const appFileRejected = createAction('APP_FILE_REJECTED', error => error);

export const appFileSubmitted = createAction(
  'APP_FILE_SUBMITTED',
  (file, username, password, comments, isEnterpriseInternal, linkedAppStoreAppId) => ({
    comments,
    file,
    isEnterpriseInternal,
    linkedAppStoreAppId,
    password,
    username,
  }),
);
