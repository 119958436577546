import identity from 'lodash/identity';
import overEvery from 'lodash/overEvery';
import qs from 'query-string';

export function isAppleAppStoreBlocker(finding) {
  return !!finding.importance_tags && finding.importance_tags.includes('APPLE_P1');
}

export function isGooglePlayBlocker(finding) {
  return !!finding.importance_tags && finding.importance_tags.includes('GOOGLE_P1');
}

export function isSecurityP1(finding) {
  return (
    !!finding.importance_tags &&
    finding.importance_tags.includes('SECURITY_P1') &&
    (finding.priority === 'P1' || finding.priority === 'P0')
  );
}

export function wasPreviouslySecurityP1(finding) {
  return (
    !!finding.importance_tags &&
    finding.importance_tags.includes('SECURITY_P1') &&
    finding.priority !== 'P1' &&
    finding.priority !== 'P0'
  );
}

export function isPriorityAlert(finding) {
  return (
    finding.priority === 'P1' ||
    finding.priority === 'P0' ||
    isGooglePlayBlocker(finding) ||
    isAppleAppStoreBlocker(finding) ||
    isSecurityP1(finding)
  );
}

export function isComplianceIssue(finding) {
  return !!finding.compliance_policy_references && finding.compliance_policy_references.length > 0;
}

export function isPermanentlyClosedIssue(finding) {
  return typeof finding.is_permanently_closed !== 'undefined' && finding.is_permanently_closed;
}

const categoryFilters = {
  apple: isAppleAppStoreBlocker,
  compliance: isComplianceIssue,
  google: isGooglePlayBlocker,
  priority: isPriorityAlert,
  security: isSecurityP1,
};

export default function findingsFilterCreator(query) {
  const filters = [];
  const keys = Object.keys(query || {});

  if (!query || !keys.length) {
    return identity;
  }

  const params = {
    category: 'all',
    compliancePolicy: null,
    ...query,
  };

  params.category.split(' ').forEach(part => {
    if (categoryFilters[part]) {
      filters.push(categoryFilters[part]);
    }
  });

  if (params.compliancePolicy && params.compliancePolicy.length > 0) {
    const compliancePolicy = params.compliancePolicy;
    filters.push(finding => {
      if (!finding.compliance_policy_references) {
        return false;
      }
      return finding.compliance_policy_references.find(cpr => compliancePolicy.indexOf(cpr.compliance_policy) >= 0);
    });
  }

  return overEvery(filters);
}

const COMPLIANCE_POLICY_FILTER_PARAM = 'compliancePolicy';

export function setComplianceSearchFilter(browserHistory, compliancePolicies) {
  const { location } = browserHistory;
  const state = location.state || {};
  const search = qs.parse(location.search);
  const compliancePoliciesFilter = compliancePolicies.join(',');

  search[COMPLIANCE_POLICY_FILTER_PARAM] = compliancePoliciesFilter;
  browserHistory.replace({
    ...location,
    search: `?${qs.stringify(search)}`,
    state: {
      ...state,
      [COMPLIANCE_POLICY_FILTER_PARAM]: compliancePoliciesFilter,
    },
  });
}

export function getComplianceSearchFilter() {
  const stateParam = window.history.state instanceof Object && window.history.state[COMPLIANCE_POLICY_FILTER_PARAM];

  if (stateParam) {
    return stateParam ? stateParam.split(',') : [];
  }

  const search = qs.parse(window.location.search, {
    arrayFormat: 'comma',
  });
  const value = search[COMPLIANCE_POLICY_FILTER_PARAM];
  return Array.isArray(value) ? value : value ? [value] : [];
}

export function setState(browserHistory, state) {
  const { push, location } = browserHistory;
  push({
    ...location,
    state: {
      ...location.state,
      ...state,
    },
  });
}
