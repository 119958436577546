import config from '@dt/config';
import numeral from 'numeral';
import EventTypes from './EventTypes';

const ALL_APPS_SUFFIX = '_ALL_APPS';

// Taken directly from Acheron
// would like to replace with something else.
function getSimplestRole(role) {
  if (typeof role !== 'string') {
    return role;
  }

  return role.replace(ALL_APPS_SUFFIX, '');
}

export const EventCategories = {
  API_KEY_MANAGEMENT: 'apiKeyManagement',
  APP_INTEGRATION: 'appIntegration',
  APP_PROTECTION: 'appProtection',
  CUSTOMER_OVERRIDE: 'customerOverride',
  DO_MORE: 'doMore',
  ENGAGEMENT: 'engagement',
  ERRORS: 'errors',
  FINDING_DETAILS: 'findingDetails',
  MADE_TO_ORDER: 'madeToOrder',
  PREFERENCES: 'preferences',
  SHADOWSCAN: 'shadowscan',
  USER_MANAGEMENT: 'userManagement',
};

const app_name = config.analyticsName;

export const dataCreators = {
  apiKeyCreated: () => ({
    event_category: EventCategories.API_KEY_MANAGEMENT,
    type: 'apiKeyCreated',
  }),
  apiKeyDeleted: () => ({
    event_category: EventCategories.API_KEY_MANAGEMENT,
    type: 'apiKeyDeleted',
  }),
  apiKeyEdited: () => ({
    event_category: EventCategories.API_KEY_MANAGEMENT,
    type: 'apiKeyEdited',
  }),
  appIntegrationInquired: () => ({
    event_category: EventCategories.APP_INTEGRATION,
    type: 'appProtectionInquired',
  }),
  appProtectionInquired: () => ({
    event_category: EventCategories.APP_PROTECTION,
    type: 'appProtectionInquired',
  }),
  appSearchPerformed: (search_term, results) => ({
    event_category: EventCategories.ENGAGEMENT,
    event_label: search_term,
    type: 'appSearchPerformed',
    value: results,
  }),
  appSecureAppIssuesExported: appName => ({
    event_category: EventCategories.DO_MORE,
    event_label: appName,
    type: 'AppSecureAppIssuesExported',
  }),
  appSecureExportCurrentFindings: filterName => ({
    event_category: EventCategories.ENGAGEMENT,
    event_label: filterName,
    type: 'AppSecureExportCurrentFindings',
  }),
  appSecureReportSaved: name => ({
    event_category: EventCategories.ENGAGEMENT,
    event_label: name,
    type: 'AppSecureReportSaved',
  }),
  appSecureSavedReportExported: reportName => ({
    event_category: EventCategories.ENGAGEMENT,
    event_label: reportName,
    type: 'AppSecureSavedReportExported',
  }),
  broadCastMessageDismissed: () => ({
    event_category: EventCategories.ENGAGEMENT,
    type: 'broadcastMessageDismissed',
  }),
  broadCastMessageLinkClicked: url => ({
    event_category: EventCategories.ENGAGEMENT,
    event_label: url,
    type: 'broadcastMessageLinkClicked',
  }),
  component: screen_name => ({
    app_name,
    app_version: config.bitbucketBuildNumber,
    screen_name,
    type: EventTypes.SCREEN_VIEW,
  }),
  contactPublisherRequestClicked: app_name => ({
    event_category: EventCategories.ENGAGEMENT,
    event_label: app_name,
    type: 'contactPublisherClicked',
  }),
  exportPerformed: () => ({
    event_category: EventCategories.MADE_TO_ORDER,
    type: 'export',
  }),
  findingsCommented: () => ({
    event_category: EventCategories.FINDING_DETAILS,
    type: 'fingingsCommented',
  }),
  findingsSorted: (sortField, sortDirection) => ({
    event_category: EventCategories.DO_MORE,
    event_label: sortField,
    type: 'findingsSorted',
    value: sortDirection ? 'ascending' : 'descending',
  }),
  findingsViewToggled: showClosed => ({
    event_category: EventCategories.DO_MORE,
    event_label: showClosed ? 'showClosed' : 'showAll',
    type: 'findingsViewToggled',
  }),
  login: method => ({ method, type: EventTypes.LOGIN }),
  logout: () => ({
    event_category: EventCategories.ENGAGEMENT,
    type: 'logout',
  }),
  mediawatchArticleClicked: url => ({
    event_category: EventCategories.FINDING_DETAILS,
    event_label: url,
    type: 'mediawatchArticleClicked',
  }),
  notFoundPage: path => ({
    app_version: config.bitbucketBuildNumber,
    event_category: EventCategories.ERRORS,
    type: 'notFoundPage',
    value: path,
  }),
  policyViolationExceptionChange: exceptionAdded => ({
    event_category: EventCategories.ENGAGEMENT,
    event_label: exceptionAdded,
    type: 'policyViolationExceptionChange',
  }),
  preferencesNotificationEdited: () => ({
    event_category: EventCategories.PREFERENCES,
    type: 'notificationEdited',
  }),
  previewOpenAPIDefinition: previewId => ({
    event_category: EventCategories.ENGAGEMENT,
    event_label: previewId,
    type: 'previewOpenAPIDefinition',
  }),
  previewRequestClicked: (app_name, app_id) => ({
    event_category: EventCategories.ENGAGEMENT,
    event_label: app_name,
    type: 'previewRequestClicked',
    value: app_id,
  }),
  priorityChange: (oldPriority = 'P99', newPriority = 'P99') => ({
    event_category: EventCategories.CUSTOMER_OVERRIDE,
    event_label: numeral(newPriority).value() - numeral(oldPriority).value() > 0 ? 'lowerPriority' : 'raisePriority',
    type: 'priorityChange',
    value: newPriority,
  }),
  shadowscanInquired: () => ({
    event_category: EventCategories.SHADOWSCAN,
    type: 'shadowscanCardInquired',
  }),
  targetClosed: reason => ({
    event_category: EventCategories.CUSTOMER_OVERRIDE,
    event_label: reason,
    type: 'targetClosed',
  }),
  userBeganSession: role => ({
    role: getSimplestRole(role),
    type: 'userBeganSession',
  }),
  userDeleted: role => ({
    event_category: EventCategories.USER_MANAGEMENT,
    event_label: role,
    type: 'userDeleted',
  }),
  userEdited: role => ({
    event_category: EventCategories.USER_MANAGEMENT,
    event_label: role,
    type: 'userEdited',
  }),
  userInvited: role => ({
    event_category: EventCategories.USER_MANAGEMENT,
    event_label: role,
    type: 'userInvited',
  }),
};

export { default as validate } from './validate';
export { default as Analytics } from './AnalyticsComponent';
export { default as CustomerNameContext } from './CustomerNameContext';
export { default as TrackingProvider } from './TrackingProvider';
export { default } from './analytics';
