import gql from 'graphql-tag';
export const asset_tag_key_list = gql `
  query AssetTagKeyList($filter_by_text: String, $cursor: String, $page_size: Float) {
    asset_tag_key_list(filter_by_text: $filter_by_text, cursor: $cursor, page_size: $page_size) {
      pagination_information {
        next_cursor
        total_count
      }
      asset_tag_keys {
        id
        name
        imported_external_id
        imported_from
      }
    }
  }
`;
export const asset_tag_value_list = gql `
  query AssetTagValueList($tag_uuid: ID!, $filter_by_text: String, $cursor: String, $page_size: Float) {
    asset_tag_value_list(tag_uuid: $tag_uuid, filter_by_text: $filter_by_text, cursor: $cursor, page_size: $page_size) {
      pagination_information {
        next_cursor
        total_count
      }
      asset_tags {
        id
        tag_id
        tag
        value
        imported_external_id
        imported_from
      }
    }
  }
`;
export const getById = gql `
  query AssetTagsById($uuid: ID!) {
    asset_tags(uuid: $uuid) {
      asset_tags {
        id
        tag_id
        tag
        value
        imported_from
        imported_external_id
        imported_from_icon_url
      }
    }
  }
`;
export const putById = gql `
  mutation PutAssetTags($uuid: ID!, $body: AssetTagPutParams!) {
    asset_tags_put(uuid: $uuid, body: $body) {
      asset_tags {
        id
        tag_id
        tag
        value
        imported_from
        imported_external_id
        imported_from_icon_url
      }
    }
  }
`;
export default {
    getById,
    list: asset_tag_key_list,
    putById,
    values: {
        list: asset_tag_value_list,
    },
};
