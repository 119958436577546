import React, { useState } from 'react';
import { Tab } from '@mui/material';
import { Tabs } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { palette } from '@dt/theme';
import Markdown from './Markdown';

const withStyles = makeStyles(() => ({
  selectedTab: {
    borderBottom: `2px solid ${palette.brand35}`,
    color: palette.brand35,
  },
  tab: {
    maxWidth: 'inherit',
    minWidth: 72,
  },
  tabContainer: {
    minHeight: 160,
    overflowX: 'auto',
  },
  tabContainerContent: {
    borderTop: `1px solid ${palette.gray40}`,
    padding: 14,
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
  },
}));

// TODO: Need to move this out of material-components.
function ApiOperationRequestResponse({ api_operation_scan_trace, policy_violations }) {
  const classes = withStyles();
  const [activeTab, setActiveTab] = useState('request');

  return (
    <Box borderRadius={0} px={3} py={1} style={{ backgroundColor: palette.gray50 }}>
      {/* Additional information: Only applicable to specific policy violations. */}
      {policy_violations.length > 0 && (
        <Typography gutterBottom variant="subtitle1">
          Additional information
        </Typography>
      )}
      {policy_violations.map(
        ({ id, status, additional_info }) =>
          status === 'OPEN' && (
            <Typography component="div" key={id} variant="body2">
              <Markdown text={additional_info} />
            </Typography>
          ),
      )}

      {/* API Operation Request/Response Details */}
      <Tabs
        aria-label="operation detail content"
        indicatorColor="primary"
        onChange={(e, newValue) => setActiveTab(newValue)}
        style={{ color: palette.brand35 }}
        value={activeTab}
      >
        <Tab
          className={classes.tab}
          classes={{
            selected: classes.selectedTab,
          }}
          label="Request"
          value="request"
        />
        <Tab
          className={classes.tab}
          classes={{
            selected: classes.selectedTab,
          }}
          label="Response"
          value="response"
        />
      </Tabs>

      <div className={classes.tabContainer}>
        <Typography className={classes.tabContainerContent} component="pre" id="api-operation-panel">
          {activeTab === 'request'
            ? api_operation_scan_trace
              ? api_operation_scan_trace.http_request
              : 'Currently unavailable. Please check back later!'
            : null}
          {activeTab === 'response'
            ? api_operation_scan_trace
              ? api_operation_scan_trace.http_response
              : 'Currently unavailable. Please check back later!'
            : null}
        </Typography>
      </div>
    </Box>
  );
}

export default ApiOperationRequestResponse;
