import config from '@dt/config';
import fetch, { parse } from '@dt/fetch';
import { check, result } from './util';

/**
 * Every customer has the ID number 9 saved for only a global jira config
 * We can hardcode that right here.
 */
export const GLOBAL_JIRA_CONFIG_ID = '9';

/**
 * Helper method for transitioning a Jira config response to a Jira Config Request
 */

export const jiraConfigToRequest = (jiraConfig, isGlobal, isPatch) => {
  const sfc = jiraConfig.severity_field_config ? { data: jiraConfig.severity_field_config } : undefined;
  const staticFields = jiraConfig.static_fields ? { data: jiraConfig.static_fields } : undefined;
  const dynamicFields = jiraConfig.dynamic_fields ? { data: jiraConfig.dynamic_fields } : undefined;

  const base = {
    base_url: jiraConfig.base_url,
    dynamic_fields: dynamicFields,
    export_filters: jiraConfig.export_filters,
    export_pre_prod: jiraConfig.export_pre_prod,
    export_prod: jiraConfig.export_prod,
    minimum_severity_filter: jiraConfig.minimum_severity_filter,
    password: jiraConfig.password ? jiraConfig.password : undefined,
    project_key_or_id: jiraConfig.project_key_or_id,
    severity_field_config: sfc,
    static_fields: staticFields,
    type_of_issue_name: jiraConfig.type_of_issue_name,
    username: jiraConfig.username,
  };

  // Due to how the backend is implemented, we can't have is_global set to false when patching it
  // So we simply just remove the field when we're in the global config and patching it
  if (isGlobal && !isPatch) base.is_global = isGlobal;

  // When global, we only need the minimum requirements, ignore everything else
  if (isGlobal) return base;

  return {
    ...base,
    base_id: jiraConfig.base_id ? jiraConfig.base_id : undefined,
    mobile_app_id: jiraConfig.mobile_app_id ? jiraConfig.mobile_app_id : undefined,
    raw_mobile_app_id: jiraConfig.raw_mobile_app_id ? parseInt(jiraConfig.raw_mobile_app_id, 10) : undefined,
  };
};

/**
 * Type to be used in the jira forms. There is a helper function to transform data into
 * what the server accepts before sending it off
 */

export async function get(id) {
  return fetch(`${config.sevenhellApiBaseUrl}/v2/integration_configs/jira/${id}`).then(parse).then(result);
}

export async function list() {
  return fetch(`${config.sevenhellApiBaseUrl}/v2/integration_configs/jira/all`).then(parse).then(result);
}

export async function del(id) {
  return fetch(`${config.sevenhellApiBaseUrl}/v2/integration_configs/jira/${id}`, {
    method: 'DELETE',
  })
    .then(parse)
    .then(check);
}

export async function create(jiraConfig) {
  return fetch(`${config.sevenhellApiBaseUrl}/v2/integration_configs/jira`, {
    body: JSON.stringify(jiraConfig),
    method: 'POST',
  })
    .then(parse)
    .then(result);
}

export async function patch(jiraConfig, id) {
  return fetch(`${config.sevenhellApiBaseUrl}/v2/integration_configs/jira/${id}`, {
    body: JSON.stringify(jiraConfig),
    method: 'PATCH',
  })
    .then(parse)
    .then(result);
}
