import { createAction } from 'redux-actions';
import getKey from './getKey';

export function actionIsLoadMoreForType(action, type) {
  return action.type === paginationLoadMoreAction.toString() && actionIsForType(action, type);
}

export function actionIsForType(action, type) {
  return !!action.meta && typeof action.meta.type === 'string' && action.meta.type === type;
}

const payloadCreator = (type, params) => params;
const metaCreator = (type, params) => ({
  key: getKey(params),
  type: type,
});

export const paginationMountedAction = createAction('PAGINATION_MOUNTED', payloadCreator, metaCreator);

export const paginationLoadMoreAction = createAction('PAGINATION_LOAD_MORE', payloadCreator, metaCreator);

export const paginationLoadedInitialAction = createAction('PAGINATION_LOADED_INITIAL', metaCreator);

export const paginationBeginRequest = createAction('PAGINATION_REQUEST_BEGIN', metaCreator);

export const paginationEndRequest = createAction('PAGINATION_REQUEST_END', (type, params, pagination_response) => ({
  ...metaCreator(type, params),
  ...pagination_response,
}));

export const paginationRequestFailed = createAction('PAGINATION_REQUEST_FAILED', metaCreator);
