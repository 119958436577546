import React, { memo } from 'react';
import clsx from 'clsx';

const variantToElement = {
  body: 'p',
  bodyS: 'p',
  button: 'span',
  caption: 'span',
  chip: 'span',
  code: 'code',
  helperText: 'span',
  label: 'span',
  labelL: 'span',
  link: 'span',
  numberXL: 'span',
  titleL: 'h2',
  titleM: 'h3',
  titleS: 'h4',
  titleXL: 'h1',
  titleXS: 'h5',
};

function Text({ variant = 'body', component, style = {}, color, className = '', children, label, noWrap, onClick }) {
  const Comp = component || variantToElement[variant];

  return (
    <Comp
      aria-label={label}
      className={clsx(`text-${variant}`, noWrap && 'text-truncate', className)}
      onClick={onClick}
      style={{ color, ...style }}
    >
      {children}
    </Comp>
  );
}

export default memo(Text);
