import { createSelector } from 'reselect';
import { createArraySelector } from 'reselect-map';
import { sortPoliciesRulesType } from './util';

export const policy_rule_types = ({ policy_rule_types }) => policy_rule_types;

export const policy_rules_array = createArraySelector(
  (state, props = {}) =>
    props.policyId ? state.policy_rules.for_policy[props.policyId] || [] : Object.keys(state.policy_rules.id),
  state => state.policy_rules.id,
  (policy_rule_id, policy_rules) => {
    return policy_rules[policy_rule_id];
  },
);

export const policy_rules = createSelector(policy_rules_array, policy_rules_array =>
  policy_rules_array.filter(Boolean),
);

export const getAllPolicyRuleType = createArraySelector(
  (state, props = {}) =>
    props.group ? state.policy_rule_types.for_group[props.group] || [] : Object.keys(state.policy_rule_types.id),
  state => state.policy_rule_types.id,
  (policy_rule_type_id, policy_rule_types) => policy_rule_types[policy_rule_type_id],
);

export const getAllPolicyRuleTypesSorted = createSelector(getAllPolicyRuleType, policy_rule_type =>
  sortPoliciesRulesType(policy_rule_type.filter(Boolean)),
);
