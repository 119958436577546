import React, { memo } from 'react';
import { Avatar, Chip, Grid } from '@mui/material';
import { palette } from '@dt/theme';

const COLOR_MAPPING = {
  BLUE: palette.blue50,
  GREY: palette.gray50,
  YELLOW: palette.yellow50,
};

const PolicyRuleImportanceTag = ({ items = [] }) => {
  return (
    <Grid container spacing={1}>
      {items?.map((tag, index) => {
        const { title, icon_url: iconUrl, background_color: backgroundColor } = tag ?? {};
        return (
          <Grid item key={`${title}-${index}`}>
            <Chip
              avatar={<Avatar alt={`icon-${title}-index`} src={iconUrl} />}
              label={title}
              style={{
                backgroundColor: COLOR_MAPPING[backgroundColor],
              }}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default memo(PolicyRuleImportanceTag);
