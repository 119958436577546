import '@dt/horizon-api';
import { createAction } from 'redux-actions';

export const policyRulesReceived = createAction('policy_rules/received', taskTypes => taskTypes);

export const policyRuleEdit = createAction('policy_rules/edit', (policy_id, policy_rule_id, params) => ({
  params,
  policy_id,
  policy_rule_id,
}));

export const policyRulesConfigMounted = createAction('mounted/policy_rules/config', () => ({ key: 'policyRules' }));

export const policyRuleDeleteFlowStart = createAction('policy_rules/flow/start', policy_rule => ({
  policy_rule,
}));

export const policyRuleDeleteFlowConfirm = createAction('policy_rules/flow/confirm', (policy_rule_id, explanation) => ({
  explanation,
  policy_rule_id,
}));

export const policyRuleDeleteFlowStop = createAction('policy_rules/flow/stop', policy_rule_id => ({
  policy_rule_id,
}));

export const policyRuleDeleteFlowStep = createAction('policy_rules/flow/step', (policy_rule_id, step) => ({
  policy_rule_id,
  step,
}));

export const policyRuleDeleteFlowComplete = createAction('policy_rules/flow/complete', policy_rule_id => ({
  policy_rule_id,
}));

export const policyRuleViewViolationsClicked = createAction(
  'policy_rules/view_violations/open/clicked',
  policy_rule_id => ({
    policy_rule_id,
  }),
);

export const policyRuleViewViolationsCloseClicked = createAction(
  'policy_rules/view_violations/close/clicked',
  () => {},
);

export const policyRuleViewViolationsDrawerMounted = createAction(
  'policy_rules/view_violations/drawer/mounted',
  policy_rule_id => ({
    key: 'view_policy_rule_issues',
    policy_rule_id,
  }),
);

export const policyRuleCreate = createAction('policy_rules/create', (policy_id, params) => ({
  params,
  policy_id,
}));

export const policyRuleCreateError = createAction(
  'policy_rules/create/error',
  (policy_id, policy_rule_type_id, error) => ({
    error,
    policy_id,
    policy_rule_type_id,
  }),
);

export const policyRuleCreateSuccess = createAction(
  'policy_rules/create/success',
  (policy_id, policy_rule_type_id) => ({
    policy_id,
    policy_rule_type_id,
  }),
);
