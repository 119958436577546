import React, { useState, useEffect } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import { TextField, Skeleton, TablePagination, styled, List, ListItem, Checkbox, Stack, } from '@mui/material';
// @ts-expect-error ignore js file
import { useQuery } from '@dt/apollo-link-schema-rest';
import assets from '@dt/graphql-support/horizon/ts/assets';
import { Message } from '../../../Message';
import SastPageIssuesAssetDisplay from '../../../sast/SastPageIssuesAssetDisplay';
const ROWS_PER_PAGE = 10;
const RepoList = styled(List)({
    height: 300,
    overflow: 'scroll',
    overflowX: 'hidden',
});
const TableFiltersSourceCodeRepository = ({ onChange, onKeyPress, selectedRepoIds, label }) => {
    const [page, setPage] = useState(0);
    const [keyword, setKeyWord] = useState('');
    const { data, loading, error, fetchMore } = useQuery(assets.list, {
        variables: {
            filter_by_asset_type: 'REPOSITORY',
            filter_by_text: keyword || null,
        },
    });
    useEffect(() => {
        setPage(0);
    }, [keyword]);
    if (error) {
        return React.createElement(Message, { message: error.message, variant: "error" });
    }
    const { pagination_information: paginationInformation, assets: repoList = [] } = data?.asset_list ?? {};
    const totalCount = paginationInformation?.total_count ?? 0;
    const noItems = totalCount === 0;
    const handleChangeKeyword = (value) => {
        setKeyWord(value);
    };
    const handleChangePage = (_, newPage) => {
        if ((repoList.length || 0) / ROWS_PER_PAGE / (newPage + 1) <= 1) {
            fetchMore?.();
        }
        setPage(newPage);
    };
    const handleToggleRepo = (selectedRepo) => {
        if (selectedRepoIds.includes(selectedRepo.id)) {
            onChange({ filter_by_asset_id: selectedRepoIds.filter(el => el !== selectedRepo.id) });
        }
        else {
            onChange({ filter_by_asset_id: [...selectedRepoIds, selectedRepo.id] });
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(TextField, { InputProps: {
                endAdornment: React.createElement(SearchIcon, null),
            }, fullWidth: true, id: "inventory-filter-keyword", label: label, onChange: e => handleChangeKeyword(e.target.value), onKeyUp: onKeyPress, value: keyword }),
        React.createElement(RepoList, null,
            !loading &&
                !noItems &&
                repoList.slice(page * ROWS_PER_PAGE, page * ROWS_PER_PAGE + ROWS_PER_PAGE).map((repo, index) => (React.createElement(ListItem, { dense: true, key: index, onClick: () => handleToggleRepo(repo) },
                    React.createElement(Stack, { direction: "row", justifyContent: "flex-start", overflow: "hidden" },
                        React.createElement(Checkbox, { checked: selectedRepoIds.includes(repo.id) }),
                        React.createElement(SastPageIssuesAssetDisplay, { assetName: repo.name, assetTypeIconUrl: repo.asset_type_icon_url, assetUrl: repo.url }))))),
            loading && (React.createElement(React.Fragment, null, Array.from({ length: ROWS_PER_PAGE }).map((_, index) => (React.createElement(ListItem, { dense: true, key: index },
                React.createElement(Skeleton, { animation: "wave", key: index, width: "100%" }))))))),
        !noItems && (React.createElement(TablePagination, { component: "div", count: totalCount, 
            // @ts-ignore
            nextIconButtonProps: loading ? { disabled: true } : null, onPageChange: handleChangePage, page: page, rowsPerPage: ROWS_PER_PAGE, rowsPerPageOptions: [] }))));
};
export default TableFiltersSourceCodeRepository;
