import React from 'react';
import Skeleton from '@mui/material/Skeleton';
import { PolicyViolationsAffectedAssetLink } from './PolicyViolationsAffectedAssetLink';

const PolicyViolationsAffectedAssetLinkTextComponent = function PolicyViolationsAffectedAssetLinkText(props) {
  if (props.isLoading) {
    return <Skeleton animation="pulse" height={38} variant="rectangular" />;
  }

  if (typeof props.affected_asset.url !== 'string') {
    return null;
  }

  return (
    <PolicyViolationsAffectedAssetLink
      affected_asset={props.affected_asset}
      isMobileSecure={props.isMobileSecure}
      isV2={props.isV2}
      productBasePath={props.productBasePath}
    >
      {typeof props.excludeName === 'boolean' && props.excludeName
        ? props.affected_asset.url
        : `${props.affected_asset.asset_type_name} at ${props.affected_asset.url}`}
    </PolicyViolationsAffectedAssetLink>
  );
};

export const PolicyViolationsAffectedAssetLinkText = PolicyViolationsAffectedAssetLinkTextComponent;
