import { values } from '@dt/functions';
import { createSelector } from 'reselect';
import { createObjectSelector } from 'reselect-map';
import { getCurrentSearchId, getResultsOfSearch } from '../inventory/search/selectors';
import { getAllTasksWithAffectedComponents } from '../policy_violations/selectors';
import { cloudResources, decorate } from './util';

const idFromProps = (_, props = {}) => props.id;

export const getCloudResourceFromId = createSelector(
  cloudResources,
  idFromProps,
  getAllTasksWithAffectedComponents,

  (resources, id, policy_violations_decorated) => {
    if (!id) {
      throw new Error('id is required for getCloudResourceFromId selector');
    }

    const resource = resources.id[id];

    if (!resource) {
      return null;
    }

    return decorate(resource, policy_violations_decorated);
  },
);

export const getCloudResourceObj = createObjectSelector(
  state => state.cloud_resources.id,
  getAllTasksWithAffectedComponents,

  (resource, policy_violations_decorated) => decorate(resource, policy_violations_decorated),
);

export const getCloudResources = createSelector(
  getCloudResourceObj,

  resources => values(resources).filter(Boolean),
);

export const getCloudResourcesForInventory = createSelector(
  cloudResources,
  getCurrentSearchId,
  getAllTasksWithAffectedComponents,

  (cloud_resources_state, searchId, policy_violations_decorated) => {
    const cloud_resources = getResultsOfSearch(searchId, cloud_resources_state);
    return values(cloud_resources)
      .map(resource => decorate(resource, policy_violations_decorated))
      .filter(Boolean);
  },
);
