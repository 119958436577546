import React from 'react';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { PolicyViolationLabel, RelevanceTag, Text } from '@dt/material-components';
import ComplianceTagStrip from '@dt/material-components/compliance-tag/ComplianceTagStrip';
import { Link } from '@reach/router';
import { dateFormats } from '../../util/dateFormats';
import { formatDateDefault } from '../../util/formatDateDefault';

function PolicyViolationCardV2(props) {
  if (props.isLoading) {
    return (
      <Card>
        <CardContent>
          <Grid alignItems="center" container spacing={2}>
            <Grid container item spacing={1} xs={12}>
              <Grid item xs={6}>
                <Skeleton height={22} variant="rectangular" width={'80%'} />
              </Grid>
              <Grid container item justifyContent={'flex-end'} xs={6}>
                <Skeleton height={22} variant="rectangular" width={'20%'} />
              </Grid>
            </Grid>
            <Grid container item spacing={1} xs={12}>
              <Grid item xs={6}>
                <Skeleton height={22} variant="rectangular" width={'60%'} />
              </Grid>
              <Grid container item justifyContent={'flex-end'} xs={6}>
                <Skeleton height={22} variant="rectangular" width={'30%'} />
              </Grid>
            </Grid>
            <Grid item spacing={1} xs={12}>
              <Skeleton height={22} variant="rectangular" width={'20%'} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }

  const {
    productBasePath,
    id,
    relevance,
    title,
    status,
    date_resolved,
    exception_date_created,
    date_created,
    affected_asset_name,
    affected_asset_type_name,
    compliance_policy_references,
    isV2,
  } = props;

  return (
    <Card>
      <CardContent>
        <Link
          style={{ display: 'block' }}
          to={isV2 ? `${productBasePath}/v2/policy-violations/${id}` : `${productBasePath}/policy-violations/${id}`}
        >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Grid alignItems="center" container spacing={2}>
                <Grid item md={6} xs={12}>
                  <Grid alignItems="center" container spacing={2}>
                    <Grid item>
                      <RelevanceTag relevance={relevance} size="medium" />
                    </Grid>
                    <Grid item>
                      <Typography component="div" variant="body1">
                        {title}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Grid alignItems="center" container justifyContent={'flex-end'} spacing={2}>
                    <Grid item>
                      <Text component="div" variant="code">
                        {`ID: ${id.substr(0, 8)}`}
                      </Text>
                    </Grid>
                    <Grid item>
                      <PolicyViolationLabel status={status} />
                    </Grid>
                    <Box component={Grid} display={{ lg: 'none', md: 'none', xs: 'block' }} item xs={12}>
                      <Typography component="div" variant="body2">
                        {date_resolved
                          ? `Resolved ${formatDateDefault({ date: date_resolved, formatStr: dateFormats.yyyyMMdd })}`
                          : exception_date_created
                            ? `Closed ${formatDateDefault({ date: date_created, formatStr: dateFormats.yyyyMMdd })}`
                            : `Caught ${formatDateDefault({ date: date_created, formatStr: dateFormats.yyyyMMdd })}`}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid alignItems="center" container spacing={2}>
                <Grid item md={6} xs={12}>
                  <Grid alignItems="center" container spacing={2}>
                    <Grid item>
                      <Typography component="div" variant="body2">
                        {affected_asset_type_name && affected_asset_name
                          ? `${affected_asset_type_name} at ${affected_asset_name}`
                          : 'Unknown Asset Affected'}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Grid alignItems="center" container justifyContent={'flex-end'} spacing={2}>
                    <Grid item>
                      <Typography component="div" variant="body2">
                        {date_resolved
                          ? `Resolved ${formatDateDefault({ date: date_resolved })}`
                          : exception_date_created
                            ? `Closed ${formatDateDefault({ date: date_created })}`
                            : `Caught ${formatDateDefault({ date: date_created })}`}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid alignItems="center" container spacing={2}>
                <Grid item xs={12}>
                  {compliance_policy_references && (
                    <ComplianceTagStrip complianceReferences={compliance_policy_references} granular />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Link>
      </CardContent>
    </Card>
  );
}

export default React.memo(PolicyViolationCardV2);
