import React, { memo, useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Checkbox, IconButton, ListItem, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
// @ts-ignore JS component
import { Text } from '@dt/material-components';
import TableFiltersAssetTagKeyValues from './TableFiltersAssetTagKeyValues';
const useStyles = makeStyles(() => ({
    customTooltip: {
        position: 'relative',
        zIndex: 9999,
    },
    keyItem: {
        justifyContent: 'space-between',
    },
}));
const InventoryTableFilterAssetTagsComponent = function InventoryTableFilterAssetTags({ assetTagKey, selectedAssetTags, onToggleAssetTagKey, onToggleAssetTagValue, }) {
    const classes = useStyles();
    const [totalValues, setTotalValues] = useState();
    const [showValue, setShowValues] = useState(false);
    return (React.createElement(React.Fragment, null,
        React.createElement(ListItem, { button: true, className: classes.keyItem, dense: true },
            React.createElement(Box, { alignItems: 'center', display: 'flex', flexGrow: 1, onClick: () => onToggleAssetTagKey(assetTagKey.name) },
                React.createElement(Checkbox, { checked: Boolean(selectedAssetTags[assetTagKey.name] === null) ||
                        Boolean(selectedAssetTags[assetTagKey.name]?.length), indeterminate: Boolean(selectedAssetTags[assetTagKey.name]?.length) &&
                        Number(selectedAssetTags[assetTagKey.name]?.length) < Number(totalValues) }),
                React.createElement(Text, { component: 'div', noWrap: true }, assetTagKey.name)),
            React.createElement(Box, { flex: '0 0 30px' },
                React.createElement(Tooltip, { PopperProps: { style: { zIndex: 99999 } }, disableInteractive: true, title: 'Click to see all values for this tag' },
                    React.createElement(IconButton, { "aria-label": "expand row", onClick: () => {
                            setShowValues(prev => !prev);
                        }, size: "small" }, showValue ? React.createElement(KeyboardArrowUpIcon, null) : React.createElement(KeyboardArrowDownIcon, null))))),
        showValue && (React.createElement(Box, { ml: 4 },
            React.createElement(TableFiltersAssetTagKeyValues, { assetTagKey: assetTagKey, onToggleAssetTagValue: onToggleAssetTagValue, selectedAssetTags: selectedAssetTags, setTotalValues: setTotalValues })))));
};
export default memo(InventoryTableFilterAssetTagsComponent);
