import React, { memo } from 'react';
import DownArrow from '@mui/icons-material/ExpandMore';
import { Fab } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { palette } from '@dt/theme';

const useStyles = makeStyles({
  Fab: {
    color: palette.white,
    marginLeft: '10px !important',
  },

  ViewMoreButton: {
    alignItems: 'center',
    backgroundColor: palette.accent,
    display: 'flex',
    justifyContent: 'center',
    marginTop: 1,
    padding: 5,
  },
});

export default memo(function ViewMoreButton(props) {
  const { onRequestViewMore, label, count } = props;
  const classes = useStyles();

  return (
    <div className={classes.ViewMoreButton} onClick={onRequestViewMore}>
      <span>
        View {count} more {label}
      </span>
      <Fab className={classes.Fab} color="primary" size="small">
        <DownArrow />
      </Fab>
    </div>
  );
});
