export default async function* pages(requestFn) {
  let cursor;
  do {
    const response = await requestFn({ cursor });

    // Backend should always provide pagination information on pagable requests.
    if (!response.pagination_information) {
      throw new Error("'pagination_information' was not found on the response.");
    }

    cursor = response.pagination_information.next_cursor;

    yield response;
  } while (cursor);
}
