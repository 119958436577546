import { HostedOnEnum } from '@dt/horizon-api';
import { AssetTypeDict } from '../inventory/types';
import {
  getAggregatedRelevance,
  getHistoricAggregatedRelevance,
  getUnresolvedPolicyViolationPointValue,
} from '../policy_violations/util';

export const cloudResources = ({ cloud_resources }) => cloud_resources;

export function decorate(cloud_resource, policy_violations_decorated) {
  // The backend doesn't provide a `hosted_on` property.
  // Cloud resources are hosted on wherever they're discovered.
  let hosted_on;
  let discovered_via_authenticator_id;
  if (cloud_resource.discovered_via_aws_authenticator_id) {
    hosted_on = HostedOnEnum.AMAZON_WEB_SERVICES;
    discovered_via_authenticator_id = cloud_resource.discovered_via_aws_authenticator_id;
  } else if (cloud_resource.discovered_via_gcp_authenticator_id) {
    hosted_on = HostedOnEnum.GOOGLE_CLOUD_PLATFORM;
    discovered_via_authenticator_id = cloud_resource.discovered_via_gcp_authenticator_id;
  } else if (cloud_resource.discovered_via_azure_authenticator_id) {
    hosted_on = HostedOnEnum.AZURE;
    discovered_via_authenticator_id = cloud_resource.discovered_via_azure_authenticator_id;
  } else if (cloud_resource.discovered_via_axway_authenticator_id) {
    hosted_on = HostedOnEnum.AXWAY;
    discovered_via_authenticator_id = cloud_resource.discovered_via_axway_authenticator_id;
  } else if (cloud_resource.discovered_via_mulesoft_authenticator_id) {
    hosted_on = HostedOnEnum.MULESOFT;
    discovered_via_authenticator_id = cloud_resource.discovered_via_mulesoft_authenticator_id;
  } else if (cloud_resource.discovered_via_apigee_authenticator_id) {
    hosted_on = HostedOnEnum.APIGEE;
    discovered_via_authenticator_id = cloud_resource.discovered_via_apigee_authenticator_id;
  }
  // TODO: Does not support `discovered_via_apigee_on_prem_authenticator_id` because the backend doesn't support it.
  else {
    hosted_on = HostedOnEnum.UNKNOWN;
  }

  const policy_violations_decorated_filtered = policy_violations_decorated.filter(({ id }) =>
    cloud_resource.policy_violation_ids.includes(id),
  );

  return {
    ...cloud_resource,
    aggregated_relevance: getAggregatedRelevance({
      policy_violations_decorated: policy_violations_decorated_filtered,
    }),
    asset_type: AssetTypeDict.CLOUD_RESOURCE,
    discovered_via_authenticator_id: discovered_via_authenticator_id,
    historic_aggregated_relevance: getHistoricAggregatedRelevance({
      policy_violations_decorated: policy_violations_decorated_filtered,
    }),
    hosted_on,
    name: cloud_resource.name,
    policy_violations_decorated: policy_violations_decorated_filtered,
    unresolved_policy_violations_point_value: getUnresolvedPolicyViolationPointValue({
      policy_violations_decorated: policy_violations_decorated_filtered,
    }),
  };
}
