import config from '@dt/config';
import fetch, { parse } from '@dt/fetch';
import qs from 'query-string';
import './types';

const api = config.horizonApiBaseUrl;
const version = 'v1';

export async function list(params) {
  return fetch(`${api}/${version}/policy_violations?${qs.stringify(params || {})}`).then(parse);
}

export async function details(id) {
  return fetch(`${api}/${version}/policy_violations/${id}`).then(parse);
}

export async function patch(id, payload) {
  return fetch(`${api}/${version}/policy_violations/${id}`, {
    body: JSON.stringify(payload),
    method: 'PATCH',
  }).then(parse);
}

export const comments = {
  delete: async function (PolicyViolationId, commentId) {
    return fetch(`${api}/${version}/policy_violations/${PolicyViolationId}/comments/${commentId}`, {
      method: 'DELETE',
    }).catch(response => response.json());
  },

  insert: async function (policyViolationId, payload) {
    return fetch(`${api}/${version}/policy_violations/${policyViolationId}/comments`, {
      body: JSON.stringify(payload),
      method: 'POST',
    }).then(parse);
  },

  list: async function (PolicyViolationId) {
    return fetch(`${api}/${version}/policy_violations/${PolicyViolationId}/comments`).then(parse);
  },
};
