import React, { memo } from 'react';
import { Button, Card, CardContent, Grid, Typography } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { Link } from '@reach/router';
import OpenPolicyViolationsCountCardPolicyViolationList from './OpenPolicyViolationsCountCardPolicyViolationList';
import OpenPolicyViolationsCountCardTagStrips from './OpenPolicyViolationsCountCardTagStrips';

export default memo(function OpenPolicyViolationsCountCard(props) {
  if (props.isLoading) {
    return (
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Skeleton animation="wave" height={30} variant="rectangular" width={'30%'} />
            </Grid>
            <Grid container item spacing={1} xs={12}>
              {[1, 2, 3, 4, 5, 6].map((_, i) => (
                <Grid item key={i} xs={12}>
                  <Skeleton animation="wave" height={30} variant="rectangular" width={'60%'} />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }

  const { productBasePath, id, policy_violations_count, isV2 = false } = props;

  return (
    <Card>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid alignItems="center" container spacing={2}>
              <Grid item>
                <Typography color="textPrimary" style={{ fontWeight: 'bold' }} variant="body2">
                  Open Policy Violations Count
                </Typography>
              </Grid>
              <Grid item>
                <OpenPolicyViolationsCountCardTagStrips policy_violations_count={policy_violations_count} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <OpenPolicyViolationsCountCardPolicyViolationList id={id} isV2={isV2} productBasePath={productBasePath} />
          </Grid>
          <Grid item xs={12}>
            <Link to={`${productBasePath}/cloud-resources/${id}/policy-violations/`}>
              <Button color="primary" variant="text">
                See All Violations
              </Button>
            </Link>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
});
