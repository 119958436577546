import { useLazyQuery as useApolloLazyQuery } from '@apollo/client';
import useBaseQuery from './useBaseQuery';

export default function useQuery(query, apolloOptions, options = { ...null }) {
  const [execute, apolloResult] = useApolloLazyQuery(query, {
    notifyOnNetworkStatusChange: true,
    ...apolloOptions,
  });

  const resultsWithFetchMore = useBaseQuery(apolloResult, options);

  return [execute, resultsWithFetchMore];
}
