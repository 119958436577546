import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import assets from '@dt/graphql-support/horizon/assets';
import useAssetTagEditLogs from './use_asset_tag_edit_logs';
import useAssetTagEditSave from './use_asset_tag_edit_save';
import useAssetTagEditValidation from './use_asset_tag_edit_validation';

export default function useCrudAssetTags({ onClose, assetId }) {
  const [primaryAssetTags, setPrimaryAssetTags] = useState([]);
  const [assetTags, setAssetTags] = useState([]);
  const [shouldShowAddNewTagBtn, setShouldShowAddNewTagBtn] = useState(true);
  const { validations, set: setAssetTagsForValidation } = useAssetTagEditValidation();
  const { logs, setPrimary: setPrimaryAssetTagsForLogs, setNew: setNewAssetTagsForLogs } = useAssetTagEditLogs();
  const { saveQuery, loading: saveQueryLoading, error: saveQueryError } = useAssetTagEditSave(assetId, onClose);

  const { data, loading, error } = useQuery(assets.asset_tags.get, {
    variables: {
      uuid: assetId,
    },
  });

  useEffect(() => {
    const assetTagsData =
      data?.asset_tags?.asset_tags?.map(item => ({
        id: item.id,
        imported_external_id: item.imported_external_id,
        imported_from: item.imported_from,
        tag: item.tag,
        tag_id: item.id,
        value: item.value,
      })) || [];
    setPrimaryAssetTags(assetTagsData);
    setAssetTags(assetTagsData);
  }, [data]);

  //update assetTags for validation hook
  useEffect(() => {
    setAssetTagsForValidation(assetTags);
  }, [primaryAssetTags, assetTags, setAssetTagsForValidation]);

  //update assetTags for Logs hook
  useEffect(() => {
    setPrimaryAssetTagsForLogs(primaryAssetTags);
    setNewAssetTagsForLogs(assetTags);
  }, [primaryAssetTags, assetTags, setPrimaryAssetTagsForLogs, setNewAssetTagsForLogs]);

  useEffect(() => {
    if (assetTags.length > 5) {
      setShouldShowAddNewTagBtn(false);
    } else {
      setShouldShowAddNewTagBtn(true);
    }
  }, [assetTags]);

  const saveChanges = () => {
    saveQuery({
      variables: {
        body: {
          asset_tags: assetTags.map(item => ({
            imported_external_id: item.imported_external_id,
            imported_from: item.imported_from,
            key: item.tag,
            value: item.value,
          })),
        },
        uuid: assetId,
      },
    });
  };

  const update = (oldAssetTag, newAssetTag) => {
    setAssetTags(assetTags =>
      assetTags.map(assetTag => {
        if (assetTag.tag === oldAssetTag.tag && assetTag.value === oldAssetTag.value) {
          return {
            ...assetTag,
            tag: newAssetTag.tag,
            value: newAssetTag.value,
          };
        }
        return assetTag;
      }),
    );
  };

  const remove = (key, value) => {
    setAssetTags(
      assetTags.filter(tag => {
        if (tag.tag === key) {
          if (tag.value === value) {
            return false;
          }
        }
        return true;
      }),
    );
  };

  const onAddTagClick = () => {
    setAssetTags([
      ...assetTags,
      {
        id: '',
        tag: '',
        tag_id: '',
        value: '',
      },
    ]);
  };

  return {
    error: error || saveQueryError,
    list: assetTags,
    loading: loading || saveQueryLoading,
    logs,
    onAddTagClick,
    primaryList: primaryAssetTags,
    remove,
    saveChanges,
    shouldShowAddNewTagBtn,
    update,
    validations,
  };
}
