import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { setTemporaryToken } from '@dt/horizon-api';
import {
  REJECT_REASON,
  clearSessionAndRedirectToExpired,
  getTemporaryAccessTokenUserAccount,
  getUserAccount,
  redirectToLogin,
} from '@dt/session';
import Raven from 'raven-js';

/*
 * Retreives the current actor's authenticated session.
 *
 * There are primarily three workflows.
 * - Authenticated                      => Render page content.
 * - Unauthenticated/Error (Redirect)   => Redirect the user to the login page.
 *                                         This is on by default but can be turned off.
 * - Unauthenticated/Error (Render)     => Render page error.
 *
 * NOTE: Fetch requests still need to be checked for an active session.
 *       This is to ensure that a user is redirected as soon as they use their session and
 *       its no longer active.
 *
 * @param SessionOptions.unauthenticatedRedirect - Redirect if the user is not authenticated.
 *                                                 On by default.
 *
 * @example
 *   const Page = function Page() {
 *     const { data, loading, error } = useSession({ unauthenticatedRedirect: false });
 *     if (error) return <PageError />;
 *     if (loading || !data) return <PageSkeleton />;
 *     return <PageContent />
 *   };
 */
export const useSession = options => {
  const unauthenticatedRedirect =
    typeof options?.unauthenticatedRedirect === 'boolean' ? options.unauthenticatedRedirect : true;

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  useEffect(() => {
    let isMounted = true;
    setLoading(true);

    // Obtain user_account from sevenhell.
    //
    // TODO: This needs to be sorted out. `getUserAccount` is cached.
    //
    //       Caching the user account can have unintended side effects.
    //       This could put the user in an "Unauthenticated" render state where
    //       their session could have expired.
    //
    //       To fix this a request should be made to the gate *every* time this
    //       hook is used.
    getUserAccount()
      .then(result => {
        // Stop if unmounted.
        if (!isMounted) {
          return;
        }

        if (!result.no_session_reason) {
          setData({
            user_account: result,
          });
        } else {
          // Provide error to client call site.
          setError(new Error(result.no_session_reason));
          if (unauthenticatedRedirect) {
            // Redirect when no user account was found.
            if (result.no_session_reason === REJECT_REASON.NO_SESSION_ID) {
              !options?.useV2Navigation && redirectToLogin();
            } else if (result.no_session_reason === REJECT_REASON.EXPIRED_SESSION_ID) {
              clearSessionAndRedirectToExpired();
            } else {
              const error = new Error('Response Invalid');
              console.error(error);
              Raven.captureException(error, {
                extra: {
                  msg: 'An error occurred fetching the user account.',
                },
              });
              clearSessionAndRedirectToExpired();
            }
          }
        }
      })
      .catch(e => {
        Raven.captureException(e, {
          extra: { msg: 'An error occurred fetching the user account.' },
        });
        setError(e);
        if (unauthenticatedRedirect) {
          clearSessionAndRedirectToExpired();
        }
      })
      .finally(() => {
        setLoading(false);
      });

    return () => {
      isMounted = false;
    };
  }, [unauthenticatedRedirect]); // eslint-disable-line

  return { data, error, loading };
};

/*
 * Used for secure share pages.
 * The temporary access token is prepared here.
 *
 * @param token - Temporary access token.
 *
 * @example
 *   const Page = function Page() {
 *     const { loading, error } = useTemporaryAccessToken({ token });
 *     if (error) return <PageError />;
 *     if (loading || !data) return <PageSkeleton />;
 *     return <PageContent />
 *   };
 */
export const useTemporaryAccessToken = ({ token }) => {
  const [isMounted, setIsMounted] = useState(false);
  const isReady = useSelector(state => state.ready);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  useEffect(() => {
    setLoading(true);
    if (token) {
      setTemporaryToken(token);
      setIsMounted(true);
      if (token && isReady && isMounted) {
        getTemporaryAccessTokenUserAccount(token)
          .then(result => {
            setData({
              token,
              ...result,
            });
          })
          .catch(e => {
            Raven.captureException(e, {
              extra: { msg: 'An error occurred fetching the user account.' },
            });
            setError(e);
            if (!token) {
              clearSessionAndRedirectToExpired();
            }
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  }, [token, isMounted, isReady]);

  return { data, error, loading };
};

/*
 * Compares the provided actor's authenticated session with provided access controls.
 *
 * If the user doesn't meet those access controls invalid is returned.
 * If this is a cross-product view, ignore the access list and defer to B/E.
 * Otherwise the user has access.
 *
 * This hook is designed to be a simple access control check against the requested
 * user session.
 */
export const useAuthorization = (session, accessControls, crossProductView) => {
  let isAuthorized = true;
  if (session) {
    if (typeof crossProductView === 'undefined' || !crossProductView) {
      for (const accessControl of accessControls) {
        if (!session.user_account.currentUser[accessControl]) {
          isAuthorized = false;
          break;
        }
      }
    }
  } else {
    isAuthorized = false;
  }

  return { isAuthorized };
};
