import { createAction } from 'redux-actions';
import createReducerCreator from 'rezz/createReducerCreator';
import updateInList from 'rezz/updateInList';

export const updateFindings = createAction('SDK_ISSUES_UPDATE', finding => finding);

export default createReducerCreator({
  equalityCheck: (a, b) => a.id === b.id,
  initialState: [],
})(updateInList(updateFindings.toString()));
