import React from 'react';
import { CircularProgress } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function ConfirmDialog(props) {
  const [isRunning, setisRunning] = React.useState(false);

  async function onConfirm() {
    setisRunning(true);
    await props.onConfirm();
    setisRunning(false);
  }

  const isLoading = isRunning || props.isLoading;

  return (
    <Dialog
      aria-describedby="alert-confirm-description"
      aria-labelledby="alert-confirm-title"
      onClose={props.onCancel}
      open={props.isOpen}
    >
      <DialogTitle id="alert-confirm-title">{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-confirm-description">{props.children}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button disabled={isLoading} onClick={props.onCancel} variant="contained">
          Cancel
        </Button>
        <Button
          color="primary"
          disabled={isLoading}
          endIcon={isLoading && <CircularProgress size={24} />}
          onClick={() => void onConfirm()}
          variant="contained"
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
