import { useEffect, useRef, useState } from 'react';
const useRefWidth = ({ ref }) => {
    const [width, setWidth] = useState(0);
    const callStack = useRef(null);
    useEffect(() => {
        let observerRefValue = null;
        const resizeObserver = new ResizeObserver(entries => {
            if (callStack.current) {
                clearTimeout(callStack.current);
            }
            callStack.current = setTimeout(() => {
                if (ref.current) {
                    for (const entry of entries) {
                        if (entry.contentRect) {
                            const newWidth = entry.contentRect.width;
                            // for whatever reason, on prod this observer can 'flutter' between
                            // values. these values are always less than one pixel apart. ensuring
                            // that any new width set is > 1px different in either direction
                            // before kicking off a re-render improves performance and prevents
                            // this from affecting any DOM/D3 events
                            if (newWidth > width + 0.5 || newWidth < width - 0.5) {
                                setWidth(newWidth);
                            }
                        }
                    }
                }
            }, 100);
        });
        if (ref.current) {
            observerRefValue = ref.current;
            resizeObserver.observe(ref.current);
        }
        return () => {
            if (observerRefValue) {
                resizeObserver.unobserve(observerRefValue);
            }
        };
    }, [ref, width, setWidth]);
    return { width };
};
export default useRefWidth;
