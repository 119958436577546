import { Actions as NotificationsActions } from '@dt/notifications';
import { withProgressIndicator } from '@dt/progress-indicator';
import {
  GLOBAL_JIRA_CONFIG_ID,
  create as createJiraIntegrationConfig,
  jiraConfigToRequest,
  list as listJiraIntegrationConfigs,
  patch as patchJiraIntegrationConfig,
} from '@dt/user-api/jira_config';
import { tryAndParseErrorMessage } from '@dt/user-api/util';
import { all, call, put, select, spawn, take, takeEvery } from 'redux-saga/effects';
import {
  finishedLoadingApps,
  jiraIntegrationAppSpecificDashboardPageViewRequested,
  jiraIntegrationConfigCreateFormSubmitted,
  jiraIntegrationConfigPatchFormSubmitted,
  jiraIntegrationDisplayCardOptionsPageViewRequested,
  jiraIntegrationEditPageViewRequested,
  jiraIntegrationFetchListConfigurations,
  jiraIntegrationReceivedListConfigurations,
} from '../actions';
import { apps as selectApps } from '../selectors/apps';

const doesContainGlobalJiraConfiguration = configurations =>
  !!(configurations && configurations.find(config => config.id === GLOBAL_JIRA_CONFIG_ID));

const createConfigurationsFromAppAndJiraConfig = (configurations, apps) => {
  const configs = configurations ? configurations : [];
  return apps.map(app => {
    const fields =
      configs.find(config => config.id === app.id) ||
      configs.find(config => config.id === GLOBAL_JIRA_CONFIG_ID) ||
      null;

    return {
      app,
      fields,
      isGlobal: (fields && fields.id === GLOBAL_JIRA_CONFIG_ID) || (!fields && !!app),
    };
  });
};

function* watchForCreateJiraIntegrationConfig() {
  const action = yield take(jiraIntegrationConfigCreateFormSubmitted.toString());

  // Grab the current app from redux state
  const { id, jiraIntegrationConfigChanges } = action.payload;
  const isIdGlobal = id === GLOBAL_JIRA_CONFIG_ID.toString();

  // Concat all the changes that happened
  const config = Object.assign({}, ...jiraIntegrationConfigChanges, {
    mobile_app_id: id,
  });

  // Convert payload into something the server understands
  const jiraConfigPayload = jiraConfigToRequest(config, isIdGlobal, false);

  try {
    yield call(createJiraIntegrationConfig, jiraConfigPayload);
    const list = yield call(listJiraIntegrationConfigs);
    const result = createConfigurationsFromAppAndJiraConfig(list.jira_integrations, yield select(selectApps));
    yield put(
      jiraIntegrationReceivedListConfigurations({
        configurations: result,
        isThereAGlobalConfiguration: doesContainGlobalJiraConfiguration(list.jira_integrations),
      }),
    );
  } catch (err) {
    yield put(
      NotificationsActions.requestNotifyUser({
        text: tryAndParseErrorMessage(err),
      }),
    );
  }
}

function* watchForPatchJiraIntegrationConfig() {
  const action = yield take(jiraIntegrationConfigPatchFormSubmitted.toString());
  const { id, jiraIntegrationConfigChanges } = action.payload;

  const config = Object.assign({}, ...jiraIntegrationConfigChanges);

  // Convert payload into something the server understands
  const isGlobal = id === GLOBAL_JIRA_CONFIG_ID;
  const jiraConfigPayload = jiraConfigToRequest(config, isGlobal, true);

  try {
    yield call(patchJiraIntegrationConfig, jiraConfigPayload, id);
    const list = yield call(listJiraIntegrationConfigs);
    const apps = yield select(selectApps);
    const result = createConfigurationsFromAppAndJiraConfig(list.jira_integrations, apps);
    yield put(
      jiraIntegrationReceivedListConfigurations({
        configurations: result,
        isThereAGlobalConfiguration: doesContainGlobalJiraConfiguration(list.jira_integrations),
      }),
    );
  } catch (err) {
    yield put(
      NotificationsActions.requestNotifyUser({
        text: tryAndParseErrorMessage(err),
      }),
    );
  }
}

function* watchForJiraIntegrationConfigListViewPageMounted() {
  const [configs, apps] = yield all([
    call(function* () {
      yield take(jiraIntegrationFetchListConfigurations.toString());
      try {
        return yield call(listJiraIntegrationConfigs);
      } catch (err) {
        NotificationsActions.requestNotifyUser({
          text: tryAndParseErrorMessage(err),
        });
      }
    }),
    call(function* () {
      yield take(finishedLoadingApps.toString());
      return yield select(selectApps);
    }),
  ]);
  const result = createConfigurationsFromAppAndJiraConfig(configs.jira_integrations, apps);

  yield put(
    jiraIntegrationReceivedListConfigurations({
      configurations: result,
      isThereAGlobalConfiguration: doesContainGlobalJiraConfiguration(configs.jira_integrations),
    }),
  );
}

function* watchForjiraIntegrationDisplayCardOptionsPageViewRequested() {
  yield takeEvery(jiraIntegrationDisplayCardOptionsPageViewRequested.toString(), function* () {
    window.location.assign(`/devsecops/v2/integrations/jira`);
    yield* withProgressIndicator(function* () {});
  });
}

function* watchForJiraIntegrationAppSpecificDashboardPageViewRequested() {
  yield takeEvery(jiraIntegrationAppSpecificDashboardPageViewRequested.toString(), function* () {
    window.location.assign(`/devsecops/v2/integrations/jira/apps`);
    yield* withProgressIndicator(function* () {});
  });
}

function* WatchForjiraIntegrationEditPageViewRequested() {
  yield takeEvery(jiraIntegrationEditPageViewRequested.toString(), function* (action) {
    if (action.payload.toString() === GLOBAL_JIRA_CONFIG_ID) {
      window.location.assign(`/devsecops/v2/integrations/jira/global`);
    } else {
      window.location.assign(`/devsecops/v2/integrations/jira/${action.payload}`);
    }
    yield* withProgressIndicator(function* () {});
  });
}

export default function* jiraIntegrationConfigFlow() {
  // yield spawn(watchForJiraIntegrationPageMounted);
  yield spawn(WatchForjiraIntegrationEditPageViewRequested);
  yield spawn(watchForjiraIntegrationDisplayCardOptionsPageViewRequested);
  yield spawn(watchForJiraIntegrationAppSpecificDashboardPageViewRequested);

  yield spawn(watchForCreateJiraIntegrationConfig);
  yield spawn(watchForPatchJiraIntegrationConfig);
  yield spawn(watchForJiraIntegrationConfigListViewPageMounted);
}
