import React from 'react';
import { Stack, styled } from '@mui/material';
import Text from '@dt/ahura/src/components/Text';
const LabelText = styled(Text)({
    margin: 0,
});
const TopStack = styled(Stack)(({ theme }) => ({
    maxWidth: '270px',
    padding: theme.spacing(1),
}));
export const MenuItemWithIcon = ({ icon, label, description }) => {
    return (React.createElement(TopStack, { direction: "row", spacing: 2 },
        React.createElement("img", { alt: `${label} Icon`, src: icon }),
        React.createElement(Stack, null,
            React.createElement(LabelText, { component: "p" }, label),
            React.createElement(Text, { component: "span", variant: "label" }, description))));
};
