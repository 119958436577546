import { createSelector } from 'reselect';

const cacheMap = state => state.resource_fetch.cache;
const getKey = (state, props = {}) => props.key;

export const getCacheStatusFromKeySelector = createSelector(
  getKey,
  cacheMap,

  (key, cacheMap) => {
    let started = null;
    let ended = null;

    const cache = cacheMap.get(key);

    if (cache) {
      started = cache.startedAt;
      ended = cache.endedAt;
    }

    return {
      ended,
      started,
    };
  },
);
