import config from '@dt/config';
import fetch, { parse } from '@dt/fetch';
import qs from 'query-string';

const api = config.horizonApiBaseUrl;
const version = 'v1';

export async function list(params) {
  return fetch(`${api}/${version}/events?${qs.stringify(params || {})}`).then(parse);
}
