import React, { useCallback, useEffect, useState } from 'react';
import { Alert, AlertTitle } from '@mui/material';
import { Button, Grid, IconButton, Snackbar } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Text from '@dt/ahura/src/components/Text';
import { useNavigate } from '@reach/router';
import { differenceInCalendarDays, isAfter } from 'date-fns';
import useCookie from '../hooks/use_cookie';
import { dateFormats } from '../util/dateFormats';
import { formatDateDefault } from '../util/formatDateDefault';
const safeParse = (value) => {
    try {
        return value ? JSON.parse(value) : null;
    }
    catch (e) {
        console.error(e);
        return null;
    }
};
const useStyles = makeStyles({
    snackbar: {
        width: '80%',
    },
});
const HIDE_AFTER_SHOWN_TIMES = 2;
const expires = '2023-09-03';
const hash = 'c7fa6ff47492e011a686218193275812';
const expiryDate = formatDateDefault({ date: new Date(expires), formatStr: dateFormats.MMMddyyyy });
const daysToExpiry = differenceInCalendarDays(expiryDate, new Date());
const cookieKey = hash ? `DT-${hash}` : '';
const TemporaryBroadcastMessage = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [dismissed, setDismissed] = useState(false);
    const [isClosed, setIsClosed] = useState(false);
    const [broadcastCookie, setBroadcastCookie] = useCookie(cookieKey);
    const broadcastCookieJSON = safeParse(broadcastCookie);
    const dismissedOrExpiredCookie = broadcastCookieJSON
        ? broadcastCookieJSON.dismissed ||
            isAfter(formatDateDefault({ date: new Date(), formatStr: dateFormats.yyyyMMdd }), broadcastCookieJSON.expires)
        : false;
    const timesShownCookie = broadcastCookieJSON?.timesShown || 1;
    const [timesShown, setTimeShown] = useState(timesShownCookie);
    const incrementTimesShown = () => {
        const cookieValue = JSON.stringify({
            dismissed: false,
            expires: expiryDate,
            timesShown: timesShown + 1,
        });
        setBroadcastCookie(cookieValue, { expires: daysToExpiry });
        setTimeShown((prev) => prev + 1);
        setIsClosed(true);
    };
    const BROADCAST_MESSAGE = {
        expires,
        hash,
        message: (React.createElement("div", { style: { alignItems: 'center', display: 'flex', flexDirection: 'column' } },
            React.createElement(IconButton, { onClick: incrementTimesShown, size: "small", style: { position: 'absolute', right: 6, top: 6 } }, "X"),
            React.createElement("ul", null,
                React.createElement("li", null,
                    React.createElement(Text, { component: "span", variant: "body" }, "New Product: SAST")),
                React.createElement("li", null,
                    React.createElement(Text, { component: "span", variant: "body" }, "New Features: Mobile Secure, API Secure, and Slack"))))),
        timesShown,
        title: "Watch Data Theorem's QBR for 2023 Q3",
    };
    // No existing cookie for the current broadcast message
    useEffect(() => {
        if (typeof broadcastCookie === 'undefined') {
            const cookieValue = JSON.stringify({
                dismissed: false,
                expires: expiryDate,
                timesShown,
            });
            daysToExpiry > 0 || timesShown < 2
                ? setBroadcastCookie(cookieValue, { expires: daysToExpiry })
                : setBroadcastCookie(cookieValue);
        }
    }, [broadcastCookie, setBroadcastCookie, timesShown]);
    const handleDismissBroadcastMessage = useCallback((event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setDismissed(true);
        const cookieValue = JSON.stringify({
            dismissed: true,
            expires: broadcastCookie ? JSON.parse(broadcastCookie)['expires'] : 0,
        });
        setBroadcastCookie(cookieValue, {
            expires: daysToExpiry > 0 ? daysToExpiry : 0,
        });
    }, [setBroadcastCookie, broadcastCookie]);
    if (isClosed ||
        dismissed ||
        dismissedOrExpiredCookie ||
        daysToExpiry <= 0 ||
        timesShown > HIDE_AFTER_SHOWN_TIMES ||
        timesShownCookie > HIDE_AFTER_SHOWN_TIMES) {
        return null;
    }
    if (!BROADCAST_MESSAGE.message) {
        return null;
    }
    return (React.createElement(Snackbar, { anchorOrigin: { horizontal: 'center', vertical: 'top' }, className: classes.snackbar, onClose: handleDismissBroadcastMessage, open: !dismissed },
        React.createElement(Alert, { action: React.createElement(Grid, { container: true, direction: 'row', justifyContent: 'flex-end' },
                React.createElement(Button, { "aria-label": 'Dismiss', color: "primary", onClick: () => {
                        handleDismissBroadcastMessage();
                        navigate('/devsecops/v2/stanford-dish-security-university');
                    }, size: 'small', variant: "outlined" }, "Watch Now")), onClose: handleDismissBroadcastMessage, severity: "success", style: { position: 'relative' }, variant: "standard" },
            BROADCAST_MESSAGE.title && React.createElement(AlertTitle, null, BROADCAST_MESSAGE.title),
            BROADCAST_MESSAGE.message)));
};
export default TemporaryBroadcastMessage;
