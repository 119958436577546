import React, { memo, useState } from 'react';
import { Checkbox, Grid, List, ListItem, TablePagination } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { makeStyles } from '@mui/styles';
import { useQuery } from '@dt/apollo-link-schema-rest';
import { ErrorState } from '@dt/components';
import asset_groups from '@dt/graphql-support/horizon/asset_groups';
import { Text } from '@dt/material-components';

const useStyles = makeStyles(theme => ({
  itemLogo: {
    justifyContent: 'center',
  },
  list: {
    padding: 0,
    paddingLeft: theme.spacing(3),
  },
  mainList: {
    height: 300,
    overflow: 'scroll',
    overflowX: 'auto',
  },
}));

const AssetFilterAssetGroup = ({ selectedAssetGroupIds, onChange }) => {
  const [page, setPage] = useState(0);
  const classes = useStyles();
  const rowsPerPage = 10;

  const { data, loading, error, fetchMore } = useQuery(asset_groups.v2_list, {
    variables: {
      page_size: 10,
    },
  });

  if (error) {
    return <ErrorState error={error.message} />;
  }

  if (loading) {
    return (
      <Grid container justifyContent={'center'} spacing={1}>
        {[...new Array(6)].map((_, key) => (
          <Grid item key={key} xs={10}>
            <Skeleton animation="wave" height={40} width={`100%`} />
          </Grid>
        ))}
      </Grid>
    );
  }

  const paginationInformation = data?.asset_group_list_v2.pagination_information;
  const totalCount = paginationInformation?.total_count ?? 0;
  const items = data?.asset_group_list_v2.asset_groups || [];
  const noItems = totalCount === 0;

  const handleChangePage = (event, newPage) => {
    if ((items.length || 0) / rowsPerPage / (newPage + 1) <= 1) {
      fetchMore && fetchMore();
    }
    setPage(newPage);
  };

  const handleToggleAssetGroup = selectedAssetGroup => {
    if (selectedAssetGroupIds.includes(selectedAssetGroup.id)) {
      onChange(selectedAssetGroupIds.filter(el => el !== selectedAssetGroup.id));
    } else {
      onChange([...selectedAssetGroupIds, selectedAssetGroup.id]);
    }
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <List className={classes.mainList} component="nav">
          {items.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((assetGroup, key) => (
            <ListItem button dense key={key} onClick={() => handleToggleAssetGroup(assetGroup)}>
              <Checkbox checked={selectedAssetGroupIds.includes(assetGroup.id)} />
              <Text noWrap>{assetGroup.name}</Text>
            </ListItem>
          ))}
        </List>
        {!noItems && (
          <TablePagination
            component="div"
            count={totalCount}
            // @ts-ignore
            nextIconButtonProps={loading ? { disabled: true } : null}
            onPageChange={handleChangePage}
            page={page}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={[]}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default memo(AssetFilterAssetGroup);
