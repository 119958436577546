import { createAction } from 'redux-actions';

export const specialScanRequestReceived = createAction('special_scan_request/received', list => list);

export const piiReportsInApiResponsesReceived = createAction(
  'special_scan_request/pii_reports_in_api_responses/received',
  pii_reports_in_api_responses => ({
    pii_reports_in_api_responses,
  }),
);
