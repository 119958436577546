import config from '@dt/config';
import fetch, { parse } from '@dt/fetch';

const api = config.horizonApiBaseUrl;
const version = 'v1';

export async function create(params) {
  return fetch(`${api}/${version}/ip_range_scans`, {
    body: JSON.stringify(params),
    method: 'POST',
  }).then(parse);
}
