import React, { useState } from 'react';
import { Tab, Tabs } from '@mui/material';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import { palette } from '@dt/theme';
import Text from './Text';

const useStyles = makeStyles(() => ({
  selectedTab: {
    borderBottom: `3px solid ${palette.brand35}`,
    color: palette.brand35,
  },
  tab: {
    color: palette.gray30,
    maxWidth: 'inherit',
  },
  tabContainer: {
    overflowX: 'auto',
    paddingBlockStart: '1em',
  },
  tabContainerContent: {
    padding: 0,
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
  },
}));

export default function QueryAndResponse({ scanTrace }) {
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState('query');

  return (
    <Box borderRadius={0} px={3} py={1} style={{ padding: 0, width: '100%' }}>
      <Tabs
        aria-label="query and response details"
        indicatorColor="primary"
        onChange={(e, newValue) => setActiveTab(newValue)}
        style={{
          borderBottom: `2px solid ${palette.gray30}`,
          color: palette.brand35,
        }}
        value={activeTab}
      >
        <Tab
          className={classes.tab}
          classes={{
            selected: classes.selectedTab,
          }}
          label="Query"
          value="query"
        />

        <Tab
          className={classes.tab}
          classes={{
            selected: classes.selectedTab,
          }}
          label="Response"
          value="response"
        />
      </Tabs>

      <div className={classes.tabContainer}>
        <Text className={classes.tabContainerContent} variant="code">
          {activeTab === 'query'
            ? scanTrace?.query
              ? scanTrace?.query
              : 'Currently unavailable. Please check back later!'
            : null}

          {activeTab === 'response'
            ? scanTrace?.response
              ? scanTrace?.response
              : 'Currently unavailable. Please check back later!'
            : null}
        </Text>
      </div>
    </Box>
  );
}
