import gql from 'graphql-tag';

const get = gql`
  query CloudResourceDetails($id: ID) {
    cloud_resource_details(id: $id) {
      asset_groups {
        id
      }
      cloud_resources {
        id
        date_created
        name
        region
        resource_type
        asset_type_icon_url
        asset_type_name
        runtime
        status
        date_no_longer_accessible
        discovered_via
        hosted_on
        cloud_console_url
        url
        policy_violations {
          id
          status
          violated_policy_rule {
            id
            relevance
            policy_rule_type {
              id
              title
            }
          }
        }
      }
      policy_violations {
        id
        status
        additional_info
        date_created
        date_resolved
        exception_type
        exception_date_created
        affected_cloud_resource_id
        violated_policy_rule {
          id
          relevance
          policy_rule_type {
            id
            title
            compliance_policy_references {
              compliance_standard
              compliance_standard_criteria
              description
              link
            }
          }
        }
        affected_network_service {
          id
          asset_type_name
          domain_name {
            id
            name
          }
        }
        affected_cloud_resource {
          id
          asset_type_name
          url
        }
      }
      restful_apis {
        id
        base_url
        asset_type_name
        asset_type_icon_url
      }
      network_services {
        id
        url
        hosted_on
        asset_type_icon_url
        asset_type_name
      }
      web_applications {
        id
        base_url
        asset_type_name
        asset_type_icon_url
      }
      mobile_applications {
        id
      }
      events {
        id
      }
      policy_rule_types {
        id
      }
      policy_rules {
        id
      }
    }
  }
`;

const get_v2 = gql`
  query CloudResourceDetailsVersion2($id: ID) {
    cloud_resource_details_v2(id: $id) {
      id
      date_created
      name
      region
      resource_type
      asset_type_icon_url
      asset_type_name
      runtime
      status
      date_no_longer_accessible
      discovered_via
      hosted_on
      cloud_console_url
      url
      open_urgent_policy_violations_count
      open_important_policy_violations_count
      open_proactive_policy_violations_count
      belongs_to_cloud_account {
        id
        cloud_account_icon_url
        cloud_account_type_name
        discovered_via_cloud_authenticator_id
        customer_supplied_name
      }
      connected_assets {
        id
        name
        url
        asset_type
        asset_type_name
        asset_type_icon_url
      }
    }
  }
`;

const list = gql`
  query CloudResourcesList(
    $cursor: String
    $include: String
    $filter_by_discovered_via: String
    $filter_by_domain_name_id: ID
    $filter_by_cloud_provider: [HostedOn]
    $filter_by_text: String
    $filter_by_named_filter: String
    $filter_by_category: [FilterByCategoryEnum]
    $order_by: CloudResourceOrderBy
  ) {
    cloud_resource_list(
      cursor: $cursor
      include: $include
      filter_by_discovered_via: $filter_by_discovered_via
      filter_by_domain_name_id: $filter_by_domain_name_id
      filter_by_cloud_provider: $filter_by_cloud_provider
      filter_by_text: $filter_by_text
      filter_by_named_filter: $filter_by_named_filter
      filter_by_category: $filter_by_category
      order_by: $order_by
    ) {
      pagination_information {
        next_cursor
        total_count
      }
      cloud_resources {
        cloud_events_count
        id
        url
        name
        status
        date_no_longer_accessible
        hosted_on
        runtime
        region
        resource_type
        asset_type_name
        asset_type_icon_url
        date_created
        cloud_console_url
        discovered_via
        aws_arn
        gcp_project_id
        mulesoft_domain
        azure_tenant_id
        axway_org_id
        kong_workspace_name
        open_urgent_policy_violations_count
        open_important_policy_violations_count
        open_proactive_policy_violations_count
        resource_type_icon_url
        included_break_glass_stats {
          remediation_field {
            remediation_instructions
            remediation_url
          }
          unauthenticated_policy_violation_id
        }
        included_code_red_stats {
          unauthenticated_policy_violation_id
        }
        included_cloud_dlp_stats {
          pii_leaking_policy_violation_id
          pii_leaking_policy_violation_status
          unauthenticated_policy_violation_id
          unauthenticated_policy_violation_status
        }
        included_cloud_ransomware_stats {
          malware_detected_policy_violation_uuid
          new_directories_since_last_week
          new_directories_since_last_week_percentage
          new_edits_since_last_week
          new_files_since_last_week
          new_files_since_last_week_percentage
          ransomware_detected_policy_violation_uuid
          readable_storage_policy_violation_uuid
          writable_storage_policy_violation_uuid
          total_directories_count
          total_files_count
          scan_run_id
          last_scan_date
        }
      }
    }
  }
`;

const policy_violation_list = gql`
  query CloudResourceDetailsPolicyViolationsList($cursor: String, $id: ID) {
    cloud_resource_details_policy_violations_list(cursor: $cursor, id: $id) {
      pagination_information {
        next_cursor
        total_count
      }
      policy_violations {
        id
        date_created
        date_resolved
        exception_type
        additional_info
        status
        exception_date_created
        exception_explanation
        affected_asset {
          id
          name
          url
          asset_type
          asset_type_icon_url
          asset_type_name
          date_created
          hosted_on
          aws_arn
          belongs_to_cloud_account {
            id
            cloud_account_icon_url
            cloud_account_type_name
            discovered_via_cloud_authenticator_id
            customer_supplied_name
          }
        }
        violated_policy_rule {
          id
          policy_rule_type {
            id
            title
            description
            recommendation
            default_relevance
            compliance_policy_references {
              compliance_standard
              compliance_standard_criteria
              description
              link
            }
            related_rule_types {
              id
              title
              description
              recommendation
              default_relevance
              compliance_policy_references {
                compliance_standard
                compliance_standard_criteria
                description
                link
              }
            }
          }
          relevance
        }
      }
    }
  }
`;

export default {
  get,
  get_v2,
  list,
  policy_violations: {
    list: policy_violation_list,
  },
};
