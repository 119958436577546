import { useMutation } from '@apollo/client';
// @ts-ignore: #TODO-IMPLEMENT-TYPES-APOLLO-LINK-SCHEMA-REST
import { useQuery } from '@dt/apollo-link-schema-rest';
import policy_violations from '@dt/graphql-support/horizon/ts/policy_violations';
import alerts_integrations from '@dt/graphql-support/public/integrations/ts/alerts_integrations';
const useExportViolationExportToJira = ({ policyViolationId, handleClose, setSnackOpen, setJiraTicketId }) => {
    const { data, fetchMore, loading, error } = useQuery(alerts_integrations.list, {
        fetchPolicy: 'network-only',
        variables: {
            filter_by_integration_type: ['JIRA'],
        },
    });
    const [exportToJira, { error: errorExportToJira, loading: loadingExportToJira }] = useMutation(policy_violations.exportToJira, {
        onError: () => {
            handleClose();
            setSnackOpen('ERR');
        },
        update: (cache, result) => {
            if (result?.errors) {
                return;
            }
            const queryVariables = {
                id: policyViolationId,
            };
            const q = cache.readQuery({
                query: policy_violations.v2_get,
                variables: queryVariables,
            });
            const newItem = result?.data?.policy_violation_export_jira;
            setJiraTicketId(newItem?.external_key || '');
            const previousExternalIntegrations = q?.policy_violation_details_v2.external_integrations;
            cache.writeQuery({
                data: {
                    policy_violation_details_v2: {
                        ...q?.policy_violation_details_v2,
                        external_integrations: [...(previousExternalIntegrations || []), newItem],
                    },
                },
                query: policy_violations.v2_get,
                variables: queryVariables,
            });
            handleClose();
            setSnackOpen('OK');
        },
    });
    return {
        data,
        error,
        errorExportToJira,
        exportToJira,
        fetchMore,
        loading,
        loadingExportToJira,
    };
};
export default useExportViolationExportToJira;
