import { receivedSearchResults } from './../actions';

export default function (state = {}, action) {
  if (action.type !== receivedSearchResults.toString()) {
    return state;
  }

  return {
    ...state,
    [action.payload.search]: action.payload.search_results,
  };
}
