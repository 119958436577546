import React, { memo } from 'react';
import { Card, CardContent, Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { palette } from '@dt/theme';

const useStyles = makeStyles({
  cardContainer: {
    backgroundColor: palette.gray50,
    height: '100%',
    minHeight: 220,
  },
});

function ToolkitCard({ children }) {
  const classes = useStyles();
  return (
    <Card className={classes.cardContainer}>
      <CardContent>
        <Grid container>{children}</Grid>
      </CardContent>
    </Card>
  );
}

export default memo(ToolkitCard);
