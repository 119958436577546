import React from 'react';
import { Grid } from '@mui/material';
import PolicyRuleTypesCardLog4ShellDiagram from './PolicyRuleTypesCardLog4ShellDiagram';

const POLICY_RULE_TYPE_LOOKUP = {
  '897acf49-6d42-59e0-8d7b-0d0cea6b25a8': <PolicyRuleTypesCardLog4ShellDiagram />,
  'cc8e35cf-2ddd-526b-bce9-350f7247e75b': <PolicyRuleTypesCardLog4ShellDiagram />,
};

const PolicyRuleTypesCardRelativeComponents = ({ policy_rule_type_id }) => {
  if (POLICY_RULE_TYPE_LOOKUP[policy_rule_type_id]) {
    return (
      <Grid item xs={12}>
        {POLICY_RULE_TYPE_LOOKUP[policy_rule_type_id]}
      </Grid>
    );
  }

  return null;
};

export default React.memo(PolicyRuleTypesCardRelativeComponents);
