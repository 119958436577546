import { array, object, string } from 'yup';

export const TargetStatusSchema = object().shape({
  date: string().required(),
  status: string().required(),
});

const TargetAdditionalContextSchema = object().shape({
  body: string().required(),
  type: string().required(),
});

export const TargetSchema = object().shape({
  additional_contexts: array().of(TargetAdditionalContextSchema),
  formatted_text: string().required(),
  id: string().required(),
  statuses: array().of(TargetStatusSchema).required(),
});
