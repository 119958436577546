import { useCallback, useEffect, useState } from 'react';

export default function useMouseMove(onMove) {
  const [active, setActive] = useState(false);

  const handleUp = useCallback(() => {
    setActive(false);
  }, []);

  const handleDown = useCallback(() => {
    setActive(true);
  }, []);

  // set up listening
  useEffect(() => {
    if (active) {
      document.addEventListener('mouseup', handleUp, true);
      document.addEventListener('mousemove', onMove, true);
    }
    return () => {
      document.removeEventListener('mouseup', handleUp, true);
      document.removeEventListener('mousemove', onMove, true);
    };
  }, [active, onMove, handleUp]);

  return [handleDown, handleUp, active];
}
