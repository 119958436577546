import { asset_groups } from '@dt/horizon-api';
import { callPromise } from '@dt/redux-saga-wrapped-effects';
import { all, call, put, spawn, takeEvery } from 'redux-saga/effects';
import { getPolicyList } from '../policies/resource_fetch.sagas';
import {
  handleNormalizedResponse,
  takePatternAndFetch,
  watchForLoadMoreAndFetchNextPage,
} from '../resource_fetch/sagas';
import {
  assetGroupChangePolicy,
  assetGroupDeleteClicked,
  assetGroupsCreate,
  assetGroupsCreateError,
  assetGroupsCreateSuccess,
  assetGroupsDetailsMounted,
  assetGroupsListMounted,
  assetGroupsReceived,
  assetGroupsWizardListMounted,
} from './actions';
import { assetGroupMembershipCreate } from './memberships/actions';
import watchForResourceFetchingMemberships, { assetGroupMembershipCreateSaga } from './memberships/sagas';
import resourceType from './resourceType';
import { getAllAssetGroups, getAssetGroupDetails } from './resource_fetch.sagas';

export default function* watchForResourceFetching() {
  yield all([
    spawn(watchForResourceFetchingMemberships),
    call(takePatternAndFetch, assetGroupsListMounted.toString(), function* () {
      yield call(getAllAssetGroups, {});
    }),
    call(takePatternAndFetch, assetGroupsWizardListMounted.toString(), function* () {
      yield call(getPolicyList, {});
    }),
    call(watchForLoadMoreAndFetchNextPage, resourceType, function* ({ cursor }) {
      yield call(getAllAssetGroups, { cursor });
    }),

    call(takePatternAndFetch, assetGroupsDetailsMounted.toString(), function* (action) {
      yield call(getAssetGroupDetails, action.payload.assetGroupId);
    }),
    takeEvery(assetGroupsCreate.toString(), assetGroupsCreateSaga),
    takeEvery(assetGroupDeleteClicked.toString(), assetGroupsDeleteSaga),
    takeEvery(assetGroupChangePolicy.toString(), assetGroupPatchSaga),
  ]);
}

function* assetGroupsCreateSaga(action) {
  try {
    const payload = action.payload;

    const result = yield* callPromise(asset_groups.create, {
      attached_policy_id: payload.attached_policy_id,
      description: payload.description,
      name: payload.name,
    });

    if (result._type === 'error') {
      throw new Error(result.title);
    }

    const asset_group = result.body.asset_groups && result.body.asset_groups[0];
    if (asset_group) {
      yield put(assetGroupsReceived([asset_group]));
    } else {
      throw new Error('Error while creating asset group');
    }

    // Check if need to assign any asset
    if (payload.assets) {
      yield call(
        assetGroupMembershipCreateSaga,
        assetGroupMembershipCreate({
          assetGroupId: asset_group.id,
          assets: payload.assets,
        }),
      );
    }

    yield put(assetGroupsCreateSuccess(asset_group.id));
  } catch (e) {
    yield put(assetGroupsCreateError('Could not add asset group'));
  }
}

function* assetGroupsDeleteSaga(action) {
  const payload = action.payload;
  yield* callPromise(asset_groups.remove, payload);
}

function* assetGroupPatchSaga(action) {
  const payload = action.payload;

  const response = yield* callPromise(asset_groups.patch, payload.assetGroupId, {
    attached_policy_id: payload.policyId,
  });

  if (response._type === 'error') {
    throw new Error(response.title);
  }

  yield call(handleNormalizedResponse, response.body);
}
