import immer from 'immer';
import {
  openAPIDefinitionUpload,
  openAPIDefinitionUploadError,
  openAPIDefinitionUploadReset,
  openAPIDefinitionUploadSuccess,
  openAPIDefinitionsReceived,
} from './actions';

const initialState = {
  for_discovered_via: {},
  id: {},
  uploadError: null,
  uploadSuccess: false,
};

export default immer((draft, action) => {
  if (action.type === openAPIDefinitionUpload.toString()) {
    draft.uploadSuccess = false;
    draft.uploadError = null;
  } else if (action.type === openAPIDefinitionUploadSuccess.toString()) {
    draft.uploadSuccess = true;
    draft.uploadError = null;
  } else if (action.type === openAPIDefinitionUploadError.toString()) {
    draft.uploadSuccess = false;
    draft.uploadError = action.payload;
  } else if (action.type === openAPIDefinitionUploadReset.toString()) {
    draft.uploadSuccess = false;
    draft.uploadError = null;
  } else if (action.type === openAPIDefinitionsReceived.toString()) {
    for (const openApiDefinition of action.payload) {
      draft.id[openApiDefinition.id] = openApiDefinition;
      const discovered = (draft.for_discovered_via[openApiDefinition.discovered_via] =
        draft.for_discovered_via[openApiDefinition.discovered_via] || []);
      if (!discovered.includes(openApiDefinition.id)) {
        discovered.push(openApiDefinition.id);
      }
    }
  }
}, initialState);
