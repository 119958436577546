import React, { useMemo, useState } from 'react';
import TableFilters from '../components/table_filters/TableFilters';
import { filterItems } from '../components/table_filters/components/TableFiltersPopper';
import useTableFIlterUrlParams from './use_table_filter_url_params';

/**
 *  implement filters to UI
 *  read filter params from URL
 *  write filter params to URL
 *  send filter_by_*** params to back-end queries
 *
 *  steps to add new filter parameter
 *    1. add new param to Filter Type in ./src/components/table_filters/components/TableFiltersPopper.js
 *    2. add new param to filterTitles Object in ./src/components/table_filters/TableFilters.js
 *    3. create new filter UI in ./src/components/table_filters/components/filters
 *    4. create new filter chips in ./src/components/table_filters/components/chips/TableFiltersChips.js
 *    5. add filter to read/write functions in ./src/hooks/use_table_filter.js
 *
 */

export const useTableFilter = ({
  assetsSearchFilterValuesQueryParams,
  filterEnums,
  readOnlyFilters,
  initialFilterValues,
  enableBookmarking,
  disabled,
  onApply,
  isMobilePhishing = false,
  isSast = false,
  isPolicyRules = false,
  isIssues = false,
  isWebSecureChanges = false,
  isPostmanProtect = false,
  isAntiFraud = false,
  isApiSecureThreats = false,
}) => {
  const urlParams = useTableFIlterUrlParams(Object.keys(filterItems));

  const [userInterfaceFilter, setUserInterfaceFilter] = useState(
    enableBookmarking ? { ...initialFilterValues, ...urlParams.read() } : initialFilterValues ?? null,
  );

  const filters = useMemo(() => {
    return userInterfaceFilter
      ? {
          ...readOnlyFilters,
          ...userInterfaceFilter,
        }
      : readOnlyFilters || null;
  }, [readOnlyFilters, userInterfaceFilter]);

  const handleSetUserInterfaceFilter = React.useMemo(
    () => filter => {
      setUserInterfaceFilter(filter);
      enableBookmarking && urlParams.write(filter);
      onApply && onApply();
    },
    [enableBookmarking, urlParams, onApply],
  );

  const resetFilters = (bypassOnApply = false) => {
    setUserInterfaceFilter(null);
    if (!bypassOnApply) {
      onApply && onApply();
    }
  };

  const FilterComponent = React.useMemo(() => {
    const FilterComponent = ({ filterButtonName }) => {
      return (
        <TableFilters
          assetsSearchFilterValuesQueryParams={assetsSearchFilterValuesQueryParams}
          disabled={Boolean(disabled)}
          filterButtonName={filterButtonName}
          filterEnums={filterEnums}
          filters={filters || {}}
          isAntiFraud={isAntiFraud}
          isApiSecureThreats={isApiSecureThreats}
          isIssues={isIssues}
          isMobilePhishing={isMobilePhishing}
          isPolicyRules={isPolicyRules}
          isPostmanProtect={isPostmanProtect}
          isSast={isSast}
          isWebSecureChanges={isWebSecureChanges}
          onApply={handleSetUserInterfaceFilter}
          readOnlyFilters={readOnlyFilters || {}}
        />
      );
    };
    return FilterComponent;
  }, [
    assetsSearchFilterValuesQueryParams,
    filterEnums,
    isMobilePhishing,
    isPolicyRules,
    isSast,
    isIssues,
    isPostmanProtect,
    filters,
    readOnlyFilters,
    disabled,
    handleSetUserInterfaceFilter,
    isWebSecureChanges,
    isAntiFraud,
    isApiSecureThreats,
  ]);

  return {
    FilterComponent,
    filters,
    resetFilters,
    userInterfaceFilter,
  };
};
