import React, { memo } from 'react';
import FlagIcon from '@mui/icons-material/AssistantPhoto';
import InfoIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import { Avatar, Checkbox, List, ListItem, ListItemIcon } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Text } from '@dt/material-components';
import { palette } from '@dt/theme';

const useStyles = makeStyles(theme => ({
  itemLogo: {
    justifyContent: 'center',
  },
  list: {
    padding: 0,
    paddingLeft: theme.spacing(3),
  },
  mainList: {
    maxHeight: 350,
    overflow: 'auto',
    overflowX: 'auto',
  },
}));

const TableFiltersSeverity = ({ selectedList, onChange }) => {
  const classes = useStyles();

  const handleChange = id => {
    if (selectedList.includes(id)) {
      onChange(selectedList.filter(item => item !== id));
    } else {
      onChange(selectedList.concat(id));
    }
  };

  return (
    <List className={classes.mainList} component="nav">
      <ListItem button dense onClick={() => handleChange('HIGH')} selected={selectedList.includes('HIGH')}>
        <Checkbox checked={Boolean(selectedList.includes('HIGH'))} />
        <ListItemIcon className={classes.itemLogo}>
          <Avatar style={{ backgroundColor: palette.red50, height: 24, width: 24 }}>
            <WarningIcon style={{ fill: palette.red10, fontSize: 16 }} />
          </Avatar>
        </ListItemIcon>
        <Text component={'div'} noWrap variant={'body'}>
          High
        </Text>
      </ListItem>

      <ListItem button dense onClick={() => handleChange('MEDIUM')} selected={selectedList.includes('MEDIUM')}>
        <Checkbox checked={Boolean(selectedList.includes('MEDIUM'))} />
        <ListItemIcon className={classes.itemLogo}>
          <Avatar style={{ backgroundColor: palette.yellow50, height: 24, width: 24 }}>
            <FlagIcon style={{ fill: palette.yellow10, fontSize: 16 }} />
          </Avatar>
        </ListItemIcon>
        <Text component={'div'} noWrap variant={'body'}>
          Medium
        </Text>
      </ListItem>

      <ListItem button dense onClick={() => handleChange('LOW')} selected={selectedList.includes('LOW')}>
        <Checkbox checked={Boolean(selectedList.includes('LOW'))} />
        <ListItemIcon className={classes.itemLogo}>
          <Avatar style={{ backgroundColor: palette.blue50, height: 24, width: 24 }}>
            <InfoIcon style={{ fill: palette.blue10, fontSize: 16 }} />
          </Avatar>
        </ListItemIcon>

        <Text component={'div'} noWrap variant={'body'}>
          Low
        </Text>
      </ListItem>
    </List>
  );
};

export default memo(TableFiltersSeverity);
