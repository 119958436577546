import React, { useState } from 'react';
import AssistantIcon from '@mui/icons-material/Assistant';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Tooltip } from '@mui/material';
import { useQuery } from '@dt/apollo-link-schema-rest';
import policy_violations from '@dt/graphql-support/horizon/policy_violations';
import { Markdown } from '@dt/material-components';

export const PolicyViolationsActionBarRemediateButton = ({ policy_violation_id }) => {
  // Get policy violation.
  const { data: policyViolationsGetData } = useQuery(policy_violations.v2_get, {
    variables: {
      id: policy_violation_id,
    },
  });

  const [remediationOpen, setRemediationOpen] = useState(false);

  const remediation = policyViolationsGetData?.policy_violation_details_v2.auto_remediation_info;

  // No remediation available, disable completely.
  if (!remediation)
    return (
      <Tooltip disableInteractive title="This violation doesn't support auto remediation">
        <div>
          <Button color="primary" disabled size="small" startIcon={<AssistantIcon />} variant="contained">
            Remediate
          </Button>
        </div>
      </Tooltip>
    );

  return (
    <>
      <Button
        color="primary"
        onClick={() => setRemediationOpen(true)}
        size="small"
        startIcon={<AssistantIcon />}
        variant="contained"
      >
        Remediate
      </Button>

      <Dialog
        aria-labelledby="dialog-remediation-title"
        onClose={() => setRemediationOpen(false)}
        open={remediationOpen}
        scroll="paper"
      >
        <DialogTitle id="dialog-remediation-title">Remediation Instructions</DialogTitle>

        <DialogContent>
          <DialogContentText>
            <Markdown text={remediation.remediation_instructions} />
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setRemediationOpen(false)}>Close</Button>
          <a href={remediation.remediation_url} rel="noopener noreferrer" target="_blank">
            <Button color="primary" onClick={() => setRemediationOpen(false)} variant="outlined">
              Start
            </Button>
          </a>
        </DialogActions>
      </Dialog>
    </>
  );
};
