import { createAction } from 'redux-actions';
import createReducerCreator from 'rezz/createReducerCreator';
import set from 'rezz/set';

export const setRowCount = createAction('SET_ROW_COUNT', (query, rowCount) => ({
  query,
  rowCount,
}));

export default createReducerCreator({
  initialState: {},
  keyStrategy: action => action.payload.query,
  payloadStrategy: action => action.payload.rowCount,
})(set(setRowCount.toString()));
