import { policy_rule_types } from '@dt/horizon-api';
import { callPromise } from '@dt/redux-saga-wrapped-effects';
import { call } from 'redux-saga/effects';
import { handleNormalizedResponse, withCache } from './../resource_fetch/sagas';

export const getPolicyRuleTypeList = withCache('policy_rule_list', function* getPolicyRuleTypeList() {
  const response = yield* callPromise(policy_rule_types.list);

  if (response._type === 'error') {
    throw new Error(response.title);
  }

  yield call(handleNormalizedResponse, response.body);
});
