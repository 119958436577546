import { policy_violations } from '@dt/horizon-api';
import { paginationBeginRequest, paginationEndRequest } from '@dt/pagination';
import { callPromise } from '@dt/redux-saga-wrapped-effects';
import { call, put } from 'redux-saga/effects';
import { handleNormalizedResponse, withCache } from './../resource_fetch/sagas';
import resourceType from './resourceType';

export const getPolicyViolationList = withCache('policy_violations_list', function* getPolicyViolationList(params) {
  yield put(paginationBeginRequest(resourceType, params || {}));

  const response = yield* callPromise(policy_violations.list, params ? params : {});
  if (response._type === 'error') {
    throw new Error(response.title);
  }

  yield call(handleNormalizedResponse, response.body);

  if (response.body.pagination_information) {
    yield put(paginationEndRequest(resourceType, params || {}, response.body.pagination_information));
  }
});

export const getPolicyViolationDetails = withCache(
  policy_violation_id => `policy_violation_${policy_violation_id}`,
  function* getPolicyViolationDetails(policy_violation_id) {
    const response = yield* callPromise(policy_violations.details, policy_violation_id);

    if (response._type === 'error') {
      throw new Error(response.title);
    }

    yield call(handleNormalizedResponse, response.body);
  },
);
