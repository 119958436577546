import { asset_groups } from '@dt/horizon-api';
import { paginationBeginRequest, paginationEndRequest } from '@dt/pagination';
import { callPromise } from '@dt/redux-saga-wrapped-effects';
import { call, putResolve } from 'redux-saga/effects';
import { handleNormalizedResponse, withCache } from '../resource_fetch/sagas';
import resourceType from './resourceType';

export const getAllAssetGroups = withCache('asset_groups', function* getAlertsIntegrations(params) {
  yield putResolve(paginationBeginRequest(resourceType, params));
  const response = yield* callPromise(asset_groups.list);

  if (response._type === 'error') {
    throw new Error(response.title);
  }

  yield call(handleNormalizedResponse, response.body);
  if (response.body.pagination_information) {
    yield putResolve(paginationEndRequest(resourceType, params, response.body.pagination_information));
  }
});

export const getAssetGroupDetails = withCache(
  networkServiceId => `asset_group_${networkServiceId}`,
  function* (assetGroupId) {
    const response = yield* callPromise(asset_groups.details, assetGroupId);

    if (response._type === 'error') {
      throw new Error(response.title);
    }

    yield call(handleNormalizedResponse, response.body);
  },
);
