// sort-imports-ignore
import { billingAction } from '@dt/billing-info';
import { Raven } from '@dt/global';
import { MobileAppReleaseTypeValues } from '@dt/graphql-support/enums';
import { withProgressIndicator } from '@dt/progress-indicator';
import { callPromise, callSaga, select } from '@dt/redux-saga-wrapped-effects';
import { getUserAccount } from '@dt/session';
import app_uploads from '@dt/user-api/app_uploads';
import { list, patch } from '@dt/user-api/mobile_apps';
import * as uploadXCUITestService from '@dt/user-api/xcuitest_uploads';
import { call, put, spawn, takeEvery } from 'redux-saga/effects';
import {
  appFileAccepted,
  appFileRejected,
  appFileSubmitted,
  appXCUITestFileAccepted,
  appXCUITestFileRejected,
  appXCUITestFileSubmit,
  finishedLoadingApps,
  receivedAppsError,
  startedLoadingApps,
  uploadButtonClicked,
} from '../actions';
import { updateAppLinkingFailure, updateAppLinkingStarted, updateAppLinkingSuccess } from '../actions/mobileApps';
import { updateApp } from '../reducers/apps';
import { browserHistory } from '../util/history';
import paginate, { paginateToEnd } from './util/paginate';

const TYPE = 'apps';

export default function* appsFlow() {
  yield spawn(loadAppsAtLogin);
  yield spawn(watchForUploadButtonClicked);
  yield spawn(watchForFileSubmitted);
  yield spawn(watchForXCUITestFileSubmitted);
  yield spawn(watchForAppLinking);

  yield takeEvery(billingAction.redirectToPurchaseQuoteAction.toString(), function* () {
    yield call(browserHistory.push, '/pricing');
  });
}

function* loadApps(params) {
  const apps = yield* callSaga(paginate, TYPE, params, function* (params) {
    return yield* callPromise(list, params);
  });

  if (apps && apps.mobile_apps && apps.mobile_apps.length) {
    yield put(updateApp(apps.mobile_apps));
    return apps.mobile_apps;
  }
}

function* loadAllApps(params = {}) {
  return yield* withProgressIndicator(function* () {
    yield put(startedLoadingApps());
    try {
      return yield* callSaga(paginateToEnd, loadApps, TYPE, params, params);
    } catch (err) {
      yield put(receivedAppsError());
      throw err;
    } finally {
      yield put(finishedLoadingApps());
    }
  }, TYPE);
}

export function* loadAppsAtLogin() {
  const userAccount = yield* callPromise(getUserAccount);

  if (userAccount.no_session_reason) {
    // No need to fetch apps if not logged in
    return;
  }

  const { accountInfo } = userAccount;

  const { apps } = yield* select(state => state);

  if (!accountInfo.toggles || !accountInfo.toggles.scan_and_secure) {
    return;
  }

  if (!apps || apps.length < 1) {
    yield call(loadAllApps, { filter: 'SCAN_AND_SECURE_APPS' });
  }
}

function* watchForUploadButtonClicked() {
  yield takeEvery(uploadButtonClicked.toString(), function* () {
    yield call(browserHistory.push, '/upload');
  });
}

function* watchForFileSubmitted() {
  yield takeEvery(appFileSubmitted.toString(), function* (action) {
    const { file, username, password, comments, isEnterpriseInternal, linkedAppStoreAppId } = action.payload;
    yield* uploadFile(file, username, password, comments, isEnterpriseInternal, linkedAppStoreAppId);
  });
}

function* uploadFile(file, username, password, comments, isEnterpriseInternal, linkedAppStoreAppId) {
  try {
    const upload_url = yield* callPromise(app_uploads.create);

    if (typeof upload_url !== 'string') {
      throw new Error('POST file upload did not return a response');
    }

    const form = new FormData();
    form.append('file', file);
    form.append('source', 'DT_UPLOAD_FORM');

    if (username) {
      form.append('username', username);
    }
    if (password) {
      form.append('password', password);
    }
    if (comments) {
      form.append('comments', comments);
    }
    if (typeof isEnterpriseInternal === 'boolean' && isEnterpriseInternal) {
      form.append('release_type', MobileAppReleaseTypeValues.ENTERPRISE);
    } else {
      form.append('release_type', MobileAppReleaseTypeValues.PRE_PROD);
    }
    if (linkedAppStoreAppId) {
      form.append('linked_app_store_mobile_app_id', linkedAppStoreAppId);
    }

    const response = yield* callPromise(fetch, upload_url, {
      body: form,
      method: 'POST',
    });
    const body = yield* callPromise(async () => response.json());

    if (body.error) {
      yield put(appFileRejected(body.error));
      return;
    }

    yield put(appFileAccepted(body));
  } catch (err) {
    if (err instanceof Error) {
      yield put(appFileRejected(err.message));
      Raven.captureException(err);
      return;
    }
    throw err;
  }
}

function* watchForXCUITestFileSubmitted() {
  yield takeEvery(appXCUITestFileSubmit.toString(), function* (action) {
    const { file, bundle_id, release_type, version } = action.payload;
    yield* uploadXCUITestFile(file, bundle_id, release_type, version);
  });
}

function* uploadXCUITestFile(file, bundle_id, release_type, version) {
  try {
    const url = yield* callPromise(uploadXCUITestService.create, {});

    if (typeof url !== 'string') {
      throw new Error('POST file upload did not return a response');
    }

    const form = new FormData();
    form.append('file', file);
    form.append('source', 'DT_APP_XCUITEST_FORM');
    form.append('bundle_id', bundle_id);
    form.append('release_type', release_type);
    form.append('version', version);

    const response = yield call(fetch, url, {
      body: form,
      method: 'POST',
    });

    // fetch don't throw unless is network error
    if (!response.ok) {
      // Better response? AppUploader actually returns a nice explaination why it failed
      throw new Error('Upload failed.');
    }

    yield put(appXCUITestFileAccepted(response));
  } catch (err) {
    yield put(appXCUITestFileRejected(err));
  }
}

function* watchForAppLinking() {
  yield takeEvery(updateAppLinkingStarted.toString(), function* (action) {
    const { app, isEnterpriseInternal, linkedAppStoreAppId } = action.payload;
    yield* withProgressIndicator(function* () {
      try {
        // TODO: Sevenhell does not yet support updating an app's release type e.g. removing an app from the ENTERPRISE
        // category. We still support the deprecated functionality, temporarily.
        const updatedApp = yield* callPromise(patch, app.id, {
          is_enterprise_internal: isEnterpriseInternal,
          linked_app_store_mobile_app_id: linkedAppStoreAppId,
        });

        if (updatedApp && updatedApp.id === app.id) {
          yield put(updateApp([updatedApp]));
          yield put(updateAppLinkingSuccess(updatedApp));
        }
      } catch (e) {
        yield put(updateAppLinkingFailure(e));
      }
    });
  });
}
