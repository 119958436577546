import gql from 'graphql-tag';
export const api_changes_stats = gql `
  query ApiChangesStats {
    api_changes_stats {
      api_changes_total_count
      api_changes_stats {
        api_change_type
        api_change_name
        api_change_count
        api_change_meta_type
      }
    }
  }
`;
export const apis_analysis = gql `
  query ApisAnalysis {
    apis_analysis {
      global_datapoints {
        datapoint_name
        datapoint_count
        secondary_datapoint_count
        secondary_datapoint_name
      }
      apis_analysis {
        date
        datapoints {
          datapoint_name
          datapoint_count
          secondary_datapoint_count
          secondary_datapoint_name
        }
      }
    }
  }
`;
export const shadow_assets_stats = gql `
  query ShadowAssetsStats($filter_by_named_filter: String) {
    shadow_assets_stats(filter_by_named_filter: $filter_by_named_filter) {
      shadow_assets_stats {
        shadow_reason
        shadow_reason_name
        shadow_assets_count
      }
    }
  }
`;
