import React from 'react';
import { Tooltip, Typography } from '@mui/material';
import { palette } from '@dt/theme';
import {
  getDisplayTextFromComplianceStandard,
  getDisplayTextFromComplianceStandardCriteria,
  getIconFromComplianceStandard,
} from './util';

const ComplianceTag = ({ compliance_standard: standard, compliance_standard_criteria: criteria, description }) => {
  const tooltipText = (
    <>
      {getDisplayTextFromComplianceStandard(standard)} {getDisplayTextFromComplianceStandardCriteria(criteria)}
      <hr />
      {description}
    </>
  );

  return (
    <Tooltip title={tooltipText}>
      <div
        style={{
          alignItems: 'center',
          backgroundColor: palette.gray45,
          borderRadius: 4,
          display: 'flex',
          margin: '8px 4px 0',
          padding: '4px 6px',
        }}
      >
        <img
          src={getIconFromComplianceStandard(standard)}
          style={{
            height: '14px',
            marginRight: 4,
            width: '14px',
          }}
        />
        <Typography variant="caption">
          {`${getDisplayTextFromComplianceStandard(standard)} ${getDisplayTextFromComplianceStandardCriteria(
            criteria,
          )}`}
        </Typography>
      </div>
    </Tooltip>
  );
};

export default React.memo(ComplianceTag);
