import React, { memo } from 'react';
import { Grid } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { useStyles } from './NotificationItem';

export default memo(function NotificationItemLoadingSkeleton() {
  const styles = useStyles();

  return (
    <Grid className={styles.mainContainer} container direction="row" spacing={2}>
      <Grid item xs={1}>
        <Skeleton animation="wave" height={32} variant="circular" width={32} />
      </Grid>
      <Grid container direction="column" item xs={11}>
        <Grid className={styles.title} item>
          <Skeleton animation="wave" width={365} />
        </Grid>
        <Grid item>
          <Skeleton animation="wave" width={365} />
        </Grid>
        <Grid
          alignContent="center"
          alignItems="center"
          className={styles.title}
          container
          direction="row"
          item
          spacing={2}
        >
          <Grid item>
            <Skeleton animation="wave" height={16} variant="circular" width={16} />
          </Grid>
          <Grid item>
            <Skeleton animation="wave" width={333} />
          </Grid>
        </Grid>
        <Grid item>
          <Skeleton animation="wave" width={135} />
        </Grid>
      </Grid>
    </Grid>
  );
});
