import React, { memo } from 'react';
import { makeStyles } from '@mui/styles';
import AppProtectionBadgeTasksDialog from '@dt/ahura/src/components/protection/AppProtectionBadgeTasksDialog';
import { badgeToIssueTypeMap } from '@dt/components';
import { Card as ProtectionBadgeCard } from '@dt/components/src/app-protection-badges';

const LARGE_SIZE = 72;

const useStyles = makeStyles({
  card: {
    margin: 2,
    minWidth: 120,
    width: '18%',
  },

  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
});

function getTasksForBadge(badge, tasksForApp, badgeToIssueTypeMapping) {
  const issueTypeIds = [];
  badgeToIssueTypeMapping
    .filter(mapping => mapping.protectionBadge === badge)
    .forEach(issueType => issueTypeIds.push(issueType.taskIssueTypeId));

  return tasksForApp.filter(task => issueTypeIds.includes(task.issue_type_id));
}

function tasksDialog(badgeType, enabled, tasksForBadge, onClose) {
  return (
    <AppProtectionBadgeTasksDialog
      badgeType={badgeType}
      enabled={enabled}
      onClose={onClose}
      tasksForBadge={tasksForBadge}
    />
  );
}

function ProtectionBadgeList(props) {
  const { badges, tasksForApp } = props;
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {badges.map(([badge, enabled], i) => (
        <div className={classes.card} key={i}>
          <ProtectionBadgeCard
            enabled={enabled}
            height={LARGE_SIZE}
            tasksDialog={tasksForApp ? tasksDialog : () => {}}
            tasksForBadge={tasksForApp ? getTasksForBadge(badge, tasksForApp, badgeToIssueTypeMap) : []}
            type={badge}
            width={LARGE_SIZE}
          />
        </div>
      ))}
    </div>
  );
}

export default memo(ProtectionBadgeList);
