import React, { useEffect, useState } from 'react';
import { Button, CircularProgress, Dialog, Snackbar, Tooltip } from '@mui/material';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import PolicyViolationExportToJiraDialogStep1 from './PolicyViolationExportToJiraDialogStep1';
import PolicyViolationExportToJiraDialogStep2 from './PolicyViolationExportToJiraDialogStep2';
import PolicyViolationExportToJiraDialogStepNoIntegrations from './PolicyViolationExportToJiraDialogStepNoIntegrations';
import jiraIcon from './jiraIcon.svg';
import useExportViolationExportToJira from './usePolicyViolationExportToJira';
const PolicyViolationExportToJira = ({ policyViolationId, policyViolationName, jiraTicketId }) => {
    const [step, setStep] = useState('no-integrations');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [jiraIntegration, setJiraIntegration] = useState();
    const [ticketId, setJiraTicketId] = useState(jiraTicketId);
    const [snackOpen, setSnackOpen] = useState(null);
    const handleClose = () => {
        setStep('select');
        setJiraIntegration(undefined);
        setIsModalOpen(false);
    };
    const handleSnackClose = (event) => {
        if (event)
            event.stopPropagation();
        setSnackOpen(null);
    };
    const { data, loading, fetchMore, exportToJira, errorExportToJira, loadingExportToJira } = useExportViolationExportToJira({
        handleClose,
        policyViolationId,
        setJiraTicketId,
        setSnackOpen,
    });
    const { alerts_integrations: items } = data?.alerts_integrations_list || {};
    const isExportButtonDisabled = loading || loadingExportToJira || jiraTicketId;
    useEffect(() => {
        if (items?.length === 1) {
            setStep('confirm');
            setJiraIntegration(items[0]);
        }
        if (items?.length === 0) {
            setStep('no-integrations');
        }
        if (items?.length > 1) {
            setStep('select');
        }
    }, [items]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Tooltip, { title: jiraTicketId ? `This policy violation has already been exported to Jira as ticket ${jiraTicketId}` : '' },
            React.createElement("div", null,
                React.createElement(Button, { "aria-controls": "export-to-jira-button", "aria-haspopup": "true", disabled: isExportButtonDisabled, onClick: () => setIsModalOpen(true), size: "small", startIcon: loadingExportToJira ? React.createElement(CircularProgress, { size: 18 }) : null, variant: "contained" },
                    React.createElement(Box, { alignItems: "center", display: "flex" },
                        React.createElement("img", { alt: "jira-icon", src: jiraIcon }),
                        React.createElement(Box, { ml: 1 }, "Export to Jira"))))),
        React.createElement(Dialog, { fullWidth: true, id: "jira-export-menu", maxWidth: "sm", onClose: handleClose, open: isModalOpen },
            step === 'no-integrations' && (React.createElement(PolicyViolationExportToJiraDialogStepNoIntegrations, { handleClose: handleClose })),
            step === 'select' && (React.createElement(PolicyViolationExportToJiraDialogStep1, { fetchMore: fetchMore, handleClose: handleClose, handleNext: () => {
                    setStep('confirm');
                }, integrations: items, jiraIntegration: jiraIntegration, setJiraIntegration: setJiraIntegration })),
            step === 'confirm' && (React.createElement(PolicyViolationExportToJiraDialogStep2, { errorMessage: errorExportToJira?.message, handleClose: handleClose, handleNext: () => {
                    if (jiraIntegration) {
                        exportToJira({
                            variables: {
                                body: {
                                    alerts_integration_id: jiraIntegration.id,
                                },
                                id: policyViolationId,
                            },
                        });
                    }
                }, jiraIntegrationName: jiraIntegration?.name || '', loading: loadingExportToJira, policyViolationName: policyViolationName }))),
        React.createElement(Snackbar, { anchorOrigin: { horizontal: 'right', vertical: 'bottom' }, autoHideDuration: 5000, onClose: handleSnackClose, open: Boolean(snackOpen) },
            React.createElement(Box, null,
                snackOpen === 'ERR' && (React.createElement(Alert, { onClose: handleSnackClose, severity: "error" }, "An error occurred. Please try again later.")),
                snackOpen === 'OK' && (React.createElement(Alert, { onClose: handleSnackClose, severity: "success" }, `Success! The violation was exported to the following Jira Ticket: ${ticketId}`))))));
};
export default PolicyViolationExportToJira;
