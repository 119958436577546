import { handleActions } from 'redux-actions';
import Actions from './actions';

export default handleActions(
  {
    [Actions.requestNotifyUser.toString()]: (state, { payload: { notification } }) => state.concat(notification),
    [Actions.dismissNotificationClicked.toString()]: (state, { payload: { notification } }) =>
      state.filter(current => current !== notification),
  },
  [],
);
