import React, { memo, useCallback, useEffect, useState } from 'react';
import { Checkbox, FormControl, FormControlLabel } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { PiiTypeEnum, PiiTypeEnumHumanReadable } from '@dt/horizon-api';

const piiLabels = Object.keys(PiiTypeEnum).sort((a, b) => a.localeCompare(b));

const useStyles = makeStyles({
  checkbox_root: {
    paddingBottom: '0px',
    paddingLeft: '4px',
    paddingRight: '4px',
    paddingTop: '0px',
  },
  form_control_label: {
    marginLeft: '0px',
    marginRight: '0px',
    padding: '2px',
    width: '360px',
  },
  form_control_root: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
});

function PiiSelector({ defaultSelected = [], onChange }) {
  const css = useStyles();
  const selectedMap = Object.fromEntries(defaultSelected.map(d => [d, true]));
  const [selected, setSelected] = useState(selectedMap);

  useEffect(() => {
    if (onChange) {
      const selectedKeys = Object.keys(selected).filter(k => Boolean(selected[k]));
      onChange(selectedKeys);
    }
  }, [selected, onChange]);

  const handleChange = useCallback(value => {
    setSelected(s => ({ ...s, [value]: !s[value] }));
  }, []);

  return (
    <FormControl classes={{ root: css.form_control_root }} component="fieldset">
      {piiLabels.map(p => (
        <FormControlLabel
          classes={{ root: css.form_control_label }}
          control={
            <Checkbox
              checked={Boolean(selected[p])}
              classes={{ root: css.checkbox_root }}
              color="primary"
              disableRipple
              onChange={e => handleChange(e.target.value)}
              size="small"
              value={p}
            />
          }
          key={p}
          label={PiiTypeEnumHumanReadable[p]}
        />
      ))}
    </FormControl>
  );
}

export default memo(PiiSelector);
