import React from 'react';
import { Grid } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { Message } from '@components';
import { LegendItem, LegendLabel, LegendOrdinal } from '@visx/legend';
import ParentSize from '@visx/responsive/lib/components/ParentSize';
import { scaleOrdinal } from '@visx/scale';
import PieChart from '../../../components/charts/PieChart';
const colorPalette = [
    '#00E190',
    '#7000FF',
    '#C89EFF',
    '#FA7E3D',
    '#2D8A63',
    '#38B000',
    '#76FFF7',
    '#A5206F',
    '#D1FF4C',
    '#FF7A00',
    '#FF9BE9',
];
const ordinalColorScale = scaleOrdinal({
    range: colorPalette,
});
const legendGlyphSize = 15;
const ApiShadowAssetsPageChartDonut = ({ data, loading, error }) => {
    if (loading) {
        return React.createElement(Skeleton, null);
    }
    if (error) {
        React.createElement(Message, { m: 0, message: error.message, variant: 'error' });
    }
    const items = data?.shadow_assets_stats.shadow_assets_stats || [];
    const formatted = items.map(a => ({
        color: ordinalColorScale(a.shadow_reason_name),
        name: a.shadow_reason_name,
        value: a.shadow_assets_count,
    }));
    return (React.createElement(Grid, { container: true, spacing: 2 },
        React.createElement(Grid, { item: true, xs: 6 },
            React.createElement(ParentSize, null, ({ width }) => React.createElement(PieChart, { data: formatted, height: 175, subText: 'Shadow Assets', text: '', width: width }))),
        React.createElement(Grid, { item: true, xs: 6 },
            React.createElement(LegendOrdinal, { labelFormat: label => `${label}`, scale: ordinalColorScale }, labels => (React.createElement("div", { style: { display: 'flex', flexDirection: 'column' } }, labels.map((label, i) => (React.createElement(LegendItem, { key: `legend-quantile-${i}`, margin: "0 5px" },
                React.createElement("svg", { height: legendGlyphSize, width: legendGlyphSize },
                    React.createElement("rect", { fill: label.value, height: legendGlyphSize, width: legendGlyphSize })),
                React.createElement(LegendLabel, { align: "left", margin: "0 0 0 4px" }, label.text))))))))));
};
export default ApiShadowAssetsPageChartDonut;
