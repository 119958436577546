import gql from 'graphql-tag';
const stats = gql `
  query PostmanProtectStatus {
    postman_protect_stats_get {
      onboarded_postman_collection_count
      vulnerability_count
      vulnerability_fixed_count
      vulnerability_open_count
      lines_of_code_reviewed_count
      lines_of_code_changed_count
      collection_updated_count
      developer_count
    }
  }
`;
const list = gql `
  query PostmanProtectWorkflowList(
    $filter_by_named_filter: String
    $page_size: Float
    $cursor: String
    $order_by: String
    $filter_by_team_name: [String]
    $filter_by_workspace_name: [String]
    $filter_by_text: String
    $filter_by_user_id: [String]
  ) {
    postman_protect_workflow_list(
      filter_by_named_filter: $filter_by_named_filter
      page_size: $page_size
      cursor: $cursor
      order_by: $order_by
      filter_by_team_name: $filter_by_team_name
      filter_by_workspace_name: $filter_by_workspace_name
      filter_by_text: $filter_by_text
      filter_by_user_id: $filter_by_user_id
    ) {
      pagination_information {
        next_cursor
        total_count
        exact_total_count
        hint_for_total_count
        is_approximate_total_count_enabled
      }
      workflows {
        id
        postman_workflow_display_name
        postman_collection_filename
        postman_collection_hash
        postman_environment_filename
        postman_environment_hash
        related_asset {
          id
          name
          url
          asset_type
          asset_type_name
          asset_type_icon_url
        }
        last_scan_date
        last_scan_status
        total_issues_count
        dast_issues_count
        sast_issues_count
        postman_cloud_workspace_name
        postman_cloud_team_name
        onboarding_type
        owner_display_name
      }
    }
  }
`;
const details = gql `
  query PostmanProtectWorkflowDetails($id: ID!) {
    postman_protect_workflow_details(id: $id) {
      id
      postman_workflow_display_name
      postman_collection_filename
      postman_collection_hash
      postman_environment_filename
      postman_environment_hash
      related_asset {
        id
        name
        url
        asset_type
        asset_type_name
        asset_type_icon_url
      }
      last_scan_date
      last_scan_status
      total_issues_count
      dast_issues_count
      sast_issues_count
      postman_collection_raw_file
      sast_annotations {
        policy_violation_id
        policy_violation_relevance
        policy_violation_title
        start_line
        end_line
        start_column
        end_column
      }
      dast_execution_result_as_json
      dast_execution_result_as_fallback
      dast_annotations {
        policy_violation_id
        policy_violation_relevance
        policy_violation_title
        execution_index
        test_index
      }
    }
  }
`;
const named_filters = gql `
  query PostmanProtectNamedFilters {
    postman_protect_named_filters_get {
      postman_workflows_named_filters {
        name
        value
        icon_url
        group
      }
    }
  }
`;
const get_table_filters = gql `
  query PostmanProtectGetTableFilters {
    postman_protect_get_table_filters {
      postman_protect_search_filter_values {
        belongs_to_filter
        name
        value
        value_category
        icon_url
      }
    }
  }
`;
const api_connect_tools = gql `
  query PostmanProtectApiConnectTools {
    postman_protect_api_connect_tools {
      curl_command
    }
  }
`;
const postman_protect_batch_delete = gql `
  mutation PostmanProtectBatchDelete($postman_workflow_id: [String!]!) {
    postman_protect_batch_delete(postman_workflow_id: $postman_workflow_id) {
      nothing
    }
  }
`;
const postman_protect = {
    api_connect_tools,
    details,
    get_table_filters,
    list,
    named_filters,
    postman_protect_batch_delete,
    stats,
};
export default postman_protect;
