import React, { memo } from 'react';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { Button } from '@mui/material';

const InventoryTableExportButtonComponent = function InventoryTableExportButton({ callExport, loading }) {
  return (
    <Button
      disabled={loading}
      onClick={() => callExport()}
      size="small"
      startIcon={<CloudDownloadIcon />}
      variant="contained"
    >
      Export
    </Button>
  );
};

export default memo(InventoryTableExportButtonComponent);
