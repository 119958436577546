function localeStringSort(a, b) {
  return a.localeCompare(b);
}

function stringFromParametricRequest(params) {
  const keys = Object.keys(params)
    .sort(localeStringSort)
    .filter(key => key !== 'cursor');
  const values = keys
    .map(k => params[k])
    .filter(Boolean)
    .sort(localeStringSort);
  return `${keys.join('-')}-${values.join('-')}`;
}

export default function getKey(params) {
  return stringFromParametricRequest(params);
}
