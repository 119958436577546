import React from 'react';
import { Waypoint } from 'react-waypoint';
import { Button, DialogActions, DialogContent, Grid, MenuItem, Select } from '@mui/material';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
// @ts-ignore: #TODO-IMPLEMENT-TYPES-MATERIAL-COMPONENTS
import { Text } from '@dt/material-components';
import PolicyViolationExportToJiraDialogHeader from './PolicyViolationExportToJiraDialogHeader';
const PolicyViolationExportToJiraDialogStep1 = ({ handleClose, handleNext, jiraIntegration, setJiraIntegration, integrations, fetchMore, }) => {
    return (React.createElement(React.Fragment, null,
        React.createElement(PolicyViolationExportToJiraDialogHeader, { handleClose: handleClose, title: "Export Policy Violation to Jira" }),
        React.createElement(DialogContent, null,
            React.createElement(Grid, { container: true, spacing: 1 },
                React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(Box, { mb: 2, mt: 2 },
                        React.createElement(Text, { component: "span", variant: "body" }, "Choose which Jira integration the policy violation should be exported to:"))),
                React.createElement(Grid, { item: true, xs: 12 },
                    React.createElement(Select, { displayEmpty: true, fullWidth: true, onChange: e => {
                            const integrationId = e.target.value;
                            const integration = integrations.find(({ id }) => id === integrationId);
                            if (integration) {
                                setJiraIntegration(integration);
                            }
                        }, value: jiraIntegration?.id, variant: "outlined" },
                        [
                            React.createElement(MenuItem, { disabled: true, key: "select-header", value: "" },
                                React.createElement(Text, { component: "span", mb: 1, variant: "body" }, "Choose Jira integration")),
                            integrations?.map((row) => (React.createElement(MenuItem, { key: row.id, value: row.id },
                                React.createElement(Text, { component: "span", mb: 1, variant: "body" }, row.name)))),
                        ],
                        fetchMore && (React.createElement(React.Fragment, null,
                            React.createElement(MenuItem, { dense: true },
                                React.createElement(Skeleton, { animation: "wave", height: 40, width: `100%` })),
                            React.createElement(Waypoint, { onEnter: () => {
                                    if (fetchMore) {
                                        fetchMore();
                                    }
                                } }))))))),
        React.createElement(DialogActions, null,
            React.createElement(Button, { onClick: handleClose }, "Cancel"),
            React.createElement(Button, { color: "primary", disabled: !jiraIntegration, onClick: handleNext }, "Next"))));
};
export default PolicyViolationExportToJiraDialogStep1;
