import React from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import Box from '@mui/material/Box';
import { default as SecurityTemplateImportanceTagEnumValue } from '@dt/enums/SecurityTemplateImportanceTagEnum';
import { Text } from '@dt/material-components';

export const PopoutFilterPluginStoreBlockerDisplayLookup = {
  [SecurityTemplateImportanceTagEnumValue.APPLE_P1]: 'App Store Blocker',
  [SecurityTemplateImportanceTagEnumValue.GOOGLE_P1]: 'Google Play Store',

  // NOTE: Not all importance tags are accessible from this filter plugin.
  [SecurityTemplateImportanceTagEnumValue.APPLE]: null,
  [SecurityTemplateImportanceTagEnumValue.GOOGLE]: null,
  [SecurityTemplateImportanceTagEnumValue.SECURITY]: null,
  [SecurityTemplateImportanceTagEnumValue.SECURITY_P1]: null,
};

/*
 * Popout filter plugin.
 * User can select mobile app release types.
 *
 * @param value - Current state.
 * @param onChange - State change.
 */
export const PopoutFilterPluginStoreBlocker = ({ value, disabled, onChange }) => {
  return (
    <Box display="flex" flexDirection="column">
      {Object.keys(PopoutFilterPluginStoreBlockerDisplayLookup)
        .filter(key => !!PopoutFilterPluginStoreBlockerDisplayLookup[key])
        .map(key => {
          const val = PopoutFilterPluginStoreBlockerDisplayLookup[key] || '';
          return (
            <FormControlLabel
              control={
                <Checkbox
                  checked={!!value[key]}
                  color="primary"
                  disabled={!!disabled[key]}
                  inputProps={{
                    'aria-label': `${val}`,
                  }}
                  onChange={e => {
                    (value =>
                      onChange(prev => ({
                        ...prev,

                        [key]: value,
                      })))(e.target.checked);
                  }}
                />
              }
              key={key}
              label={<Text variant="body">{val}</Text>}
            />
          );
        })}
    </Box>
  );
};
