import immer from 'immer';
import {
  inventoryExportCancelClicked,
  inventoryExportClicked,
  inventoryExportCompleted,
  inventoryExportRequestRow,
  inventoryExportResetSagaRequestsClicked,
  inventoryExportRestartRequestRow,
} from './actions';

const initialState = {
  inProgress: false,
  resetSagaRequest: false,
};

export default immer((draft, action) => {
  if (action.type === inventoryExportClicked.toString()) {
    draft.inProgress = true;
  }

  if (action.type === inventoryExportCancelClicked.toString()) {
    draft.inProgress = false;
    draft.resetSagaRequest = false;
  }

  if (action.type === inventoryExportCompleted.toString()) {
    draft.inProgress = false;
  }

  if (action.type === inventoryExportResetSagaRequestsClicked.toString()) {
    draft.resetSagaRequest = true;
  }

  if (action.type === inventoryExportRequestRow.toString()) {
    draft.resetSagaRequest = false;
  }

  if (action.type === inventoryExportRestartRequestRow.toString()) {
    draft.resetSagaRequest = false;
  }
}, initialState);
