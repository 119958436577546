import { jiraIntegrationReceivedListConfigurations } from '../actions';

const initialState = {
  configurations: [],
  isThereAGlobalConfiguration: false,
};

export default (state = initialState, action) => {
  if (action.type === jiraIntegrationReceivedListConfigurations.toString()) {
    return {
      configurations: action.payload.configurations,
      isThereAGlobalConfiguration: action.payload.isThereAGlobalConfiguration,
    };
  }

  return state;
};
