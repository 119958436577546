import gql from 'graphql-tag';
export const assets_related_graph = gql `
  query GetAssetsRelatedGraph($assetId: String!) {
    assets_related_graph(assetId: $assetId) {
      related_assets_graph {
        exploit_vectors {
          additional_info
          title
        }
        links {
          source
          target
          highlighted
          highlight_color
        }
        nodes {
          included_graph_issues {
            id
            title
            icon_url
          }
          additional_info
          id
          name
          url
          asset_type
          asset_type_name
          asset_type_icon_url
          hosted_on
          hosted_on_icon_url
          hosted_on_name
          open_urgent_policy_violations_count
          open_important_policy_violations_count
          open_proactive_policy_violations_count
          discovered_via
          discovered_via_icon_url
          discovered_via_name
          status
          date_created
          highlighted
          highlight_color
        }
      }
    }
  }
`;
const v2_get = gql `
  query AssetDetailsV2($id: ID!) {
    assets_details_v2(id: $id) {
      id
      asset_type
      asset_type_icon_url
      asset_type_name
      belongs_to_asset_group {
        id
        name
      }
      date_created
      date_no_longer_accessible
      discovered_via
      discovered_via_icon_url
      discovered_via_name
      hosted_on
      hosted_on_icon_url
      hosted_on_name
      name
      open_important_policy_violations_count
      open_proactive_policy_violations_count
      open_urgent_policy_violations_count
      status
      url
      belongs_to_cloud_account {
        id
        cloud_account_icon_url
        cloud_account_type_name
        customer_supplied_name
      }
    }
  }
`;
const api_assets_changes = gql `
  query ApiAssetsChanges($filter_by_named_filter: String, $cursor: String) {
    api_assets_changes(filter_by_named_filter: $filter_by_named_filter, cursor: $cursor) {
      pagination_information {
        next_cursor
        total_count
      }
      included_api_changes_stats {
        last_year_changes_count
        last_30_days_changes_count
        last_7_days_changes_count
        last_30_days_changes_with_issues_count
        changes_with_issues_count
      }
      api_changes {
        api_change_icon_url
        api_change_description
        api_change_type
        api_change_name
        date_created
        asset_name
        asset_type_icon_url
        asset_url
        http_method
        path
        api_authentication_scheme_names
        detected_pii_types
        is_internet_accessible
        is_compliant_to_security_policy
        issues_count
        discovered_via
        discovered_via_icon_url
        discovered_via_name
      }
    }
  }
`;
const inventory_v2_keyword_search_values = gql `
  query InventoryV2KeywordSearchValues {
    inventory_v2_keyword_search_values {
      assets_named_filters {
        name
        value
        icon_url
        group
      }
    }
  }
`;
const assets_named_filters = gql `
  query AssetsNamedFilters {
    assets_named_filters {
      assets_named_filters {
        name
        value
        icon_url
        group
      }
    }
  }
`;
const api_changes_named_filters = gql `
  query ApiChangesNamedFilters {
    api_changes_named_filters {
      api_changes_named_filters {
        name
        value
        icon_url
        group
      }
    }
  }
`;
const kubernetes_cluster_component_resource = gql `
  query KubernetesClusterComponentResource($id: ID!) {
    kubernetes_cluster_component_resource(id: $id) {
      asset_type_icon_url
      asset_type_name
      component_type
      component_type_name
      date_created
      date_no_longer_accessible
      discovered_via
      discovered_via_icon_url
      discovered_via_name
      hosted_on
      id
      metadata_creation_timestamp
      metadata_labels
      metadata_name
      metadata_namespace
      metadata_uid
      open_important_policy_violations_count
      open_proactive_policy_violations_count
      open_urgent_policy_violations_count
      parent_kubernetes_cluster_asset {
        cluster_name
        kubernetes_version
        id
      }
      status
    }
  }
`;
const kubernetes_cluster_resource = gql `
  query KubernetesClusterResource($id: ID!) {
    kubernetes_cluster_resource(id: $id) {
      asset_type_icon_url
      asset_type_name
      cluster_name
      date_created
      date_no_longer_accessible
      discovered_via
      discovered_via_icon_url
      discovered_via_name
      hosted_on
      id
      kubernetes_cluster_components_count
      kubernetes_control_plane_url
      kubernetes_version
      open_important_policy_violations_count
      open_proactive_policy_violations_count
      open_urgent_policy_violations_count
      status
    }
  }
`;
const kubernetes_cluster_components = gql `
  query KubernetesClusterComponents($id: ID!, $cursor: String) {
    kubernetes_cluster_components(id: $id, cursor: $cursor) {
      pagination_information {
        next_cursor
        total_count
      }
      kubernetes_cluster_components {
        asset_type_icon_url
        asset_type_name
        component_type
        component_type_name
        date_created
        date_no_longer_accessible
        discovered_via
        discovered_via_icon_url
        discovered_via_name
        hosted_on
        id
        metadata_creation_timestamp
        metadata_labels
        metadata_name
        metadata_namespace
        metadata_uid
        open_important_policy_violations_count
        open_proactive_policy_violations_count
        open_urgent_policy_violations_count
        parent_kubernetes_cluster_asset {
          cluster_name
          kubernetes_version
          id
        }
        status
      }
    }
  }
`;
const list = gql `
  query AssetList(
    $cursor: String
    $filter_by_asset_group_id: [ID]
    $filter_by_no_asset_group: Boolean
    $filter_by_asset_tags: String
    $filter_by_asset_type_group: [String]
    $filter_by_asset_type: [AssetTypeEnum]
    $filter_by_cloud_provider: [HostedOn]
    $filter_by_discovered_via: [DiscoveredVia]
    $filter_by_is_shadow: Boolean
    $filter_by_named_filter: String
    $filter_by_ownership: String
    $filter_by_policy_rule_type_id: String
    $filter_by_related_asset: String
    $filter_by_related_first_party_asset_id: [ID]
    $filter_by_text: String
    $filter_by_vendor_id: String
    $include_v2_fields: Boolean
    $include: String
    $order_by: String
    $page_size: Float
  ) {
    asset_list(
      cursor: $cursor
      filter_by_asset_group_id: $filter_by_asset_group_id
      filter_by_no_asset_group: $filter_by_no_asset_group
      filter_by_asset_tags: $filter_by_asset_tags
      filter_by_asset_type_group: $filter_by_asset_type_group
      filter_by_asset_type: $filter_by_asset_type
      filter_by_cloud_provider: $filter_by_cloud_provider
      filter_by_discovered_via: $filter_by_discovered_via
      filter_by_is_shadow: $filter_by_is_shadow
      filter_by_named_filter: $filter_by_named_filter
      filter_by_ownership: $filter_by_ownership
      filter_by_policy_rule_type_id: $filter_by_policy_rule_type_id
      filter_by_related_asset: $filter_by_related_asset
      filter_by_related_first_party_asset_id: $filter_by_related_first_party_asset_id
      filter_by_text: $filter_by_text
      filter_by_vendor_id: $filter_by_vendor_id
      include_v2_fields: $include_v2_fields
      include: $include
      order_by: $order_by
      page_size: $page_size
    ) {
      pagination_information {
        next_cursor
        total_count
        exact_total_count
        hint_for_total_count
        is_approximate_total_count_enabled
      }
      assets {
        id
        name
        url
        status
        asset_type
        additional_info
        included_graph_issues {
          id
          title
          icon_url
        }
        asset_type_icon_url
        asset_type_name
        belongs_to_asset_group {
          id
          name
        }
        date_created
        date_no_longer_accessible
        discovered_via
        discovered_via_icon_url
        discovered_via_name
        hosted_on
        open_important_policy_violations_count
        open_proactive_policy_violations_count
        open_urgent_policy_violations_count
        included_cloud_ransomware_stats {
          last_scan_date
          total_files_count
          last_scan_change_files_count
          total_directories_count
          last_scan_change_directories_count
          is_publicly_writable
          is_publicly_readable
          is_malware_detected
          is_ransomware_detected
        }
        tags {
          id
          tag_id
          tag
          value
          imported_external_id
          imported_from
          imported_from_icon_url
        }
        hosted_on_icon_url
        hosted_on_name
        included_api_radar_stats {
          last_tested_date
          active_protection_status
          api_authentication_scheme_names
          api_protect_api_calls_analyzed_count
          has_active_protection_enabled
          insecure_operations_count
          secure_operations_count
          total_operations_count
          api_protect_api_date_last_non_dt_processed_request
          api_protect_date_last_processed_request
          api_protect_is_zombie
          api_protect_zombie_days
        }
        included_supply_chain_secure_details {
          related_mobile_apps_count
          related_web_apps_count
          vendor {
            id
            icon_url
            name
          }
        }
        included_api_custom_check_details {
          api_operation_exposed_methods
          api_operations_count
          onboarded_api_custom_check_types
        }
        included_vendor_management_stats {
          resolved_violations_count
          wont_fix_violations_count
        }
        included_network_telemetry_stats {
          sources
          telemetry_sources
          last_event_date
          open_network_telemetry_policy_violations_count
          open_urgent_network_telemetry_policy_violations_count
          open_important_network_telemetry_policy_violations_count
          open_proactive_network_telemetry_policy_violations_count
        }
        included_v2_fields {
          detected_pii_types
          is_internet_accessible
          is_compliant_to_security_policy
        }
        purpose
        shadow_reasons {
          shadow_reason_name
          shadow_reason
        }
        related_asset_ids
        highlighted
        highlight_color
      }
    }
  }
`;
const all_related_assets = gql `
  query AllAssetsRelatedToAssetResourceList($cursor: String, $id: ID!) {
    all_assets_related_to_asset_resource_list(cursor: $cursor, id: $id) {
      pagination_information {
        next_cursor
        total_count
        exact_total_count
        hint_for_total_count
        is_approximate_total_count_enabled
      }
      assets {
        id
        name
        url
        status
        asset_type
        asset_type_icon_url
        asset_type_name
        belongs_to_asset_group {
          id
          name
        }
        date_created
        date_no_longer_accessible
        discovered_via
        discovered_via_icon_url
        discovered_via_name
        hosted_on
        open_important_policy_violations_count
        open_proactive_policy_violations_count
        open_urgent_policy_violations_count
        hosted_on_icon_url
        hosted_on_name
      }
    }
  }
`;
export default {
    all_related_assets,
    api_assets_changes,
    api_changes_named_filters,
    assets_named_filters,
    inventory_v2_keyword_search_values,
    kubernetes_cluster_component_resource,
    kubernetes_cluster_components,
    kubernetes_cluster_resource,
    list,
    v2_get,
};
