import React, { memo } from 'react';
import HelpIcon from '@mui/icons-material/Help';
import { Avatar, TableCell, Tooltip, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import { Text } from '@dt/material-components';

const InventoryTableCellVendor = ({ vendor }) => {
  const { palette } = useTheme();

  return (
    <TableCell>
      {vendor ? (
        <Tooltip disableInteractive title={vendor.name}>
          <Box alignItems={'center'} display={'flex'}>
            <Box display={'flex'} mr={1}>
              <img alt={`${vendor.name} Icon`} height={24} src={vendor.icon_url} width={24} />
            </Box>
            <Text component={'div'} noWrap variant={'body'}>
              {vendor.name}
            </Text>
          </Box>
        </Tooltip>
      ) : (
        <Box alignItems={'center'} display={'flex'}>
          <Box display={'flex'} mr={1}>
            <Avatar
              style={{
                backgroundColor: palette.gray[45],
                color: palette.gray[20],
                height: 24,
                width: 24,
              }}
            >
              <HelpIcon fontSize={'small'} />
            </Avatar>
          </Box>
          <Text component={'div'} noWrap variant={'body'}>
            {'Unknown Vendor'}
          </Text>
        </Box>
      )}
    </TableCell>
  );
};

export default memo(InventoryTableCellVendor);
