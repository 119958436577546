import config from '@dt/config';
import fetch, { parse } from '@dt/fetch';
import qs from 'query-string';
import { array, boolean, number, object, string } from 'yup';
import { result } from './util';

// region Typing

export const findingSortChoiceObject = {
  AGE: 'AGE',
  DATE_CREATED: 'DATE_CREATED',
  NONE: 'NONE',
  PRIORITY: 'PRIORITY',
  SEVERITY: 'SEVERITY',
  STATUS: 'STATUS',
};

// endregion

// region Type Validation
const SearchFilterQuerySchema = object().shape({
  compliance_policies: array().of(string()).ensure(),
  current_statuses: array().of(string()).ensure(),
  keywords: string().nullable(),
  min_age: number(),
  priorities: array().of(string()).ensure(),
  release_types: array().of(string()).ensure(),
  severities: array().of(string()).ensure(),
  sort_by: string(),
  statuses: object().shape({
    date_range: object().shape({
      from_date: string().nullable(),
      to_date: string().nullable(),
      type: string(),
    }),
    statuses: array().of(string()).ensure(),
  }),
  store_blockers: array().of(string()).ensure(),
});

const SearchQuerySchema = object().shape({
  description: string().required(),
  filter_query: SearchFilterQuerySchema.required(),
  id: string(),
  is_owner: boolean().required(),
  is_private: boolean().required(),
  title: string().required(),
});

const SearchQueryListResponseSchema = object().shape({
  pagination_information: object().shape({
    next_cursor: string().nullable(),
    total_count: string().nullable(),
  }),
  search_queries: array().of(SearchQuerySchema).ensure(),
});
// endregion

export async function create(params) {
  return fetch(`${config.sevenhellApiBaseUrl}/v2/search_queries`, {
    body: JSON.stringify(params),
    method: 'POST',
  })
    .then(parse)
    .then(result)
    .then(response => SearchQuerySchema.validate(response));
}

export async function get(id) {
  return fetch(`${config.sevenhellApiBaseUrl}/v2/search_queries/${id}`)
    .then(parse)
    .then(result)
    .then(response => SearchQuerySchema.validate(response));
}

export async function list(params) {
  return fetch(
    `${config.sevenhellApiBaseUrl}/v2/search_queries${
      params && qs.stringify(params) ? `?${qs.stringify(params)}` : ''
    }`,
  )
    .then(parse)
    .then(result)
    .then(response => SearchQueryListResponseSchema.validate(response));
}

export async function patch(id, params) {
  return fetch(`${config.sevenhellApiBaseUrl}/v2/search_queries/${id}`, {
    body: JSON.stringify(params),
    method: 'PATCH',
  })
    .then(parse)
    .then(result)
    .then(response => SearchQuerySchema.validate(response));
}

export async function remove(id) {
  return fetch(`${config.sevenhellApiBaseUrl}/v2/search_queries/${id}`, {
    method: 'DELETE',
  }).then(parse);
}
