import React, { memo, useState } from 'react';
import SettingsIcon from '@mui/icons-material/Settings';
import { Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { palette } from '@dt/theme';
import { GlobalSettingsMenu } from '../index';

const useStyle = makeStyles({
  menuIconBox: {
    alignItems: 'center',
    display: 'flex',
    height: 40,
    justifyContent: 'center',
    marginRight: '16px',
    maxHeight: 40,
    maxWidth: 40,
    minHeight: 40,
    minWidth: 40,
    width: 40,
  },

  menuInner: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-start',
  },

  menuOuter: {
    '&.active': {
      backgroundColor: palette.brand35,
    },
    '&.bottom': {
      marginTop: 'auto',
    },
    '&:hover': {
      backgroundColor: palette.brand20,
    },
    alignItems: 'center',
    borderRadius: 4,
    color: palette.white,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'flex-start',
    marginBottom: 8,
    marginLeft: 16,
    marginRight: 16,
    paddingBottom: 0,
    paddingLeft: 0,

    paddingRight: 0,

    paddingTop: 0,
  },

  menuText: {
    '&.title': {
      color: palette.white,
      fontSize: 20,
    },
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
});

function ConfigurableLHSMenuSettings() {
  const css = useStyle();

  const [settingsAnchorEl, setSettingsAnchorEl] = useState(null);
  const handleOpenSettings = event => setSettingsAnchorEl(event.currentTarget);
  const handleCloseSettings = () => setSettingsAnchorEl(null);

  return (
    <>
      <div
        aria-controls="settings-menu"
        aria-haspopup="true"
        aria-label="Settings Menu"
        className={css.menuOuter}
        onClick={event => handleOpenSettings(event)}
        type="button"
      >
        <Tooltip disableInteractive title="Settings">
          <div className={css.menuInner}>
            <div className={css.menuIconBox}>
              <SettingsIcon color="inherit" />
            </div>
            <div className={css.menuText}>Settings</div>
          </div>
        </Tooltip>
      </div>
      <GlobalSettingsMenu anchorEl={settingsAnchorEl} handleClose={handleCloseSettings} />
    </>
  );
}

export default memo(ConfigurableLHSMenuSettings);
