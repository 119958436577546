import React, { memo } from 'react';
import { Avatar } from '@mui/material';
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import { palette } from '@dt/theme';
import { dateFormats } from '../../../apps/ahura/src/util/dateFormats';
import { formatDateDefault } from '../../../apps/ahura/src/util/formatDateDefault';
import ExtLink from '../ExtLink';
import Text from '../Text';

export const useStyles = makeStyles({
  detailAvatar: {
    '&&': {
      height: 18,
      width: 18,
    },
  },
  iconAvatar: {
    '&&': {
      height: 32,
      padding: 0,
      width: 32,
    },
  },
});

export default memo(function NotificationItem({ notification }) {
  const styles = useStyles();
  const { date_created, detail, detail_icon_url, icon_url, portal_url, subtitle, title } = notification;

  return (
    <ExtLink aria-label={detail} target="_blank" to={portal_url}>
      <Box display={'flex'}>
        <Box mr={1}>
          <Avatar className={styles.iconAvatar} src={icon_url} />
        </Box>
        <Box>
          {title ? (
            <Box mt={'5px'}>
              <Text color={palette.gray20} component={'div'} noWrap variant="titleXS">
                {title}
              </Text>
            </Box>
          ) : null}
          {subtitle ? (
            <Box mb={'5px'}>
              <Text color={palette.gray30} component={'div'} variant="body">
                {subtitle}
              </Text>
            </Box>
          ) : null}
          <Box display={'flex'} mb={'4px'}>
            <Box mr={1}>
              <Avatar className={styles.detailAvatar} src={detail_icon_url} />
            </Box>
            <Box>
              <Text color={palette.gray20} component="div" variant="body">
                {detail}
              </Text>
            </Box>
          </Box>
          <Box>
            <Text color={palette.gray35} component="div" variant="caption">
              {formatDateDefault({ date: date_created, formatStr: `${dateFormats.MMddyyyy}, h:mm:ss a` })}
            </Text>
          </Box>
        </Box>
      </Box>
    </ExtLink>
  );
});
