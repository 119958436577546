import { clearSessionAndRedirectToExpired } from '@dt/session';

export async function result(response) {
  await check(response);
  if (!response.ok) {
    // Force redirect for unauthenticated requests.
    if (response.status === 401) {
      await clearSessionAndRedirectToExpired();
    }

    throw new Error(response.description || 'An error occurred.');
  }

  return response.body;
}

export async function noResult(response) {
  await check(response);
}

export function byProperty(key) {
  return async function (response) {
    // Once flow supports it: Promise<$PropertyType<T, typeof key>>
    if (!response) {
      throw new Error('An unexpected error occurred');
    }
    return response[key];
  };
}

export async function check(response) {
  if (response.status < 200 || response.status > 399) {
    throw new Error(response.description || 'An error occurred.');
  }
}

export function fixParamArrays(params) {
  return Object.keys(params).reduce((collector, key) => {
    if (Array.isArray(params[key])) {
      return { ...collector, [key]: { data: params[key] } };
    } else {
      return collector;
    }
  }, params);
}

/**
 * This looks pretty ugly but basically you give it an object that represents an error and this abomination will try a
 * couple different techniques for extracting that error to a string.
 * @param mysteryObj
 * @param defaultError
 * @return {*}
 */
export function tryAndParseErrorMessage(mysteryObj, defaultError = 'An error occurred') {
  if (!mysteryObj) {
    return defaultError;
  } else if (typeof mysteryObj === 'string') {
    return mysteryObj;
  } else if (mysteryObj.result) {
    if (typeof mysteryObj.result === 'string') {
      return mysteryObj.result;
    } else if (mysteryObj.result.error) {
      if (typeof mysteryObj.result.error.message === 'string') {
        return mysteryObj.result.error.message;
      } else if (typeof mysteryObj.result.error === 'string') {
        return mysteryObj.result.error;
      } else {
        return tryAndParseErrorMessage(mysteryObj.result.error, defaultError);
      }
    } else {
      return tryAndParseErrorMessage(mysteryObj.result, defaultError);
    }
  } else {
    if (typeof mysteryObj === 'object') {
      for (const key in mysteryObj) {
        if (typeof mysteryObj[key] === 'string') {
          return mysteryObj[key];
        }
      }
    }
    if (typeof mysteryObj.toString === 'function') {
      // This function is such garbage but we basically can't change it because
      // we can't predict what types of error messages the server might be
      // returning that this function is handling without doing a lot of work.
      //$FlowFixMe see above
      return mysteryObj.toString();
    } else {
      return defaultError;
    }
  }
}

export async function typify(promise) {
  let status, message;
  try {
    const response = await promise;
    status = response.status;
    const json = await response.json();

    if (status >= 200 && status < 300) {
      return {
        ...json,
        _error: false,
      };
    } else {
      if (json && json.error && typeof json.error === 'object' && typeof json.error.message === 'string') {
        message = json.error.message;
      } else {
        message = 'An error ocurred';
      }
      return {
        _error: true,
        message,
        status,
      };
    }
  } catch (err) {
    message = err.toString();
    return {
      _error: true,
      message,
      status,
    };
  }
}
