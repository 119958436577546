import config from '@dt/config';
import fetch, { parse } from '@dt/fetch';
import qs from 'query-string';
import { check, result } from './util';

export function get() {
  return fetch(`${config.sevenhellApiBaseUrl}/v2/current_user`).then(parse).then(result);
}

export function patch(params) {
  return fetch(
    `${config.sevenhellApiBaseUrl}/v2/current_user${params && qs.stringify(params) ? `?${qs.stringify(params)}` : ''}`,
    {
      method: 'PATCH',
    },
  )
    .then(parse)
    .then(result);
}

export const push_tokens = {
  create(params) {
    return fetch(
      `${config.sevenhellApiBaseUrl}/v2/current_user/push_tokens${
        params && qs.stringify(params) ? `?${qs.stringify(params)}` : ''
      }`,
      {
        method: 'POST',
      },
    )
      .then(parse)
      .then(check);
  },
};

export const notification_preferences = {
  get: () => {
    return fetch(`${config.sevenhellApiBaseUrl}/v2/current_user/notification_preferences`).then(parse).then(result);
  },
  patch: params => {
    return fetch(`${config.sevenhellApiBaseUrl}/v2/current_user/notification_preferences`, {
      body: JSON.stringify(params),
      method: 'PATCH',
    })
      .then(parse)
      .then(result);
  },
};

export const renewal_reminder_dismissal = {
  create: params => {
    return fetch(`${config.sevenhellApiBaseUrl}/v2/current_user/renewal_reminder_dismissal`, {
      body: JSON.stringify(params),
      method: 'POST',
    })
      .then(parse)
      .then(result);
  },
};
