import React from 'react';
import ImportantWarningIcon from '@mui/icons-material/Flag';
import InformationalProactiveIcon from '@mui/icons-material/Info';
import WarningIcon from '@mui/icons-material/Warning';
import { Chip } from '@mui/material';
import { withStyles } from '@mui/styles';
import { PolicyRuleTypeRelevance } from '@dt/horizon-api';
import { palette } from '@dt/theme';

const styles = {
  [PolicyRuleTypeRelevance.URGENT]: {
    backgroundColor: palette.red50,
    color: palette.red30,
  },
  [PolicyRuleTypeRelevance.IMPORTANT]: {
    backgroundColor: palette.yellow50,
    color: palette.yellow10,
  },
  [PolicyRuleTypeRelevance.PROACTIVE]: {
    backgroundColor: palette.blue50,
    color: palette.blue30,
  },
  /* No dedicated style yet */
  [PolicyRuleTypeRelevance.INFORMATIONAL]: {
    backgroundColor: palette.blue50,
    color: palette.blue30,
  },
  noLabel: {
    paddingLeft: 0,
  },
};

const RelevanceTag = ({ relevance, noLabel, size, classes }) => {
  const Icon =
    relevance === 'URGENT'
      ? WarningIcon
      : relevance === 'IMPORTANT'
        ? ImportantWarningIcon
        : InformationalProactiveIcon;

  const chipSize = size ? size : 'small';
  const iconSize = chipSize === 'small' ? 20 : 24;

  return (
    <Chip
      classes={{ label: noLabel && classes.noLabel, root: classes[relevance] }}
      icon={<Icon style={{ ...styles[relevance], fontSize: iconSize, paddingLeft: 2 }} />}
      label={noLabel ? null : PolicyRuleTypeRelevance[relevance]}
      size={chipSize}
      style={{
        borderRadius: 4,
      }}
    />
  );
};

export default React.memo(withStyles(styles)(RelevanceTag));
