import React, { memo } from 'react';
import { useMutation } from '@apollo/client';
import scoped_access_tokens from '@dt/graphql-support/sevenhell/scoped_access_tokens';
import Banner from './Banner';
import { SharedLinksButtonCommon } from './SharedLinksButtonCommon';

/*
 * NOTE: Use the `SharedLinksButton` facade component.
 *
 * Shared links button used to share a mobile application.
 *
 * @param mobile_application_id - Used with the api when creating a shared link
 *
 * @example
 *     <SharedLinksButtonMobileApplication mobile_application_id={mobile_application.id} />
 */
const SharedLinksButtonMobileApplicationComponent = function SharedLinksButtonMobileApplication({
  mobile_application_id,
}) {
  const [createSharedLink, { loading, error }] = useMutation(scoped_access_tokens.create_for_mobile_app_id);

  const handleOnCreate = async validity_duration => {
    const { data } = await createSharedLink({
      variables: {
        body: {
          validity_duration: validity_duration,
        },
        mobile_app_id: mobile_application_id,
      },
    });

    const access_token = data?.scoped_access_token_for_mobile_app_id?.access_token;
    return access_token
      ? `${window.location.origin}/mobile/share/${access_token}/app/${mobile_application_id}/findings`
      : '';
  };

  return (
    <SharedLinksButtonCommon
      error={error}
      loading={loading}
      manageShareLink="/shared-links"
      onCreate={handleOnCreate}
      title="Create a Secure Link to the App's Findings"
    >
      <Banner icon={<></>} inline status="warning">
        The secure link will allow temporary access to this application and its issues.
      </Banner>
    </SharedLinksButtonCommon>
  );
};

export const SharedLinksButtonMobileApplication = memo(SharedLinksButtonMobileApplicationComponent);
