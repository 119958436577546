import React, { memo } from 'react';
import { palette } from '@dt/theme';

function ProductIconSupplierTrust({ backgroundColor = palette.white }) {
  return (
    <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
      <path
        clipRule="evenodd"
        d="M3.15595 15.46L5.72258 14.7267C6.28831 14.5651 6.88908 14.5734 7.45014 14.7505L11.0773 15.896C11.3696 15.9883 11.6742 16.0352 11.9807 16.0352H16.4786C16.801 16.0352 17.1213 15.9832 17.4272 15.8813L21.028 14.681C21.4129 14.5527 21.8346 14.599 22.1824 14.8078C23.0957 15.3557 23.0784 16.6851 22.1512 17.2092L13.3895 22.1615C12.6902 22.5567 11.8616 22.655 11.0892 22.4343L5.71133 20.8978C5.26782 20.7711 4.79827 20.7669 4.35259 20.8858C2.73235 21.3179 1.14282 20.0967 1.14282 18.4198V18.1289C1.14282 16.8896 1.96435 15.8005 3.15595 15.46ZM11.5183 17.0728H15.02C15.6647 17.0728 16.1873 17.5954 16.1873 18.24C16.1873 18.8847 15.6647 19.4073 15.02 19.4073H9.18382V19.926H15.02C15.9512 19.926 16.706 19.1712 16.706 18.24C16.706 17.3089 15.9512 16.554 15.02 16.554H11.5183V17.0728Z"
        fill={backgroundColor}
        fillRule="evenodd"
      />
      <path
        clipRule="evenodd"
        d="M6.31287 1.13303H17.687V12.5072H6.31287V1.13303ZM14.068 2.16705H9.93192V4.23507H14.068V2.16705ZM9.41491 1.65004V4.75208H14.585V1.65004H9.41491ZM16.653 9.92214H14.585V10.9562H16.653V9.92214Z"
        fill={backgroundColor}
        fillRule="evenodd"
      />
    </svg>
  );
}

export default memo(ProductIconSupplierTrust);
