import { values } from '@dt/functions';
import { createSelector } from 'reselect';
import { createObjectSelector } from 'reselect-map';
import { getCurrentSearchId, getResultsOfSearch } from '../inventory/search/selectors';
import { getAllNetworkServices } from '../network_services/selectors';
import { openapi_definitions } from '../openapi_definitions/selectors';
import { getAllTasksWithAffectedComponents } from '../policy_violations/selectors';
import { decorate, restfulApis } from './util';

const idFromProps = (_, props = {}) => props.id;

export const getRestfulAPIFromId = createSelector(
  restfulApis,
  idFromProps,
  openapi_definitions,
  getAllNetworkServices,
  getAllTasksWithAffectedComponents,

  (apis, id, openapi_definitions, network_services_decorated, policy_violations_decorated) => {
    if (!id) {
      throw new Error('id is required for getRestfulAPIFromId selector');
    }

    const api = apis.id[id];

    if (!api) {
      return null;
    }

    return decorate(api, network_services_decorated, values(openapi_definitions.id), policy_violations_decorated);
  },
);

export const getRestfulAPIsObj = createObjectSelector(
  state => state.restful_apis.id,
  openapi_definitions,
  getAllNetworkServices,
  getAllTasksWithAffectedComponents,

  (api, openapi_definitions, network_services_decorated, policy_violations_decorated) =>
    decorate(api, network_services_decorated, values(openapi_definitions.id), policy_violations_decorated),
);

export const getRestfulAPIs = createSelector(
  getRestfulAPIsObj,

  apis => values(apis).filter(Boolean),
);

export const getRestfulAPIsForInventory = createSelector(
  restfulApis,
  getCurrentSearchId,
  openapi_definitions,
  getAllNetworkServices,
  getAllTasksWithAffectedComponents,

  (restful_apis, searchId, openapi_definitions, network_services_decorated, policy_violations_decorated) => {
    const apis = getResultsOfSearch(searchId, restful_apis);
    return values(apis)
      .map(api =>
        decorate(api, network_services_decorated, values(openapi_definitions.id), policy_violations_decorated),
      )
      .filter(Boolean);
  },
);

export const getAllApiOperations = createSelector(
  getRestfulAPIs,

  getRestfulAPIs => {
    const restful_apis_decorated = getRestfulAPIs;
    return restful_apis_decorated
      .flatMap(restful_api_decoreated => restful_api_decoreated.api_operations_decorated)
      .filter(Boolean);
  },
);

export const getRestfulApiFromApiOperationId = createSelector(
  getAllApiOperations,
  (_, props = {}) => props.id,

  (getAllApiOperations, idFromProps) => {
    const api_operations_decorated = getAllApiOperations;
    const id = idFromProps;

    return api_operations_decorated.find(api_operation_decorated => api_operation_decorated.id === id);
  },
);
