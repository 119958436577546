import React from 'react';
import { Button } from '@mui/material';
// @ts-ignore: @reach/router types
import { Link, useLocation } from '@reach/router';
import NavigationItemContent from './NavigationItemContent';
const NavigationItem = ({ baseUrl, label, to, Icon, isSvg = false, isButton = false, disabled = false, tooltip = false, onClick, }) => {
    const location = useLocation();
    let isCurrent = false;
    if (baseUrl && location.pathname.indexOf(baseUrl) > -1) {
        let condition = true;
        if (baseUrl.includes('devsecops') && location.pathname.includes('stanford-dish-security-university')) {
            condition = baseUrl.includes('stanford-dish-security-university');
        }
        isCurrent = condition;
    }
    if (isButton) {
        return (React.createElement(Button, { "aria-label": 'navigation-menu', disabled: disabled, onClick: onClick, style: {
                background: 'none',
                border: 'none',
                cursor: 'pointer',
                margin: 0,
                minWidth: 0,
                padding: 0,
            } },
            React.createElement(NavigationItemContent, { Icon: Icon, isCurrent: isCurrent, isSvg: isSvg, label: label, tooltip: tooltip })));
    }
    if (disabled) {
        return (React.createElement(NavigationItemContent, { Icon: Icon, disabled: true, isCurrent: isCurrent, isSvg: isSvg, label: label, tooltip: tooltip }));
    }
    return (React.createElement(Link, { to: disabled ? '' : to },
        React.createElement(NavigationItemContent, { Icon: Icon, isCurrent: isCurrent, isSvg: isSvg, label: label, tooltip: tooltip })));
};
export default NavigationItem;
