import React, { memo } from 'react';
import CloudIcon from '@mui/icons-material/Cloud';
import ApiIcon from '@mui/icons-material/DeviceHub';
import { Avatar, Checkbox, List, ListItem, ListItemIcon } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ThirdPartyAssetTypeGroupEnumValues } from '@dt/graphql-support/enums';
import { Text } from '@dt/material-components';
import SdkIcon from '@dt/material-components/svg/sdk.svg';
import { palette } from '@dt/theme';

const useStyles = makeStyles(theme => ({
  itemLogo: {
    justifyContent: 'center',
  },
  list: {
    padding: 0,
    paddingLeft: theme.spacing(3),
  },
  mainList: {
    maxHeight: 350,
    overflow: 'auto',
    overflowX: 'auto',
  },
}));

const TableFiltersAssetTypeGroup = ({ selectedList, onChange }) => {
  const classes = useStyles();

  const handleChange = id => {
    if (selectedList.includes(id)) {
      onChange(selectedList.filter(item => item !== id));
    } else {
      onChange(selectedList.concat(id));
    }
  };

  return (
    <List className={classes.mainList} component="nav">
      <ListItem
        button
        dense
        onClick={() => handleChange(ThirdPartyAssetTypeGroupEnumValues.API)}
        selected={selectedList.includes(ThirdPartyAssetTypeGroupEnumValues.API)}
      >
        <Checkbox checked={Boolean(selectedList.includes(ThirdPartyAssetTypeGroupEnumValues.API))} />
        <ListItemIcon className={classes.itemLogo}>
          <Avatar style={{ height: 24, width: 24 }}>
            <ApiIcon style={{ fill: palette.gray20, fontSize: 16 }} />
          </Avatar>
        </ListItemIcon>
        <Text component={'div'} noWrap variant={'body'}>
          Third Party APIs
        </Text>
      </ListItem>

      <ListItem
        button
        dense
        onClick={() => handleChange(ThirdPartyAssetTypeGroupEnumValues.MOBILE_SDK)}
        selected={selectedList.includes(ThirdPartyAssetTypeGroupEnumValues.MOBILE_SDK)}
      >
        <Checkbox checked={Boolean(selectedList.includes(ThirdPartyAssetTypeGroupEnumValues.MOBILE_SDK))} />
        <ListItemIcon className={classes.itemLogo}>
          <Avatar style={{ height: 24, width: 24 }}>
            <img alt="mobile-sdk-icon" height={16} src={SdkIcon} />
          </Avatar>
        </ListItemIcon>
        <Text component={'div'} noWrap variant={'body'}>
          Third Party OSS/SDKs
        </Text>
      </ListItem>

      <ListItem
        button
        dense
        onClick={() => handleChange(ThirdPartyAssetTypeGroupEnumValues.CLOUD_RESOURCE)}
        selected={selectedList.includes(ThirdPartyAssetTypeGroupEnumValues.CLOUD_RESOURCE)}
      >
        <Checkbox checked={Boolean(selectedList.includes(ThirdPartyAssetTypeGroupEnumValues.CLOUD_RESOURCE))} />
        <ListItemIcon className={classes.itemLogo}>
          <Avatar style={{ height: 24, width: 24 }}>
            <CloudIcon style={{ fill: palette.gray20, fontSize: 16 }} />
          </Avatar>
        </ListItemIcon>

        <Text component={'div'} noWrap variant={'body'}>
          Third Party Cloud Resource
        </Text>
      </ListItem>
    </List>
  );
};

export default memo(TableFiltersAssetTypeGroup);
