import { shared_links } from '@dt/horizon-api';
import { paginationBeginRequest, paginationEndRequest } from '@dt/pagination';
import { callPromise } from '@dt/redux-saga-wrapped-effects';
import { call, putResolve } from 'redux-saga/effects';
import { handleNormalizedResponse, withCache } from '../resource_fetch/sagas';
import resourceType from './resourceType';

export const getSharedLinks = withCache('shared_links', function* getSharedLinks(params) {
  yield putResolve(paginationBeginRequest(resourceType, params));
  const response = yield* callPromise(shared_links.list);

  if (response._type === 'error') {
    throw new Error(response.title);
  }

  yield call(handleNormalizedResponse, response.body);
  if (response.body.pagination_information) {
    yield putResolve(paginationEndRequest(resourceType, params, response.body.pagination_information));
  }
});
