import { useParseAssetTagFilters } from '@dt/components';
import { palette } from '@dt/theme';
import { Chip } from '@mui/material';
import { withStyles } from '@mui/styles';
import React, { memo } from 'react';

const FilterChip = withStyles({
  deleteIcon: {
    '&:hover': {
      color: palette.gray30,
    },
    color: palette.gray20,
  },
  root: {
    background: palette.white,
    border: `0.5px solid ${palette.gray20}`,
    color: palette.gray20,
  },
})(Chip);

const AssetFilterChips = ({ filters, readOnlyFilters, onResetFilter }) => {
  const assetTagsFilter = useParseAssetTagFilters(filters?.filter_by_asset_tags);

  return (
    <>
      {filters.filter_by_text && (
        <FilterChip
          disabled={Boolean(readOnlyFilters.filter_by_text)}
          label={`keyword = ${filters.filter_by_text}`}
          onDelete={() => {
            const { filter_by_text, ...rest } = filters;
            onResetFilter({ ...rest });
          }}
          size={'small'}
        />
      )}

      {filters.filter_by_external_id && (
        <FilterChip
          disabled={Boolean(readOnlyFilters.filter_by_external_id)}
          label={`external ID = ${filters.filter_by_external_id}`}
          onDelete={() => {
            const { filter_by_external_id, ...rest } = filters;
            onResetFilter({ ...rest });
          }}
          size={'small'}
        />
      )}

      {/* asset type filter chip */}
      {filters.filter_by_asset_type && filters.filter_by_asset_type[0] && (
        <FilterChip
          disabled={Boolean(readOnlyFilters.filter_by_asset_type)}
          label={`Asset Type =
            "${filters.filter_by_asset_type[0]?.split('_').join(' ') || ''}"
            ${
              (Number(filters.filter_by_asset_type?.length) > 1 &&
                `+ ${Number(filters.filter_by_asset_type?.length) - 1} more`) ||
              ''
            }
          `}
          onDelete={() => {
            const { filter_by_asset_type, ...rest } = filters;
            onResetFilter({ ...rest });
          }}
          size={'small'}
        />
      )}

      {/* asset type filter chip */}
      {filters.filter_by_related_first_party_asset_type && filters.filter_by_related_first_party_asset_type[0] && (
        <FilterChip
          disabled={Boolean(readOnlyFilters.filter_by_related_first_party_asset_type)}
          label={`Asset Type =
            "${filters.filter_by_related_first_party_asset_type[0]?.split('_').join(' ') || ''}"
            ${
              (Number(filters.filter_by_related_first_party_asset_type?.length) > 1 &&
                `+ ${Number(filters.filter_by_related_first_party_asset_type?.length) - 1} more`) ||
              ''
            }
          `}
          onDelete={() => {
            const { filter_by_related_first_party_asset_type, ...rest } = filters;
            onResetFilter({ ...rest });
          }}
          size={'small'}
        />
      )}

      {/* discovered via filter chip */}
      {filters.filter_by_discovered_via && filters.filter_by_discovered_via[0] && (
        <FilterChip
          disabled={Boolean(readOnlyFilters.filter_by_discovered_via)}
          label={`Discovery Source =
            "${filters.filter_by_discovered_via[0].split('_').join(' ') || ''}"
            ${
              (Number(filters.filter_by_discovered_via?.length) > 1 &&
                `+ ${Number(filters.filter_by_discovered_via?.length) - 1} more`) ||
              ''
            }
          `}
          onDelete={() => {
            const { filter_by_discovered_via, ...rest } = filters;
            onResetFilter({ ...rest });
          }}
          size={'small'}
        />
      )}

      {/* hosted on filter chip */}
      {filters.filter_by_cloud_provider && filters.filter_by_cloud_provider[0] && (
        <FilterChip
          disabled={Boolean(readOnlyFilters.filter_by_cloud_provider)}
          label={`Hosted On =
            "${filters.filter_by_cloud_provider[0].split('_').join(' ') || ''}"
            ${
              (Number(filters.filter_by_cloud_provider?.length) > 1 &&
                `+ ${Number(filters.filter_by_cloud_provider?.length) - 1} more`) ||
              ''
            }
          `}
          onDelete={() => {
            const { filter_by_cloud_provider, ...rest } = filters;
            onResetFilter({ ...rest });
          }}
          size={'small'}
        />
      )}

      {/* asset group filter chip */}
      {filters.filter_by_asset_group_id && (
        <FilterChip
          disabled={Boolean(readOnlyFilters.filter_by_asset_group_id)}
          label={`Asset Group ID = ${filters.filter_by_asset_group_id[0] || ''}
            ${
              (Number(filters.filter_by_asset_group_id?.length) > 1 &&
                `+ ${Number(filters.filter_by_asset_group_id?.length) - 1} more`) ||
              ''
            }`}
          onDelete={() => {
            const { filter_by_asset_group_id, ...rest } = filters;
            onResetFilter({ ...rest });
          }}
          size={'small'}
        />
      )}

      {/* Shadow Assets Filter Chip */}
      {filters.filter_by_is_shadow && (
        <FilterChip
          disabled={Boolean(readOnlyFilters.filter_by_is_shadow)}
          label={`shadow assets = on`}
          onDelete={() => {
            const { filter_by_is_shadow, ...rest } = filters;
            onResetFilter({ ...rest });
          }}
          size={'small'}
        />
      )}

      {/* Mobile platform */}
      {filters.filter_by_platform && (
        <FilterChip
          disabled={Boolean(readOnlyFilters.filter_by_platform)}
          label={`Platform = ${filters.filter_by_platform[0] || ''}
            ${
              (Number(filters.filter_by_platform?.length) > 1 &&
                `+ ${Number(filters.filter_by_platform?.length) - 1} more`) ||
              ''
            }`}
          onDelete={() => {
            const { filter_by_platform, ...rest } = filters;
            onResetFilter({ ...rest });
          }}
          size={'small'}
        />
      )}

      {filters.filter_by_active_protection_status && (
        <FilterChip
          disabled={Boolean(readOnlyFilters.filter_by_active_protection_status)}
          label={`Active Protection Status = ${filters.filter_by_active_protection_status[0] || ''}
            ${
              (Number(filters.filter_by_active_protection_status?.length) > 1 &&
                `+ ${Number(filters.filter_by_active_protection_status?.length) - 1} more`) ||
              ''
            }`}
          onDelete={() => {
            const { filter_by_active_protection_status, ...rest } = filters;
            onResetFilter({ ...rest });
          }}
          size={'small'}
        />
      )}

      {filters.filter_by_vulnerable_to_toolkit_hacks && (
        <FilterChip
          disabled={Boolean(readOnlyFilters.filter_by_vulnerable_to_toolkit_hacks)}
          label={`Hack Status = ${filters.filter_by_vulnerable_to_toolkit_hacks[0] || ''}
            ${
              (Number(filters.filter_by_vulnerable_to_toolkit_hacks?.length) > 1 &&
                `+ ${Number(filters.filter_by_vulnerable_to_toolkit_hacks?.length) - 1} more`) ||
              ''
            }`}
          onDelete={() => {
            const { filter_by_vulnerable_to_toolkit_hacks, ...rest } = filters;
            onResetFilter({ ...rest });
          }}
          size={'small'}
        />
      )}

      {/* Shadow Assets Filter Chip */}
      {assetTagsFilter.list()?.map((assetTag, idx) => (
        <FilterChip
          disabled={Boolean(readOnlyFilters.filter_by_asset_tags)}
          key={idx}
          label={`asset tag = ${assetTag.key}${assetTag.value ? `:${assetTag.value}` : ''}`}
          onDelete={() => {
            const { filter_by_asset_tags, ...rest } = filters;
            onResetFilter({
              ...rest,
              filter_by_asset_tags: assetTagsFilter.remove(assetTag),
            });
          }}
          size={'small'}
        />
      ))}

      {/* Mobile Phishing Filters */}
      {filters.filter_by_discovered_application_keyword && (
        <FilterChip
          disabled={Boolean(readOnlyFilters.filter_by_discovered_application_keyword)}
          label={`Keyword = ${filters.filter_by_discovered_application_keyword}`}
          onDelete={() => {
            const { filter_by_discovered_application_keyword, ...rest } = filters;
            onResetFilter({ ...rest });
          }}
          size={'small'}
        />
      )}

      {filters.filter_by_infringement_level && filters.filter_by_infringement_level[0] && (
        <FilterChip
          disabled={Boolean(readOnlyFilters.filter_by_infringement_level)}
          label={`Fraudulent behavior =
            "${filters.filter_by_infringement_level[0]?.split('_').join(' ') || ''}"
            ${
              (Number(filters.filter_by_infringement_level?.length) > 1 &&
                `+ ${Number(filters.filter_by_infringement_level?.length) - 1} more`) ||
              ''
            }
          `}
          onDelete={() => {
            const { filter_by_infringement_level, ...rest } = filters;
            onResetFilter({ ...rest });
          }}
          size={'small'}
        />
      )}

      {filters.filter_by_discovered_app_status && filters.filter_by_discovered_app_status[0] && (
        <FilterChip
          disabled={Boolean(readOnlyFilters.filter_by_discovered_app_status)}
          label={`App status =
            "${filters.filter_by_discovered_app_status[0]?.split('_').join(' ') || ''}"
            ${
              (Number(filters.filter_by_discovered_app_status?.length) > 1 &&
                `+ ${Number(filters.filter_by_discovered_app_status?.length) - 1} more`) ||
              ''
            }
          `}
          onDelete={() => {
            const { filter_by_discovered_app_status, ...rest } = filters;
            onResetFilter({ ...rest });
          }}
          size={'small'}
        />
      )}

      {filters.filter_by_triaging_decision && filters.filter_by_triaging_decision[0] && (
        <FilterChip
          disabled={Boolean(readOnlyFilters.filter_by_triaging_decision)}
          label={`Triaging decision =
              "${filters.filter_by_triaging_decision[0]?.split('_').join(' ') || ''}"
              ${
                (Number(filters.filter_by_triaging_decision?.length) > 1 &&
                  `+ ${Number(filters.filter_by_triaging_decision?.length) - 1} more`) ||
                ''
              }
            `}
          onDelete={() => {
            const { filter_by_triaging_decision, ...rest } = filters;
            onResetFilter({ ...rest });
          }}
          size={'small'}
        />
      )}

      {/* Policy Rules */}
      {filters.filter_by_severity && filters.filter_by_severity[0] && (
        <FilterChip
          disabled={Boolean(readOnlyFilters.filter_by_severity)}
          label={`Severity =
                "${filters.filter_by_severity[0]?.split('_').join(' ') || ''}"
                ${
                  (Number(filters.filter_by_severity?.length) > 1 &&
                    `+ ${Number(filters.filter_by_severity?.length) - 1} more`) ||
                  ''
                }
              `}
          onDelete={() => {
            const { filter_by_severity, ...rest } = filters;
            onResetFilter({ ...rest });
          }}
          size={'small'}
        />
      )}

      {filters.filter_by_has_policy_violations_with_status &&
        filters.filter_by_has_policy_violations_with_status[0] && (
          <FilterChip
            disabled={Boolean(readOnlyFilters.filter_by_has_policy_violations_with_status)}
            label={`Policy Violation Status =
                  "${filters.filter_by_has_policy_violations_with_status[0]?.split('_').join(' ') || ''}"
                  ${
                    (Number(filters.filter_by_has_policy_violations_with_status?.length) > 1 &&
                      `+ ${Number(filters.filter_by_has_policy_violations_with_status?.length) - 1} more`) ||
                    ''
                  }
                `}
            onDelete={() => {
              const { filter_by_has_policy_violations_with_status, ...rest } = filters;
              onResetFilter({ ...rest });
            }}
            size={'small'}
          />
        )}

      {/* Vendors Filter Chip */}
      {filters.filter_by_uses_third_party_vendor_id && (
        <FilterChip
          disabled={Boolean(readOnlyFilters.filter_by_uses_third_party_vendor_id)}
          label={`Vendors = ${Number(filters.filter_by_uses_third_party_vendor_id?.length)} vendors`}
          onDelete={() => {
            const { filter_by_uses_third_party_vendor_id, ...rest } = filters;
            onResetFilter({ ...rest });
          }}
          size={'small'}
        />
      )}

      {/* Web/Mobile Apps Filter Chip */}
      {filters.filter_by_related_first_party_asset_id && (
        <FilterChip
          disabled={Boolean(readOnlyFilters.filter_by_related_first_party_asset_id)}
          label={`Web/Mobile Apps = ${Number(filters.filter_by_related_first_party_asset_id?.length)} apps`}
          onDelete={() => {
            const { filter_by_related_first_party_asset_id, ...rest } = filters;
            onResetFilter({ ...rest });
          }}
          size={'small'}
        />
      )}

      {/* Third Party Asset Type Filter Chip */}
      {filters.filter_by_asset_type_group && (
        <FilterChip
          disabled={Boolean(readOnlyFilters.filter_by_asset_type_group)}
          label={`Third Party Asset Type = ${Number(filters.filter_by_asset_type_group?.length)} Asset Types`}
          onDelete={() => {
            const { filter_by_asset_type_group, ...rest } = filters;
            onResetFilter({ ...rest });
          }}
          size={'small'}
        />
      )}

      {filters.filter_by_subscription && filters.filter_by_subscription[0] && (
        <FilterChip
          disabled={Boolean(readOnlyFilters.filter_by_subscription)}
          label={`Subscription =
                      "${filters.filter_by_subscription[0]?.split('_').join(' ') || ''}"
                      ${
                        (Number(filters.filter_by_subscription?.length) > 1 &&
                          `+ ${Number(filters.filter_by_subscription?.length) - 1} more`) ||
                        ''
                      }
                    `}
          onDelete={() => {
            const { filter_by_subscription, ...rest } = filters;
            onResetFilter({ ...rest });
          }}
          size={'small'}
        />
      )}

      {filters.filter_by_belongs_to_products && filters.filter_by_belongs_to_products[0] && (
        <FilterChip
          disabled={Boolean(readOnlyFilters.filter_by_belongs_to_products)}
          label={`Products =
                    "${filters.filter_by_belongs_to_products[0]?.split('_').join(' ') || ''}"
                    ${
                      (Number(filters.filter_by_belongs_to_products?.length) > 1 &&
                        `+ ${Number(filters.filter_by_belongs_to_products?.length) - 1} more`) ||
                      ''
                    }
                  `}
          onDelete={() => {
            const { filter_by_belongs_to_products, ...rest } = filters;
            onResetFilter({ ...rest });
          }}
          size={'small'}
        />
      )}
    </>
  );
};

export default memo(AssetFilterChips);
