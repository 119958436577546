import React from 'react';
import ImportantWarningIcon from '@mui/icons-material/Flag';
import InformationalProactiveIcon from '@mui/icons-material/Info';
import ResolvedResolutionIcon from '@mui/icons-material/ThumbUp';
import UrgentCriticalIcon from '@mui/icons-material/Warning';
import { Label } from '@dt/material-components';
import { oldPaletteToNewPaletteMap } from '@dt/theme';

/*
 * Shows the user an icon for a policy violations status.
 *
 * @deprecated - Use PolicyViolationsStatusesBadge or PolicyViolationsStatusesIcon instead.
 */
export const PolicyViolationsStatusesBadgeIconImage = ({ type, huge, tiny, background, color }) => {
  const size = huge ? 48 : tiny ? 12 : 18;

  const props = {
    style: {
      color: color ? color : oldPaletteToNewPaletteMap[type],
      height: size,
      width: size,
    },
  };

  if (type === 'bad') {
    return (
      <Label variant={background || 'red'}>
        <UrgentCriticalIcon {...props} />
      </Label>
    );
  } else if (type === 'warning') {
    return (
      <Label variant={background || 'yellow'}>
        <ImportantWarningIcon {...props} />
      </Label>
    );
  } else if (type === 'info') {
    return (
      <Label variant={background || 'blue'}>
        <InformationalProactiveIcon {...props} />
      </Label>
    );
  } else if (type === 'good') {
    return (
      <Label variant={background || 'green'}>
        <ResolvedResolutionIcon {...props} />
      </Label>
    );
  } else if (type === 'neutral') {
    return (
      <Label variant={background || 'gray'}>
        <ResolvedResolutionIcon {...props} />
      </Label>
    );
  } else {
    throw new Error('invalid type prop passed to PolicyViolationIcon');
  }
};
